import { ReactNode } from "react";

import { cx } from "utils";


export default function MaxWidthPage({ className, fullWidth = true, children }: { className?: string, fullWidth?: boolean, children: ReactNode }) {
  return (
    <div className={ cx(className, "flex justify-center") }>
      <div className={ cx("max-w-[1400px]", fullWidth && "w-full") }>
        { children }
      </div>
    </div>
  );
};
