import ProjectGridView from "components/hosted-scraping/ProjectGridView";
import ProjectListView from "components/hosted-scraping/ProjectListView";

import { ProjectConfigWithStats, useHostedScrapingProjects } from "providers/HostedScrapingProvider";
import { ReactComponent as ProjectGridIcon } from "assets/icons/project-grid.svg";
import { ReactComponent as ProjectListIcon } from "assets/icons/project-list.svg";
import EmptyProjectListView from "components/hosted-scraping/EmptyProjectListView";
import EmptyProjectGridView from "components/hosted-scraping/EmptyProjectGridView";
import { useLocalStorage } from "hooks/useLocalStorage";
import { FullWidthLoadingSpinner } from "components/FullWidthLoadingSpinner";
import { useCustomerIO } from "providers/CustomerIOProvider";
import BorderedPage from "components/BorderedPage";
import { useMemo } from "react";
import { SortByListBox } from "components/hosted-scraping/edit-project-components/SortByListBox";

type ProjectViewType = 'grid'|'list';

const COLOR_ACTIVE = '#465C6F';
const COLOR_INACTIVE = '#A1B3C1';

export const PROJECTS_TITLE = "DataPipeline Projects";

type ProjectOrder = undefined
  | 'last_run_first'
  | 'last_run_last'
  | 'last_created_first'
  | 'last_created_last'
  | 'scheduled_first'
  | 'scheduled_last';

function reverse<T>(f: (a: T, b: T) => number ): (a: T, b: T) => number {
  return (a, b) => f(b, a);
}

function composeComparators<T>(fnPrimary: (a: T, b: T) => number, fnSecondary: (a: T, b: T) => number): (a: T, b: T) => number {
  return (a, b) => {
    const winner = fnPrimary(a, b);
    return winner === 0 ? fnSecondary(a, b) : winner;
  }
}

// recently run < run a long time ago < never run
const compareByLastRun = (a: ProjectConfigWithStats, b: ProjectConfigWithStats) => {
  return a.lastRunAt === null && b.lastRunAt === null
    ? 0
    : a.lastRunAt === null
    ? -1
    : b.lastRunAt === null
    ? 1
    : new Date(a.lastRunAt).getTime() - new Date(b.lastRunAt).getTime();
}

const compareByEnabled = (a: ProjectConfigWithStats, b: ProjectConfigWithStats) => {
  return a.enabled === b.enabled ? 0 : a.enabled ? -1 : 1;
}

const compareByCreatedAt = (a: ProjectConfigWithStats, b: ProjectConfigWithStats) => {
    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
}

const orderProjects = (projects: ProjectConfigWithStats[], order: ProjectOrder) => {
  if (order === 'last_run_first') {
    return projects.sort(reverse(compareByLastRun));
  } else if (order === 'last_run_last') {
    return projects.sort(compareByLastRun);
  } else if (order === 'last_created_first') {
    return projects.sort(reverse(compareByCreatedAt));
  } else if (order === 'last_created_last') {
    return projects.sort(compareByCreatedAt);
  } else if (order === 'scheduled_first') {
    return projects.sort(composeComparators(compareByEnabled, compareByCreatedAt))
  } else if (order === 'scheduled_last') {
    return projects.sort(composeComparators(reverse(compareByEnabled), compareByCreatedAt))
  } else {
    return projects;
  }
}

/*
 * Note on the name: The PoC version was called ProjectsView and after I got the
 * design the new element got the name BeautifulProjectsView
 */

const sortByOptions = [
  {text: 'Last run first', value: 'last_run_first'},
  {text: 'Last run last', value: 'last_run_last'},
  {text: 'Last created first', value: 'last_created_first'},
  {text: 'Last created last', value: 'last_created_last'},
  {text: 'Scheduled first', value: 'scheduled_first'},
  {text: 'Scheduled last', value: 'scheduled_last'},
];

export default function BeautifulProjectsView() {
  const { projects, inProgress } = useHostedScrapingProjects();
  const [ view, setView ] = useLocalStorage<ProjectViewType>('ProjectsViewLayout','list');
  const [ projectOrder, setProjectOrder ] = useLocalStorage<ProjectOrder>('ProjectsOrder', 'last_created_first');

  const orderedProjects = useMemo(
    () => orderProjects(projects, projectOrder as ProjectOrder),
    [projects, projectOrder]
  );

  const customerIO = useCustomerIO();
  customerIO.page();

  if (inProgress && projects.length === 0) {
    return (<FullWidthLoadingSpinner />);
  }

  const projectItems =
  (view === 'grid' && orderedProjects.length > 0) ?
    (<ProjectGridView projects={orderedProjects} />)
  : (view === 'list' && orderedProjects.length > 0) ?
    (<ProjectListView projects={orderedProjects} />)
  : (view === 'grid' && orderedProjects.length === 0) ?
    (<EmptyProjectGridView/>)
  : (view === 'list' && orderedProjects.length === 0) ?
    (<EmptyProjectListView/>)
  : null;


  // TODO: The background should be bg-neutral-50 (249, 250, 251) but it's way darker than it should be
  return (
    <BorderedPage className="bg-slate-50 h-full" title={PROJECTS_TITLE}>
      <div className="flex flex-col items-start gap-5 md:px-14 pt-10 pb-24">
        <div className="w-full flex flex-row align-center items-center gap-2 justify-center">
          <div>All projects ({orderedProjects.length})</div>
          <div className="grow"></div>
          <div className="flex flex-row items-center">
            <SortByListBox className="mr-4 w-60" value={projectOrder} options={sortByOptions} callback={(newValue) => setProjectOrder(newValue as ProjectOrder)} />
            <div onClick={() => setView('grid')}>
              <ProjectGridIcon className="m-2 cursor-pointer" fill={view === 'grid' ? COLOR_ACTIVE : COLOR_INACTIVE}/>
            </div>
            <div onClick={() => setView('list')}>
              <ProjectListIcon className="m-2 cursor-pointer" fill={view === 'list' ? COLOR_ACTIVE : COLOR_INACTIVE}/>
            </div>
          </div>
        </div>
        {projectItems}
      </div>
    </BorderedPage>
  );

}
