import { forwardRef, useMemo } from "react";

import GoogleReCaptchaV2Widget from "./GoogleReCaptchaV2Widget";
import TurnstileWidget from "./TurnstileWidget";


export interface CaptchaWidgetInstance {
  reset: () => void;
}

export interface CaptchaWidgetProps {
  setCompleted?: (completed: boolean) => void;
}


const CaptchaField = forwardRef<CaptchaWidgetInstance, CaptchaWidgetProps>(({ setCompleted }: CaptchaWidgetProps, ref) => {

  const showTurnstile = useMemo(() => {
    if (!process.env.REACT_APP_SHOW_TURNSTILE_PERCENTAGE) {
      return false;
    } else {
      return (Math.random() * 100) < Number.parseInt(process.env.REACT_APP_SHOW_TURNSTILE_PERCENTAGE);
    }
  }, []);


  if (process.env.REACT_APP_TURNSTILE_SITE_KEY && showTurnstile) {
    return (
      <>
        <input type="hidden" name="token-type" value="turnstile"/>
        <TurnstileWidget
          ref={ ref }
          setCompleted={ setCompleted }
        />
      </>
    );
  } else if (process.env.REACT_APP_RECAPTCHA_V2_SITE_KEY) {
    return (
      <>
        <input type="hidden" name="token-type" value="reCaptchaV2" />
        <GoogleReCaptchaV2Widget
          ref={ ref }
          setCompleted={ setCompleted }
        />
      </>
    );
  } else {
    throw new Error("Either Google ReCaptcha or Turnstile site key should be set.");
  }

});

export default CaptchaField;
