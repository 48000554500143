import { Navigate, useLocation, useSearchParams } from "react-router-dom";

import AuthForm from "components/AuthForm";
import AuthPage, { AuthPageLink, AuthPageLinks } from "components/AuthPage";
import useNewOnboardingFeatureSwitch from "components/onboarding/useNewOnboardingFeatureSwitch";


interface InternalState {
  socialEmail: string,
  name: string,
  provider: 'google' | 'github'
  googleIdToken?: string
}

interface LocationState {
  socialEmail: string,
  name: string,
  idToken: string
}

const getInternalState = (locationState: LocationState, searchParams: any): InternalState => {
  if (locationState) {
    // google uses an internal route and passes data in the 'state' parameter
    return {
      provider: 'google',
      socialEmail: locationState.socialEmail,
      name: locationState.name,
      googleIdToken: locationState.idToken
    };
  } else if (searchParams && searchParams.has('name') && searchParams.has('email')) {
    // gitHub uses redirects, parameters come in search parameters
    return {
      provider: 'github',
      socialEmail: searchParams.get('email'),
      name: searchParams.get('name')
    };
  } else {
    return {} as InternalState;
  }
}


export default function OfferSocialSignup() {
  const location = useLocation();
  const [ searchParams ] = useSearchParams();

  const useNewOnboarding = useNewOnboardingFeatureSwitch();

  const internalState = getInternalState(location.state as LocationState, searchParams);

  if (!internalState.provider) {
    // necessary arguments are missing, redirect to login page
    return <Navigate to="/login" />;
  }

  return (
    <AuthPage
      title="Sign up"
      heading={[
        <>Welcome{internalState.name && (" " + internalState.name)},</>,
        "It looks like you don't have an account with us yet. We can create one for you using the email address below, or you can click on \"Go Back\" to select a different account."
      ]}
    >
      <AuthForm
        state={ location.state }
        readonlyEmail={ internalState.socialEmail as string }
        askForPassword={ false }
        emailButtonText="Create account"
        showTerms={ true }
        showCaptcha={ false }
        defaultErrorMessage="There was an error creating your account"
        errorToasterId="offer-social-signup-error-toast"
      >
        <input type="hidden" name="provider" value={ internalState.provider } />
        <input type="hidden" name="google-token" value={ internalState.googleIdToken } />
        { useNewOnboarding &&
          <input type="hidden" name="redirect" value="/welcome/2" />
        }
      </AuthForm>

      <AuthPageLinks>
        <AuthPageLink text="" link={{ text: "Go Back", to: "/login"}} />
        <AuthPageLink text="Having trouble signing up? Contact" link={{ to: "mailto:support@scraperapi.com", text: "support@scraperapi.com" }} />
      </AuthPageLinks>
    </AuthPage>
  );

};
