import { useUser } from "routes/dataroutes/UserData";

import { getUsername } from "utils/userUtils";


function Greeting(
  {
    title,
    content
  }: {
    title: string,
    content: string
  }
) {
  return (
    <div className="flex flex-col gap-y-2">
      <div className="text-2xl text-neutral-900 font-semibold">{ title }</div>
      <div className="text-base text-neutral-700">{ content }</div>
    </div>
  );
}

export default function Greetings() {
  const user = useUser();
  const username = getUsername(user);
  const salutation = `Hello${ username ? " " + username : "" }!`;

  if (user?.planSlug === "free") {
    if (user.isFreeTrial) {
      return (
        <Greeting
          title="Welcome to ScraperAPI!"
          content="Let's get started and make the most out of your trial."
        />
      );
    } else {
      return (
        <Greeting
          title={ salutation }
          content="Look at your statistics and let us know when you're ready to upgrade to a higher plan."
        />
      );
    }
  } else if (user?.planSlug) {
    return (
      <Greeting
        title={ salutation }
        content="What do you need today? Take a look at your usage statistics and let us know if you need help."
      />
    );
  }

  return (
    <Greeting title="Hello!" content="" />
  );
};
