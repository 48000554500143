interface IOnboardingHeaderProps {
  title: string;
  description?: string | JSX.Element;
  icon?: JSX.Element;
}


export default function OnboardingHeader(
  { title, description, icon }: IOnboardingHeaderProps
) {

  return (
    <div className="flex flex-col gap-y-3">
      <div className="text-2xl font-medium text-brandDarkest dark:text-primary-800 flex flex-row gap-x-3 items-center">
        { title }
        { icon }
      </div>
      { description && (
        <div className="text-gray dark:text-neutral-600">
          { description }
        </div>
      )}
    </div>
  );

};
