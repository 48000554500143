import { useEffect } from "react";
import { useIssues, IssueSeverity } from "providers/IssuesProvider";
import { useUser } from "routes/dataroutes/UserData";
import { UserType } from "providers/UserProvider";
import { Banner } from "./Banner";

const hasOutOfCreditsIssue = (user: UserType | null) => {
  if (user === null) {
    return undefined
  }

  if (!(user.apiCallLimit)) {
    return undefined;
  }

  const ratio = user.requestCount / user.apiCallLimit;

  // Can't dismiss these issues
  if (user.requestCount < user.apiCallLimit && ratio >= 0.8) {
    return {
      title: 'You are running low on credits. Consider renewing early, enable auto-renewal or upgrading your plan to avoid interruptions 👉 Go to [billing page|billing_page]',
      severity: 'info' as IssueSeverity,
      dismissible: false
    };
  } else if (user.requestCount >= user.apiCallLimit) {
    return {
      title: '‼️ You\'ve exhausted your credits. Please renew, enable auto-renewal or upgrade your plan to resume scraping automatically on your [billing page|billing_page]',
      severity: 'warning' as IssueSeverity,
      dismissible: false
    };
  }
  return undefined;
}

export default function IssuesBanner() {

  const { refresh, issues } = useIssues();
  const user = useUser();

  useEffect(() => {
    const controller = new AbortController();
    refresh(controller);

    return () => {
      controller.abort();
    }
  }, [ refresh ]);

  const primaryIssue = issues && issues[0];
  const outOfCreditsIssue = hasOutOfCreditsIssue(user);

  return (
    <>
      { primaryIssue && (
        <Banner title={primaryIssue.title} dismissible={primaryIssue.dismissible} severity={primaryIssue.severity}/>
      )}
      { outOfCreditsIssue && (
        <Banner title={outOfCreditsIssue.title} dismissible={outOfCreditsIssue.dismissible} severity={outOfCreditsIssue.severity}/>
      )}
    </>
  );

};
