import Button from "components/Button";

import { ReactComponent as AddNewProjectIcon } from "assets/icons/project-add-new.svg";
import { ReactComponent as EmptyListIllustration } from "assets/images/project-list-empty-illustration.svg";


const NewProjectButton = () => (
  <div className="flex flex-row h-8">
    <Button text="NEW PROJECT"
            icon={ { Icon: AddNewProjectIcon } }
            href="/projects/new"
            size="LG"
    />
  </div>
)

export default function EmptyProjectListView() {

  return (
    <div className="w-full bg-white border border-solid border-slate-200">
      <div className="p-12 w-full flex flex-col items-center gap-8">
        <EmptyListIllustration />
        <div className="text-center text-slate-600">
        <p className="mb-4">You want to extract data from URL’s or specific domains on a schedule? And you don’t want to run the code yourself?</p>
        <p>With the hosted projects you can extract data easily without running the code by yourself.</p>
        <p>All projects will be saved here, where you can monitor and manage the progress and performance, and export the collected data after each successful run.</p>
        </div>
        <NewProjectButton/>
      </div>
    </div>
  );
}
