import { ReactNode, useCallback, useEffect, useState } from "react";
import { Location, Outlet, useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import scraperApi from "api";
import { join } from "utils";

import {SupportWidget, useSupportWidget} from "hooks/useSupportWidget";

import { ChargebeePortalContextType, useChargebeePortals } from "providers/ChargebeePortalProvider";
import { useApiCall, useApiCalls } from "providers/ApiCallsProvider";

import { useFeatureSwitch } from "components/FeatureSwitch";
import Modal from "components/Modal";
import Button from "components/Button";

import { useUser } from "routes/dataroutes/UserData";


const mandatoryAddressFields = {
  first_name: "first name",
  last_name: "last name",
  line1: "address line 1",
  city: "city",
  state: "state",
  country: "country",
  zip: "zip code"
};


function ErrorDialog(
  {
    headline,
    message,
    cancelCallback,
    actionText,
    actionCallback,
    actionButtonClassName = "button button-secondary"
  }: {
    headline: string,
    message: string,
    cancelCallback: () => void,
    actionText: string,
    actionCallback: () => void,
    actionButtonClassName?: string;
  }
) {
  return (
    <Modal headline={headline}>
      <div className="flex flex-col items-center justify-center p-16 gap-x-3 gap-y-5">
        {message}
      </div>
      <div className="flex justify-end gap-x-4 items-center p-5">
        <Button
          text="Cancel"
          className="button button-secondary"
          onClick={cancelCallback}
          size="MD"
        />
        <Button
          text={actionText}
          className={ actionButtonClassName }
          onClick={actionCallback}
          size="MD"
        />
      </div>
    </Modal>
  )
}


interface ValidationError {
  title?: string;
  message: string;
  buttonText: string;
  buttonAction: (triggerRetryCallback: () => void) => any;
}

function Validator<T>(
  {
    apiCallback,
    validateCallback,
    closeModalCallback,
    supportWidget,
    userEmail,
    errorTitle,
    children
  } : {
    apiCallback: () => Promise<T>;
    validateCallback: (t: T | undefined) => ValidationError | undefined;
    closeModalCallback: () => void;
    supportWidget: SupportWidget,
    userEmail: string | undefined;
    errorTitle: string;
    children: ReactNode;
  }
) {
  const [ retries, setRetries ] = useState(0);

  const retriableApiCallback = useCallback(() => {
    return apiCallback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ retries, apiCallback ]);

  const {
    status,
    error,
    result
  } = useApiCall<T>(retriableApiCallback);

  if ((status === "not_started") || (status === "in_progress")) {
    return <></>;
  }

  if (error) {
    return <ErrorDialog
      headline={errorTitle}
      message={error.message}
      cancelCallback={closeModalCallback}
      actionText="Contact support"
      actionCallback={() => {
        supportWidget.showSupportForm(userEmail);
      }}
    />;
  }

  const validationError = validateCallback(result);

  if (validationError) {
    return <ErrorDialog
      headline={validationError.title || errorTitle}
      message={validationError.message}
      cancelCallback={closeModalCallback}
      actionText={validationError.buttonText}
      actionCallback={() => {
        validationError.buttonAction(() => { setRetries(r => r + 1); })
      }}
      actionButtonClassName="button button-primary"
    />;
  } else {
    return <>{children}</>;
  }
}

function ValidatePaymentMethod(
  {
    closeModal,
    supportWidget,
    chargebeePortals,
    userEmail,
    skip,
    children
  }: {
    closeModal: () => void;
    supportWidget: SupportWidget;
    chargebeePortals: ChargebeePortalContextType;
    userEmail: string | undefined;
    skip?: boolean;
    children: ReactNode;
  }
) {
  type PaymentMethod = {
    status: string;
  };

  const validatePaymentMethod = (paymentMethod: PaymentMethod | undefined) => {
    const validationError = (title: string | undefined, message: string, buttonText: string): ValidationError => {
      return {
        title,
        message,
        buttonText,
        buttonAction: (triggerRetryCallback) => {
          chargebeePortals.openChargebeePortal(
            "PAYMENT_SOURCES",
            {
              close: triggerRetryCallback
            }
          );
        }
      }
    };
    if (!paymentMethod) {
      return validationError("Payment Method Error", "Missing payment method", "Add payment method");
    } else if (paymentMethod.status === "expired") {
      return validationError("Payment Method Error", "Your payment method has expired", "Update payment method");
    } else if (paymentMethod.status === "invalid") {
      return validationError("Payment Method Error", "Your payment method is invalid", "Update payment method");
    }

    return undefined;
  };

  if (skip) {
    return <>{ children }</>;
  }

  return (
    <Validator
      apiCallback={scraperApi.subscription.paymentMethod}
      validateCallback={validatePaymentMethod}
      closeModalCallback={closeModal}
      supportWidget={supportWidget}
      userEmail={userEmail}
      errorTitle="Error Checking Payment Method"
    >
      {children}
    </Validator>
  );
}

function ValidateBillingAddress(
  {
    closeModal,
    supportWidget,
    chargebeePortals,
    userEmail,
    skip,
    children
  }: {
    closeModal: () => void;
    supportWidget: SupportWidget;
    chargebeePortals: ChargebeePortalContextType;
    userEmail: string | undefined;
    skip?: boolean;
    children: ReactNode
  }
) {
  type BillingAddress = {
    [key: string]: any;

    line1?: string;
    city?: string;
    state?: string;
    country?: string;
    zip?: string;
  };

  const validateBillingAddress = (billingAddress: BillingAddress | undefined) => {
    const validationError = (title: string, message: string, buttonText: string): ValidationError => {
      return {
        title,
        message,
        buttonText,
        buttonAction: (triggerRetryCallback) => {
          chargebeePortals.openChargebeePortal(
            "ADDRESS",
            {
              close: triggerRetryCallback
            }
          );
        }
      }
    };

    if (!billingAddress) {
      return validationError("Billing Address Error", "Missing billing address", "Set billing address");
    } else {
      const missingFields = [];
      for (const [ field, fieldName ] of Object.entries(mandatoryAddressFields)) {
        // backward compatibility, backend was used to sending the values only, now it sends the values combined with validation related properties
        if (!billingAddress[field] || (typeof billingAddress[field] === 'object' && !billingAddress[field].value)) {
          missingFields.push(fieldName);
        }
      }
      if (missingFields.length > 0) {
        return validationError(
          "Billing Address Error",
          `Your billing information is incomplete. Please add ${join(missingFields, ", ", " and ")} to your billing address.`,
          "Update billing address"
        );
      }
    }

    return undefined;
  };

  if (skip) {
    return <>{ children }</>;
  }

  return (
    <Validator
      apiCallback={scraperApi.subscription.billingAddress}
      validateCallback={validateBillingAddress}
      closeModalCallback={closeModal}
      supportWidget={supportWidget}
      userEmail={userEmail}
      errorTitle="Error Checking Billing Address"
    >
      {children}
    </Validator>
  );
}


export default function ProtectedBillingLayout() {

  const location = useLocation();
  const navigate = useNavigate();
  const locationState = location?.state as { backgroundLocation: Location; };

  const closeModal = useCallback(() => {
    if (locationState?.backgroundLocation) {
      navigate(locationState.backgroundLocation);
    } else {
      navigate(-1);
    }
  }, [ locationState?.backgroundLocation, navigate ]);

  const supportWidget = useSupportWidget();
  const chargebeePortals = useChargebeePortals();
  const user = useUser();

  const { addApiCallUuid, removeApiCallUuid } = useApiCalls();

  const skipCheckingPaymentSources = useFeatureSwitch("REACT_APP_NEW_SUBSCRIPTION_DIALOGS_PAYMENT_SOURCES_USERS");
  const skipCheckingBillingAddress = useFeatureSwitch("REACT_APP_NEW_SUBSCRIPTION_DIALOGS_BILLING_ADDRESS_USERS");

  useEffect(() => {
    if (!skipCheckingBillingAddress || !skipCheckingPaymentSources) {
      const validatorUuid = uuidv4();
      addApiCallUuid(validatorUuid);
      return () => {
        removeApiCallUuid(validatorUuid);
      }
    }
  }, [ addApiCallUuid, removeApiCallUuid, skipCheckingPaymentSources, skipCheckingBillingAddress ]);


  const validateProps = {
    closeModal,
    supportWidget,
    chargebeePortals,
    userEmail: user?.email
  };

  return (
    <ValidatePaymentMethod {...validateProps} skip={ skipCheckingPaymentSources }>
      <ValidateBillingAddress {...validateProps} skip={ skipCheckingBillingAddress }>
        <Outlet/>
      </ValidateBillingAddress>
    </ValidatePaymentMethod>
  );
};
