const LOCAL_STORAGE_KEY = "saOnboardingRandom";

function getUserRandom() {
  let userRandom = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (!userRandom) {
    localStorage.setItem(LOCAL_STORAGE_KEY, Math.random().toString());
    userRandom = localStorage.getItem(LOCAL_STORAGE_KEY);
  }

  return userRandom ? parseFloat(userRandom) : undefined;
}

export default function useNewOnboardingFeatureSwitch() {
  let newOnboardingEnabled = false;
  if (process.env.REACT_APP_USE_NEW_ONBOARDING?.toLowerCase() === "true") {
    const userRandom = getUserRandom();
    newOnboardingEnabled = userRandom ? userRandom < (process.env.REACT_APP_NEW_ONBOARDING_PERCENTAGE ? (parseFloat(process.env.REACT_APP_NEW_ONBOARDING_PERCENTAGE) / 100.0) : 1) : true;
  }
  return newOnboardingEnabled;
};
