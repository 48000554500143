import { ReactNode } from "react";
import { Id, toast, ToastOptions } from "react-toastify";

import { ReactComponent as CheckIcon } from "assets/icons/toaster-checkmark.svg";
import { ReactComponent as ExclamationIcon } from "assets/icons/toaster-exclamation-mark.svg";
import { ReactComponent as InfoIcon } from "assets/icons/toaster-info.svg";
import { ReactComponent as XIcon } from "assets/icons/x-icon.svg";

import { cx } from "utils";


interface StyledIconProps {
  icon: JSX.Element,
  background: string,
  border: string
}

function StyledIcon({ icon, background, border}: StyledIconProps) {
  return (
    <div className={cx(border, "border-[8px] rounded-[24px]")}>
      <div
        className={cx(background, "w-[24px] h-[24px] flex flex-col justify-center items-center rounded-[24px] p-[4px]")}>
        {icon}
      </div>
    </div>
  );
}


interface ToasterTypes {
  [index: string]: StyledIconProps;
}

const IconProps: ToasterTypes = {
  "success": {
    icon: <CheckIcon/>,
    background: "bg-green dark:bg-success-600",
    border: "border-green/[.15] dark:border-success-600/[.15]"
  },
  "info": {
    icon: <InfoIcon/>,
    background: "bg-brandLight dark:bg-info-600",
    border: "border-brandLight/[.15] dark:border-info-600/[.15]"
  },
  "warning": {
    icon: <ExclamationIcon/>,
    background: "bg-orange dark:bg-warning-600",
    border: "border-orange/[.15] dark:border-warning-600/[.15]"
  },
  "error": {
    icon: <ExclamationIcon/>,
    background: "bg-red dark:bg-error-600",
    border: "border-red/[.15] dark:border-error-600/[.15]"
  }
};


interface ToasterProps extends ToastOptions {
  title: string | ReactNode;
  description: string | ReactNode | undefined;
}


export type ToasterId = Id;

export default class Toaster {

  static success(title: string | ReactNode, description?: string | ReactNode, options?: Omit<ToastOptions, "type">): ToasterId {
    return Toaster.showToast({
      type: "success",
      title,
      description,
      ...options
    });
  }

  static info(title: string | ReactNode, description?: string | ReactNode, options?: Omit<ToastOptions, "type">): ToasterId {
    return Toaster.showToast({
      type: "info",
      title,
      description,
      ...options
    });
  }

  static warning(title: string | ReactNode, description?: string | ReactNode, options?: Omit<ToastOptions, "type">): ToasterId {
    return Toaster.showToast({
      type: "warning",
      title,
      description,
      ...options
    });
  }

  static error(title: string | ReactNode, description?: string | ReactNode, options?: Omit<ToastOptions, "type">): ToasterId {
    return Toaster.showToast({
      type: "error",
      title,
      description,
      ...options
    });
  }

  static dismiss(toastId?: Id) {
    return toast.dismiss(toastId);
  }


  private static showToast(toasterProps: ToasterProps): ToasterId {
    return toast(({closeToast}) =>
      <div className="flex flex-row justify-between content-center gap-x-4 ml-2">
        <div className="flex flex-col items-start font-sans text-brandDarkest dark:text-primary-800">
          <div className="font-semibold">{toasterProps.title}</div>
          {toasterProps.description && (
            <div className="text-sm">{toasterProps.description}</div>
          )}
        </div>
        <div className="flex flex-col self-center">
          <XIcon className="w-[10px] h-[10px] text-lightGray dark:text-neutral-500 hover:text-gray-800 dark:hover:text-neutral-800 transition-colors cursor-pointer" onClick={closeToast} />
        </div>
      </div>,
      {
        ...toasterProps,
        icon: ({ type }) => {
          return <StyledIcon {...IconProps[type]} />;
        },
        style: {
          maxWidth: "540px",
          minWidth: "400px"
        }
      }
    );
  }

};
