import { isValidQuery } from "components/hostedScrapingValidators";
import { SDEContent } from "./sdecontentTypes";

export const EbaySearchContent: SDEContent = {
  projectType: 'async_ebay_search',
  projectCategory: 'ebay',
  apiUrlFn: (apiHost: string) => {
    return `${apiHost}/structured/ebay/search`;
  },
  docsSection: '',  // TODO still missing
  docsSectionAsync: '', // TODO still missing
  inputKey: 'query',
  inputsKey: 'queries',
  validatorFunction: isValidQuery,
  newProjectEditProject: {
    inputBoxLabels: {
      listLiteralInputTitle: 'Query',
      listLiteralInputPlaceholder: 'Enter one query per line.',
    },
    inputSectionLabels: {
      inputSectionTitle: "Input queries",
      inputSectionDescription: "Enter queries in the input field or upload CSV with your query to begin scraping Ebay.",
    },
    projectContent: {
      title: 'Ebay search pages',
      description: 'Extract details from search results on Ebay. Download the results in a well-structured JSON file.',
      icon: 'ebay',
      inputTitle: 'Query',
      },
  },
  apiPlayground: {
    inputSectionLabels: {
      inputSectionTitle: 'Input query',
      inputSectionDescription: 'Enter queries to begin scraping Ebay.',
    },
    projectContent: {
      title: 'Ebay search pages',
      description: 'Extract details from search pages on Ebay.',
      icon: 'ebay',
    },
    enterInputsPlaceholder: 'sneakers',
    enterInputsMessage: 'Enter search query for scraping',
    codeViewPlaceHolder: 'Please provide a valid search query',
    dropdownTitle: 'Ebay search'
  }
};
