export const followRedirectToDisableFollowRedirect = (followRedirect: boolean | undefined): boolean | undefined => {
  switch (followRedirect) {
    case true: {
      return false;
    }
    case false: {
      return true;
    }
    case undefined: {
      return undefined;
    }
  }
}

export const disableFollowRedirectToFollowRedirect = (disableFollowRedirect: boolean | undefined): boolean | undefined => {
  switch (disableFollowRedirect) {
    case true: {
      return false;
    }
    case false: {
      return true;
    }
    case undefined: {
      return undefined;
    }
  }
}