import { MILLISECOND } from "./timeConstants";

export const compareDates = (date1: Date | string | number | undefined, date2: Date | string | number | undefined): number => {
  if (!date1 && !date2) {
    return 0;
  }
  if (!date1) {
    return -1;
  }
  if (!date2) {
    return 1;
  }
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  return d1.getTime() - d2.getTime();
};

export const dateDiff = (date1: Date | string | number | undefined, date2: Date | string | number | undefined, units: number = MILLISECOND): number => {
  if (!date1 || !date2) {
    return 0;
  }

  const d1 = new Date(date1);
  const d2 = new Date(date2);

  return Math.round((d2.getTime() - d1.getTime()) / units);
}
