export type UserAnswerType = {
  [index: string]: string[];
};

export function getUserAnswersFromLocalStorage(key: string) {
  return JSON.parse(localStorage.getItem(key) || "{}");
}

export function setUserAnswersInLocalStorage(key: string, answers: UserAnswerType) {
  localStorage.setItem(key, JSON.stringify(answers));
}

export function updateUserAnswers(answers: UserAnswerType, form: HTMLFormElement, input?: HTMLInputElement) {
  const formData = new FormData(form);
  const formQuestionIds = Array.from(formData.keys());
  for (const questionId of formQuestionIds) {
      answers[questionId] = formData.getAll(questionId) as string[];
  }

  if (input && !input.checked && !formQuestionIds.includes(input.name)) {
    // user unchecked all answers for this question
    delete answers[input.name];
  }

  return answers;
}
