type ValidationRules = {
  required?: boolean
};

export function checkValidity(value: string, rules: ValidationRules) {
  if (rules.required && (!value || (value.trim() === ""))) {
    return false;
  }

  return true;
}
