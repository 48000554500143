import React from 'react';
import { XIcon } from '@heroicons/react/outline';
import { cx } from 'utils'; // Assuming you have a utility function for class merging

interface CloseButtonProps {
  width?: number;
  height?: number;
  onPress?: () => void;
  inverse?: boolean;
}

const CloseButton: React.FC<CloseButtonProps> = ({
  width = 16,
  height = 16,
  onPress,
  inverse = false,
}) => {
  return (
    <button
      onClick={onPress}
      className={cx(
        "focus:outline-none rounded-sm p-1 transition-colors duration-200",
        inverse
          ? "text-white hover:bg-white/20 active:bg-white/30"
          : "text-neutral-500 hover:bg-neutral-50 active:bg-neutral-100 hover:text-neutral-500 active:text-neutral-600"
      )}
      aria-label="Close"
    >
      <XIcon
        width={width}
        height={height}
      />
    </button>
  );
};

export default CloseButton;