import { ApiCallConfig } from "routes/dashboard/ApiPlaygroundTypes";
import { CollectorType, NewProjectConfig } from "./types";
import { isSDEProject } from "sdecontent";

export const newProject = (userId: number, projectName: string|undefined, collectorType: CollectorType): NewProjectConfig => ({
  "userId": userId,
  "name": projectName || '',
  'scrapingInterval': 'weekly',
  // Cron will be updated later
  "cron": "0 1 * * 1",
  "enabled": true,
  "supposedToRunAt": new Date(),
  "config": {
    "type": collectorType,
    "apiParams": {
      "outputFormat": isSDEProject(collectorType) ? 'json' : undefined,
    },
  },
  "input": {
    "type": "list_literal",
    "list": '',
  },
  "output": {
    "type": "save",
  },
  notificationConfig: {
    notifyOnSuccess: 'never',
    notifyOnFailure: 'with_every_run',
    notificationChanged: new Date(),
  },
  demoProject: false
});

export const newProjectFromApiCallConfig = (userId: number, projectName: string|undefined, apiCallConfig: ApiCallConfig): NewProjectConfig => ({
  "userId": userId,
  "name": projectName || '',
  'scrapingInterval': 'weekly',
  // Cron will be updated later
  "cron": "0 1 * * 1",
  "enabled": true,
  "supposedToRunAt": new Date(),
  "config": apiCallConfig.config,
  "input": {
    "type": "list_literal",
    "list": apiCallConfig.input,
  },
  "output": {
    "type": "save",
  },
  notificationConfig: {
    notifyOnSuccess: 'never',
    notifyOnFailure: 'with_every_run',
    notificationChanged: new Date(),
  },
  demoProject: false
});
