import React from "react";
import { cx } from "utils";

import { ReactComponent as GoogleIcon } from "assets/icons/google-icon.svg";
import { ReactComponent as GitHubIcon } from "assets/icons/github-icon.svg";

import Button, { ButtonProps, IconPosition } from "components/Button";


interface SignInButtonProps extends Omit<ButtonProps, "theme" | "custom" | "customTheme"> {
  provider: "google" | "github";
}

const providerConfig: { [index: string]: { text: string, Icon: React.ComponentType<any>, iconPosition?: IconPosition }} = {
  google: {
    text: "Sign in with Google",
    Icon: GoogleIcon,
    iconPosition: "trailing"
  },
  github: {
    text: "Sign in with GitHub",
    Icon: GitHubIcon
  },
};

const SignInButton: React.FC<SignInButtonProps> = ({
  provider,
  className,
  ...props
}) => {
  const { text, Icon, iconPosition } = providerConfig[provider];

  return (
    <Button
      {...props}
      text={text}
      icon={ {
        Icon: Icon,
        style: cx("bg-white p-[9px] border-[#e8eaed]", iconPosition === "trailing" ? "rounded-r-[3px]" : "rounded-l-[3px]"),
        size: "w-[18px] h-[18px]",
        position: iconPosition ?? "leading"
      } }
      className={
        cx(
          className,
          iconPosition === "trailing" ? "pr-0.5" : "pl-0.5",
          "button button-signin"
        )
      }
    />
  );
};

export default SignInButton;
