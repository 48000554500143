export type JsonUrlInputConfig = { type: 'json_url'; url: string; };
export type ListLiteralInputConfig = { type: 'list_literal'; list: string; };
export type UploadCsvListConfig = { type: 'upload_csv_list'; inputKey: string|null; fileName: string };
export type UploadJsonListConfig = { type: 'upload_json_list'; inputKey: string|null; fileName: string };
export type WebhookInputConfig = { type: 'webhook_input'; url: string; auth?: { user: string; pass: string }; };

export type InputConnectorConfig =
  | JsonUrlInputConfig
  | ListLiteralInputConfig
  | UploadCsvListConfig
  | UploadJsonListConfig
  | WebhookInputConfig
  ;

export const hasEmptyInput = (input: InputConnectorConfig) => {
  switch (input.type) {
    case 'json_url': return !Boolean(input.url) || input.url.trim() === '';
    case 'list_literal': return !Boolean(input.list) || input.list.trim() === '';
    case 'upload_csv_list': return !Boolean(input.inputKey) || input.inputKey!.trim() === '';
    case 'upload_json_list': return !Boolean(input.inputKey) || input.inputKey!.trim() === '';
    case 'webhook_input': return !Boolean(input.url) || input.url.trim() === '';
  }
}

export type DevNullOutputConfig = { type: 'devnull'; };
export type WebhookOutputConfig = {
  type: 'webhook';
  url: string;
  webhookEncoding?: 'raw_encoding' | 'multipart_form_data_encoding';
  basicAuth?: { user: string; pass: string };
};
export type SaveOutputConfig = {
  type: 'save';
};

export type OutputConfig =
  | DevNullOutputConfig
  | WebhookOutputConfig
  | SaveOutputConfig;


export type CollectorType =
    | 'async_urls'
    | string
;

export type OutputFormat =
  // Only makes sense for SDE projects.
  | 'json'
  // Only makes sense for SDE projects. The result will be a CSV
  | 'csv'
  // Keep the original format (JSON in case of SDE)
  | undefined;

export type AsyncApiParams = {
  [index: string]: unknown;
  outputFormat?: OutputFormat;
}

export type AsyncUrls = { type: 'async_urls', apiParams: AsyncApiParams | undefined };


export type SDECollectorConfig = {
  [index: string]: unknown;
  type: string;
  apiParams?: AsyncApiParams;
};

export type CollectorConfig =
  | AsyncUrls
  | SDECollectorConfig
  ;

export type ProjectStats = Readonly<{
  lastRunAt: Date;
  credits: number;
  // creditsPerRequest: number;
  creditsInBillingPeriod: number;
  allJobsCount: number;
  doneJobsCount: number;
  errorJobsCount: number;
  cancelledJobsCount: number;
  successfulTasks: number;
  failedTasks: number;
}>

export type ProjectConfig = Readonly<{
  id: number;
  userId: number;
  name: string;
  enabled: boolean;
  scrapingInterval: ScrapingInterval;
  cron: string;
  createdAt: Date;
  supposedToRunAt: Date | undefined;
  config: CollectorConfig;
  input: InputConnectorConfig;
  output: OutputConfig;
  notificationConfig: NotificationConfig;
  demoProject: boolean;
}>;

// This is the one that is sent to the backend
export type NewProjectConfig = Omit<ProjectConfig, 'id'|'createdAt'>

export type CostCalculationProject = NewProjectConfig & { language?: string };

export type ProjectConfigWithStats = ProjectConfig & ProjectStats;

export type ResultInDoSpace = {
  savedToDoSpacesAt: Date | null;
  resultKey: string | null;
  format: 'zip' | 'zip-csv' | null | undefined;
};

export type WebhookResult = {
  url: string;
  success: boolean;
  lastTriedAt: Date;
  lastFailureMessage: string | null;
};

export type ResultData = Readonly<{
  doSpaces?: ResultInDoSpace;
  webhook?: WebhookResult;
  failureReport: { input: string; error: string }[];
  failureReportDOSpacesKey?: string;
}>;

export type Job = Readonly<{
  id: number;
  publicJobId: string;
  projectId: number;
  userId: number;
  status: 'done' | 'error' | 'running' | 'cancelled' | 'cancelling';
  message: string;
  createdAt: Date;
  startedAt: Date;
  finishedAt: Date;
  result: ResultData;
  credits: number;
  successfulTasks: number;
  inProgressTasks: number;
  // Important: This is not distinct from the set of inProgressTasks
  inProgressTasksWithAttempts: number;
  cancelledTasks: number;
  failedTasks: number;
  // The next retry of a single subtask not the whole job
  nextRetry?: Date | null;
}>;

export const countOfAllTasks = (job?: Job): number => {
  if (job === undefined) {
    return 0;
  }
  return job.successfulTasks + job.inProgressTasks + job.cancelledTasks + job.failedTasks;
}

export type NotifyOnSuccess = 'never' | 'with_every_run' | 'daily' | 'weekly' ;
export type NotifyOnFailure = 'with_every_run' | 'daily' | 'weekly' ;
export type NotificationConfig = {
  notifyOnSuccess: NotifyOnSuccess;
  notifyOnFailure: NotifyOnFailure;
  notificationChanged?: Date;
};

export type ScrapingInterval =
 | 'hourly'
 | 'daily'
 | 'weekly'
 | 'monthly'
 | 'custom'
 | 'cron'
;
