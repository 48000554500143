import { useRef } from "react";

import InputFieldWithValidation, { IInputFieldWithValidationProps } from "components/InputFieldWithValidation";


export default function CardExpiryInput({ name, validate = true, ...props }: { name?: string, validate?: boolean } & IInputFieldWithValidationProps) {

  const expiryYearRef = useRef<HTMLInputElement>(null);
  const expiryMonthRef = useRef<HTMLInputElement>(null);

  const unformatCardExpiry = (cardExpiryStr: string, cursorPosition: number | null): [ string, number ] => {
    const nonNullCursorPosition = cursorPosition || 0;
    const valueBeforeCursor = cardExpiryStr.slice(0, nonNullCursorPosition);
    const numbersBeforeCursor = valueBeforeCursor.replace(/[\D]/g, "");
    const realCursorPosition = nonNullCursorPosition - (valueBeforeCursor.length - numbersBeforeCursor.length);

    return [ (numbersBeforeCursor + cardExpiryStr.slice(nonNullCursorPosition).replace(/[\D]/g, "")).slice(0, 4), realCursorPosition ];
  };

  const formatCardExpiry = (cardExpiry: string | ReadonlyArray<string> | number | undefined, cursorPosition?: number | null): [ string, number | null ] => {
    if (typeof cardExpiry !== "string") {
      return [ "", 0 ];
    } else {
      let cursorShift = 0;
      let formattedValue = cardExpiry.slice(0, 2);
      if (expiryMonthRef.current) {
        expiryMonthRef.current.value = formattedValue;
      }
      if (cardExpiry.length > 2) {
        const expiryYear = cardExpiry.slice(2, 4);
        formattedValue += "/" + expiryYear;
        if (expiryYearRef.current) {
          expiryYearRef.current.value = expiryYear;
        }
        if (cursorPosition && (cursorPosition > 2)) {
          cursorShift++;
        }
      }

      return [ formattedValue, cursorPosition ? (cursorPosition + cursorShift) : null ];
    }
  };


  return (
    <>
      <input type="hidden" name={ (name || "expiry") + ".year" } ref={ expiryYearRef } />
      <input type="hidden" name={ (name || "expiry") + ".month" } ref={ expiryMonthRef } />
      <InputFieldWithValidation
        { ...props }
        label="Expiration date"
        type="text"
        placeholder="MM/YY"
        formatValue={ formatCardExpiry }
        unformatValue={ unformatCardExpiry }
        required={ validate }
      />
    </>
  );
};
