export const async_walmart_product = {
  input: "5253396052",
  result: "{\n" +
    "  \"product_name\": \"AT&T Samsung Galaxy S24 Ultra Titanium Violet 512GB\",\n" +
    "  \"product_description\": \"Do more with the most epic Galaxy yet. Capture every detail of your candlelight meal with impressive Nightography and zoom in to see the live violinist playing across the room.Once you’re back in your hotel, elevate your pics from good to great right on your Galaxy S24 Ultra. You can even use your built in S Pen to add fun doodles before posting. Unleash new ways to create, connect and more with Galaxy S24 Ultra. Epic, just like that.\",\n" +
    "  \"brand\": \"SAMSUNG\",\n" +
    "  \"image\": \"https://i5.walmartimages.com/seo/AT-T-Samsung-Galaxy-S24-Ultra-Titanium-Violet-512GB_784b47ee-bfb7-4543-af9d-5406c37ffed5.fa51da09222008a396f35c6e245e2e2a.jpeg\",\n" +
    "  \"offers\": [\n" +
    "    {\n" +
    "      \"url\": \"https://www.walmart.com/ip/AT-T-Samsung-Galaxy-S24-Ultra-Titanium-Violet-512GB/5253396052\",\n" +
    "      \"availability\": \"InStock\",\n" +
    "      \"available_delivery_method\": \"OnSitePickup\",\n" +
    "      \"item_condition\": \"NewCondition\"\n" +
    "    }\n" +
    "  ]\n" +
    "}"
};
