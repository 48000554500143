export const async_amazon_product = {
  input: "B07FTKQ97Q",
  result: "{\n" +
    "  \"name\": \"Animal Adventure | Sweet Seats | Pink Owl Children's Plush Chair\",\n" +
    "  \"product_information\": {\n" +
    "    \"product_dimensions\": \"14 x 19 x 20 inches\",\n" +
    "    \"color\": \"Pink\",\n" +
    "    \"material\": \"95% Polyurethane Foam, 5% Polyester Fibers\",\n" +
    "    \"style\": \"Pink Owl\",\n" +
    "    \"product_care_instructions\": \"Machine Wash\",\n" +
    "    \"number_of_items\": \"1\",\n" +
    "    \"brand\": \"Animal Adventure\",\n" +
    "    \"fabric_type\": \"Polyurethane\",\n" +
    "    \"unit_count\": \"1.0 Count\",\n" +
    "    \"item_weight\": \"1.9 pounds\",\n" +
    "    \"asin\": \"B06X3WKY59\",\n" +
    "    \"item_model_number\": \"49559\",\n" +
    "    \"manufacturer_recommended_age\": \"18 months - 4 years\",\n" +
    "    \"best_sellers_rank\": [\n" +
    "      \"#9,307 in Home & Kitchen (See Top 100 in Home & Kitchen)\",\n" +
    "      \"#69 in Kids' Furniture\"\n" +
    "    ],\n" +
    "    \"customer_reviews\": {\n" +
    "      \"ratings_count\": 5665,\n" +
    "      \"stars\": \"4.7 out of 5 stars\"\n" +
    "    },\n" +
    "    \"is_discontinued_by_manufacturer\": \"No\",\n" +
    "    \"release_date\": \"January 1, 2018\",\n" +
    "    \"manufacturer\": \"Animal Adventure\"\n" +
    "  },\n" +
    "  \"brand\": \"Visit the Animal Adventure Store\",\n" +
    "  \"brand_url\": \"https://www.amazon.com/stores/AnimalAdventure/page/8871D37C-D748-4489-8FBA-8D6A62092857?ref_=ast_bln\",\n" +
    "  \"full_description\": \"Our Sweet Seats character chairs double as adorable room décor and a child’s favorite plush pal. Rich fabrics, sweet dimensional faces and a soft yet sturdy design make these character chairs ideal for kids 18 months +, and parents will be thrilled to learn that they can easily wash and care for their child’s new favorite seat. WASHING INSTRUCTIONS: Simply take a paper clip, hook through the safety zipper and pull to open the character chair cover. Carefully cut the tack stitch thread that secures the cover to the center foam block with a pair of scissors. Once all tacks have been cut, you can remove the foam blocks. Once you’ve removed all of the inner foam pieces, your cover is ready to wash. For best results, we recommend using a delicates bag in a machine wash cold setting, and afterwards laying the cover flat to air dry.\",\n" +
    "  \"pricing\": \"\",\n" +
    "  \"list_price\": \"\",\n" +
    "  \"shipping_price\": null,\n" +
    "  \"availability_status\": \"\",\n" +
    "  \"images\": [\n" +
    "    \"https://m.media-amazon.com/images/I/51RzYQSm5oL.jpg\",\n" +
    "    \"https://m.media-amazon.com/images/I/416w6PJNGWL.jpg\",\n" +
    "    \"https://m.media-amazon.com/images/I/51vmBiGcqNL.jpg\",\n" +
    "    \"https://m.media-amazon.com/images/I/51Zm4EdynxL.jpg\",\n" +
    "    \"https://m.media-amazon.com/images/I/510JJx9LuiL.jpg\",\n" +
    "    \"https://m.media-amazon.com/images/I/51JwT+-IwnL.jpg\"\n" +
    "  ],\n" +
    "  \"product_category\": \"Home & Kitchen › Furniture › Kids' Furniture\",\n" +
    "  \"average_rating\": 4.7,\n" +
    "  \"small_description\": \"About this item \\n \\nPolyurethane Imported Plush slip cover is removable and washable. Zipper closure is child-safe (parents can easily open closer with a simple paper clip). Lightweight and easy to move. The perfect size (14\\\"L x 19\\\"W x 20\\\"H). Ages 18 months and up.\",\n" +
    "  \"feature_bullets\": [\n" +
    "    \"Polyurethane\",\n" +
    "    \"Imported\",\n" +
    "    \"Plush slip cover is removable and washable.\",\n" +
    "    \"Zipper closure is child-safe (parents can easily open closer with a simple paper clip).\",\n" +
    "    \"Lightweight and easy to move.\",\n" +
    "    \"The perfect size (14\\\"L x 19\\\"W x 20\\\"H).\",\n" +
    "    \"Ages 18 months and up.\"\n" +
    "  ],\n" +
    "  \"total_reviews\": 5665,\n" +
    "  \"total_answered_questions\": 111,\n" +
    "  \"model\": \"49559\",\n" +
    "  \"customization_options\": {\n" +
    "    \"style\": [\n" +
    "      {\n" +
    "        \"is_selected\": true,\n" +
    "        \"url\": null,\n" +
    "        \"value\": \"Pink Owl\",\n" +
    "        \"price_string\": \"\",\n" +
    "        \"price\": 0,\n" +
    "        \"image\": null\n" +
    "      },\n" +
    "      {\n" +
    "        \"is_selected\": false,\n" +
    "        \"url\": \"https://www.amazon.com/dp/B0731Y59HG/ref=twister_B0B9FV38F9?_encoding=UTF8&psc=1\",\n" +
    "        \"value\": \"Teal Owl\",\n" +
    "        \"price_string\": \"\",\n" +
    "        \"price\": 0,\n" +
    "        \"image\": null\n" +
    "      }\n" +
    "    ]\n" +
    "  },\n" +
    "  \"seller_id\": null,\n" +
    "  \"seller_name\": null,\n" +
    "  \"fulfilled_by_amazon\": null,\n" +
    "  \"fast_track_message\": \"\",\n" +
    "  \"aplus_present\": true\n" +
    "}"
};
