export const async_amazon_offer = {
  input: "B07R1P4DQG",
  result: "{\n" +
    "\t\"item\": {\n" +
    "\t\t\"name\": \"Razer Blade 15 Gaming Laptop 2019: Intel Core i7-9750H 6 Core, NVIDIA GeForce RTX 2060, 15.6\\\" FHD 1080p 144 Hz, 16GB RAM, 512GB SSD, CNC Aluminum, Chroma RGB Lighting, Thunderbolt 3\",\n" +
    "\t\t\"subtitle\": \"\",\n" +
    "\t\t\"author\": \"\",\n" +
    "\t\t\"brand\": \"Razer\",\n" +
    "\t\t\"product_information\": {\n" +
    "\t\t\t\"brand\": \"Razer\",\n" +
    "\t\t\t\"series\": \"Blade 15\",\n" +
    "\t\t\t\"screen_size\": \"15.6 Inches\",\n" +
    "\t\t\t\"color\": \"Classic Black\",\n" +
    "\t\t\t\"hard_disk_size\": \"512 GB\",\n" +
    "\t\t\t\"cpu_model\": \"Core i7 Family\",\n" +
    "\t\t\t\"ram_memory_installed_size\": \"16 GB\",\n" +
    "\t\t\t\"operating_system\": \"Windows 10\",\n" +
    "\t\t\t\"card_description\": \"Dedicated\",\n" +
    "\t\t\t\"graphics_coprocessor\": \"NVIDIA GeForce RTX 2060\"\n" +
    "\t\t},\n" +
    "\t\t\"listings_link\": \"https://www.amazon.com/gp/aod/ajax/ref=tmm_pap_used_aod_0?filters=%257B%2522all%2522%253Atrue%252C%2522usedLikeNew%2522%253Atrue%252C%2522usedVeryGood%2522%253Atrue%252C%2522usedGood%2522%253Atrue%257D&condition=used&asin=B07R1P4DQG&pc=dp;\",\n" +
    "\t\t\"image\": \"\",\n" +
    "\t\t\"average_rating\": 4.4,\n" +
    "\t\t\"total_reviews\": 697\n" +
    "\t},\n" +
    "\t\"listings\": [\n" +
    "\t\t{\n" +
    "\t\t\t\"price_with_currency\": \"\",\n" +
    "\t\t\t\"price\": null,\n" +
    "\t\t\t\"shipping_price\": null,\n" +
    "\t\t\t\"has_prime_shipping\": false,\n" +
    "\t\t\t\"ships_from\": \"Amazon.com\",\n" +
    "\t\t\t\"sold_by\": \"Amazon.com\",\n" +
    "\t\t\t\"fullfilled_by_amazon\": false\n" +
    "\t\t}\n" +
    "\t],\n" +
    "\t\"pagination\": []\n" +
    "}"
};
