import { useRef } from "react";

import { ReactComponent as VisaIcon } from "assets/icons/visa-icon.svg";
import { ReactComponent as MastercardIcon } from "assets/icons/mastercard-icon.svg";

import InputFieldWithValidation, { IInputFieldWithValidationProps } from "components/InputFieldWithValidation";


export default function CardNumberInput({ name, validate = true, ...props }: { name?: string, validate?: boolean } & IInputFieldWithValidationProps) {

  const hiddenInputRef = useRef<HTMLInputElement>(null);

  const unformatCardNumber = (cardNumberStr: string, cursorPosition: number | null): [ string, number | null ] => {
    const nonNullCursorPosition = cursorPosition || 0;
    const valueBeforeCursor = cardNumberStr.slice(0, nonNullCursorPosition);
    const numbersBeforeCursor = valueBeforeCursor.replace(/[\D]/g, "");
    const realCursorPosition = nonNullCursorPosition - (valueBeforeCursor.length - numbersBeforeCursor.length);

    return [ (numbersBeforeCursor + cardNumberStr.slice(nonNullCursorPosition).replace(/[\D]/g, "")).slice(0, 16), realCursorPosition ];
  };

  const formatCardNumber = (cardNumber: string | ReadonlyArray<string> | number | undefined, cursorPosition?: number | null): [ string, number | null ] => {
    if (typeof cardNumber !== "string") {
      return [ "", 0 ];
    } else {
      let cursorShift = 0;

      let formattedValue = cardNumber.slice(0, 4);
      if (cardNumber.length > 4) {
        formattedValue += " " + cardNumber.slice(4, 8);
        if (cursorPosition && (cursorPosition > 4)) {
          cursorShift++;
        }
      }
      if (cardNumber.length > 8) {
        formattedValue += " " + cardNumber.slice(8, 12);
        if (cursorPosition && (cursorPosition > 8)) {
          cursorShift++;
        }
      }
      if (cardNumber.length > 12) {
        formattedValue += " " + cardNumber.slice(12, 16);
        if (cursorPosition && (cursorPosition > 12)) {
          cursorShift++;
        }
      }

      return [ formattedValue, cursorPosition ? (cursorPosition + cursorShift) : null ];
    }
  };


  return (
    <>
      <input type="hidden" name={ name || "card_number" } ref={ hiddenInputRef } />
      <InputFieldWithValidation
        { ...props }
        label="Card Number"
        type="text"
        placeholder="XXXX XXXX XXXX XXXX"
        value=""
        formatValue={ formatCardNumber }
        unformatValue={ unformatCardNumber }
        unformattedInputRef={ hiddenInputRef }
        required={ validate }
        pattern={ validate ? "[\\d ]{19}" : undefined }
        patternMismatchErrorMessage="Please enter a valid card number."
        // TODO pass isValidated property, but only after the form is submitted at least once
        TrailingElement={ (
          <div className="flex flex-row gap-x-1">
            <VisaIcon />
            <MastercardIcon />
          </div>
        ) }
        inputPaddingForTrailingElement="pr-20"
      />
    </>
  );
};
