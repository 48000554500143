import _ from "lodash";
import { saveAs } from "file-saver";

import scraperApi from "api";

import { domainReportFileName, parseDateLabels } from "utils/chartsUtils";

import { ChangeLogData } from "pages/dashboard/WhatsNewCard";

import IRouterActionError from "./IRouterActionError";
import OkResponse from "./OkResponse";
import throwIfCanceled from "./throwIfCanceled";


export async function changelogLoader(): Promise<ChangeLogData | IRouterActionError> {
  try {
    const mostRecentChangelogEntries = await scraperApi.status.changelog(3);
    return mostRecentChangelogEntries;
  } catch (err) {
    throwIfCanceled(err);

    return {
      error: {
        message: (err as Error).message
      }
    };
  }
}


export async function usageHistoryLoader({ request }: { request: Request }): Promise<{ updatedAt: Date, data: any } | IRouterActionError> {
  try {
    const searchParams = new URL(request.url).searchParams;
    const domainsFilter = _.union(searchParams.getAll("domains[value]"), searchParams.getAll("domains"));
    const intervalFilter = searchParams.get("interval[value][interval]") || "30";
    const resolutionAmount = searchParams.get("interval[value][resolution][amount]") || "1";
    const resolutionUnit = searchParams.get("interval[value][resolution][unit]") || "day";

    const data = await scraperApi.stats.usageHistory(
      new URLSearchParams({ domains: domainsFilter.join(","), interval: intervalFilter, "resolution-amount": resolutionAmount, "resolution-unit": resolutionUnit }),
      { signal: request.signal }
    );

    // TODO might want to aggregate the data based on the domain filters, otherwise a stacked barchart will be rendered and the tooltip would act a bit weird
    parseDateLabels(data, undefined);

    return {
      updatedAt: new Date(),
      data: data
    };
  } catch (err) {
    throwIfCanceled(err);

    return {
      error: {
        message: (err as Error).message,
      }
    };
  }
}

export async function downloadPerDomainReportLoader(): Promise<Response | IRouterActionError> {
  try {
    const perDomainReport = await scraperApi.stats.perDomainReport({ source: "clickhouse" });
    const blob = new Blob([ perDomainReport ], { type: "text/csv;charset=utf-8" });
    saveAs(blob, domainReportFileName());
    return new OkResponse();
  } catch (err) {
    throwIfCanceled(err);

    return {
      error: {
        message: "There was an error generating the per-domain report. Please try again later or contact our support team for help."
      }
    };
  }
}
