export const async_google_shopping = {
  input: "Skateboards",
  result: "{\n" +
    "\t\"search_information\": {\n" +
    "\t\t\"query_displayed\": \"Skateboards\"\n" +
    "\t},\n" +
    "\t\"ads\": [],\n" +
    "\t\"shopping_results\": [\n" +
    "\t\t{\n" +
    "\t\t\t\"position\": 1,\n" +
    "\t\t\t\"docid\": \"5776882772502681376\",\n" +
    "\t\t\t\"link\": \"https://www.google.com/url?url=http://www.flipkart.com/iris-wood-composite-70-cm-x-20-skull-skateboard/p/itm6e5e6fb6265d7%3Fpid%3DOTYFDWCEF5Q3HWVE%26lid%3DLSTOTYFDWCEF5Q3HWVEOROGYK%26marketplace%3DFLIPKART%26cmpid%3Dcontent_outdoor-toy_8965229628_gmc&rct=j&q=&esrc=s&sa=U&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ2SkIqws&usg=AOvVaw3EHS57pNUMc3sn5Kwti4yV\",\n" +
    "\t\t\t\"title\": \"Iris Wood-composite 70 Cm X 20 Cm Skull Skateboard\",\n" +
    "\t\t\t\"source\": \"Flipkart\",\n" +
    "\t\t\t\"price\": \"₹1,539.00\",\n" +
    "\t\t\t\"extracted_price\": 1539,\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn2.gstatic.com/shopping?q=tbn:ANd9GcSheNJLtIZOR5BpHb5vd8UvS3r-iPcpOU4KhMrEn2XYjGn3VOaxsdyBY4uDfLTdt7_4nLcgh83gu0Vabg0D6Z8Huu4aBum-LmL0SEwJN5c&usqp=CAE\",\n" +
    "\t\t\t\"delivery_options\": \"+Delivery\"\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"position\": 2,\n" +
    "\t\t\t\"docid\": \"18385927778210766878\",\n" +
    "\t\t\t\"link\": \"https://www.google.com/url?url=https://www.jiomart.com/p/sportstoysluggage/ancsg-wood-skateboard/593971318%3Fsource%3Dshoppingads&rct=j&q=&esrc=s&sa=U&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ2SkItgs&usg=AOvVaw0m2hjcKfI_M9a6O7Lfapsm\",\n" +
    "\t\t\t\"title\": \"ANCSG Wood Skateboard\",\n" +
    "\t\t\t\"source\": \"JioMart\",\n" +
    "\t\t\t\"price\": \"₹549.00\",\n" +
    "\t\t\t\"extracted_price\": 549,\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn1.gstatic.com/shopping?q=tbn:ANd9GcTP_JOy79jdsWA2qoHcRFgqiGZ6EZpHjK_hryT5x8bkti8pcpJC91BDGbzXmj6mU2D_2P9NTP-ka56TQZwsPH_8MntT2tq-3g&usqp=CAE\",\n" +
    "\t\t\t\"delivery_options\": \"+Delivery\"\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"position\": 3,\n" +
    "\t\t\t\"docid\": \"7720725143416811799\",\n" +
    "\t\t\t\"link\": \"https://www.google.com/url?url=https://meshinesports.en.made-in-china.com/product/mFHGaCrlRJYA/China-Skateboard-Skateboard-Skateboard-New-Design-New-Style-Special-Maple-Complete-Skateboard.html%3Futm_source%3Dggshop%26utm_medium%3Dfeed%26utm_campaign%3Dqiao-826-1904211082%26srsltid%3DAd5pg_FKHeZdY5ge5bnAqDe73mxtZQI6sON4B0x1AsA6sxKq6S9LBsk46Io&rct=j&q=&esrc=s&sa=U&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ2SkIwQs&usg=AOvVaw2VF6dryruXSbzJXKdkxD5p\",\n" +
    "\t\t\t\"title\": \"Skateboard Skateboard Skateboard Special Maple Complete Skateboard,Skate Board ...\",\n" +
    "\t\t\t\"source\": \"Made-in-China.com\",\n" +
    "\t\t\t\"price\": \"₹806.23 + tax\",\n" +
    "\t\t\t\"extracted_price\": 806.23,\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn0.gstatic.com/shopping?q=tbn:ANd9GcS_SYZiHxF4r26Ud0szi44B-mFHCs6BhuQMFGgL8nHwCFkcKSfwqFZwFZuqoSXNFuO1iUjnBJm_gIoLtnaOSpP7rvbLq4PL568lq4tPHaolVhDh8dEHNZsa&usqp=CAE\",\n" +
    "\t\t\t\"product_href\": \"https://www.google.com/url?url=https://meshinesports.en.made-in-china.com/product/mFHGaCrlRJYA/China-Skateboard-Skateboard-Skateboard-New-Design-New-Style-Special-Maple-Complete-Skateboard.html%3Futm_source%3Dggshop%26utm_medium%3Dfeed%26utm_campaign%3Dqiao-826-1904211082%26srsltid%3DAd5pg_FKHeZdY5ge5bnAqDe73mxtZQI6sON4B0x1AsA6sxKq6S9LBsk46Io&rct=j&q=&esrc=s&sa=U&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ1SkIvwsoAA&usg=AOvVaw0nKdoOr75DOV-xz0G0632_\",\n" +
    "\t\t\t\"delivery_options\": \"Free delivery\",\n" +
    "\t\t\t\"delivery_options_extracted_price\": 0\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"position\": 4,\n" +
    "\t\t\t\"docid\": \"16484151921700876319\",\n" +
    "\t\t\t\"link\": \"https://www.google.com/url?url=http://www.flipkart.com/searegal-4-wheels-skate-boards-26-5-inch-x-23-6-skateboard/p/itm6b4f65c6673d8%3Fpid%3DSKDGFC6RS5RF82P5%26lid%3DLSTSKDGFC6RS5RF82P5FFIZ9J%26marketplace%3DFLIPKART%26cmpid%3Dcontent_skateboard_8965229628_gmc&rct=j&q=&esrc=s&sa=U&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ2SkIzQs&usg=AOvVaw09dy1EAU8TYfC6DEXDM-3Y\",\n" +
    "\t\t\t\"title\": \"Searegal 4 Wheels Skate Boards 26.5 Inch X 23.6 Inch Skateboard\",\n" +
    "\t\t\t\"source\": \"Flipkart\",\n" +
    "\t\t\t\"price\": \"₹648.00\",\n" +
    "\t\t\t\"extracted_price\": 648,\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn1.gstatic.com/shopping?q=tbn:ANd9GcSsofCHU84s-PtxVfKxZbjnbDKzaRRjW0-6ypOgcwalKF-_-qp9Rsts91_JYUb6ogopi-TzH_a9xQ8eT4f-8ADl83gIjefFIujlfq6G5cA&usqp=CAE\",\n" +
    "\t\t\t\"delivery_options\": \"+Delivery\"\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"position\": 5,\n" +
    "\t\t\t\"docid\": \"11913381444661005092\",\n" +
    "\t\t\t\"link\": \"https://www.google.com/url?url=http://www.flipkart.com/dykidrah-black-led-lights-skateboard-4-wheels-skate-boards-beginners-longboard-6-inch-x-24-skateboard/p/itmfe9a9214cca8f%3Fpid%3DSKDGAQZTRQJZN3AV%26lid%3DLSTSKDGAQZTRQJZN3AV1QLWB9%26marketplace%3DFLIPKART%26cmpid%3Dcontent_skateboard_8965229628_gmc&rct=j&q=&esrc=s&sa=U&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ2SkI2As&usg=AOvVaw2Ow0aokGeOYQSFacli51HB\",\n" +
    "\t\t\t\"title\": \"Dykidrah Black Led Lights Skateboard, 4 Wheels Skate Boards, Beginners Longboard ...\",\n" +
    "\t\t\t\"source\": \"Flipkart\",\n" +
    "\t\t\t\"price\": \"₹1,499.00\",\n" +
    "\t\t\t\"extracted_price\": 1499,\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn3.gstatic.com/shopping?q=tbn:ANd9GcTnOR7EPkAYCC-Ra2MdSq1-oUduArJFt7mPXRI4BZ1kFHl15V6ID9fJe9sqrwgtIAYV71N1KNxA_qszbVk4Lm3NBRH55wKK8Ya1VgreWddklAYLtSyUfxZX&usqp=CAE\",\n" +
    "\t\t\t\"delivery_options\": \"+Delivery\"\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"position\": 6,\n" +
    "\t\t\t\"docid\": \"15053712696883346367\",\n" +
    "\t\t\t\"link\": \"https://www.google.com/shopping/product/1451203193691734256?q=Skateboards&num=100&prds=eto:11173328889058777207_0,pid:15053712696883346367,rsk:PC_15139794746697084865&sa=X&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ8wII4ws\",\n" +
    "\t\t\t\"title\": \"Kids And Adult Skateboard 7.75 Inch CP100 - 7.75\\\" By OXELO | Decathlon\",\n" +
    "\t\t\t\"source\": \"Decathlon Sports India\",\n" +
    "\t\t\t\"price\": \"₹3,499.00\",\n" +
    "\t\t\t\"extracted_price\": 3499,\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn3.gstatic.com/shopping?q=tbn:ANd9GcT4fSBdjN5nmkDb1ESCzKtkHN5-BjZXUzo9AiVkHojKdYT0EgngXsrNlqScjBfQrBARfSsAO5BuBY-3bZGkmNNute3ybVBcCUCturm0or9wp8YELPl7zgr1&usqp=CAE\",\n" +
    "\t\t\t\"product_href\": \"https://www.google.com/shopping/product/1451203193691734256?q=Skateboards&num=100&prds=eto:11173328889058777207_0,pid:15053712696883346367,rsk:PC_15139794746697084865&sa=X&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ8gII4QsoAA\",\n" +
    "\t\t\t\"product_id\": \"1451203193691734256\",\n" +
    "\t\t\t\"rating\": 4.7,\n" +
    "\t\t\t\"delivery_options\": \"+Delivery\"\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"position\": 7,\n" +
    "\t\t\t\"docid\": \"13551780088021546611\",\n" +
    "\t\t\t\"link\": \"https://www.google.com/url?url=https://www.jiomart.com/p/sportstoysluggage/fivara-skateboard/594049662%3Fsource%3Dshoppingads&rct=j&q=&esrc=s&sa=U&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ2SkI7ws&usg=AOvVaw35Ac39KQUfKovuoAabdVBt\",\n" +
    "\t\t\t\"title\": \"Fivara Skateboard\",\n" +
    "\t\t\t\"source\": \"JioMart\",\n" +
    "\t\t\t\"price\": \"₹599.00\",\n" +
    "\t\t\t\"extracted_price\": 599,\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn0.gstatic.com/shopping?q=tbn:ANd9GcRW0F3iQCo73QloB8xdiVt3orDeKyI9CTGkuSa5utThGuh14Q71lcWwWwyB3m1BnapjrjT5NEOob960fYNfFaS8sBCSc7d7ZA&usqp=CAE\",\n" +
    "\t\t\t\"delivery_options\": \"+Delivery\"\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"position\": 8,\n" +
    "\t\t\t\"docid\": \"15682605425141625427\",\n" +
    "\t\t\t\"link\": \"https://www.google.com/shopping/product/13811812754426462414?q=Skateboards&num=100&prds=eto:5576647191159440156_0,pid:15682605425141625427&sa=X&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ8wII-gs\",\n" +
    "\t\t\t\"title\": \"Toy Gallery Skating Board Skate Board\",\n" +
    "\t\t\t\"source\": \"Jyoti Groups\",\n" +
    "\t\t\t\"price\": \"₹418.00\",\n" +
    "\t\t\t\"extracted_price\": 418,\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn1.gstatic.com/shopping?q=tbn:ANd9GcSVmrqym61YxsSmUdiKI6eow7sJs4DyBBpE2IYUxj3D7jYluWb4JAsNya58bvnUCxqP13uENT3J8kdPkp1ojuV9N5g45BmQN41m6vnikSFccKaNH7xbHZQLew&usqp=CAE\",\n" +
    "\t\t\t\"product_href\": \"https://www.google.com/shopping/product/13811812754426462414?q=Skateboards&num=100&prds=eto:5576647191159440156_0,pid:15682605425141625427&sa=X&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ8gII-AsoAA\",\n" +
    "\t\t\t\"product_id\": \"13811812754426462414\",\n" +
    "\t\t\t\"delivery_options\": \"+Delivery\"\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"position\": 9,\n" +
    "\t\t\t\"docid\": \"12463595461138960553\",\n" +
    "\t\t\t\"link\": \"https://www.google.com/url?url=https://meshinesports.en.made-in-china.com/product/aZiTdBDlXJUb/China-Skateboards-31-Inch-Complete-Skateboard-7-Layer-Canadian-Maple-Deck-Skateboard-for-Kids-and-Beginners.html%3Futm_source%3Dggshop%26utm_medium%3Dfeed%26utm_campaign%3Dqiao-826-1904496172%26srsltid%3DAd5pg_F06fbjMWw6bcUCsS_oztfg2_kJQpVo0lZ7QKcrNnNelrTzLr4Gxt0&rct=j&q=&esrc=s&sa=U&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ2SkIhQw&usg=AOvVaw3LBirqBDTkG-3chfuCNUJm\",\n" +
    "\t\t\t\"title\": \"Skateboards 31 Inch Complete Skateboard 7 Layer Canadian Maple Deck Skateboard ...\",\n" +
    "\t\t\t\"source\": \"Made-in-China.com\",\n" +
    "\t\t\t\"price\": \"₹904.95 + tax\",\n" +
    "\t\t\t\"extracted_price\": 904.95,\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn1.gstatic.com/shopping?q=tbn:ANd9GcQ1kkOIHGLDpx0PYjvvS9V5RHDxiiQBeZbha9PVTT4e1cztJXgyKyWrXn3GqeLOPRrwrj8qNEvAtwt0TYhs3l_1xk6wpv1IzSGKp8B-pdXYbtNbNAuxa-WF&usqp=CAE\",\n" +
    "\t\t\t\"product_href\": \"https://www.google.com/url?url=https://meshinesports.en.made-in-china.com/product/aZiTdBDlXJUb/China-Skateboards-31-Inch-Complete-Skateboard-7-Layer-Canadian-Maple-Deck-Skateboard-for-Kids-and-Beginners.html%3Futm_source%3Dggshop%26utm_medium%3Dfeed%26utm_campaign%3Dqiao-826-1904496172%26srsltid%3DAd5pg_F06fbjMWw6bcUCsS_oztfg2_kJQpVo0lZ7QKcrNnNelrTzLr4Gxt0&rct=j&q=&esrc=s&sa=U&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ1SkIgwwoAA&usg=AOvVaw1KErqB0j4JLQ39O25qr0jN\",\n" +
    "\t\t\t\"delivery_options\": \"Free delivery\",\n" +
    "\t\t\t\"delivery_options_extracted_price\": 0\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"position\": 10,\n" +
    "\t\t\t\"docid\": \"10015151715787745681\",\n" +
    "\t\t\t\"link\": \"https://www.google.com/url?url=http://www.flipkart.com/jaspo-rapido-wooden-skateboard-6-inch-x-27/p/itm4d0190dafcece%3Fpid%3DSKDFNTBSDPPBF6VD%26lid%3DLSTSKDFNTBSDPPBF6VD061TGA%26marketplace%3DFLIPKART%26cmpid%3Dcontent_skateboard_8965229628_gmc&rct=j&q=&esrc=s&sa=U&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ2SkIkQw&usg=AOvVaw0ErbFWFYBWACYMCgjRU4HK\",\n" +
    "\t\t\t\"title\": \"Jaspo Rapido Wooden Skateboard 6 Inch X 27 Inch Skateboard\",\n" +
    "\t\t\t\"source\": \"Flipkart\",\n" +
    "\t\t\t\"price\": \"₹1,329.00\",\n" +
    "\t\t\t\"extracted_price\": 1329,\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn0.gstatic.com/shopping?q=tbn:ANd9GcQlX7JqIqnpj_9OIlq82GA85P1aB_dGo6mooViyQbIOdrR9Uim9ESkevgI6zd7bzLo-1ut5BZp-YZLvn0ERw6ka_aPYKlkFS1yaedaARH8S&usqp=CAE\",\n" +
    "\t\t\t\"delivery_options\": \"+Delivery\"\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"position\": 11,\n" +
    "\t\t\t\"docid\": \"15361706969318667322\",\n" +
    "\t\t\t\"link\": \"https://www.google.com/url?url=https://www.meesho.com/rtable%2520Skateboard%2520Kids%2520Skate%2520Boards%2520Mini%2520Cruiser%2520Skateboard%2520Beginners%2520Long%2520Board%2520for%2520Kids%2520Adults/p/2IRS29&rct=j&q=&esrc=s&sa=U&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ2SkInAw&usg=AOvVaw2aiF_0RCgNgt6ZD52oUq98\",\n" +
    "\t\t\t\"title\": \"rtable Skateboard Kids Skate Boards Mini Cruiser Skateboard Beginners Long Board ...\",\n" +
    "\t\t\t\"source\": \"Meesho\",\n" +
    "\t\t\t\"price\": \"₹665.00\",\n" +
    "\t\t\t\"extracted_price\": 665,\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn1.gstatic.com/shopping?q=tbn:ANd9GcT8Fq32-ILafRQryiGaHA9WGCjK8lAnbH2Kgd12z1XPEgYcbjeyx6QOe_vFR7KTo2jA6Sxr9JPxGdwz6cnhcP_8xhhHduyNvaMDhcqkaHKF6lp9jPs89zaZQg&usqp=CAE\",\n" +
    "\t\t\t\"delivery_options\": \"Free delivery and free 7-day returns\",\n" +
    "\t\t\t\"delivery_options_extracted_price\": 0\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"position\": 12,\n" +
    "\t\t\t\"docid\": \"6899260624645913516\",\n" +
    "\t\t\t\"link\": \"https://www.google.com/url?url=https://www.dankiesskateboards.com/product-page/yuno-complete-skateboard&rct=j&q=&esrc=s&sa=U&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ2SkIpww&usg=AOvVaw1MJVDdvhEGN6VHC6ue1IL_\",\n" +
    "\t\t\t\"title\": \"Yuno Complete Skateboard\",\n" +
    "\t\t\t\"source\": \"Dankies Skateboards\",\n" +
    "\t\t\t\"price\": \"₹6,399.00\",\n" +
    "\t\t\t\"extracted_price\": 6399,\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn3.gstatic.com/shopping?q=tbn:ANd9GcRIT-k246DzvZmolfBhqd4C2vMEIAan6nbS0jrFB0yxOzsKTqFQoykj4OIHjli_dMCVU_EABNxpHoaWqVocBn3beSca4ROgi_-40AIRQ0qJZ24ocw3sZ-xV&usqp=CAE\",\n" +
    "\t\t\t\"delivery_options\": \"Free delivery\",\n" +
    "\t\t\t\"delivery_options_extracted_price\": 0\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"position\": 13,\n" +
    "\t\t\t\"docid\": \"11823889745359989168\",\n" +
    "\t\t\t\"link\": \"https://www.google.com/url?url=https://www.jiomart.com/p/sportstoysluggage/prospo-multicolor-skate-board/591020536%3Fsource%3Dshoppingads&rct=j&q=&esrc=s&sa=U&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ2SkIsgw&usg=AOvVaw2cNjzX7QwojjCdXLHifeh-\",\n" +
    "\t\t\t\"title\": \"PROSPO Metal Skate Board for All Age Group Adults (White, Full Size)\",\n" +
    "\t\t\t\"source\": \"JioMart\",\n" +
    "\t\t\t\"price\": \"₹1,149.00\",\n" +
    "\t\t\t\"extracted_price\": 1149,\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn0.gstatic.com/shopping?q=tbn:ANd9GcQhlt54JI7ew31CPXsWGccddfMwMkl2boKSjuL0EXYCq7n01aO8kWRAWg8HmeORkPoi-krV2MpFfxyr_BxuPBU1h8P78szMgA&usqp=CAE\",\n" +
    "\t\t\t\"delivery_options\": \"+Delivery\"\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"position\": 14,\n" +
    "\t\t\t\"docid\": \"17080677995779228306\",\n" +
    "\t\t\t\"link\": \"https://www.google.com/url?url=https://letsclickit.com/products/optimus%25C2%25AE-kids-maple-wooden-skate-board-large-24-inch-x-6-inch-for-6-yrs-to-14-yrs-beginners-intermediate-design-may-vary-as-per-availability%3Fvariant%3D44301548552438%26currency%3DINR%26utm_medium%3Dproduct_sync%26utm_source%3Dgoogle%26utm_content%3Dsag_organic%26utm_campaign%3Dsag_organic&rct=j&q=&esrc=s&sa=U&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ2SkIvQw&usg=AOvVaw2qBD6VK9Ts3T1T9Ca74LcX\",\n" +
    "\t\t\t\"title\": \"Optimus Kids Maple Wooden Skate Board Large 24 Inch x 6 Inch for 6 Yrs to 14 Yrs ...\",\n" +
    "\t\t\t\"source\": \"LetsClickIt\",\n" +
    "\t\t\t\"price\": \"₹549.00\",\n" +
    "\t\t\t\"extracted_price\": 549,\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn3.gstatic.com/shopping?q=tbn:ANd9GcTSq-b_GL_m9K0_PBp3xuLuCWiL2Xwl81qyocuR4lfmouIl4K8HF4Yw5UCO9-rdrtdL4M5Mf-Ep1_wirPPNjs3BtNHgWAFrEkof09NjmUhN&usqp=CAE\",\n" +
    "\t\t\t\"delivery_options\": \"Free delivery\",\n" +
    "\t\t\t\"delivery_options_extracted_price\": 0\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"position\": 15,\n" +
    "\t\t\t\"docid\": \"10055600249158565238\",\n" +
    "\t\t\t\"link\": \"https://www.google.com/url?url=https://meshinesports.en.made-in-china.com/product/VOcAeNlBlJUd/China-7-Layer-Canadian-Maple-Double-Kick-Concave-Skateboard-for-Kids-Youth-Teens-31-X-8-Complete-Standard-Skate-Boards-for-Beginners.html%3Futm_source%3Dggshop%26utm_medium%3Dfeed%26utm_campaign%3Dqiao-826-1905469582%26srsltid%3DAd5pg_G7kH6o2RQgrWoyMC9FJE2pqhlxiJ0f8ixvd_zkyEl0F4BeT-US9bY&rct=j&q=&esrc=s&sa=U&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ2SkIyAw&usg=AOvVaw1wHR1nb3raRxzs5AyMoajz\",\n" +
    "\t\t\t\"title\": \"7 Layer Canadian Maple Double Kick Concave Skateboard for Kids Youth Teens,31 X 8 ...\",\n" +
    "\t\t\t\"source\": \"Made-in-China.com\",\n" +
    "\t\t\t\"price\": \"₹806.23 + tax\",\n" +
    "\t\t\t\"extracted_price\": 806.23,\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn2.gstatic.com/shopping?q=tbn:ANd9GcS38Fnc57dPtk9YVcxp3SZ6nZcO4iNAeV6ABV0Yoe7hXXbJppXjzqES91JoDSKCNHA9OmO8JOH8MX5h_AiOViFiBLpC6CxonFt9zCeh6qQ5gUzniMS5lF8Z&usqp=CAE\",\n" +
    "\t\t\t\"product_href\": \"https://www.google.com/url?url=https://meshinesports.en.made-in-china.com/product/VOcAeNlBlJUd/China-7-Layer-Canadian-Maple-Double-Kick-Concave-Skateboard-for-Kids-Youth-Teens-31-X-8-Complete-Standard-Skate-Boards-for-Beginners.html%3Futm_source%3Dggshop%26utm_medium%3Dfeed%26utm_campaign%3Dqiao-826-1905469582%26srsltid%3DAd5pg_G7kH6o2RQgrWoyMC9FJE2pqhlxiJ0f8ixvd_zkyEl0F4BeT-US9bY&rct=j&q=&esrc=s&sa=U&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ1SkIxgwoAA&usg=AOvVaw0c89s6YZ2hn6LWkS_fujE6\",\n" +
    "\t\t\t\"delivery_options\": \"Free delivery\",\n" +
    "\t\t\t\"delivery_options_extracted_price\": 0\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"position\": 16,\n" +
    "\t\t\t\"docid\": \"8292023287148673252\",\n" +
    "\t\t\t\"link\": \"https://www.google.com/url?url=http://www.flipkart.com/yozonky-portable-skateboard-wheels-skate-boards-6-inch-x-4-skateboard/p/itmb0586816d73ec%3Fpid%3DSKDGKZMQUXVEXZFU%26lid%3DLSTSKDGKZMQUXVEXZFULDXTHG%26marketplace%3DFLIPKART%26cmpid%3Dcontent_skateboard_8965229628_gmc&rct=j&q=&esrc=s&sa=U&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ2SkI1Aw&usg=AOvVaw0uCWproOT8r61v58XXlMxx\",\n" +
    "\t\t\t\"title\": \"Yozonky Portable Skateboard, Wheels Skate Boards 6 Inch X 4 Inch Skateboard\",\n" +
    "\t\t\t\"source\": \"Flipkart\",\n" +
    "\t\t\t\"price\": \"₹549.00\",\n" +
    "\t\t\t\"extracted_price\": 549,\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn0.gstatic.com/shopping?q=tbn:ANd9GcSgz7jOpG8g8eneq4wYuT9LvHWJYekqeQh72xBl_QeYHsXxl8YzRoIKDxjFFTVLCrNllkZJVabrHicEd_l0dTBSWQCAHgBNtMljrWxY5PrfwjmsDsoEHEcsFQ&usqp=CAE\",\n" +
    "\t\t\t\"delivery_options\": \"+Delivery\"\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"position\": 17,\n" +
    "\t\t\t\"docid\": \"1097035244632989164\",\n" +
    "\t\t\t\"link\": \"https://www.google.com/url?url=https://www.alibaba.com/pla/skateboard-surface-custom-design-hot-selling_62566188125.html%3Fmark%3Dgoogle_shopping%26biz%3Dpla%26searchText%3DSkateboards%26product_id%3D62566188125%26seo%3D1&rct=j&q=&esrc=s&sa=U&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ2SkI3ww&usg=AOvVaw07q9KoRXJuJpUQZWjNnDwF\",\n" +
    "\t\t\t\"title\": \"skateboard urface custom,1 Piece\",\n" +
    "\t\t\t\"source\": \"Alibaba.com\",\n" +
    "\t\t\t\"price\": \"₹1,053.03 + tax\",\n" +
    "\t\t\t\"extracted_price\": 1053.03,\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn3.gstatic.com/shopping?q=tbn:ANd9GcST4dWFqeKFv_gvVc_xvzLxjtnGvpnkdAWiGB8U4jHMW95UiRQ9FV-ZHuqPRVBJ8Uz1-grzpTMlv_8C7Ol6CvC-p6E1fLD13wyME-sMquY3AnbGqiI-yBTN&usqp=CAE\",\n" +
    "\t\t\t\"delivery_options\": \"₹6,226.87 delivery\",\n" +
    "\t\t\t\"delivery_options_extracted_price\": 6226.87\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"position\": 18,\n" +
    "\t\t\t\"docid\": \"9653421967685376887\",\n" +
    "\t\t\t\"link\": \"https://www.google.com/url?url=https://www.jiomart.com/p/sportstoysluggage/beleev-skateboards-for-beginners-24-x-6-complete-skateboard-for-kids-teens-adults/592196814%3Fsource%3Dshoppingads&rct=j&q=&esrc=s&sa=U&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ2SkI6ww&usg=AOvVaw2FQLeZfUWWq74Hwlvd6qJS\",\n" +
    "\t\t\t\"title\": \"BELEEV Skateboards for Beginners, 24\\\"x 6\\\" Complete Skateboard for Kids Teens & Adults\",\n" +
    "\t\t\t\"source\": \"JioMart\",\n" +
    "\t\t\t\"price\": \"₹699.00\",\n" +
    "\t\t\t\"extracted_price\": 699,\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn3.gstatic.com/shopping?q=tbn:ANd9GcRdLeBrysFJXYHjTuzjH5Cv6FVswjTdKf0mbZ8oytCXLv1GbSz8y-B-UOv2uROASe1vw7Y_KsK-AKsiZApSsVTQS8pdo64z7Q&usqp=CAE\",\n" +
    "\t\t\t\"delivery_options\": \"+Delivery\"\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"position\": 19,\n" +
    "\t\t\t\"docid\": \"12743494945795327654\",\n" +
    "\t\t\t\"link\": \"https://www.google.com/url?url=http://www.flipkart.com/gshockaspire-large-wooden-big-adults-kids-playing-skating-board-skateboard-multidesign-6-inch-x-24/p/itmb6687a5487c04%3Fpid%3DSKDGGGZ7HCY99FVZ%26lid%3DLSTSKDGGGZ7HCY99FVZBY4ELQ%26marketplace%3DFLIPKART%26cmpid%3Dcontent_skateboard_8965229628_gmc&rct=j&q=&esrc=s&sa=U&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ2SkI9gw&usg=AOvVaw3SmfE81ZarPLSREYx7Gsha\",\n" +
    "\t\t\t\"title\": \"Gshockaspire Large Wooden Big Adults Kids Playing Skating Board Skateboard ...\",\n" +
    "\t\t\t\"source\": \"Flipkart\",\n" +
    "\t\t\t\"price\": \"₹499.00\",\n" +
    "\t\t\t\"extracted_price\": 499,\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn0.gstatic.com/shopping?q=tbn:ANd9GcQiyXyxAar85JTTkp1X4C0_7nQKZotPOvtPrnlSO6IJrAuQkpq10GC7OQoUtW9O33kMQk4BxiQHlgyrc7zowh-6Cx3esJQd8Z0VqWF-vTT-ZpsPK2ug2Wn1oQ&usqp=CAE\",\n" +
    "\t\t\t\"delivery_options\": \"+Delivery\"\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"position\": 20,\n" +
    "\t\t\t\"docid\": \"3315102271224760195\",\n" +
    "\t\t\t\"link\": \"https://www.google.com/url?url=https://meshinesports.en.made-in-china.com/product/PdcfsVzAaRUp/China-Manufacturer-Customize-Chinese-Maple-Skateboard-Deck-for-Extreme-Sports-and-Outdoors.html%3Futm_source%3Dggshop%26utm_medium%3Dfeed%26utm_campaign%3Dqiao-826-1925382352%26srsltid%3DAd5pg_EFPpAWhQQxzvlpPAKnLxV7LcB9Im0FfIm2TYyM9YMj5JkOLxK-Chk&rct=j&q=&esrc=s&sa=U&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ2SkIgQ0&usg=AOvVaw2-itaNkvKoHfHSJj5Nc80L\",\n" +
    "\t\t\t\"title\": \"Manufacturer Customize Maple Skateboard Deck for Extreme Sports and Outdoors ...\",\n" +
    "\t\t\t\"source\": \"Made-in-China.com\",\n" +
    "\t\t\t\"price\": \"₹946.08 + tax\",\n" +
    "\t\t\t\"extracted_price\": 946.08,\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn0.gstatic.com/shopping?q=tbn:ANd9GcSoeCySTrfiaBtBTcbKxrpUt3-mWp7rfd8DFVegILfCtiAWIbrmg0T1e8aF8JPqSt9A-lzdANA2-jL2G4ON75BZcr5KbK82praaxYAHbFO9coEM3Er5INttsQ&usqp=CAE\",\n" +
    "\t\t\t\"product_href\": \"https://www.google.com/url?url=https://meshinesports.en.made-in-china.com/product/PdcfsVzAaRUp/China-Manufacturer-Customize-Chinese-Maple-Skateboard-Deck-for-Extreme-Sports-and-Outdoors.html%3Futm_source%3Dggshop%26utm_medium%3Dfeed%26utm_campaign%3Dqiao-826-1925382352%26srsltid%3DAd5pg_EFPpAWhQQxzvlpPAKnLxV7LcB9Im0FfIm2TYyM9YMj5JkOLxK-Chk&rct=j&q=&esrc=s&sa=U&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ1SkI_wwoAA&usg=AOvVaw3aGgXjiyjZxZ8x65HOk5U5\",\n" +
    "\t\t\t\"delivery_options\": \"Free delivery\",\n" +
    "\t\t\t\"delivery_options_extracted_price\": 0\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"position\": 21,\n" +
    "\t\t\t\"docid\": \"18107044388050903651\",\n" +
    "\t\t\t\"link\": \"https://www.google.com/url?url=https://www.ebay.com/itm/265349686725%3Fvar%3D565190987088&rct=j&q=&esrc=s&sa=U&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ2SkIjQ0&usg=AOvVaw3sPgPLVWzIYWMwvjJnflY_\",\n" +
    "\t\t\t\"title\": \"Cal 7 Speed Blue 7.5\\\"Heat Press Canadian Maple Skateboard Deck W /\",\n" +
    "\t\t\t\"source\": \"eBay\",\n" +
    "\t\t\t\"price\": \"₹3,043.09 + tax\",\n" +
    "\t\t\t\"extracted_price\": 3043.09,\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn3.gstatic.com/shopping?q=tbn:ANd9GcSGrTq0rdZNQYu3Nln_4WeA2d9ZzIqgldJJklkvvKUgwWpEIVFkHRblPwu2tGvirfii-sZP9kZoGHqdGtARRLjQ_Ru_BfLiRNw-nL5KzSrXUdY6S9zMI0Jx&usqp=CAE\",\n" +
    "\t\t\t\"delivery_options\": \"+Delivery\"\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"position\": 22,\n" +
    "\t\t\t\"docid\": \"1957613996494436851\",\n" +
    "\t\t\t\"link\": \"https://www.google.com/url?url=https://drivnapparel.com/products/copy-of-aysi%3Fcurrency%3DUSD%26variant%3D39325711007805%26utm_medium%3Dcpc%26utm_source%3Dgoogle%26utm_campaign%3DGoogle%2520Shopping&rct=j&q=&esrc=s&sa=U&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ2SkImQ0&usg=AOvVaw1xnHfSFxhKmoCvgZh12qDc\",\n" +
    "\t\t\t\"title\": \"Drivn OG Deck, 8.00 X 31.77\",\n" +
    "\t\t\t\"source\": \"Drivn Apparel\",\n" +
    "\t\t\t\"price\": \"₹8,967.22 + tax\",\n" +
    "\t\t\t\"extracted_price\": 8967.22,\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn3.gstatic.com/shopping?q=tbn:ANd9GcRDpsPjoDmAMsMEmO37uIVK71Pd8lpC90TPmMephKRXzCPcKCmu79h-YLA3laBXfMOl-0pEFefPpRDJ3F5dxu91B8FvWPMyoLpZviOVKUG8epNIEdXTdv7w&usqp=CAE\",\n" +
    "\t\t\t\"delivery_options\": \"+Delivery\"\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"position\": 23,\n" +
    "\t\t\t\"docid\": \"10468395772425312798\",\n" +
    "\t\t\t\"link\": \"https://www.google.com/url?url=https://letsclickit.com/products/optimus%25C2%25AE-kids-maple-wooden-skate-board-large-24-inch-x-6-inch-for-6-yrs-to-14-yrs-beginners-intermediate-design-may-vary-as-per-availability-5%3Fvariant%3D44301732413686%26currency%3DINR%26utm_medium%3Dproduct_sync%26utm_source%3Dgoogle%26utm_content%3Dsag_organic%26utm_campaign%3Dsag_organic&rct=j&q=&esrc=s&sa=U&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ2SkIpQ0&usg=AOvVaw2pSUyBInep5ZxnNNZAEpPW\",\n" +
    "\t\t\t\"title\": \"Optimus Kids Maple Wooden Skate Board Large 24 Inch x 6 Inch for 6 Yrs to 14 Yrs ...\",\n" +
    "\t\t\t\"source\": \"LetsClickIt\",\n" +
    "\t\t\t\"price\": \"₹549.00\",\n" +
    "\t\t\t\"extracted_price\": 549,\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn3.gstatic.com/shopping?q=tbn:ANd9GcTeL6vUYz-b7VUUtHPpxDvYTjbLaEvC2ghLF0BQBy6iVDQWEOb7WpbR90FBCokGdj16ygDwHrrgc03665isCk4rAQwuirXFU_jPvplVAjY&usqp=CAE\",\n" +
    "\t\t\t\"delivery_options\": \"Free delivery\",\n" +
    "\t\t\t\"delivery_options_extracted_price\": 0\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"position\": 24,\n" +
    "\t\t\t\"docid\": \"14815291894432933495\",\n" +
    "\t\t\t\"link\": \"https://www.google.com/url?url=https://squarebazaar.in/products/airavats-wooden-skateboard-alpha-7815sk8%3Fcurrency%3DINR%26variant%3D43050430333142%26utm_medium%3Dcpc%26utm_source%3Dgoogle%26utm_campaign%3DGoogle%2520Shopping&rct=j&q=&esrc=s&sa=U&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ2SkIsA0&usg=AOvVaw1TneoYQ03_d_X-Y8B2KS5L\",\n" +
    "\t\t\t\"title\": \"Airavat's Wooden Skateboard ALPHA 7815SK8, Mark Top\",\n" +
    "\t\t\t\"source\": \"SquareBazaar\",\n" +
    "\t\t\t\"price\": \"₹3,175.00\",\n" +
    "\t\t\t\"extracted_price\": 3175,\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn1.gstatic.com/shopping?q=tbn:ANd9GcQDl6lHcygiM2EhGH0qB5mhv7OUI2VRqXKvKq56_4aCNzBIql7HP3a9xF3eSLUtAElrhj7T3jd2iBJizzXA3od13PnZ7lB1vbwONu8BYhXkeJTeXx2xfVtNxg&usqp=CAE\",\n" +
    "\t\t\t\"delivery_options\": \"Free delivery\",\n" +
    "\t\t\t\"delivery_options_extracted_price\": 0\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"position\": 25,\n" +
    "\t\t\t\"docid\": \"2483462176712938501\",\n" +
    "\t\t\t\"link\": \"https://www.google.com/shopping/product/7607421491569149657?q=Skateboards&num=100&prds=eto:12208082391251981521_0,pid:2483462176712938501,rsk:PC_7661354213974770967&sa=X&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ8wIIuw0\",\n" +
    "\t\t\t\"title\": \"Oxelo By Decathlon Skateboard Play 7.5 Inch X 27 Inch Skateboard\",\n" +
    "\t\t\t\"source\": \"Decathlon Sports India\",\n" +
    "\t\t\t\"price\": \"₹2,599.00\",\n" +
    "\t\t\t\"extracted_price\": 2599,\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn3.gstatic.com/shopping?q=tbn:ANd9GcQ0vnWX8g0Acaf4hNczOzAjshq9A_JG1VZa4FkrElo3hTGCaUBwA4Wd176PJ-v-tT-Pg75ktHQERP1IastquqWsF5M2x-dOgji7S_3TRlbEgW5J5DBTNOTnCQ&usqp=CAE\",\n" +
    "\t\t\t\"product_href\": \"https://www.google.com/shopping/product/7607421491569149657?q=Skateboards&num=100&prds=eto:12208082391251981521_0,pid:2483462176712938501,rsk:PC_7661354213974770967&sa=X&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ8gIIuQ0oAA\",\n" +
    "\t\t\t\"product_id\": \"7607421491569149657\",\n" +
    "\t\t\t\"rating\": 4.7,\n" +
    "\t\t\t\"delivery_options\": \"+Delivery\"\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"position\": 26,\n" +
    "\t\t\t\"docid\": \"15778087986852905067\",\n" +
    "\t\t\t\"link\": \"https://www.google.com/url?url=https://www.ubuy.co.in/product/3VVDAE3Z6-creature-complete-skateboards%3Fsrsltid%3DAd5pg_G7AXKSqg72o8VerDaZYX7PoS2G2RTqW3dCCjAtGWF0ZomnGuc0eD0&rct=j&q=&esrc=s&sa=U&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ2SkIxw0&usg=AOvVaw0yW2H6GWZzqWzLyJHxQ39f\",\n" +
    "\t\t\t\"title\": \"Creature Complete Skateboards\",\n" +
    "\t\t\t\"source\": \"Ubuy India\",\n" +
    "\t\t\t\"price\": \"₹14,504.00\",\n" +
    "\t\t\t\"extracted_price\": 14504,\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn1.gstatic.com/shopping?q=tbn:ANd9GcSepyBP7tD5-G3RqlvOAYR1bRZWRcQWuHkHHdz8mGGBhXnfXvgPxl6M1TTZfuhEacBoD89Ntu_xSi66etNkWptfkqanFHHqGzsNa5M45zM&usqp=CAE\",\n" +
    "\t\t\t\"product_href\": \"https://www.google.com/url?url=https://www.ubuy.co.in/product/3VVDAE3Z6-creature-complete-skateboards%3Fsrsltid%3DAd5pg_G7AXKSqg72o8VerDaZYX7PoS2G2RTqW3dCCjAtGWF0ZomnGuc0eD0&rct=j&q=&esrc=s&sa=U&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ1SkIxQ0oAA&usg=AOvVaw1_3XNxleygvO8l00xyxBDm\",\n" +
    "\t\t\t\"product_id\": \"3\",\n" +
    "\t\t\t\"delivery_options\": \"₹1,750.00 delivery\",\n" +
    "\t\t\t\"delivery_options_extracted_price\": 1750\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"position\": 27,\n" +
    "\t\t\t\"docid\": \"8800006322834789143\",\n" +
    "\t\t\t\"link\": \"https://www.google.com/url?url=https://www.ibhejo.com/chromewheels-31-inch-skateboard-complete-skateboards-double-kick-skate-board-7-layer-canadian-maple-deck-skateboard-for-kids-and-beginners-241261&rct=j&q=&esrc=s&sa=U&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ2SkI0g0&usg=AOvVaw2B8goFoK1iLrz7iiIRdS1s\",\n" +
    "\t\t\t\"title\": \"ChromeWheels 31 inch Skateboard Complete Skateboards Double Kick Skate Board 7 ...\",\n" +
    "\t\t\t\"source\": \"iBhejo.com\",\n" +
    "\t\t\t\"price\": \"₹14,450.00\",\n" +
    "\t\t\t\"extracted_price\": 14450,\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn0.gstatic.com/shopping?q=tbn:ANd9GcR9ZhBfYwUYYwojhWxojLywrOvRUAw8cvTLpu6ct59DAh-G_k4zJbQ9evMu2umjKT_NhAydp1KJik2jA1nqUdEOIY9afZRCKApQ2_mn3x3F&usqp=CAE\",\n" +
    "\t\t\t\"delivery_options\": \"Free delivery\",\n" +
    "\t\t\t\"delivery_options_extracted_price\": 0\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"position\": 28,\n" +
    "\t\t\t\"docid\": \"8262592376858167708\",\n" +
    "\t\t\t\"link\": \"https://www.google.com/url?url=https://onshopdeals.com/hand-painted-skateboard-deck-maple-double-rocker-diy-skateboard-deck-8inch-men-skateboard/%3Fsrsltid%3DAd5pg_HmLhYFbXnihXUWYPHlJE84gAnEE2kcP3x2h_7FRlzE1MjSLUFYOPI&rct=j&q=&esrc=s&sa=U&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ2SkI3Q0&usg=AOvVaw3GeUTRHOBKywKOzwed8Geo\",\n" +
    "\t\t\t\"title\": \"Hand Painted Skateboard Deck Maple Double Rocker DIY Skateboard Deck 8inch Men ...\",\n" +
    "\t\t\t\"source\": \"onshopdeals.com\",\n" +
    "\t\t\t\"price\": \"₹3,686.00\",\n" +
    "\t\t\t\"extracted_price\": 3686,\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn2.gstatic.com/shopping?q=tbn:ANd9GcTp5eDEfl2rzaQE7MvmR_UNcae6qC8VxzVz9-usMzFCVkbLxe5xEFu5XCKotdbM9H6Rq89QQ9XfxU3pQjOTvBFiIF6QtOzwVgr5cYOhz_M&usqp=CAE\",\n" +
    "\t\t\t\"delivery_options\": \"₹80.00 delivery\",\n" +
    "\t\t\t\"delivery_options_extracted_price\": 80\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"position\": 29,\n" +
    "\t\t\t\"docid\": \"892386638972795087\",\n" +
    "\t\t\t\"link\": \"https://www.google.com/url?url=https://www.jiomart.com/p/sportstoysluggage/jaspo-orange-max-penalty-maple-skateboard-for-beginners-27-x-7-inch/590946907%3Fsource%3Dshoppingads&rct=j&q=&esrc=s&sa=U&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ2SkI6A0&usg=AOvVaw0PEaxweci33VEoQBMfdBDr\",\n" +
    "\t\t\t\"title\": \"Jaspo Orange Max Penalty Maple Skateboard For Beginners 27 X 7 inch\",\n" +
    "\t\t\t\"source\": \"JioMart\",\n" +
    "\t\t\t\"price\": \"₹1,487.00\",\n" +
    "\t\t\t\"extracted_price\": 1487,\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn0.gstatic.com/shopping?q=tbn:ANd9GcRI908MmfDc08e1Z0sXz4WKNSHzKVIvgsci1pr7R7r_BL76MTdUOHefYL6UZkOfzh_xVyzakwHFJB3xgGYIEq6L1nahhykh&usqp=CAE\",\n" +
    "\t\t\t\"delivery_options\": \"+Delivery\"\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"position\": 30,\n" +
    "\t\t\t\"docid\": \"9269296722767539256\",\n" +
    "\t\t\t\"link\": \"https://www.google.com/url?url=http://www.flipkart.com/jaspo-experts-27-x7-anti-skid-skateboard-grip-tape-carry-bag-7-inch-x-27/p/itmf8d3vhhsysg7g%3Fpid%3DSKDF8D3VY9ZJMV2W%26lid%3DLSTSKDF8D3VY9ZJMV2W5HC7AU%26marketplace%3DFLIPKART%26cmpid%3Dcontent_skateboard_8965229628_gmc&rct=j&q=&esrc=s&sa=U&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ2SkI8w0&usg=AOvVaw0Brk8ty8WBOrZod9pTPVfy\",\n" +
    "\t\t\t\"title\": \"Jaspo Experts 27x7 Anti Skid Skateboard With Grip Tape And Carry Bag 7 Inch X 27 ...\",\n" +
    "\t\t\t\"source\": \"Flipkart\",\n" +
    "\t\t\t\"price\": \"₹1,186.00\",\n" +
    "\t\t\t\"extracted_price\": 1186,\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn0.gstatic.com/shopping?q=tbn:ANd9GcRpcTurmURDTZwOzQVTLhJq5b8IGZs_B7ZenV48MQ4HISWtkniikEn31YfFwSznXudjChjc_HlzdjFj29xyuxDeUBkWOxrvoYG2-4wPsByOsMWwJ2QIFUme&usqp=CAE\",\n" +
    "\t\t\t\"delivery_options\": \"+Delivery\"\n" +
    "\t\t}\n" +
    "\t],\n" +
    "\t\"top_features\": [],\n" +
    "\t\"filtered_results\": [\n" +
    "\t\t{\n" +
    "\t\t\t\"filter_category\": \"Filter by type\",\n" +
    "\t\t\t\"image_link\": \"https://www.google.com/search?tbm=shop&q=Skateboards&tbs=vw:g,mr:1,pdtr0:912752%7C912753&sa=X&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQwQkIqBQoAA\",\n" +
    "\t\t\t\"title_link\": \"https://www.google.com/search?tbm=shop&q=Skateboards&tbs=vw:g,mr:1,pdtr0:912752%7C912753&sa=X&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ1yoIqRQoAQ\",\n" +
    "\t\t\t\"title\": \"Street\",\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn0.gstatic.com/shopping?q=tbn:ANd9GcS4mCdPT58rEZqsxxz7IiXdcglmP69n_njlBuy11S6jMdKPeBblbwmMpLa8HjUbk6ft6S3IidgHlW-uc3ozFo35w2BjJIYTeBZWdI4FEFE&usqp=CAE\"\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"filter_category\": \"Filter by type\",\n" +
    "\t\t\t\"image_link\": \"https://www.google.com/search?tbm=shop&q=Skateboards&tbs=vw:g,mr:1,pdtr0:912752%7C912754&sa=X&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQwQkIqxQoAA\",\n" +
    "\t\t\t\"title_link\": \"https://www.google.com/search?tbm=shop&q=Skateboards&tbs=vw:g,mr:1,pdtr0:912752%7C912754&sa=X&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ1yoIrBQoAQ\",\n" +
    "\t\t\t\"title\": \"Longboard\",\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn3.gstatic.com/shopping?q=tbn:ANd9GcRhK4psoo4Fsvl5Ji6R2qAfkJujfmamoKHKAsbu5ejyMQpwabSNmejLqb0bL7LsVYKkGM9zPPN5DsCkFULM575upcrfws3tCtR36ugeuP4pawpa5ndtguzmwg&usqp=CAE\"\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"filter_category\": \"You might like\",\n" +
    "\t\t\t\"image_link\": \"https://www.google.com/search?tbm=shop&q=Skateboards&tbs=vw:g,mr:1,pdtr0:984350%7C984351&sa=X&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQwQkIshQoAA\",\n" +
    "\t\t\t\"title_link\": \"https://www.google.com/search?tbm=shop&q=Skateboards&tbs=vw:g,mr:1,pdtr0:984350%7C984351&sa=X&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ1yoIsxQoAQ\",\n" +
    "\t\t\t\"title\": \"Wood\",\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn0.gstatic.com/shopping?q=tbn:ANd9GcRQD0HZoAfAtq8d9rUuySlQc2I7ukIpBK5niqQq9pQmmuTBhY-91s2upZ-tH-yBmpPP-1npPxNrhmLX-87zKz9nsy4uAxKmoatx9I9CSLWG2Yuy7hppNRFl&usqp=CAE\"\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"filter_category\": \"You might like\",\n" +
    "\t\t\t\"image_link\": \"https://www.google.com/search?tbm=shop&q=Skateboards&tbs=vw:g,mr:1,pdtr0:751728%7C913364&sa=X&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQwQkItRQoAA\",\n" +
    "\t\t\t\"title_link\": \"https://www.google.com/search?tbm=shop&q=Skateboards&tbs=vw:g,mr:1,pdtr0:751728%7C913364&sa=X&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ1yoIthQoAQ\",\n" +
    "\t\t\t\"title\": \"Mini\",\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn2.gstatic.com/shopping?q=tbn:ANd9GcSq12VcWU4_R7V-64stR9dzqtrBYhqQWXlnIAfp27ntZ8_99Lf8JMV-xS69dWU0EG3MVR0B1KSyJAEUfUBH19hNpSXMCWdE6nm_6OqnmGA&usqp=CAE\"\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"filter_category\": \"You might like\",\n" +
    "\t\t\t\"image_link\": \"https://www.google.com/search?tbm=shop&q=Skateboards&tbs=vw:g,mr:1,pdtr0:984350%7C984352&sa=X&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQwQkIuBQoAA\",\n" +
    "\t\t\t\"title_link\": \"https://www.google.com/search?tbm=shop&q=Skateboards&tbs=vw:g,mr:1,pdtr0:984350%7C984352&sa=X&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ1yoIuRQoAQ\",\n" +
    "\t\t\t\"title\": \"Plastic\",\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn3.gstatic.com/shopping?q=tbn:ANd9GcRiMaSnU6RyOJYJCni2JfZAtDg-4DSdP3hiFknVWfpIRcdTGqNBmeBXXjrGHlwQc8DZU2spbtLwhQeh9JyJKf_V_Z1dFKIpSig2v1WSTPM&usqp=CAE\"\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"filter_category\": \"You might like\",\n" +
    "\t\t\t\"image_link\": \"https://www.google.com/search?tbm=shop&q=Skateboards&tbs=vw:g,mr:1,pdtr0:751645%7C1118913&sa=X&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQwQkIuxQoAA\",\n" +
    "\t\t\t\"title_link\": \"https://www.google.com/search?tbm=shop&q=Skateboards&tbs=vw:g,mr:1,pdtr0:751645%7C1118913&sa=X&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ1yoIvBQoAQ\",\n" +
    "\t\t\t\"title\": \"Oxelo\",\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn3.gstatic.com/shopping?q=tbn:ANd9GcQOd-SeJ4kiwk9Mx6L8TJWJCLvXJpi0Q57k8GX5GvuloXT8AgLTrvOtxRsUjXCPyfIeK0kWkpVr3e0BVOroU_tn45hGbqc8W0xuc_65PC-TttSEEw4Rk3Nq&usqp=CAE\"\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"filter_category\": \"You might like\",\n" +
    "\t\t\t\"image_link\": \"https://www.google.com/search?tbm=shop&q=Skateboards&tbs=vw:g,mr:1,pdtr0:751645%7C751648&sa=X&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQwQkIvhQoAA\",\n" +
    "\t\t\t\"title_link\": \"https://www.google.com/search?tbm=shop&q=Skateboards&tbs=vw:g,mr:1,pdtr0:751645%7C751648&sa=X&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ1yoIvxQoAQ\",\n" +
    "\t\t\t\"title\": \"Alien Workshop\",\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn2.gstatic.com/shopping?q=tbn:ANd9GcQF0saKJCZMUtGZa9nsAOv3A1g4PmMgYsmq6dJHkGMM3AKTf354YdvBX0OSLiRxXuYYirjND4uvdv2B54TP_lXq2ny4P3-FPO0ee9OlmdaKwXVj0_mEyAcE&usqp=CAE\"\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"filter_category\": \"You might like\",\n" +
    "\t\t\t\"image_link\": \"https://www.google.com/search?tbm=shop&q=Skateboards&tbs=vw:g,mr:1,pdtr0:751645%7C1584636&sa=X&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQwQkIwRQoAA\",\n" +
    "\t\t\t\"title_link\": \"https://www.google.com/search?tbm=shop&q=Skateboards&tbs=vw:g,mr:1,pdtr0:751645%7C1584636&sa=X&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ1yoIwhQoAQ\",\n" +
    "\t\t\t\"title\": \"Sector 9\",\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn3.gstatic.com/shopping?q=tbn:ANd9GcQ-oxdufwPZ0BfGTvalNdNisJhKQ4qsbEC9bnFVT5eGxbH4JAvelooHPiDjvEnU8SFQQxABE7bjGSZ-5l1G7Kr83_yElCQ4YokecekckBo&usqp=CAE\"\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"filter_category\": \"You might like\",\n" +
    "\t\t\t\"image_link\": \"https://www.google.com/search?tbm=shop&q=Skateboards&tbs=vw:g,mr:1,pdtr0:751728%7C751749&sa=X&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQwQkIxBQoAA\",\n" +
    "\t\t\t\"title_link\": \"https://www.google.com/search?tbm=shop&q=Skateboards&tbs=vw:g,mr:1,pdtr0:751728%7C751749&sa=X&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ1yoIxRQoAQ\",\n" +
    "\t\t\t\"title\": \"Drop-through\",\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn0.gstatic.com/shopping?q=tbn:ANd9GcQmzP5R1disIVOyuYwTFiuWk-a6hy0N_4T9CCrcEeUnxErLaBp-5X_V1jiIr1Il08g93kq2zPfC9oQCTLV6-i2Gx42CEqsFdFcXJwNn61QaoE6Q2yxq2QnMdg&usqp=CAE\"\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"filter_category\": \"You might like\",\n" +
    "\t\t\t\"image_link\": \"https://www.google.com/search?tbm=shop&q=Skateboards&tbs=vw:g,mr:1,pdtr0:751645%7C4461836&sa=X&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQwQkIxxQoAA\",\n" +
    "\t\t\t\"title_link\": \"https://www.google.com/search?tbm=shop&q=Skateboards&tbs=vw:g,mr:1,pdtr0:751645%7C4461836&sa=X&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ1yoIyBQoAQ\",\n" +
    "\t\t\t\"title\": \"ARCADE\",\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn0.gstatic.com/shopping?q=tbn:ANd9GcTpbFthQ0t_8tSOWm5cfFZsA9VvpFo2lPnGOxay6iDPoh86_aLPP6yrIioLv0gSbSEt3ibKgUxxZnHBghVX8NO-XhjwM8f3t28f-fYpeJJwzLJ4Ax2ANhNb2w&usqp=CAE\"\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"filter_category\": \"You might like\",\n" +
    "\t\t\t\"image_link\": \"https://www.google.com/search?tbm=shop&q=Skateboards&tbs=vw:g,mr:1,pdtr0:751645%7C2128132&sa=X&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQwQkIyhQoAA\",\n" +
    "\t\t\t\"title_link\": \"https://www.google.com/search?tbm=shop&q=Skateboards&tbs=vw:g,mr:1,pdtr0:751645%7C2128132&sa=X&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ1yoIyxQoAQ\",\n" +
    "\t\t\t\"title\": \"Vision Street Wear\",\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn1.gstatic.com/shopping?q=tbn:ANd9GcSGuSWgx6oOnc24jsrpdZ-kass-a5cQq5LKAAVrob1bbuxTVPpClZJdRmR0kvKo_Fqeym-N3hXJrvyUo1E-oWyP2dPrBHJ31Ra-Xf92j0lP&usqp=CAE\"\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"filter_category\": \"You might like\",\n" +
    "\t\t\t\"image_link\": \"https://www.google.com/search?tbm=shop&q=Skateboards&tbs=vw:g,mr:1,pdtr0:751645%7C1800715&sa=X&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQwQkIzRQoAA\",\n" +
    "\t\t\t\"title_link\": \"https://www.google.com/search?tbm=shop&q=Skateboards&tbs=vw:g,mr:1,pdtr0:751645%7C1800715&sa=X&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ1yoIzhQoAQ\",\n" +
    "\t\t\t\"title\": \"Roller Derby\",\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn1.gstatic.com/shopping?q=tbn:ANd9GcQt_xJzZ0NgA_xVDguFujgSDK6AGN6fnuVxU78AlNXBH8YrAlvxKKINqzW7W0J_3-lDxnTuPzIXXuevG0ROVIFryPKMR_ecBnSBVEq9T3ve&usqp=CAE\"\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"filter_category\": \"You might like\",\n" +
    "\t\t\t\"image_link\": \"https://www.google.com/search?tbm=shop&q=Skateboards&tbs=vw:g,mr:1,pdtr0:751645%7C956597&sa=X&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQwQkI0BQoAA\",\n" +
    "\t\t\t\"title_link\": \"https://www.google.com/search?tbm=shop&q=Skateboards&tbs=vw:g,mr:1,pdtr0:751645%7C956597&sa=X&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ1yoI0RQoAQ\",\n" +
    "\t\t\t\"title\": \"Osprey\",\n" +
    "\t\t\t\"thumbnail\": \"https://encrypted-tbn2.gstatic.com/shopping?q=tbn:ANd9GcSOiXkbGX9feIE20KNIl8WQS1RnnlHSBl-KQrWMYcdNP-Q9HIF4rEyfEb7b0i7BCvJj5fMKUJwqHqGag2WOOqaCKMJwqIkVyBohllOJyL4&usqp=CAE\"\n" +
    "\t\t}\n" +
    "\t],\n" +
    "\t\"related_searches\": [],\n" +
    "\t\"pagination\": {\n" +
    "\t\t\"pages_count\": 5,\n" +
    "\t\t\"current_page\": 1,\n" +
    "\t\t\"next_page_url\": \"https://www.google.com/search?q=Skateboards&num=100&tbs=vw:g&tbm=shop&ei=lb7cY8qFEeLV1sQPqNmywAg&start=100&sa=N&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ8tMDCNgU\",\n" +
    "\t\t\"pages\": [\n" +
    "\t\t\t{\n" +
    "\t\t\t\t\"page\": 2,\n" +
    "\t\t\t\t\"url\": \"https://www.google.com/search?q=Skateboards&num=100&tbs=vw:g&tbm=shop&ei=lb7cY8qFEeLV1sQPqNmywAg&start=100&sa=N&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ8tMDCNgU\"\n" +
    "\t\t\t},\n" +
    "\t\t\t{\n" +
    "\t\t\t\t\"page\": 3,\n" +
    "\t\t\t\t\"url\": \"https://www.google.com/search?q=Skateboards&num=100&tbs=vw:g&tbm=shop&ei=lb7cY8qFEeLV1sQPqNmywAg&start=200&sa=N&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ8tMDCNoU\"\n" +
    "\t\t\t},\n" +
    "\t\t\t{\n" +
    "\t\t\t\t\"page\": 4,\n" +
    "\t\t\t\t\"url\": \"https://www.google.com/search?q=Skateboards&num=100&tbs=vw:g&tbm=shop&ei=lb7cY8qFEeLV1sQPqNmywAg&start=300&sa=N&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ8tMDCNwU\"\n" +
    "\t\t\t},\n" +
    "\t\t\t{\n" +
    "\t\t\t\t\"page\": 5,\n" +
    "\t\t\t\t\"url\": \"https://www.google.com/search?q=Skateboards&num=100&tbs=vw:g&tbm=shop&ei=lb7cY8qFEeLV1sQPqNmywAg&start=400&sa=N&ved=0ahUKEwiKpMXY8Pj8AhXiqpUCHaisDIgQ8tMDCN4U\"\n" +
    "\t\t\t}\n" +
    "\t\t]\n" +
    "\t}\n" +
    "}"
};
