import React, { SVGProps, forwardRef } from "react";
import { cx } from "utils";

import { Size, Sizes } from 'styles/sizes';

type SpinnerSizes = Sizes<string>;

const spinnerSizes: SpinnerSizes = {
  XXS: "w-3.5 h-3.5",
  XS: "w-[22px] h-[22px]",
  SM: "w-6 h-6",
  MD: "w-8 h-8",
  LG: "w-10 h-10",
};

interface SpinnerThemeStyles {
  base: string;
}

const spinnerThemes: Record<string, SpinnerThemeStyles> = {
  primary: {
    base: "text-primary-600",
  },
  secondary: {
    base: "text-neutral-600",
  },
  white: {
    base: "text-white",
  },
};

type SpinnerTheme = keyof typeof spinnerThemes;

export interface SpinnerProps extends SVGProps<SVGSVGElement> {
  size?: Size | number;
  theme?: SpinnerTheme;
  loading?: boolean;
}

function isValidSize(size: any): size is Size {
  return typeof size === 'string' && size in spinnerSizes;
}

export const Spinner = forwardRef<SVGSVGElement, SpinnerProps>(
  (
    {
      size = "SM" as Size,
      theme = "primary",
      loading = true,
      className,
      ...props
    }: SpinnerProps,
    ref
  ) => {
    if (!loading) return null;

    const spinnerTheme = spinnerThemes[theme];

    let sizeStyle: React.CSSProperties = {};
    let sizeClass: string = '';

    if (typeof size === 'number') {
      sizeStyle = { width: `${size}px`, height: `${size}px` };
    } else if (isValidSize(size)) {
      sizeClass = spinnerSizes[size] as string;
    } else {
      sizeClass = spinnerSizes.SM as string;
    }

    const spinnerStyle = cx(spinnerTheme.base, sizeClass, "animate-spin", className);

    return (
      <svg
        ref={ref}
        className={spinnerStyle}
        style={sizeStyle}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    );
  }
);

export default Spinner;
