import { async_amazon_offer } from "./async_amazon_offer";
import { async_amazon_product } from "./async_amazon_product";
import { async_amazon_search } from "./async_amazon_search";
import { async_amazon_review } from "./async_amazon_review";
import { async_google_jobs } from "./async_google_jobs";
import { async_google_news } from "./async_google_news";
import { async_google_search } from "./async_google_search";
import { async_google_shopping } from "./async_google_shopping";
import { async_google_mapssearch } from "./async_google_mapssearch";
import { async_walmart_product } from "./async_walmart_product";
import { async_walmart_category } from "./async_walmart_category";
import { async_walmart_search } from "./async_walmart_search";
import { async_walmart_review } from "./async_walmart_review";
import { async_ebay_product } from "./async_ebay_product";


const examples: { [ index: string ]: { input: string, result: string } } = {
  async_amazon_offer,
  async_amazon_product,
  async_amazon_search,
  async_amazon_review,
  async_google_jobs,
  async_google_news,
  async_google_search,
  async_google_shopping,
  async_google_mapssearch,
  async_walmart_product,
  async_walmart_category,
  async_walmart_search,
  async_walmart_review,
  async_ebay_product,
};

export default examples;
