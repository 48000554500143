import Button from "components/Button";
import ExtLink from "components/ExtLink";
import Icons from "components/Icons";

import { fmtNumber } from "utils";

import { QuickStartStep } from "./QuickStartCard";


export default function DataPipelineQuickStartSteps() {
  return (
    <>
      <QuickStartStep
        title="Automate & Schedule with our hosted scraping solution"
        subtitle={ <>
          <ExtLink href="https://docs.scraperapi.com/v/datapipeline/how-to-create-a-new-datapipeline-project" className="underline">Create a project</ExtLink>
          {" "}for up to { fmtNumber(10000) } arbitrary URLs, product IDs or search terms, based on the selected project type.
        </> }
        index={ 2 }
      />
      <QuickStartStep
        title={ <>
          Choose parameters &{" "}
          <ExtLink href="https://docs.scraperapi.com/datapipeline/how-to-create-a-new-datapipeline-project/results-and-output-formats" className="underline">result format</ExtLink>
        </> }
        subtitle="Structured JSON or CSV download is available for all structured data projects. Parameters also vary based on project type."
        index={ 3 }
      />
      <QuickStartStep
        title={ <>
          <ExtLink href="https://docs.scraperapi.com/datapipeline/how-to-create-a-new-datapipeline-project/scraping-frequency" className="underline">Schedule</ExtLink>
          {" "}your projects
        </> }
        subtitle="Automate your projects by scheduling when your projects should run."
        index={ 4 }
      />
      <QuickStartStep
        title="Begin the onboarding guide for DataPipeline"
        subtitle="Easy onboarding for our low-code scraping tool."
        index={ 5 }
        icon={ <Icons.DataPipeline className="h-4" /> }
      >
        <Button
          text="Start guide"
          size="SM"
          className="button button-accent"
        />
      </QuickStartStep>
    </>
  );
};
