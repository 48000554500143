import { forwardRef, InputHTMLAttributes, useState } from "react";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import { cx } from "utils";

import { ReactComponent as EyeOffLineIcon } from "assets/icons/eye-off-line-icon.svg";
import { ReactComponent as EyeIcon } from "assets/icons/eye-icon.svg";

interface IInputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  TrailingElm?: React.ReactElement;
  name: string;
}

const InputField = forwardRef((
  {
    label,
    TrailingElm,
    name: inputName,
    ...props
  }: IInputFieldProps,
  ref: any
) => {
  const { register } = useFormContext();
  return (
    <div>
      {label && (
        <label
          htmlFor={props.id}
          className="block mb-1 text-sm select-none text-gray dark:text-neutral-600"
        >
          {label}
        </label>
      )}
      <div className="relative rounded-md shadow-sm">
        <input
          {...register(inputName, {
            required: props.required ? `${label} is required` : false
          })}
          {...props}
          className={cx(
            props.className,
            TrailingElm && "!pr-10",
            "focus:ring-brandPrimary dark:focus:ring-primary-600 z-0 focus:border-brandPrimary dark:focus:border-primary-600 border px-2 block w-full h-[44px] placeholder:text-base text-base text-gray dark:text-neutral-600 leading-none border-gray dark:border-neutral-600"
          )}
        />

        {TrailingElm && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3">
            {TrailingElm}
          </div>
        )}
      </div>
    </div>
  );
});

export default InputField;

export const EmailField = (props: { readonlyEmail?: string }) => {
  const emailProps: IInputFieldProps = {
    id: "email",
    label: "Email Address",
    name: "email",
    placeholder: "Please enter your email address",
    required: true,
    type: "email"
  };

  if (props.readonlyEmail) {
    emailProps.contentEditable = false;
    emailProps.readOnly = true;
    emailProps.value = props.readonlyEmail;
  }

  return (
    <InputField {...emailProps} />
  );
};

export const PasswordField = forwardRef((props: any, ref: any) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <InputField
      ref={ref}
      id="password"
      label="Password"
      name="password"
      placeholder="Please enter your password"
      required
      minLength={5}
      type={showPassword ? "text" : "password"}
      TrailingElm={
        showPassword ? (
          <EyeIcon
            className="flex-shrink-0 w-5 h-5 cursor-pointer text-gray dark:text-neutral-600"
            onClick={() => setShowPassword(false)}
          />
        ) : (
          <EyeOffLineIcon
            className="flex-shrink-0 w-5 h-5 cursor-pointer text-gray dark:text-neutral-600"
            onClick={() => setShowPassword(true)}
          />
        )
      }
      {...props}
    />
  );
});

export const ErrorMsg = ({ name }: { name: string }) => {
  const {
    formState: { errors }
  } = useFormContext();
  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => (
        <p className="mt-2 text-sm text-red-600 dark:text-error-600">{message}</p>
      )}
    />
  );
};
