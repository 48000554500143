import { v4 as uuidV4 } from "uuid";

import { ServiceScript } from "providers/ServicesProvider/ServiceScriptProvider";
import UsercentricsScriptProvider from "providers/UsercentricsProvider/UsercentricsScriptProvider";


export const FIRSTPROMOTER_SERVICE_NAME = "FirstPromoter";


export default function FirstPromoterProvider() {

  const initFirstPromoter = () => {
    let $FPROM = new fprom_obj_();
    $FPROM.init("ssvxh57a", `.scraperapi.com`);
  };

  const firstPromoterScript: ServiceScript = {
    name: FIRSTPROMOTER_SERVICE_NAME,
    domId: uuidV4(),
    src: "./fp.js",
    onLoadCallback: initFirstPromoter
  };

  return (
    <UsercentricsScriptProvider
      { ...firstPromoterScript }
    />
  );

};
