import { Navigate, Outlet, useLocation, useOutletContext } from "react-router-dom";

import { useUser } from "routes/dataroutes/UserData";


export default function BannedFromFreeLayout() {
  const user = useUser();
  const location = useLocation();
  const outletContext = useOutletContext();

  const locationState = location.state as any;
  const from = locationState?.from || locationState?.background || locationState?.backgroundLocation || "/";

  if (user?.isBlocked && user?.blockingCode !== "banned_from_free") {
    return <Navigate to={ from } replace />;
  } else {
    return <Outlet context={ outletContext } />;
  }
};
