import { useEffect, useState } from 'react'
 
export function useDebouncer<T>(value: T, ms = 300): T {
  const [debounced, setDebounced] = useState<T>(value)
 
  useEffect(() => {
    if (debounced === value) return
 
    const timeoutId = setTimeout(() => {
      setDebounced(value)
    }, ms)
 
    return () => clearTimeout(timeoutId)
  }, [debounced, ms, value])
 
  return debounced
}
