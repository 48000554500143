const Iso3166CountryCodes = {

  Afghanistan: {
    name: "Afghanistan",
    countryCode: "AF"
  },

  Albania: {
    name: "Albania",
    countryCode: "AL"
  },

  Algeria: {
    name: "Algeria",
    countryCode: "DZ"
  },

  American_Samoa: {
    name: "American Samoa",
    countryCode: "AS"
  },

  Andorra: {
    name: "Andorra",
    countryCode: "AD"
  },

  Angola: {
    name: "Angola",
    countryCode: "AO"
  },

  Anguilla: {
    name: "Anguilla",
    countryCode: "AI"
  },

  Antarctica: {
    name: "Antarctica",
    countryCode: "AQ"
  },

  Antigua_and_Barbuda: {
    name: "Antigua and Barbuda",
    countryCode: "AG"
  },

  Argentina: {
    name: "Argentina",
    countryCode: "AR"
  },

  Armenia: {
    name: "Armenia",
    countryCode: "AM"
  },

  Aruba: {
    name: "Aruba",
    countryCode: "AW"
  },

  Australia: {
    name: "Australia",
    countryCode: "AU"
  },

  Austria: {
    name: "Austria",
    countryCode: "AT"
  },

  Azerbaijan: {
    name: "Azerbaijan",
    countryCode: "AZ"
  },

  Bahamas: {
    name: "Bahamas",
    countryCode: "BS"
  },

  Bahrain: {
    name: "Bahrain",
    countryCode: "BH"
  },

  Bangladesh: {
    name: "Bangladesh",
    countryCode: "BD"
  },

  Barbados: {
    name: "Barbados",
    countryCode: "BB"
  },

  Belarus: {
    name: "Belarus",
    countryCode: "BY"
  },

  Belgium: {
    name: "Belgium",
    countryCode: "BE"
  },

  Belize: {
    name: "Belize",
    countryCode: "BZ"
  },

  Benin: {
    name: "Benin",
    countryCode: "BJ"
  },

  Bermuda: {
    name: "Bermuda",
    countryCode: "BM"
  },

  Bhutan: {
    name: "Bhutan",
    countryCode: "BT"
  },

  Bolivia: {
    name: "Bolivia",
    countryCode: "BO"
  },

  Bonaire: {
    name: "Bonaire, Sint Eustatius and Saba",
    countryCode: "BQ"
  },

  Bosnia: {
    name: "Bosnia and Herzegovina",
    countryCode: "BA"
  },

  Botswana: {
    name: "Botswana",
    countryCode: "BW"
  },

  Bouvet_Island: {
    name: "Bouvet Island",
    countryCode: "BV"
  },

  Brazil: {
    name: "Brazil",
    countryCode: "BR"
  },

  British_Indian_Ocean_Territory: {
    name: "British Indian Ocean Territory",
    countryCode: "IO"
  },

  Brunei_Darussalam: {
    name: "Brunei Darussalam",
    countryCode: "BN"
  },

  Bulgaria: {
    name: "Bulgaria",
    countryCode: "BG"
  },

  Burkina_Faso: {
    name: "Burkina Faso",
    countryCode: "BF"
  },

  Burundi: {
    name: "Burundi",
    countryCode: "BI"
  },

  Cabo_Verde: {
    name: "Cabo Verde",
    countryCode: "CV"
  },

  Cambodia: {
    name: "Cambodia",
    countryCode: "KH"
  },

  Cameroon: {
    name: "Cameroon",
    countryCode: "CM"
  },

  Canada: {
    name: "Canada",
    countryCode: "CA"
  },

  Cayman_Islands: {
    name: "Cayman Islands",
    countryCode: "KY"
  },

  Central_African_Republic: {
    name: "Central African Republic",
    countryCode: "CF"
  },

  Chad: {
    name: "Chad",
    countryCode: "TD"
  },

  Chile: {
    name: "Chile",
    countryCode: "CL"
  },

  China: {
    name: "China",
    countryCode: "CN"
  },

  Christmas_Island: {
    name: "Christmas Island",
    countryCode: "CX"
  },

  Cocos_Islands: {
    name: "Cocos (Keeling) Islands",
    countryCode: "CC"
  },

  Colombia: {
    name: "Colombia",
    countryCode: "CO"
  },

  Comoros: {
    name: "Comoros",
    countryCode: "KM"
  },

  Congo: {
    name: "Congo",
    countryCode: "CD"
  },

  Cook_Islands: {
    name: "Cook Islands",
    countryCode: "CK"
  },

  Costa_Rica: {
    name: "Costa Rica",
    countryCode: "CR"
  },

  Croatia: {
    name: "Croatia",
    countryCode: "HR"
  },

  Cuba: {
    name: "Cuba",
    countryCode: "CU"
  },

  Curacao: {
    name: "Curaçao",
    countryCode: "CW"
  },

  Cyprus: {
    name: "Cyprus",
    countryCode: "CY"
  },

  Czechia: {
    name: "Czechia",
    countryCode: "CZ"
  },

  Cote_d_Ivoire: {
    name: "Côte d'Ivoire",
    countryCode: "CI"
  },

  Denmark: {
    name: "Denmark",
    countryCode: "DK"
  },

  Djibouti: {
    name: "Djibouti",
    countryCode: "DJ"
  },

  Dominica: {
    name: "Dominica",
    countryCode: "DM"
  },

  Dominican_Republic: {
    name: "Dominican Republic",
    countryCode: "DO"
  },

  Ecuador: {
    name: "Ecuador",
    countryCode: "EC"
  },

  Egypt: {
    name: "Egypt",
    countryCode: "EG"
  },

  El_Salvador: {
    name: "El Salvador",
    countryCode: "SV"
  },

  Equatorial_Guinea: {
    name: "Equatorial Guinea",
    countryCode: "GQ"
  },

  Eritrea: {
    name: "Eritrea",
    countryCode: "ER"
  },

  Estonia: {
    name: "Estonia",
    countryCode: "EE"
  },

  Eswatini: {
    name: "Eswatini",
    countryCode: "SZ"
  },

  Ethiopia: {
    name: "Ethiopia",
    countryCode: "ET"
  },

  Falkland_Islands: {
    name: "Falkland Islands [Malvinas]",
    countryCode: "FK"
  },

  Faroe_Islands: {
    name: "Faroe Islands",
    countryCode: "FO"
  },

  Fiji: {
    name: "Fiji",
    countryCode: "FJ"
  },

  Finland: {
    name: "Finland",
    countryCode: "FI"
  },

  France: {
    name: "France",
    countryCode: "FR"
  },

  French_Guiana: {
    name: "French Guiana",
    countryCode: "GF"
  },

  French_Polynesia: {
    name: "French Polynesia",
    countryCode: "PF"
  },

  French_Southern_Territories: {
    name: "French Southern Territories",
    countryCode: "TF"
  },

  Gabon: {
    name: "Gabon",
    countryCode: "GA"
  },

  Gambia: {
    name: "Gambia",
    countryCode: "GM"
  },

  Georgia: {
    name: "Georgia",
    countryCode: "GE"
  },

  Germany: {
    name: "Germany",
    countryCode: "DE"
  },

  Ghana: {
    name: "Ghana",
    countryCode: "GH"
  },

  Gibraltar: {
    name: "Gibraltar",
    countryCode: "GI"
  },

  Greece: {
    name: "Greece",
    countryCode: "GR"
  },

  Greenland: {
    name: "Greenland",
    countryCode: "GL"
  },

  Grenada: {
    name: "Grenada",
    countryCode: "GD"
  },

  Guadeloupe: {
    name: "Guadeloupe",
    countryCode: "GP"
  },

  Guam: {
    name: "Guam",
    countryCode: "GU"
  },

  Guatemala: {
    name: "Guatemala",
    countryCode: "GT"
  },

  Guernsey: {
    name: "Guernsey",
    countryCode: "GG"
  },

  Guinea: {
    name: "Guinea",
    countryCode: "GN"
  },

  Guinea_Bissau: {
    name: "Guinea-Bissau",
    countryCode: "GW"
  },

  Guyana: {
    name: "Guyana",
    countryCode: "GY"
  },

  Haiti: {
    name: "Haiti",
    countryCode: "HT"
  },

  Heard_Island_and_McDonald_Islands: {
    name: "Heard Island and McDonald Islands",
    countryCode: "HM"
  },

  Holy_See: {
    name: "Holy See",
    countryCode: "VA"
  },

  Honduras: {
    name: "Honduras",
    countryCode: "HN"
  },

  Hong_Kong: {
    name: "Hong Kong",
    countryCode: "HK"
  },

  Hungary: {
    name: "Hungary",
    countryCode: "HU"
  },

  Iceland: {
    name: "Iceland",
    countryCode: "IS"
  },

  India: {
    name: "India",
    countryCode: "IN"
  },

  Indonesia: {
    name: "Indonesia",
    countryCode: "ID"
  },

  Iran: {
    name: "Iran",
    countryCode: "IR"
  },

  Iraq: {
    name: "Iraq",
    countryCode: "IQ"
  },

  Ireland: {
    name: "Ireland",
    countryCode: "IE"
  },

  Isle_of_Man: {
    name: "Isle of Man",
    countryCode: "IM"
  },

  Israel: {
    name: "Israel",
    countryCode: "IL"
  },

  Italy: {
    name: "Italy",
    countryCode: "IT"
  },

  Jamaica: {
    name: "Jamaica",
    countryCode: "JM"
  },

  Japan: {
    name: "Japan",
    countryCode: "JP"
  },

  Jersey: {
    name: "Jersey",
    countryCode: "JE"
  },

  Jordan: {
    name: "Jordan",
    countryCode: "JO"
  },

  Kazakhstan: {
    name: "Kazakhstan",
    countryCode: "KZ"
  },

  Kenya: {
    name: "Kenya",
    countryCode: "KE"
  },

  Kiribati: {
    name: "Kiribati",
    countryCode: "KI"
  },

  Korea_dem: {
    name: "Korea (the Democratic People's Republic of)",
    countryCode: "KP"
  },

  Korea_rep: {
    name: "Korea (the Republic of)",
    countryCode: "KR"
  },

  Kuwait: {
    name: "Kuwait",
    countryCode: "KW"
  },

  Kyrgyzstan: {
    name: "Kyrgyzstan",
    countryCode: "KG"
  },

  Lao: {
    name: "Lao People's Democratic Republic",
    countryCode: "LA"
  },

  Latvia: {
    name: "Latvia",
    countryCode: "LV"
  },

  Lebanon: {
    name: "Lebanon",
    countryCode: "LB"
  },

  Lesotho: {
    name: "Lesotho",
    countryCode: "LS"
  },

  Liberia: {
    name: "Liberia",
    countryCode: "LR"
  },

  Libya: {
    name: "Libya",
    countryCode: "LY"
  },

  Liechtenstein: {
    name: "Liechtenstein",
    countryCode: "LI"
  },

  Lithuania: {
    name: "Lithuania",
    countryCode: "LT"
  },

  Luxembourg: {
    name: "Luxembourg",
    countryCode: "LU"
  },

  Macao: {
    name: "Macao",
    countryCode: "MO"
  },

  Madagascar: {
    name: "Madagascar",
    countryCode: "MG"
  },

  Malawi: {
    name: "Malawi",
    countryCode: "MW"
  },

  Malaysia: {
    name: "Malaysia",
    countryCode: "MY"
  },

  Maldives: {
    name: "Maldives",
    countryCode: "MV"
  },

  Mali: {
    name: "Mali",
    countryCode: "ML"
  },

  Malta: {
    name: "Malta",
    countryCode: "MT"
  },

  Marshall_Islands: {
    name: "Marshall Islands",
    countryCode: "MH"
  },

  Martinique: {
    name: "Martinique",
    countryCode: "MQ"
  },

  Mauritania: {
    name: "Mauritania",
    countryCode: "MR"
  },

  Mauritius: {
    name: "Mauritius",
    countryCode: "MU"
  },

  Mayotte: {
    name: "Mayotte",
    countryCode: "YT"
  },

  Mexico: {
    name: "Mexico",
    countryCode: "MX"
  },

  Micronesia: {
    name: "Micronesia",
    countryCode: "FM"
  },

  Moldova: {
    name: "Moldova",
    countryCode: "MD"
  },

  Monaco: {
    name: "Monaco",
    countryCode: "MC"
  },

  Mongolia: {
    name: "Mongolia",
    countryCode: "MN"
  },

  Montenegro: {
    name: "Montenegro",
    countryCode: "ME"
  },

  Montserrat: {
    name: "Montserrat",
    countryCode: "MS"
  },

  Morocco: {
    name: "Morocco",
    countryCode: "MA"
  },

  Mozambique: {
    name: "Mozambique",
    countryCode: "MZ"
  },

  Myanmar: {
    name: "Myanmar",
    countryCode: "MM"
  },

  Namibia: {
    name: "Namibia",
    countryCode: "NA"
  },

  Nauru: {
    name: "Nauru",
    countryCode: "NR"
  },

  Nepal: {
    name: "Nepal",
    countryCode: "NP"
  },

  Netherlands: {
    name: "Netherlands",
    countryCode: "NL"
  },

  New_Caledonia: {
    name: "New Caledonia",
    countryCode: "NC"
  },

  New_Zealand: {
    name: "New Zealand",
    countryCode: "NZ"
  },

  Nicaragua: {
    name: "Nicaragua",
    countryCode: "NI"
  },

  Niger: {
    name: "Niger",
    countryCode: "NE"
  },

  Nigeria: {
    name: "Nigeria",
    countryCode: "NG"
  },

  Niue: {
    name: "Niue",
    countryCode: "NU"
  },

  Norfolk_Island: {
    name: "Norfolk Island",
    countryCode: "NF"
  },

  North_Macedonia: {
    name: "North Macedonia",
    countryCode: "MK"
  },

  Northern_Mariana_Islands: {
    name: "Northern Mariana Islands",
    countryCode: "MP"
  },

  Norway: {
    name: "Norway",
    countryCode: "NO"
  },

  Oman: {
    name: "Oman",
    countryCode: "OM"
  },

  Pakistan: {
    name: "Pakistan",
    countryCode: "PK"
  },

  Palau: {
    name: "Palau",
    countryCode: "PW"
  },

  Palestine: {
    name: "Palestine",
    countryCode: "PS"
  },

  Panama: {
    name: "Panama",
    countryCode: "PA"
  },

  Papua_New_Guinea: {
    name: "Papua New Guinea",
    countryCode: "PG"
  },

  Paraguay: {
    name: "Paraguay",
    countryCode: "PY"
  },

  Peru: {
    name: "Peru",
    countryCode: "PE"
  },

  Philippines: {
    name: "Philippines",
    countryCode: "PH"
  },

  Pitcairn: {
    name: "Pitcairn",
    countryCode: "PN"
  },

  Poland: {
    name: "Poland",
    countryCode: "PL"
  },

  Portugal: {
    name: "Portugal",
    countryCode: "PT"
  },

  Puerto_Rico: {
    name: "Puerto Rico",
    countryCode: "PR"
  },

  Qatar: {
    name: "Qatar",
    countryCode: "QA"
  },

  Romania: {
    name: "Romania",
    countryCode: "RO"
  },

  Russian_Federation: {
    name: "Russian Federation",
    countryCode: "RU"
  },

  Rwanda: {
    name: "Rwanda",
    countryCode: "RW"
  },

  Reunion: {
    name: "Réunion",
    countryCode: "RE"
  },

  Saint_Barthelemy: {
    name: "Saint Barthélemy",
    countryCode: "BL"
  },

  Saint_Helena: {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    countryCode: "SH"
  },

  Saint_Kitts_and_Nevis: {
    name: "Saint Kitts and Nevis",
    countryCode: "KN"
  },

  Saint_Lucia: {
    name: "Saint Lucia",
    countryCode: "LC"
  },

  Saint_Martin: {
    name: "Saint Martin",
    countryCode: "MF"
  },

  Saint_Pierre_and_Miquelon: {
    name: "Saint Pierre and Miquelon",
    countryCode: "PM"
  },

  Saint_Vincent: {
    name: "Saint Vincent and the Grenadines",
    countryCode: "VC"
  },

  Samoa: {
    name: "Samoa",
    countryCode: "WS"
  },

  San_Marino: {
    name: "San Marino",
    countryCode: "SM"
  },

  Sao_Tome: {
    name: "Sao Tome and Principe",
    countryCode: "ST"
  },

  Saudi_Arabia: {
    name: "Saudi Arabia",
    countryCode: "SA"
  },

  Senegal: {
    name: "Senegal",
    countryCode: "SN"
  },

  Serbia: {
    name: "Serbia",
    countryCode: "RS"
  },

  Seychelles: {
    name: "Seychelles",
    countryCode: "SC"
  },

  Sierra_Leone: {
    name: "Sierra Leone",
    countryCode: "SL"
  },

  Singapore: {
    name: "Singapore",
    countryCode: "SG"
  },

  Sint_Maarten: {
    name: "Sint Maarten",
    countryCode: "SX"
  },

  Slovakia: {
    name: "Slovakia",
    countryCode: "SK"
  },

  Slovenia: {
    name: "Slovenia",
    countryCode: "SI"
  },

  Solomon_Islands: {
    name: "Solomon Islands",
    countryCode: "SB"
  },

  Somalia: {
    name: "Somalia",
    countryCode: "SO"
  },

  South_Africa: {
    name: "South Africa",
    countryCode: "ZA"
  },

  South_Georgia: {
    name: "South Georgia and the South Sandwich Islands",
    countryCode: "GS"
  },

  South_Sudan: {
    name: "South Sudan",
    countryCode: "SS"
  },

  Spain: {
    name: "Spain",
    countryCode: "ES"
  },

  Sri_Lanka: {
    name: "Sri Lanka",
    countryCode: "LK"
  },

  Sudan: {
    name: "Sudan",
    countryCode: "SD"
  },

  Suriname: {
    name: "Suriname",
    countryCode: "SR"
  },

  Svalbard: {
    name: "Svalbard and Jan Mayen",
    countryCode: "SJ"
  },

  Sweden: {
    name: "Sweden",
    countryCode: "SE"
  },

  Switzerland: {
    name: "Switzerland",
    countryCode: "CH"
  },

  Syrian_Arab_Republic: {
    name: "Syrian Arab Republic",
    countryCode: "SY"
  },

  Taiwan: {
    name: "Taiwan",
    countryCode: "TW"
  },

  Tajikistan: {
    name: "Tajikistan",
    countryCode: "TJ"
  },

  Tanzania: {
    name: "Tanzania",
    countryCode: "TZ"
  },

  Thailand: {
    name: "Thailand",
    countryCode: "TH"
  },

  Timor_Leste: {
    name: "Timor-Leste",
    countryCode: "TL"
  },

  Togo: {
    name: "Togo",
    countryCode: "TG"
  },

  Tokelau: {
    name: "Tokelau",
    countryCode: "TK"
  },

  Tonga: {
    name: "Tonga",
    countryCode: "TO"
  },

  Trinidad_and_Tobago: {
    name: "Trinidad and Tobago",
    countryCode: "TT"
  },

  Tunisia: {
    name: "Tunisia",
    countryCode: "TN"
  },

  Turkmenistan: {
    name: "Turkmenistan",
    countryCode: "TM"
  },

  Turks_and_Caicos_Islands: {
    name: "Turks and Caicos Islands",
    countryCode: "TC"
  },

  Tuvalu: {
    name: "Tuvalu",
    countryCode: "TV"
  },

  Turkiye: {
    name: "Türkiye",
    countryCode: "TR"
  },

  Uganda: {
    name: "Uganda",
    countryCode: "UG"
  },

  Ukraine: {
    name: "Ukraine",
    countryCode: "UA"
  },

  United_Arab_Emirates: {
    name: "United Arab Emirates",
    countryCode: "AE"
  },

  United_Kingdom: {
    name: "United Kingdom of Great Britain and Northern Ireland",
    countryCode: "GB"
  },

  United_States_Minor_Outlying_Islands: {
    name: "United States Minor Outlying Islands",
    countryCode: "UM"
  },

  United_States_of_America: {
    name: "United States of America",
    countryCode: "US"
  },

  Uruguay: {
    name: "Uruguay",
    countryCode: "UY"
  },

  Uzbekistan: {
    name: "Uzbekistan",
    countryCode: "UZ"
  },

  Vanuatu: {
    name: "Vanuatu",
    countryCode: "VU"
  },

  Venezuela: {
    name: "Venezuela",
    countryCode: "VE"
  },

  Viet_Nam: {
    name: "Viet Nam",
    countryCode: "VN"
  },

  Virgin_Islands_GB: {
    name: "Virgin Islands (British)",
    countryCode: "VG"
  },

  Virgin_Islands_US: {
    name: "Virgin Islands (U.S.)",
    countryCode: "VI"
  },

  Wallis_and_Futuna: {
    name: "Wallis and Futuna",
    countryCode: "WF"
  },

  Western_Sahara: {
    name: "Western Sahara",
    countryCode: "EH"
  },

  Yemen: {
    name: "Yemen",
    countryCode: "YE"
  },

  Zambia: {
    name: "Zambia",
    countryCode: "ZM"
  },

  Zimbabwe: {
    name: "Zimbabwe",
    countryCode: "ZW"
  },

  Aland_Islands: {
    name: "Åland Islands",
    countryCode: "AX"
  }

};

export default Iso3166CountryCodes;
