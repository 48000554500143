import { Outlet } from "react-router-dom";

import CustomerIOProvider from "providers/CustomerIOProvider";
import FirstPromoterProvider from "providers/FirstPromoterProvider";
import ZohoProvider from "providers/ZohoProvider";


export default function ThirdPartyServicesLayout() {
  return (
    <>
      { /* support widget */ }
      <ZohoProvider/>

      { /* user related services */ }
      <FirstPromoterProvider/>
      <CustomerIOProvider/>

      <Outlet/>
    </>
  );
};
