import { forwardRef, InputHTMLAttributes } from "react";

import { cx } from "utils";


interface IDecoratedInputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  TrailingElement?: JSX.Element;
  inputPaddingForTrailingElement?: string;
  invalidTrailingElementClasses?: string;
}


const DecoratedInputField = forwardRef<HTMLInputElement, IDecoratedInputFieldProps>(
  function (
    {
      TrailingElement,
      inputPaddingForTrailingElement,
      invalidTrailingElementClasses,
      ...inputProps
    }: IDecoratedInputFieldProps,
    ref
  ) {
    return (
      <div className="relative">
        <input
          ref={ ref }
          formNoValidate
          { ...inputProps }
          className={ cx(
            inputProps.type !== "checkbox" && "w-full",
            "text-sm placeholder:text-lightGray dark:placeholder:text-neutral-500",
            "dark:rounded",
            "dark:px-2 dark:py-1",  // TODO this is for the small size only... should create proper css classes for the inputs as well, like we have for buttons
            inputProps.className,
            TrailingElement && inputPaddingForTrailingElement
          ) }
        />
        { TrailingElement && (
          <div className={ cx(
            "absolute inset-y-0 right-0 flex items-center pr-3 text-gray dark:text-neutral-600",
            invalidTrailingElementClasses
          ) }
          >
            { TrailingElement }
          </div>
        ) }
      </div>
    );
  }
);

export default DecoratedInputField;
