import React, { useState } from 'react';
import { InformationCircleIcon, ExclamationIcon, XCircleIcon, CheckCircleIcon } from "@heroicons/react/outline";
import { InformationCircleIcon as InformationCircleIconSolid } from "@heroicons/react/solid";
import { cx } from "utils";
import { Button } from "components/Button"
import CloseButton from 'components/CloseButton';

type AlertTheme = "info" | "warning" | "error" | "success";

interface AlertConfig {
  base: string;
  icon: React.ComponentType<any>;
  darkIcon?: React.ComponentType<any>;
  iconColor: string;
  border: string;
}

const alertConfigs: Record<AlertTheme, AlertConfig> = {
  info: {
    base: "bg-brandLightest dark:bg-info-50",
    icon: InformationCircleIconSolid,
    darkIcon: InformationCircleIcon,
    iconColor: "text-brandLight dark:text-info-600",
    border: "border-info-200",
  },
  warning: {
    base: "bg-warning-50",
    icon: ExclamationIcon,
    iconColor: "text-warning-600",
    border: "border-warning-200",
  },
  error: {
    base: "bg-error-50",
    icon: XCircleIcon,
    iconColor: "text-error-600",
    border: "border-error-200",
  },
  success: {
    base: "bg-success-50",
    icon: CheckCircleIcon,
    iconColor: "text-success-600",
    border: "border-success-200",
  },
};

export interface AlertProps {
  theme?: AlertTheme;
  isBanner?: boolean;
  description?: string;
  title?: string;
  showIcon?: boolean;
  closable?: boolean;
  showActions?: boolean;
  className?: string;
  onClose?: () => void;
}

const Content = ({
  theme = "info",
  description,
  title,
  showIcon,
  showActions,
}: Omit<AlertProps, 'closable' | 'onClose'>) => {
  const config = alertConfigs[theme];
  const Icon = config.icon;
  const DarkIcon = config.darkIcon;

  return (
    <>
      {showIcon && (
        <div className={cx("flex-shrink-0", config.iconColor)}>
          <Icon className={ cx("h-6 w-6", DarkIcon && "visible dark:hidden") } />
          { DarkIcon && <DarkIcon className="h-5 w-5 hidden dark:visible" /> }
        </div>
      )}
      <div className="ml-3 w-full">
        {title && <h3 className="text-base text-neutral-900">{title}</h3>}
        {description && (
          <div className={cx("text-sm text-neutral-700", title ? "mt-2" : "")}>{description}</div>
        )}
        {showActions && (
          <div className="mt-4">
              <Button text="Action" size="MD" />
          </div>
        )}
      </div>
    </>
  );
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  (
    {
      theme = 'info',
      isBanner = false,
      description,
      title,
      showIcon = true,
      closable = false,
      showActions = false,
      className,
      onClose,
      ...props
    }: AlertProps,
    ref
  ) => {
    const [isVisible, setIsVisible] = useState(true);
    const config = alertConfigs[theme];

    const handleClose = () => {
      setIsVisible(false);
      if (onClose) {
        onClose();
      }
    };

    if (!isVisible) {
      return null;
    }

    const alertStyle = cx(
      'flex items-center dark:items-start p-4',
      config.base,
      !isBanner && `dark:border dark:${config.border}`,
      className
    );

    return (
      <div ref={ref} className={alertStyle} {...props}>
        <Content
          theme={theme}
          isBanner={isBanner}
          description={description}
          title={title}
          showIcon={showIcon}
          showActions={showActions}
        />
        {closable && (
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <CloseButton
                onPress={handleClose}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default Alert;
