import { isValidUrl } from "components/hostedScrapingValidators";
import { SDEContent } from "./sdecontentTypes";

export const AsyncUrls: SDEContent = {
  projectType: 'async_urls',
  projectCategory: 'async_urls',
  apiUrlFn: (apiHost: string) => {
    return `${apiHost}/`;
  },
  docsSection: '',
  docsSectionAsync: '',
  inputKey: 'url',
  inputsKey: 'urls',
  validatorFunction: isValidUrl,
  newProjectEditProject: {
    inputBoxLabels: {
      listLiteralInputTitle: 'URLs',
      listLiteralInputPlaceholder: 'Enter one URL per line.',
    },
    inputSectionLabels: {
      inputSectionTitle: 'Input URLs',
      inputSectionDescription: "Enter URLs or upload a file",
    },
    projectContent: {
      title: 'Extract data from arbitrary websites',
      description: 'Download raw HTML from various URLs.',
      icon: 'urls',
      inputTitle: 'URL',
    },
  },
  apiPlayground: {
    inputSectionLabels: {
      inputSectionTitle: 'Input URL address',
      inputSectionDescription: 'Enter the URL you want to scrape. The URL must be correctly encoded, like https://httpbin.org/anything',
    },
    projectContent: {
      title: 'Data from URL',
      description: 'Create your request from any URL.',
      icon: 'urls',
    },
    enterInputsPlaceholder: 'https://httpbin.org/anything',
    enterInputsMessage: 'Enter URL addresses for scraping',
    codeViewPlaceHolder: 'Please provide a valid URL',
    dropdownTitle: '', // This is not in the SDE selector dropdown
  }
};
