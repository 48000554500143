
import { ReactComponent as EmtpyListIllustration } from "assets/images/project-list-empty-illustration.svg";
import { NewProjectCard } from './ProjectGridView';

export default function EmptyProjectGridView() {

  return (
    <div className="flex flex-row flex-wrap gap-6">
      <NewProjectCard />
      <div className="basis-[520px] grow shrink bg-white border border-slate-200 p-4">
        <div className="flex flex-row">
          <div className="grow-0 shrink-0 self-center">
            <EmtpyListIllustration/>
          </div>
          <div className="shrink grow text-slate-600 flex flex-col justify-center">
            <p className="mb-4">You want to extract data from URL’s or specific domains on a schedule? And you don’t want to run the code yourself?</p>
            <p>With the hosted projects you can extract data easily without running the code by yourself.</p> 
            <p>All projects will be saved here, where you can monitor and manage the progress and performance, and export the collected data after each successful run.</p>
          </div>
        </div>
      </div>
    </div>
  );
}
