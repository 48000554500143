import { Outlet } from "react-router-dom";

import ServicesProvider from "providers/ServicesProvider";
import UsercentricsProvider from "providers/UsercentricsProvider";


export default function DynamicServicesProviderLayout() {
  return (
    <ServicesProvider>
      <UsercentricsProvider>
        <Outlet/>
      </UsercentricsProvider>
    </ServicesProvider>
  );
};
