import { CollectorType } from "providers/HostedScrapingProvider";
import { AsyncApiParams } from "providers/HostedScrapingProvider/types";
import { ScrapingMethod, SupportedLanguages } from "routes/dashboard/ApiPlaygroundTypes"
import _ from "lodash";
import { collectorTypeToAPIURL, collectorTypeToInputFormat } from "sdecontent";

const API_API_HOST = process.env.REACT_APP_API_API_URL ?? 'https://api.scraperapi.com';
const PROXY_HOST = 'proxy-server.scraperapi.com';
const PROXY_PORT = '8001';
const ASYNC_URL = process.env.REACT_APP_ASYNC_URL ?? 'https://async.scraperapi.com/jobs';

const python_api_template = `import requests

payload = {{PARAMS}}
r = requests.get('{{API_URL}}', params=payload)
print(r.text)
`;

const python_proxy_template = `import requests
proxies = {
  "{{SCHEME}}": "scraperapi{{PARAMS}}:{{API_KEY}}@{{PROXY_URL}}"
}
r = requests.get('{{URL}}', proxies=proxies, verify=False)
print(r.text)
`;

const python_async_template=`import requests

r = requests.post(url = '{{ASYNC_URL}}', json={ 'apiKey': '{{API_KEY}}', 'urls': [{{URLS}}] {{PARAMS}} })
print(r.text)
`;

const curl_api_template = `curl '{{API_URL}}?{{PARAMS}}'`;

const curl_proxy_template = `curl -x "scraperapi{{PARAMS}}:{{API_KEY}}@{{PROXY_URL}}" -k "{{URL}}"`

const curl_async_template = `curl -X POST -H "Content-Type: application/json" -d '{"apiKey": "{{API_KEY}}", "urls": [{{URLS}}] {{PARAMS}} }' "{{ASYNC_URL}}"`

const ruby_api_template=`require 'net/http'
require 'json'

params = {{PARAMS}}
uri = URI('{{API_URL}}')
uri.query = URI.encode_www_form(params)
website_content = Net::HTTP.get(uri)
print(website_content)
`;

const ruby_proxy_template = `
require 'httparty'

HTTParty::Basement.default_options.update(verify: false)

response = HTTParty.get('{{URL}}', {
  http_proxyaddr: "{{PROXY_HOST}}",
  http_proxyport: "{{PROXY_PORT}}",
  http_proxyuser: "scraperapi{{PARAMS}}",
  http_proxypass: "{{API_KEY}}"
})

results = response.body
puts results
`;

const ruby_async_template = `require 'net/http'
require 'json'

uri = URI('{{ASYNC_URL}}')
req = Net::HTTP::Post.new(uri)
req.content_type = 'application/json'

# The object won't be serialized exactly like this
req.body = {
  'apiKey' => '{{API_KEY}}',
  'urls' => [{{URLS}}]{{PARAMS}}
}.to_json

req_options = {
  use_ssl: uri.scheme == 'https'
}
res = Net::HTTP.start(uri.hostname, uri.port, req_options) do |http|
  http.request(req)
end

results = res.body
puts results
`;


const nodejs_api_template = `// For this example you need the node-fetch npm packages: \`npm i node-fetch\`
import fetch from 'node-fetch';

fetch('{{API_URL}}?{{PARAMS}}')
  .then(response => {
    console.log(response)
  })
  .catch(error => {
    console.log(error)
  });
`;

const nodejs_proxy_template = `
const axios = require('axios');
axios.get('{{URL}}', {
  method: 'GET',
  proxy: {
    host: '{{PROXY_HOST}}',
    port: {{PROXY_PORT}},
    auth: {
      username: 'scraperapi{{PARAMS}}',
      password: '{{API_KEY}}'
    },
    protocol: 'http'
  }
})
  .then(console.log)
  .catch(console.error);
`;


const nodejs_async_template = `const axios = require('axios');

(async() => {
  const { data } = await axios({
    data: {
      apiKey: '{{API_KEY}}',
      urls: [{{URLS}}]{{PARAMS}}
    },
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    url: '{{ASYNC_URL}}'
  });

  console.log(data);
})();
`;

const php_api_template = `<?php
  $url = "{{API_URL}}?{{PARAMS}}";
  $ch = curl_init();
  curl_setopt($ch, CURLOPT_URL, $url);
  curl_setopt($ch, CURLOPT_RETURNTRANSFER, TRUE);
  curl_setopt($ch, CURLOPT_HEADER, FALSE);
  curl_setopt($ch, CURLOPT_SSL_VERIFYHOST, 0);
  curl_setopt($ch, CURLOPT_SSL_VERIFYPEER, 0);
  $response = curl_exec($ch);
  curl_close($ch);
  print_r($response);
  ?>
`;

const php_proxy_template=`<?php
  $ch = curl_init();
  curl_setopt($ch, CURLOPT_URL, "{{URL}}");
  curl_setopt($ch, CURLOPT_PROXY, "http://scraperapi{{PARAMS}}:{{API_KEY}}@{{PROXY_HOST}}:{{PROXY_PORT}}");
  curl_setopt($ch, CURLOPT_RETURNTRANSFER, TRUE);
  curl_setopt($ch, CURLOPT_HEADER, FALSE);
  curl_setopt($ch, CURLOPT_SSL_VERIFYHOST, 0);
  curl_setopt($ch, CURLOPT_SSL_VERIFYPEER, 0);
  $response = curl_exec($ch);
  curl_close($ch);
  var_dump($response);
  ?>
`;

const php_async_template = `<?php
$payload = json_encode(
  array(
    "apiKey" => "{{API_KEY}}",
    "urls" => {{URLS}}{{PARAMS}}
  )
);
$ch = curl_init();
curl_setopt( $ch, CURLOPT_URL, "{{ASYNC_URL}}");
curl_setOpt( $ch, CURLOPT_POST, 1);
curl_setopt( $ch, CURLOPT_POSTFIELDS, $payload );
curl_setopt( $ch, CURLOPT_HTTPHEADER, array(
  "Content-Type:application/json"
));
curl_setopt( $ch, CURLOPT_RETURNTRANSFER, TRUE);
$response = curl_exec( $ch );
curl_close( $ch );
print_r( $response );
?>`

const java_api_template = `
import java.net.*;
import java.io.*;

public class Scrape {
  public static void main(String[] args) {

    try {
      String url = "{{API_URL}}?{{PARAMS}}";
      URL urlForGetRequest = new URL(url);
      String readLine = null;
      HttpURLConnection conection = (HttpURLConnection) urlForGetRequest.openConnection();
      conection.setRequestMethod("GET");
      int responseCode = conection.getResponseCode();
      if (responseCode == HttpURLConnection.HTTP_OK) {
        BufferedReader in = new BufferedReader(new InputStreamReader(conection.getInputStream()));
        StringBuffer response = new StringBuffer();
        while ((readLine = in.readLine()) != null) {
          response.append(readLine);
        }
        in.close();
        System.out.println(response.toString());
      } else {
        throw new Exception("Error in API Call");
      }
    } catch (Exception ex) {
      ex.printStackTrace();
    }
  }
}
`;

const java_proxy_template = `
import java.net.*;
import java.io.*;
import java.util.*;

public class Scrape {
  public static void main(String[] args) {
    try {
      String apiKey = "{{API_KEY}}";
      String proxy = "{{PROXY_HOST}}";
      URL server = new URL("{{URL}}");
      String proxyUsername = "scraperapi{{PARAMS}}";
      Authenticator.setDefault(new Authenticator() {
        protected PasswordAuthentication getPasswordAuthentication() {
          return new PasswordAuthentication(proxyUsername, apiKey.toCharArray());
        }
      });
      Properties systemProperties = System.getProperties();
      systemProperties.setProperty("http.proxyHost", proxy);
      systemProperties.setProperty("http.proxyPort", "{{PROXY_PORT}}");
      systemProperties.setProperty("https.proxyHost", proxy);
      systemProperties.setProperty("https.proxyPort", "{{PROXY_PORT}}");
      System.setProperty("jdk.http.auth.tunneling.disabledSchemes", "");

      HttpURLConnection httpURLConnection = (HttpURLConnection) server.openConnection();
      httpURLConnection.connect();
      String readLine = null;
      int responseCode = httpURLConnection.getResponseCode();
      if (responseCode == HttpURLConnection.HTTP_OK) {
        BufferedReader in = new BufferedReader(new InputStreamReader(httpURLConnection.getInputStream()));
        StringBuffer response = new StringBuffer();
        while ((readLine = in.readLine()) != null) {
          response.append(readLine);
        }
        in.close();
        System.out.println(response.toString());
      } else {
        throw new Exception("Error in API Call");
      }
    } catch (Exception ex) {
      ex.printStackTrace();
    }
  }
}
`
;

const java_async_template = `
import java.net.*;
import java.io.*;
import java.nio.charset.StandardCharsets;


public class Scrape {
  public static void main(String[] args) {
    try {
      URL asyncApiUrl = new URL("{{ASYNC_URL}}");
      HttpURLConnection connection = (HttpURLConnection) asyncApiUrl.openConnection();
      connection.setRequestMethod("POST");
      connection.setRequestProperty("Content-Type", "application/json");
      connection.setDoOutput(true);
      try (OutputStream outputStream = connection.getOutputStream()) {
        outputStream.write("{\\"apiKey\\": \\"{{API_KEY}}\\", \\"urls\\": [{{URLS}}]{{PARAMS}} }".getBytes(StandardCharsets.UTF_8));
      }

      int responseCode = connection.getResponseCode();

      if (responseCode == HttpURLConnection.HTTP_OK) {
        try (BufferedReader in = new BufferedReader(new InputStreamReader(connection.getInputStream()))) {
          String readLine = null;
          StringBuffer response = new StringBuffer();

          while ((readLine = in.readLine()) != null) {
            response.append(readLine);
          }

          System.out.println(response);
        }
      } else {
        throw new Exception("Error in API Call");
      }
    } catch (Exception ex) {
      ex.printStackTrace();
    }
  }
}
`;

type ParametersMap = Record<string, boolean | string | string[] | number>;

const toPythonString = (value: undefined | boolean | string | number ): string => {
  switch (typeof value) {
    case 'undefined': return '';
    case 'string': return `'${value}'`;
    case 'boolean': return value ? 'True' : 'False';
    case 'number': return `${value}`;
  }
}

const toRubyString = (value: undefined | boolean | string | number): string => {
  switch (typeof value) {
    case 'undefined': return '';
    case 'string': return `"${value}"`
    case 'boolean': return value ? 'true' : 'false';
    case 'number': return `${value}`;
  }
}

const toPhpString = (value: undefined | boolean | string | number): string => {
  switch (typeof value) {
    case 'undefined': return '';
    case 'string': return `"${value}"`
    case 'boolean': return value ? 'TRUE' : 'FALSE';
    case 'number': return `${value}`;
  }
}

const formatPythonDict = (parameters: ParametersMap): string => {

  const elements: string[] = [];

  for (const [key, value] of Object.entries(parameters)) {
    if (Array.isArray(value)) {
      const values: (undefined | boolean | string | number)[] = value as (undefined | boolean | string | number)[];
      if (values.length === 0) {
        elements.push(`${key}: ''`);
      } else if (values.length === 1) {
        elements.push(`'${key}': '${toPythonString(values[0])}'`);
      }
      else {
        elements.push(`'${key}': [${values.map(v => `${toPythonString(v)}`).join(', ')}]`);
      }
    } else {
      elements.push(`'${key}': ${toPythonString(value)}`);
    }
  }

  return `{ ${elements.join(', ')} }`;
}

const formatRubyArray = (parameters: ParametersMap): string => {

  const elements: string[] = [];

  for (const [key, value] of Object.entries(parameters)) {
    if (Array.isArray(value)) {
      const values: (undefined | boolean | string | number)[] = value as (undefined | boolean | string | number)[];
      if (values.length === 0) {
        elements.push(`${key}: ''`);
      } else if (values.length === 1) {
        elements.push(`"${key}" => ${toRubyString(values[0])}`);
      }
      else {
        elements.push(`"${key}" => [${values.map(v => `${toRubyString(v)}`).join(', ')}]`);
      }
    } else {
      elements.push(`"${key}" => ${toRubyString(value)}`);
    }
  }

  return `{ ${elements.join(', ')} }`;
}

const formatPhpArray = (parameters: ParametersMap): string => {
  const elements: string[] = [];

  for (const [key, value] of Object.entries(parameters)) {
    if (Array.isArray(value)) {
      const values: (undefined | boolean | string | number)[] = value as (undefined | boolean | string | number)[];
      if (values.length === 0) {
        elements.push(`${key}: ''`);
      } else if (values.length === 1) {
        elements.push(`"${key}" => ${toPhpString(values[0])}`);
      }
      else {
        elements.push(`"${key}" => [${values.map(v => `${toPhpString(v)}`).join(', ')}]`);
      }
    } else {
      elements.push(`"${key}" => ${toPhpString(value)}`);
    }
  }

  return `array( ${elements.join(', ')} )`;
}

const formatPythonJsonApiParams = (parameters: ParametersMap): string => {
  if (Object.keys(parameters).length === 0) {
    return "";
  } else {
    return ", 'apiParams':" + formatPythonDict(parameters);
  }
}

const formatNodejsJsonApiParams = (parameters: ParametersMap): string => {
  if (Object.keys(parameters).length === 0) {
    return "";
  } else {
    return `,
      apiParams: ${JSON.stringify(parameters)}`;
  }
}

const formatPhpJsonApiParams = (parameters: ParametersMap): string => {
  if (Object.keys(parameters).length === 0) {
    return "";
  } else {
    return `,
    "apiParams" => ${formatPhpArray(parameters)}`;
  }
};

const formatRubyJsonApiParams = (parameters: ParametersMap): string => {
  if (Object.keys(parameters).length === 0) {
    return "";
  } else {
    return `,
    'apiParams' => ${formatRubyArray(parameters)}`;
  }
};

const formatJavaJsonApiParams = (parameters: ParametersMap): string => {
  if (Object.keys(parameters).length === 0) {
    return "";
  } else {
    const rawJSON = JSON.stringify(parameters);
    const escapedJSON = rawJSON.replace(/"/g, '\\"');
    return `, \\"apiParams\\": ${escapedJSON}`;
  }
};


const formatParameters = (joiningString: string) => (parameters: ParametersMap): string => {
  const elements: string[] = [];

  for (const [key, value] of Object.entries(parameters)) {
    if (Array.isArray(value)) {
      const values: (undefined | boolean | string)[] = value as (undefined | boolean | string)[];
      if (values.length === 0) {
        elements.push(`${key}: ''`);
      } else if (values.length === 1) {
        elements.push(`${encodeURIComponent(key)}: '${encodeURIComponent(String(values[0]))}'`);
      }
      else {
        elements.push(`${encodeURIComponent(key)}=${values.map(v => `${encodeURIComponent(String(v))}`).join(',')}`);
      }
    } else {
      elements.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    }
  }

  return elements.join(joiningString);
}

const formatParametersForURL = formatParameters('&');
const formatParametersForProxyURL = formatParameters('.');

const formatParametersForCurlBodyString = (parameters: ParametersMap): string => {
  if (Object.keys(parameters).length === 0) {
    return "";
  }
  const parametersDict = JSON.stringify(parameters);
  return ', "apiParams":' + parametersDict;
}

const sampleTemplate = (language: SupportedLanguages, scrapeingMethod: ScrapingMethod): string | undefined => {
  const isApiMethod = scrapeingMethod === 'api' || scrapeingMethod === 'structured_data_endpoint';
  if (language === 'curl' && isApiMethod) { return curl_api_template; }
  if (language === 'curl' && scrapeingMethod === 'async') { return curl_async_template; }
  if (language === 'curl' && scrapeingMethod === 'proxy_mode') { return curl_proxy_template; }
  if (language === 'python' && isApiMethod) { return python_api_template; }
  if (language === 'python' && scrapeingMethod === 'async') { return python_async_template; }
  if (language === 'python' && scrapeingMethod === 'proxy_mode') { return python_proxy_template; }
  if (language === 'nodejs' && isApiMethod) { return nodejs_api_template; }
  if (language === 'nodejs' && scrapeingMethod === 'async') { return nodejs_async_template; }
  if (language === 'nodejs' && scrapeingMethod === 'proxy_mode') { return nodejs_proxy_template; }
  if (language === 'php' && isApiMethod) { return php_api_template; }
  if (language === 'php' && scrapeingMethod === 'async') { return php_async_template; }
  if (language === 'php' && scrapeingMethod === 'proxy_mode') { return php_proxy_template; }
  if (language === 'ruby' && isApiMethod) { return ruby_api_template; }
  if (language === 'ruby' && scrapeingMethod === 'async') { return ruby_async_template; }
  if (language === 'ruby' && scrapeingMethod === 'proxy_mode') { return ruby_proxy_template; }
  if (language === 'java' && isApiMethod) { return java_api_template; }
  if (language === 'java' && scrapeingMethod === 'async') { return java_async_template; }
  if (language === 'java' && scrapeingMethod === 'proxy_mode') { return java_proxy_template; }
}

type FormatParameterFn = (parameters: ParametersMap) => string;


const formatParametersFunction = (language: SupportedLanguages, scrapeingMethod: ScrapingMethod): FormatParameterFn => {
  if (scrapeingMethod === 'api') {
    switch (language) {
      case 'curl': return formatParametersForURL;
      case 'python': return formatPythonDict;
      case 'nodejs': return formatParametersForURL;
      case 'php': return formatParametersForURL;
      case 'ruby': return formatRubyArray;
      case 'java': return formatParametersForURL;
    }
  } else if (scrapeingMethod === 'proxy_mode') {
    switch (language) {
      case 'curl': return formatParametersForProxyURL;
      case 'python': return formatParametersForProxyURL;
      case 'nodejs': return formatParametersForProxyURL;
      case 'php': return formatParametersForProxyURL;
      case 'ruby': return formatParametersForProxyURL;
      case 'java': return formatParametersForProxyURL;
    }
  } else if (scrapeingMethod === 'async') {
    switch (language) {
      case 'curl': return formatParametersForCurlBodyString;
      case 'python': return formatPythonJsonApiParams;
      case 'nodejs': return formatNodejsJsonApiParams;
      case 'php': return formatPhpJsonApiParams;
      case 'ruby': return formatRubyJsonApiParams;
      case 'java': return formatJavaJsonApiParams;
    }
  } else {
    throw new Error('Unreachable');
  }
}

type FormatUrlsFn = (urls: string[]) => string;

const formatArrayForAsyncUrlsFunction =  (language: SupportedLanguages): FormatUrlsFn => {
    switch (language) {
      case 'curl': return (urls: string[]) => urls.map(u => `"${u}"`).join(', ');
      case 'python': return (urls: string[]) => urls.map(u => `"${u}"`).join(', ');
      case 'nodejs': return (urls: string[]) => urls.map(u => `"${u}"`).join(', ');
      case 'php': return (urls: string[]) => {
        const urlList = urls.map(u => `"${u}"`).join(', ');
        return `array( ${urlList} )`;
      };
      case 'ruby': return (urls: string[]) => urls.map(u => `"${u}"`).join(', ');
      case 'java': return (urls: string[]) => urls.map(u => `\\"${u}\\"`).join(', ');
    }
}

// countryCode => country_code, keepHeaders => keep_headers, etc
const apiParamsToQueryStringMap = (apiParams: AsyncApiParams): Record<string, boolean | string | string[] > => {
  const toSnakeKey = (oldKey: string): string => {
    if (oldKey === 'retry404') {
      return 'retry_404'
    } else {
      return oldKey.replace(/([A-Z])/g, '_$1').toLowerCase();
    }
  };
  const result: Record<string, boolean | string | string[]> = {};
  for (const key of Object.keys(apiParams)) {
    const newKey = toSnakeKey(key);
    result[newKey] = `${apiParams[key]}`;
  }
  return result;
}

const apiSampleCode = (
  language: SupportedLanguages,
  collector: CollectorType,
  input: string,
  apiKey: string,
  apiParams: AsyncApiParams
) => {
  const getTheAPIEndpointFn = collectorTypeToAPIURL(collector);
  const inputKey =  collectorTypeToInputFormat(collector, 1);
  const parameters: ParametersMap = {
    'api_key': apiKey,
    [inputKey]: input,
    ...apiParamsToQueryStringMap(apiParams)
  };
  const nonNilParameter = _.omitBy(parameters, _.isNil);

  const template = sampleTemplate(language, 'api');
  if (template === undefined) {
    return undefined;
  }
  const parameterString = formatParametersFunction(language, 'api')(nonNilParameter)
  return template
    .replace('{{API_URL}}', getTheAPIEndpointFn(API_API_HOST))
    .replace('{{PARAMS}}', parameterString);
}

const proxySampleCode = (
  language: SupportedLanguages,
  input: string,
  apiKey: string,
  apiParams: AsyncApiParams
) => {
  const parameters: ParametersMap = {
    ...apiParamsToQueryStringMap(apiParams)
  };
  const nonNilParameter = _.omitBy(parameters, _.isNil);

  const template = sampleTemplate(language, 'proxy_mode');
  if (template === undefined) {
    return undefined;
  }

  const scheme = (() =>{
    try {
      return new URL(input).protocol.replace(':', '');
    } catch (e) {
      return 'http';
    }
  })();

  let parameterString = formatParametersFunction(language, 'proxy_mode')(nonNilParameter)
  if (parameterString.length > 0) {
    parameterString = '.' + parameterString;
  }
  return template
    .replace('{{API_KEY}}', apiKey)
    .replace('{{PROXY_URL}}', `${PROXY_HOST}:${PROXY_PORT}`)
    .replace('{{PROXY_HOST}}', PROXY_HOST)
    .replace('{{PROXY_PORT}}', PROXY_PORT)
    .replace('{{URL}}', input)
    .replace('{{SCHEME}}', scheme)
    .replace('{{PARAMS}}', parameterString);
}

const asyncSampleCode = (
  language: SupportedLanguages,
  input: string,
  apiKey: string,
  apiParams: AsyncApiParams
) => {
  const parameters: ParametersMap = {
    ...apiParamsToQueryStringMap(apiParams)
  };

  const inputs = input.split('\n').map(i => i.trim()).filter(i => i.length > 0);

  const nonNilParameter = _.omitBy(parameters, _.isNil);

  const template = sampleTemplate(language, 'async');
  if (template === undefined) {
    return undefined;
  }
  const parameterString = formatParametersFunction(language, 'async')(nonNilParameter)
  const formatArrayForAsyncUrls = formatArrayForAsyncUrlsFunction(language)(inputs);

  return template
    .replace('{{API_KEY}}', apiKey)
    .replace('{{URLS}}', formatArrayForAsyncUrls)
    .replace('{{ASYNC_URL}}', ASYNC_URL)
    .replace('{{PARAMS}}', parameterString);
}

export const sampleCode = (
  language: SupportedLanguages,
  scrapingMethod: ScrapingMethod,
  collector: CollectorType,
  input: string,
  apiKey: string,
  apiParams: AsyncApiParams
): string | undefined => {
  if (scrapingMethod === 'api' || scrapingMethod === 'structured_data_endpoint') {
    return apiSampleCode(language, collector, input, apiKey, apiParams);
  } else if (scrapingMethod === 'proxy_mode') {
    return proxySampleCode(language, input, apiKey, apiParams);
  } else if (scrapingMethod === 'async') {
    return asyncSampleCode(language, input, apiKey, apiParams);
  }
}
