import { Turnstile, TurnstileInstance } from "@marsidev/react-turnstile";
import { forwardRef, useImperativeHandle, useRef } from "react";

import { CaptchaWidgetProps } from "./index";


const TurnstileWidget = forwardRef(({ setCompleted }: CaptchaWidgetProps, ref) => {
  const turnstileWidgetRef = useRef<TurnstileInstance>();
  useImperativeHandle(ref, () => turnstileWidgetRef.current!);

  return (
    <Turnstile
      ref={ turnstileWidgetRef }
      siteKey={ process.env.REACT_APP_TURNSTILE_SITE_KEY! }
      options={ {
        theme: "light",
        size: "normal",
        responseField: true,
        responseFieldName: "turnstile-token"
      } }
      onError={ () => setCompleted?.(false) }
      onSuccess={ () => setCompleted?.(true) }
      onExpire={ () => setCompleted?.(false) }
    />
  );
});

export default TurnstileWidget;
