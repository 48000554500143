import Cookies from "js-cookie";


export type ScraperAPICookies =
  "sapiTargetUrl" |
  "saFirstPage" |
  "saReferrer" |
  "saTryNewDashboardDesign";

export function getCookie(name: ScraperAPICookies): string | undefined {
  return Cookies.get(name);
}

export function setCookie(name: ScraperAPICookies, value: string, options?: Cookies.CookieAttributes): void {
  Cookies.set(
    name,
    value,
    {
      expires: 365 * 100,
      domain: process.env.REACT_APP_SUBDOMAIN || ".scraperapi.com",
      ...options
    }
  );
}
