import React, { ReactNode } from 'react';
import { cx } from 'utils';

import { Size, Sizes } from 'styles/sizes';

type CardSizes = Sizes<{ padding: string, gap: string }>;

const cardSizes: CardSizes = {
  SM: { padding: "p-3", gap: "gap-y-3" },
  MD: { padding: "p-4", gap: "gap-y-4" },
  LG: { padding: "p-5", gap: "gap-y-5" },
  XL: { padding: "p-6", gap: "gap-y-6" },
};

export interface ICardProps {
  title?: string | ReactNode;
  extra?: ReactNode;
  children?: ReactNode;
  footer?: ReactNode;
  className?: string;
  size?: Size;
  hoverable?: boolean;
  segmented?: boolean;
  borderless?: boolean;
  horizontal?: boolean;
}

const Card: React.FC<ICardProps> = ({
  title,
  extra,
  children,
  footer,
  className,
  size = "MD",
  hoverable = false,
  segmented = false,
  borderless = false,
  horizontal = false,
}) => {
  const sizeClass = cardSizes[size];

  return (
    <div
      className={cx(
        !borderless && (
          "dark:rounded-md border border-neutral-200 " +

          // supporting cases when used inside a <CardStack>
          "group-[.flex]/card-stack:rounded-none " +
          // setting borders for horizontal layout
          "group-[.flex-row]/card-stack:border-r-0 " +
          "first:group-[.flex-row]/card-stack:border-l first:group-[.flex-row]/card-stack:rounded-l-md " +
          "last:group-[.flex-row]/card-stack:border-r last:group-[.flex-row]/card-stack:rounded-r-md " +

          // setting borders for vertical layout
          "group-[.flex-col]/card-stack:border-b-0 " +
          "first:group-[.flex-col]/card-stack:border-t first:group-[.flex-col]/card-stack:rounded-t-md " +
          "last:group-[.flex-col]/card-stack:border-b last:group-[.flex-col]/card-stack:rounded-b-md "
        ),
        hoverable && "transition-colors duration-200 dark:hover:bg-accent-50/50",
        "flex justify-between",
        horizontal ? "flex-row" : "flex-col",
        !borderless && sizeClass?.padding,
        sizeClass?.gap,
        className
      )}
    >
      {(title || extra) && (
        <div className={cx(
          "flex justify-between items-center",
          segmented && "border-neutral-100",
          segmented && (horizontal ? "border-r" : "border-b")
        )}>
          {title && <h2 className="text-lg font-medium">{title}</h2>}
          {extra && <div>{extra}</div>}
        </div>
      )}
      {children}
      {footer && (
        <div>{footer}</div>
      )}
    </div>
  );
};

export default Card;
