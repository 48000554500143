
const collectorInputs: Record<string, {title: string, description: string}> = {
    'json_url': {
      title: 'JSON URL',
      description: 'An URL that contains a JSON list with the data to process',
    },
    'list_literal': {
        title: 'List',
        description: 'Comma separated list',
    },
    'upload_json_list': {
        title: 'Upload JSON list',
        description: 'Uploads a JSON file with a list of your inputs',
    },
    'upload_csv_list': {
        title: 'Upload list file',
        description: 'Uploads a file with a list of your inputs',
    },
    'webhook_input': {
        title: 'Webhook input',
        description: 'Downloads a list of inputs from web server',
    },
    'unknown': {
      title: 'Unknown',
      description: 'Unknown configuration type. Probably invalid config',
    },
};

export const inputTypeToTitleAndDescription = (inputType: string): {title: string, description: string} => {
  const { title, description } = collectorInputs[inputType] || collectorInputs['unknown'];
  return{ title, description }; 
}