export const async_ebay_product = {
  input: "166619046796",
  result: "  \"product_id_epid\": \"166619046796\",\n" +
    "  \"title\": \"Apple AirPods Pro 2nd Generation 2023 A2968 Magsafe USB-C Charging - Excellent\",\n" +
    "  \"seller\": {\n" +
    "    \"name\": \"Amazing Wireless\",\n" +
    "    \"seller_url\": \"https://www.ebay.com/str/amazingwireless\",\n" +
    "    \"seller_reviews_count\": 141910,\n" +
    "    \"seller_review\": \"99.4% positive\",\n" +
    "    \"top_rated\": true\n" +
    "  },\n" +
    "  \"price\": {\n" +
    "    \"value\": 149.99,\n" +
    "    \"currency\": \"USD\"\n" +
    "  },\n" +
    "  \"images\": [\n" +
    "    \"https://i.ebayimg.com/images/g/rm4AAOSwPtJljzDc/s-l1600.webp\",\n" +
    "    \"https://i.ebayimg.com/images/g/WDgAAOSwr3ZljzDb/s-l140.webp\",\n" +
    "    \"https://i.ebayimg.com/images/g/RfwAAOSwaj5ljzDb/s-l140.webp\",\n" +
    "    \"https://i.ebayimg.com/images/g/n5wAAOSwIHNmM9iK/s-l140.webp\",\n" +
    "    \"https://i.ebayimg.com/images/g/BREAAOSw8XxmM9iK/s-l140.webp\",\n" +
    "    \"https://i.ebayimg.com/images/g/i~kAAOSw9vRmM9iK/s-l140.webp\",\n" +
    "    \"https://i.ebayimg.com/images/g/d-IAAOSwrrtmM9iK/s-l140.webp\",\n" +
    "    \"https://i.ebayimg.com/images/g/F9oAAOSwc79mM9iJ/s-l140.webp\",\n" +
    "    \"https://i.ebayimg.com/images/g/I2QAAOSwCz5mM9iK/s-l140.webp\",\n" +
    "    \"https://i.ebayimg.com/images/g/w7EAAOSwu~hiKndx/s-l140.webp\"\n" +
    "  ],\n" +
    "  \"available_quantity\": \"More than 10 available\",\n" +
    "  \"sold_items\": \"1,866 sold\",\n" +
    "  \"shipping_costs\": {\n" +
    "    \"value\": 0\n" +
    "  },\n" +
    "  \"shipping_notes\": \"Free 2-3 day shipping\",\n" +
    "  \"return_policy\": \"60 days returns. Seller pays for return shipping.\",\n" +
    "  \"condition\": \"Excellent - Refurbished\",\n" +
    "  \"year_manufactured\": \"2023\",\n" +
    "  \"brand\": \"Apple\",\n" +
    "  \"color\": \"White\",\n" +
    "  \"model\": \"Apple AirPods Pro (2nd generation)\",\n" +
    "  \"item_specifics\": [\n" +
    "    {\n" +
    "      \"label\": \"Seller Notes\",\n" +
    "      \"value\": \"“85%+ battery life. The Device is in Excellent Cosmetics. Have been Tested, 100% Functionality. Comes with Accessories.” Read Lessabout the seller notes \"\n" +
    "    },\n" +
    "    {\n" +
    "      \"label\": \"UPC\",\n" +
    "      \"value\": \"0195949052484\"\n" +
    "    },\n" +
    "    {\n" +
    "      \"label\": \"Form Factor\",\n" +
    "      \"value\": \"In-Ear Only\"\n" +
    "    },\n" +
    "    {\n" +
    "      \"label\": \"Type\",\n" +
    "      \"value\": \"Canal Earbud (In Ear Canal)\"\n" +
    "    },\n" +
    "    {\n" +
    "      \"label\": \"Number of Earpieces\",\n" +
    "      \"value\": \"Double\"\n" +
    "    },\n" +
    "    {\n" +
    "      \"label\": \"Wireless Technology\",\n" +
    "      \"value\": \"Bluetooth\"\n" +
    "    },\n" +
    "    {\n" +
    "      \"label\": \"Connectivity\",\n" +
    "      \"value\": \"Bluetooth\"\n" +
    "    },\n" +
    "    {\n" +
    "      \"label\": \"MPN\",\n" +
    "      \"value\": \"MTJV3AM/A\"\n" +
    "    },\n" +
    "    {\n" +
    "      \"label\": \"Features\",\n" +
    "      \"value\": \"Active Noise Cancellation, Water-Resistant, Sweat-Proof\"\n" +
    "    },\n" +
    "    {\n" +
    "      \"label\": \"Microphone Type\",\n" +
    "      \"value\": \"Built-In\"\n" +
    "    }\n" +
    "  ],\n" +
    "  \"item_description_from_seller_url\": \"https://vi.vipr.ebaydesc.com/itmdesc/166619046796?t=0&category=80077&seller=amazing-wireless&excSoj=1&ver=0&excTrk=1&lsite=0&ittenable=true&domain=ebay.com&descgauge=1&cspheader=1&oneClk=2&secureDesc=1\",\n" +
    "  \"rating\": 4.4,\n" +
    "  \"review_count\": 71,\n" +
    "  \"rating_count_5stars\": 55,\n" +
    "  \"rating_count_4stars\": 2,\n" +
    "  \"rating_count_3stars\": 6,\n" +
    "  \"rating_count_2stars\": 2,\n" +
    "  \"rating_count_1star\": 6,\n" +
    "  \"easy_to_use\": 84,\n" +
    "  \"well_designed\": 84,\n" +
    "  \"good_value\": 76,\n" +
    "  \"reviews\": [\n" +
    "    {\n" +
    "      \"stars\": 5,\n" +
    "      \"author\": \" by luczama66\",\n" +
    "      \"review_date\": \"May 29, 2024\",\n" +
    "      \"title\": \"First time being an eBay buyer and was super sus about buying an airpod replacement on here. Glad i did tho because the airpod works just fine. You ca\",\n" +
    "      \"content\": \"First time being an eBay buyer and was super sus about buying an airpod replacement on here. Glad i did tho because the airpod works just fine. You can obviously tell it’s used bc it has small scratches but it isn’t a big deal for me. I liked how it came with written instructions on how to reset it even for different airpod generations! Worth the price too. \",\n" +
    "      \"attrs\": [\n" +
    "        {\n" +
    "          \"label\": \"Verified purchase\",\n" +
    "          \"value\": \"Yes\"\n" +
    "        },\n" +
    "        {\n" +
    "          \"label\": \"Condition\",\n" +
    "          \"value\": \"Pre-Owned\"\n" +
    "        },\n" +
    "        {\n" +
    "          \"label\": \"Sold by\",\n" +
    "          \"value\": \"pezzs_inc\"\n" +
    "        }\n" +
    "      ]\n" +
    "    },\n" +
    "    {\n" +
    "      \"stars\": 3,\n" +
    "      \"author\": \" by kz9f424\",\n" +
    "      \"review_date\": \"May 05, 2024\",\n" +
    "      \"title\": \"They are definitely clones of AirPods Pros 2nd Gen as I noticed a few features are either missing or disabled. That and the name they have when first \",\n" +
    "      \"content\": \"They are definitely clones of AirPods Pros 2nd Gen as I noticed a few features are either missing or disabled. That and the name they have when first pairing is literally “Not Your AirPod Pros”. They do sound good though and I can live without conversation awareness and the inability to turn off charging case sounds for the money I saved buying these instead of shelling out money to buy directly from Apple. \",\n" +
    "      \"attrs\": [\n" +
    "        {\n" +
    "          \"label\": \"Verified purchase\",\n" +
    "          \"value\": \"Yes\"\n" +
    "        },\n" +
    "        {\n" +
    "          \"label\": \"Condition\",\n" +
    "          \"value\": \"Pre-Owned\"\n" +
    "        },\n" +
    "        {\n" +
    "          \"label\": \"Sold by\",\n" +
    "          \"value\": \"thunder-6325\"\n" +
    "        }\n" +
    "      ]\n" +
    "    },\n" +
    "    {\n" +
    "      \"stars\": 5,\n" +
    "      \"author\": \" by morr.wayn\",\n" +
    "      \"review_date\": \"Apr 29, 2024\",\n" +
    "      \"title\": \"Apple AirPods Pro (2nd Generation) Wireless Ear Buds USB-C Charging MTJV3AM/A \",\n" +
    "      \"content\": \"Connect and install on my I Phone was as simple as a couple of clicks, so straight foreword. They fit my ears perfectly out of the box, extra tips are included. The sound is far soupier to the other three brands I have tried. It was nice while waiting at the dentist office, listing to music, seated under the TV that was on, the TV was not noticeable as in blocked completely, very nice. These are also less massive that the Sony buds that died on me. Yes I am pleased.\",\n" +
    "      \"attrs\": [\n" +
    "        {\n" +
    "          \"label\": \"Verified purchase\",\n" +
    "          \"value\": \"Yes\"\n" +
    "        },\n" +
    "        {\n" +
    "          \"label\": \"Condition\",\n" +
    "          \"value\": \"New\"\n" +
    "        },\n" +
    "        {\n" +
    "          \"label\": \"Sold by\",\n" +
    "          \"value\": \"amazing-wireless\"\n" +
    "        }\n" +
    "      ]\n" +
    "    },\n" +
    "    {\n" +
    "      \"stars\": 5,\n" +
    "      \"author\": \" by rambo-lambo65\",\n" +
    "      \"review_date\": \"Mar 08, 2024\",\n" +
    "      \"title\": \"Lightweight with great features\",\n" +
    "      \"content\": \"A little too expensive but they are fantastic\",\n" +
    "      \"attrs\": [\n" +
    "        {\n" +
    "          \"label\": \"Verified purchase\",\n" +
    "          \"value\": \"Yes\"\n" +
    "        },\n" +
    "        {\n" +
    "          \"label\": \"Sold by\",\n" +
    "          \"value\": \"goldstar_tech\"\n" +
    "        }\n" +
    "      ]\n" +
    "    },\n" +
    "    {\n" +
    "      \"stars\": 5,\n" +
    "      \"author\": \" by rolandk51\",\n" +
    "      \"review_date\": \"Mar 03, 2024\",\n" +
    "      \"title\": \"Great sounding, great fit, quality build. A good choice \",\n" +
    "      \"content\": \"Great sounding earbuds. Fit very nice charge really well in the case. The Case charges well when resting on charging plate. Highly recommend these sound great.\",\n" +
    "      \"attrs\": [\n" +
    "        {\n" +
    "          \"label\": \"Verified purchase\",\n" +
    "          \"value\": \"Yes\"\n" +
    "        },\n" +
    "        {\n" +
    "          \"label\": \"Condition\",\n" +
    "          \"value\": \"New\"\n" +
    "        }\n" +
    "      ]\n" +
    "    }\n" +
    "  ],\n" +
    "  \"similar_items\": [\n" +
    "    {\n" +
    "      \"title\": \"Apple AirPods Pro 2nd Generation with MagSafe Wireless Charging Case (USB‑C)...\",\n" +
    "      \"url\": \"https://www.ebay.com/itm/387434387384\",\n" +
    "      \"image_url\": \"https://i.ebayimg.com/thumbs/images/g/yFkAAOSwW5Zm-fel/s-l500.jpg\",\n" +
    "      \"price\": {\n" +
    "        \"value\": 85,\n" +
    "        \"currency\": \"USD\"\n" +
    "      },\n" +
    "      \"condition\": \"Pre-owned\",\n" +
    "      \"shipping_cost\": {\n" +
    "        \"value\": 7.71,\n" +
    "        \"currency\": \"USD\"\n" +
    "      },\n" +
    "      \"seller_feedback\": \"Seller with 100% positive feedback\"\n" +
    "    },\n" +
    "    {\n" +
    "      \"title\": \"Apple AirPods Pro 2nd Generation 2023 A2968 Magsafe USB-C Charging - SEALED\",\n" +
    "      \"url\": \"https://www.ebay.com/itm/176602653143\",\n" +
    "      \"image_url\": \"https://i.ebayimg.com/thumbs/images/g/OCUAAOSwh7Bm-clk/s-l500.jpg\",\n" +
    "      \"price\": {\n" +
    "        \"value\": 174,\n" +
    "        \"currency\": \"USD\"\n" +
    "      },\n" +
    "      \"condition\": \"New\",\n" +
    "      \"shipping_cost\": {\n" +
    "        \"value\": 0\n" +
    "      },\n" +
    "      \"watchers\": 5\n" +
    "    },\n" +
    "    {\n" +
    "      \"title\": \"Apple AirPods Pro 2nd Generation with MagSafe Wireless Charging Case (USB‑C)...\",\n" +
    "      \"url\": \"https://www.ebay.com/itm/395738824335\",\n" +
    "      \"image_url\": \"https://i.ebayimg.com/thumbs/images/g/pBMAAOSwWQ1m-NeG/s-l500.jpg\",\n" +
    "      \"price\": {\n" +
    "        \"value\": 64.14,\n" +
    "        \"currency\": \"USD\"\n" +
    "      },\n" +
    "      \"condition\": \"Pre-owned\",\n" +
    "      \"shipping_cost\": {\n" +
    "        \"value\": 7.71,\n" +
    "        \"currency\": \"USD\"\n" +
    "      },\n" +
    "      \"watchers\": 11\n" +
    "    },\n" +
    "    {\n" +
    "      \"title\": \"Apple AirPods Pro 2nd Generation 2023 A2968 Magsafe USB-C Charging\",\n" +
    "      \"url\": \"https://www.ebay.com/itm/335592547939\",\n" +
    "      \"image_url\": \"https://i.ebayimg.com/thumbs/images/g/MvIAAOSwJxxm9Eog/s-l500.jpg\",\n" +
    "      \"price\": {\n" +
    "        \"value\": 119.99,\n" +
    "        \"currency\": \"USD\"\n" +
    "      },\n" +
    "      \"condition\": \"New\",\n" +
    "      \"shipping_cost\": {\n" +
    "        \"value\": 10.6,\n" +
    "        \"currency\": \"USD\"\n" +
    "      },\n" +
    "      \"seller_feedback\": \"Seller with 100% positive feedback\"\n" +
    "    }\n" +
    "  ]\n" +
    "}"
};
