import { Navigate, Outlet, useLocation, useOutletContext } from "react-router-dom";

import { useUser } from "routes/dataroutes/UserData";

import { setCookie } from "utils/cookieUtils";


export function ToLoginIfNotAuthenticated() {
  const user = useUser();
  const outletContext = useOutletContext();
  const location = useLocation();

  if (!user) {
    // saving the current url to redirect to after GitHub login, otherwise we would lose this info during the redirects
    setCookie("sapiTargetUrl", location.pathname, { expires: 1 });

    return <Navigate to="/login" state={{ from: location.pathname }} />;
  }

  return <Outlet context={outletContext} />;
}

export function ToDashboardIfAlreadyAuthenticated() {
  const user = useUser();
  const outletContext = useOutletContext();
  const location = useLocation();

  if (user && !user.email.toLowerCase().endsWith(".inactive")) {
    return <Navigate to={ location.state?.from || "/" } replace />;
  }

  return <Outlet context={ outletContext } />;
}

export function ToActivationPageIfDeactivated() {
  const user = useUser();
  const outletContext = useOutletContext();

  if (user?.email?.toLowerCase()?.endsWith(".inactive")) {
    return <Navigate to="/login" />;
  }

  if (user && !user.apiKey && !user.isBlocked) {
    return <Navigate to="/send-activation-email" />;
  }

  return <Outlet context={ outletContext } />;
}

export function ToPreviousPageIfBlocked() {
  const user = useUser();
  const outletContext = useOutletContext();
  const location = useLocation();

  if (user?.isBlocked) {
    // TODO location.state is not always set, and is mainly used for storing target path names for authentication redirects.
    //  here we should better navigate one step back in history, or to the dashboard if there is no history.
    const locationState = location.state;
    const from = locationState?.from || locationState?.background || locationState?.backgroundLocation || "/";

    return <Navigate to={ from } replace />;
  }

  return <Outlet context={ outletContext } />;
}
