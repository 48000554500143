const collectorOutputs: Record<string, any> = {
    'webhook': {
      title: 'Webhook',
      description: 'Upload the result to a URL',
    },
    'devnull': {
      title: '/dev/null',
      description: 'Discards the result',
    },
    'save': {
      title: 'Save',
      description: 'Save the results so you can download them later.',
    },
    'unknown': {
      title: 'Unknown',
      description: 'Unknown configuration type. Probably invalid config',
    },
};

export const outputTypeToTitleAndDescription = (outputType: string): {title: string, description: string} => {
  const { title, description } = collectorOutputs[outputType] || collectorOutputs['unknown'];
  return{ title, description }; 
}