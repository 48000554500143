import { isValidAsin } from "components/hostedScrapingValidators";
import { SDEContent } from "./sdecontentTypes";

export const AmazonReviewContent: SDEContent = {
  projectType: 'async_amazon_review',
  projectCategory: 'amazon',
  apiUrlFn: (apiHost: string) => {
    return `${apiHost}/structured/amazon/review`;
  },
  docsSection: 'amazon-reviews-api',
  docsSectionAsync: 'amazon-reviews-api-async',
  inputKey: 'asin',
  inputsKey: 'asins',
  validatorFunction: isValidAsin,
  newProjectEditProject: {
    inputBoxLabels: {
      listLiteralInputTitle: 'ASINs',
      listLiteralInputPlaceholder: 'Enter one ASIN per line.',
    },
    inputSectionLabels: {
      inputSectionTitle: 'Input ASINs',
      inputSectionDescription: "Based on the data type you selected, you need to add ASINs to begin scraping Amazon.",
    },
    projectContent: {
      title: 'Amazon reviews',
      description: 'Extract customer reviews with Amazon product ASINs and get a well-structured JSON file with details like product name, reviews and ratings.',
      icon: 'amazon',
      inputTitle: 'Asin',
    },
  },
  apiPlayground: {
    inputSectionLabels: {
      inputSectionTitle: 'Input ASINs',
      inputSectionDescription: 'Enter Amazon ASINs to begin scraping Amazon.',
    },
    projectContent: {
      title: 'Amazon reviews',
      description: 'Extract customer reviews with Amazon product ASINs with details like product name, reviews and ratings.',
      icon: 'amazon',
    },
    enterInputsPlaceholder: 'B06X3WKY59',
    enterInputsMessage: 'Enter ASIN for scraping',
    codeViewPlaceHolder: 'Please provide a valid ASIN',
    dropdownTitle: 'Amazon Review'
  }
};
