import { cx, fmtCurrency, fmtNumber } from "utils";

import { BillingPeriodUnit } from "providers/UserProvider";


export interface IPriceTagProps {
  price: number | string;
  originalPrice: number | undefined;
  hasDiscount?: boolean;
  period: number;
  periodUnit: BillingPeriodUnit;
  isAnnual: boolean;
  size?: "small" | "large";
}


const getPriceDivider = (isAnnual: boolean, period: number, periodUnit: BillingPeriodUnit) => {
  if (isAnnual) {
    return (periodUnit === "year" ? 12 : 1) * period;
  } else {
    return 1;
  }
};

export default function PriceTag({
  price,
  originalPrice,
  hasDiscount,
  period,
  periodUnit,
  isAnnual,
  size = "large"
}: IPriceTagProps) {

  // showing monthly prices for annual plans
  const priceDivider = getPriceDivider(isAnnual, period, periodUnit);

  const reallyHasDiscount = (hasDiscount === undefined) ? Boolean((price !== undefined) && (originalPrice !== undefined) && (price !== originalPrice)) : hasDiscount;

  const priceToDisplay = typeof price === "string" ? price : fmtNumber((price / 100) / priceDivider);
  const originalPriceToDisplay = reallyHasDiscount && originalPrice ? fmtCurrency((originalPrice / 100) / priceDivider) : <>&nbsp;</>;
  const periodStr = isAnnual ? "month" : `${period > 1 ? `${period} ${periodUnit}s` : periodUnit}`;

  return (
    <>
      { size === "large" && (
        <span className={ cx(reallyHasDiscount && originalPrice ? "" : "invisible", "text-red dark:text-error-600 line-through") }>
          <div className={ cx("text-lightGray dark:text-neutral-500", size === "large" ? "text-base" : "text-sm") }>{ originalPriceToDisplay }</div>
        </span>
      ) }
      <div className="flex items-start gap-x-0.5">
        { size === "small" && (
          <span className={ cx(reallyHasDiscount && originalPrice ? "" : "invisible", "text-red dark:text-error-600 line-through") }>
            <div className="text-lightGray dark:text-neutral-500 text-sm self-start">{ originalPriceToDisplay }</div>
          </span>
        ) }
        { typeof price !== "string" && (
          <span
            className={ cx(size === "large" ? "font-medium" : "font-semibold", "text-xl text-brandDarkest dark:text-primary-800") }>$</span>
        ) }
        <span className={ cx(size === "large" ? "text-4xl font-medium" : "text-xl font-semibold", "text-brandDarkest dark:text-primary-800") }>{priceToDisplay}</span>
      </div>
      <span className="text-sm text-gray dark:text-neutral-600 self-end pb-1 pl-1">per {periodStr}{isAnnual && (size === "large") && ", billed annually"}</span>
    </>
  );

};
