import { RiCodeBoxLine } from "@remixicon/react";

import { ReactComponent as BillingIcon } from "assets/icons/billing-icon.svg";
import { ReactComponent as DashboardIcon } from "assets/icons/dashboard-icon.svg";
import { ReactComponent as DataPipelineIcon } from "assets/icons/data-pipeline.svg";
import { ReactComponent as DocumentIcon } from "assets/icons/document-icon.svg";
import { ReactComponent as HeadsetIcon } from "assets/icons/headset-icon.svg";
import { ReactComponent as LifebuoyIcon } from "assets/icons/lifebuoy-icon.svg";
import { ReactComponent as PulseIcon } from "assets/icons/pulse-icon.svg";


const Icons = {
  Dashboard: DashboardIcon,
  APIPlayground: RiCodeBoxLine,
  DataPipeline: DataPipelineIcon,
  Billing: BillingIcon,
  Status: PulseIcon,
  Documentation: DocumentIcon,
  Support: LifebuoyIcon,
  Sales: HeadsetIcon,
};

export default Icons;
