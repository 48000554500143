import FeatureSwitchCheck from "./FeatureSwitchCheck";
import { FeatureSwitch } from "components/FeatureSwitch/FeatureSwitches";
import { UserType } from "providers/UserProvider";


export default abstract class NumberAboveOrEqualsFeatureSwitchCheck extends FeatureSwitchCheck<number> {

  private readonly getUserProperty: (user: UserType) => number;


  protected constructor(featureSwitch: FeatureSwitch, getUserProperty: (user: UserType) => number) {
    super(featureSwitch);

    this.getUserProperty = getUserProperty;
  }

  check(user: UserType): boolean {
    return this.featureValue ? this.getUserProperty(user) >= this.featureValue : false;
  }

}
