import { forwardRef, Ref } from "react";

import { Sizes } from "styles/sizes";

import { cx } from "utils";


const tagSizes: Pick<Sizes<string>, "SM" | "MD"> = {
  SM: "tag-sm",
  MD: "tag-md"
};

type TagSize = keyof typeof tagSizes;

type TagTheme = "neutral" | "info" | "success" | "warning" | "error" | "secondary";

const tagThemes: Record<TagTheme, string> = {
  neutral: "tag-neutral",
  info: "tag-info",
  success: "tag-success",
  warning: "tag-warning",
  error: "tag-error",
  secondary: "tag-secondary"
};

interface ITagProps {
  label: string;
  size?: TagSize;
  theme?: TagTheme;
  subtle?: boolean;
  className?: string;
}


const Tag = forwardRef(
  (
    {
      label,
      size = "SM",
      theme = "neutral",
      subtle = false,
      className
    }: ITagProps,
    ref: Ref<HTMLDivElement>
  ) => {
    return (
      <div className={ cx(
        "tag",
        tagSizes[size],
        tagThemes[theme],
        subtle && "tag-subtle",
        className
      ) }
         ref={ ref }
      >
        { label }
      </div>
    );
  }
);

export default Tag;
