import { CollectorConfig, CollectorType } from "providers/HostedScrapingProvider";

export type ScrapingMethod ='api' | 'async' | 'proxy_mode' | 'structured_data_endpoint'; 
export type SupportedLanguages = 'curl' | 'python' | 'nodejs' | 'php' | 'ruby' | 'java' ;

export type ApiCallConfig = {
  config: CollectorConfig;
  input: string;
  scrapingMethod: ScrapingMethod;
};

export const newApiCallConfig = (collectorType: CollectorType): ApiCallConfig => ({
  config: { 
    "type": collectorType,
    "apiParams": {},
  },
  input: '',
  scrapingMethod: 'api',
})