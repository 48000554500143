import { Menu, Transition } from "@headlessui/react";

import { ReactComponent as ArrowUpIcon } from "assets/icons/arrow-up-s-line.svg";

import * as MenuItems from "./MenuItems";

import { useNewDashboardDesign } from "providers/NewDashboardDesignProvider";

import { useUser } from "routes/dataroutes/UserData";

import { cx } from "utils";
import { getUsername } from "utils/userUtils";


export function UserBadge({ initial }: { initial: string }) {
  return (
    <div className="flex-shrink-0 text-center place-content-center justify-center w-8 h-8 bg-gray dark:bg-neutral-600 rounded-full">
      <span className="text-white font-semibold text-sm">{ initial }</span>
    </div>
  );
}

function UsernameAndEmail({ username, email }: { username: string, email: string }) {
  return (
    <div className="flex flex-col space-y-1 items-start">
      <span className="text-base font-medium text-gray dark:text-neutral-600 leading-none max-w-[160px] truncate">{ username }</span>
      <span className="text-xs text-gray dark:text-neutral-500 leading-none max-w-[160px] truncate">{ email }</span>
    </div>
  );
}

type MenuItemList = {
  items?: MenuItemList[];
  disabled?: boolean;
  menuItem?: (close?: CloseFn) => JSX.Element;
};

type CloseFn = () => void;

function RenderMenuItems({ menuItems, closeFn, indexPrefix }: { menuItems: MenuItemList[], closeFn?: CloseFn, indexPrefix?: string }) {
  return (
    <>
      { menuItems.map((item, index) => {
        const itemIndex = indexPrefix ? indexPrefix + "-" + index : index.toString(10);

        if (item.items) {
          return (
            <div className="py-3" key={ itemIndex }>
              <RenderMenuItems menuItems={ item.items } indexPrefix={ itemIndex }/>
            </div>
          );
        }

        if (item.menuItem) {
          return (
            <Menu.Item disabled={ item.disabled } key={ itemIndex }>
              <div>{ item.menuItem?.(closeFn) }</div>
            </Menu.Item>
          );
        }

        return <></>;
      }) }
    </>
  );
}

/**
 * Should never be used before login.
 */
export function UserMenuControlled({ showMenu, close, widthClassName }: { showMenu: boolean, close: (event?: MouseEvent) => void, widthClassName?: string }) {
  const user = useUser();

  const { newDashboardDesignSetting } = useNewDashboardDesign();

  const menuItems: MenuItemList[] = [];

  menuItems.push(
    {
      items: [
        {
          disabled: true,
          menuItem: () => <MenuItems.AccountEmail />
        },
        {
          disabled: true,
          menuItem: () => <MenuItems.AccountApiKey />
        }
      ]
    },
    {
      menuItem: (close?: CloseFn) => <MenuItems.ChangePassword onClick={ close } />,
    },
    {
      disabled: user?.isBlocked,
      menuItem: (close?: CloseFn) => <MenuItems.ManageApiKeys onClick={ close } />,
    },
    // TODO onboarding "how to get started quickly" button is missing here
  );

  if (newDashboardDesignSetting === "yes") {
    menuItems.push({
      menuItem: (close?: CloseFn) => <MenuItems.UseOldDashboardDesign onClick={ close }/>
    });
  }

  menuItems.push({
    disabled: true,
    menuItem: (close?: CloseFn) => <MenuItems.Logout onClick={ close }/>
  });

  return <Menu>
    <Transition
      show={ showMenu }
    >
      <div className="absolute bottom-20 left-4 z-30">
        <Menu.Items static className={ cx("origin-bottom-left bg-white shadow-lg ring-1 ring-lightGray dark:ring-neutral-500 focus:outline-none divide-y", widthClassName ?? "w-72") }>
          <RenderMenuItems menuItems={ menuItems } closeFn={ close } />
        </Menu.Items>
      </div>
    </Transition>
  </Menu>
}

export function UserMenuButtonControlled({ open }: { open?: boolean }) {
  const user = useUser()!;
  const username = getUsername(user);

  return (
    <div className="flex flex-row items-center justify-between w-full" data-user-email={ user.email }>
      <div className="flex flex-row gap-x-1.5 items-center">
        <UsernameAndEmail username={ username } email={ user.email } />
      </div>
      <ArrowUpIcon className={ cx("w-4 h-4 transition-transform duration-300", open && "-rotate-180") } />
    </div>
  );
}
