
// TODO: use the filesaver module
export const downloadFileUsingTheBlobTrick = (filename: string, mimeType: string) => (data: Buffer) => {
  const downloadUrl = window.URL.createObjectURL(new Blob([data], {type: mimeType}));
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.setAttribute('download', filename); //any other extension
  document.body.appendChild(link);
  link.click();
  link.remove();
}
