import { useEffect } from "react";
import { Outlet, useLocation, useRevalidator } from "react-router-dom";

import Greetings from "./Greetings";
import RightSidebar from "./RightSidebar";
import PlanOverviewAndStatsCard from "./PlanOverviewAndStatsCard";

import BorderedPage from "components/BorderedPage";
import MaxWidthPage from "components/MaxWidthPage";
import OnboardingCard from "components/OnboardingCard";
import Panel from "components/Panel";
import RequestsCard from "components/RequestsCard";
import SuccessRateHistoryGraph from "components/SuccessRateHistoryGraph";
import UsageChart from "components/UsageChart";

import { useContactSales } from "hooks/useContactSales";
import { useSupportWidget } from "hooks/useSupportWidget";

import BorderLayout from "layouts/BorderLayout";

import QuickStartCard from "pages/dashboard/quickstart/QuickStartCard";

import { useNewDashboardDesign } from "providers/NewDashboardDesignProvider";
import { useUserProvider } from "providers/UserProvider";

import { useUser } from "routes/dataroutes/UserData";


function AutoOpenSupportWidget() {
  const user = useUser();
  const location = useLocation();
  const supportWidget = useSupportWidget();
  const { openSupportWidget, openSalesWidget } = location.state ? location.state as { openSupportWidget: boolean, openSalesWidget: boolean } : { openSupportWidget: false, openSalesWidget: false };
  const { contactSalesFn } = useContactSales();

  useEffect(() => {
    if (openSupportWidget) {
      supportWidget.showSupportForm(user?.email);
    }
    if (openSalesWidget) {
      contactSalesFn();
    }
  }, [ openSupportWidget, openSalesWidget, supportWidget, user?.email, contactSalesFn ]);

  return <></>;
}


function DashboardHomeContent() {
  const user = useUser();
  const { subscription, appStorage } = useUserProvider();
  const revalidator = useRevalidator();

  return (
    <>
      <AutoOpenSupportWidget />
      <div className="flex flex-col gap-8 dark:gap-6 pb-8 dark:pb-6">
        { !appStorage?.hideOnboarding && (
          <OnboardingCard
            onHide={ () => {
              localStorage.setItem("hideOnboarding", "true");
              revalidator.revalidate(); // TODO <-- this is way too much here. should simply update the onboarding state instead of reloading everything
            } }
            apiKey={ user?.apiKey ?? "" }
          />
        ) }

        <div className="flex flex-col items-stretch lg:flex-row gap-x-8 gap-y-8">
          <div className="w-full lg:w-9/12">
            <UsageChart
              creditsResetOn={
                subscription?.next_renewal_at || subscription?.current_term_end
                  ? new Date((subscription.next_renewal_at || subscription.current_term_end) * 1000)
                  : undefined
              }
              usedCredits={ user?.requestCount ?? 0 }
              totalCredits={ user?.apiCallLimit ?? 0 }
              usedConcurrentRequests={ user?.concurrentRequests ?? 0 }
              totalConcurrentRequests={ user?.concurrentRequestLimit ?? 0 }
            />
          </div>
          <div className="w-full lg:w-3/12 min-w-[220px]">
            <RequestsCard
              success={ user ? user?.requestCount : 0 }
              failure={ user ? user.failedRequests : 0 }
            />
          </div>
        </div>
        <SuccessRateHistoryGraph/>
        <Panel
          ctaHref="https://scraperapi.hund.io/"
          ctaText="Status page"
          message="If you are experiencing problems scraping sites, please take a look at our status page before reporting an issue."
        />
        <Panel
          ctaHref="/billing"
          ctaText="Billing Page"
          message="If you are running out of credits you can renew your subscription on the billing page"
        />
      </div>
    </>
  );
}

export default function DashboardHome() {
  const { newDashboardDesignSetting } = useNewDashboardDesign();
  const user = useUser();

  if (newDashboardDesignSetting === "yes") {
    return (
      <MaxWidthPage fullWidth>
        <BorderedPage
          title={ <Greetings/> }
        >
          <BorderLayout
            right={ <RightSidebar /> }
          >
            <AutoOpenSupportWidget />
            <div className="flex flex-col gap-y-6">
              { user?.isFreeTrial && <QuickStartCard /> }
              <PlanOverviewAndStatsCard />
            </div>
          </BorderLayout>
        </BorderedPage>
        <Outlet/> {/* to support modals */ }
      </MaxWidthPage>
    );
  } else {
    return (
      <>
        <BorderedPage title="Home">
          <DashboardHomeContent/>
        </BorderedPage>
        <Outlet /> {/* to support modals */}
      </>
    );
  }
}
