import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";


interface GoogleOAuthProps {
  googleButtonMode: "signup" | "signin";
  setOAuthError: (error: string) => void;
  googleBtnElm: React.RefObject<HTMLButtonElement>;
  credentialsCallback: (googleCredentials: any) => Promise<void>;
  onSuccess?: (googleUser: any) => Promise<void>;
  onError?: (err: any) => Promise<void>;
}

export function useGoogleOAuth({
  googleButtonMode,
  setOAuthError,
  googleBtnElm,
  credentialsCallback,
  onSuccess,
  onError
}: GoogleOAuthProps) {
  let navigate = useNavigate();
  const checkGapIntervalLocal = useRef<NodeJS.Timer | null>(null);

  const [hideGoogleOAuth, setHideGoogleOAuth] = useState<boolean>(false);

  useEffect(() => {
    if (!googleBtnElm.current) return;

    new Promise((res, rej) => {
      const { google }: { google: any } = window as any;

      if (google) res(google);

      // Maybe there is a network delay,
      // Try for 10 seconds before giving up
      let counter = 0;
      checkGapIntervalLocal.current = setInterval(() => {
        counter += 1;
        console.log("Checking for gapi...", counter);

        const { google }: { google: any } = window as any;

        // We are going to succeed and fail, lets clear the interval
        if ((google || counter > 9) && checkGapIntervalLocal.current) {
          console.log("Clearing interval", checkGapIntervalLocal.current);
          clearInterval(checkGapIntervalLocal.current);
        }

        // We succeeded
        if (google) res(google);

        // We failed
        if (counter > 9) rej(new Error("GAPI not found"));
      }, 500);
    })
      .then((google: any) => {

        google.accounts.id.initialize({
          client_id: process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID,
          context: googleButtonMode,
          ux_mode: "popup",
          callback: credentialsCallback
        });

        google.accounts.id.renderButton(
          googleBtnElm.current,
          {
            text: googleButtonMode + "_with",
            type: "standard",
            theme: "filled_black",
            size: "large",
            width: 224,
            locale: "en-US"
          }
        );
      })
      .catch(() => {
        setHideGoogleOAuth(true);
      });

    return () => {
      if (checkGapIntervalLocal.current) {
        clearInterval(checkGapIntervalLocal.current);
      }
    };
  }, [checkGapIntervalLocal, googleBtnElm, navigate, onSuccess, onError, setOAuthError, credentialsCallback, googleButtonMode]);

  return {
    hideGoogleOAuth
  };
}
