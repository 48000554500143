import { useParams } from "react-router-dom";


export default function useOnboardingStep() {
  const { step } = useParams();

  const stepNumber = Number.parseInt(step || "", 10);

  return {
    step: stepNumber
  };
};
