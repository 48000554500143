import { useCallback, useMemo } from "react";

import { sdeDescriptors } from "sdecontent";

import Button from "components/Button";
import Icons from "components/Icons";
import Select, { SelectOption } from "components/Select";
import { sampleCode } from "components/hosted-scraping/apiPlaygroundTemplates";

import { useUser } from "routes/dataroutes/UserData";
import { ApiPlaygroundLocationState } from "routes/dashboard/ApiPlaygroundEditDetails";

import { QuickStartStep, useQuickStartContext } from "./QuickStartCard";
import { QuickStartCodeView } from "./QuickStartCodeView";
import { FIXED_WIDTH } from "./constants";
import examples from "./sde-examples";
import LanguageSelect from "./LanguageSelect";


const sdeSelectOptions: SelectOption<string>[] = sdeDescriptors.map(desc => { return { name: desc.apiPlayground.dropdownTitle, value: desc.projectType }; });

export default function SDEQuickStartSteps() {
  const user = useUser();

  const { programmingLanguage, sdeProjectType, setSdeProjectType } = useQuickStartContext();

  const selectedSde = useMemo(() => {
    return sdeSelectOptions.find(sde => sde.value === sdeProjectType) || sdeSelectOptions[0];
  }, [ sdeProjectType ]);

  const changeSelectedSde = useCallback((sde: SelectOption<string>) => {
    setSdeProjectType(sde.value);
  }, [ setSdeProjectType ]);

  const codeViewContext: Omit<ApiPlaygroundLocationState, "collectorType"> = useMemo(() => {
    return {
      scrapingMethod: "structured_data_endpoint",
      language: programmingLanguage,
      url: examples[sdeProjectType]?.input
    };
  }, [ sdeProjectType, programmingLanguage ]);

  const codeExample = useMemo(() => {
    return sampleCode(
      codeViewContext.language,
      codeViewContext.scrapingMethod,
      sdeProjectType,
      codeViewContext.url,
      user?.apiKey || "",
      {}
    );
  }, [ user?.apiKey, sdeProjectType, codeViewContext.language, codeViewContext.scrapingMethod, codeViewContext.url ]);

  const getApiPlaygroundLocationState = () => {
    return {
      ...codeViewContext,
      collectorType: sdeProjectType,
    }
  };


  return (
    <>
      <QuickStartStep
        title="Select your endpoint"
        index={ 2 }
      >
        <Select
          value={ selectedSde }
          onChange={ changeSelectedSde }
          options={ sdeSelectOptions }
          size="SM"
          className={ FIXED_WIDTH }
        />
      </QuickStartStep>
      <QuickStartStep
        title="Select your preferred programming language"
        index={ 3 }
      >
        <LanguageSelect />
      </QuickStartStep>
      <QuickStartStep
        title="Try out the snippet"
        index={ 4 }
      >
        <QuickStartCodeView language="python" codeExample={ codeExample } canZoom />
      </QuickStartStep>
      <QuickStartStep
        title="Run request directly in API Playground"
        subtitle="Learn more about all available parameters and credits costs per request."
        icon={ <Icons.APIPlayground className="h-4" /> }
      >
        <Button
          text="Go to playground"
          href="/apiplayground"
          size="SM"
          className="button button-accent"
          navigationState={ getApiPlaygroundLocationState() }
        />
      </QuickStartStep>
    </>
  );
};
