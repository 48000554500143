import { useCallback, useEffect, useMemo, useState } from 'react';
import { NewProjectSelectType } from './NewProjectSelectType';
import { NewProjectEditProject } from './NewProjectEditProject';
import { CollectorType } from 'providers/HostedScrapingProvider';
import { NewProjectConfig } from 'providers/HostedScrapingProvider';
import { useUser } from "routes/dataroutes/UserData";
import { useCustomerIO } from 'providers/CustomerIOProvider';
import { newProject, newProjectFromApiCallConfig } from 'providers/HostedScrapingProvider/newProject';
import { useLocation, useNavigate } from 'react-router-dom';
import { FullWidthLoadingSpinner } from 'components/FullWidthLoadingSpinner';


type NewProjectPage = 'chooseType' | 'editDetails';

const scrollToTop = () => {
  document.getElementById('the-big-content')?.scroll(0,0);
}

export default function NewProject() {
  /*
   * The user can get here in two ways:
   * 1. Get here using the DataPipeline -> New Project button
   * 2. Set up stuff in the API playground and then click on the "Schedule as datapipeline project" button
   *    In this case the project will be in the  location?.state?.apiCallConfig aka apiCallConfigFromApiPlaygroundPage
   */
  const location = useLocation();
  const navigate = useNavigate();
  const [page, setPage] = useState<NewProjectPage>('chooseType');
  const [projectType, setProjectType] = useState<CollectorType>('async_urls');
  const [projectName, setProjectName] = useState<string|undefined>(undefined);
  const [showFakeLoadingScreen, setShowFakeLoadingScreen] = useState(false);

  const apiCallConfigFromApiPlaygroundPage = location?.state?.apiCallConfig;

  /*
   * The transition from the API playground to the edit project page is too
   * fast. The pages are very similar so it's hard to notice that a transition
   * has happened. This loading screen tells user that something is happening
   */
  useEffect( () => {
    if (apiCallConfigFromApiPlaygroundPage) {
      setShowFakeLoadingScreen(true);
      const hideFakeLoadingScreen = setTimeout(() => {
        setShowFakeLoadingScreen(false);
      }, 800);

      return () => clearTimeout(hideFakeLoadingScreen);
    }
  }, [apiCallConfigFromApiPlaygroundPage]);

  const user = useUser();
  const customerIO = useCustomerIO();
  customerIO.page();


  const newProjectConfig: NewProjectConfig = useMemo(() => {
    const userId = user?.id || 0;
    if (apiCallConfigFromApiPlaygroundPage !== undefined) {
      return newProjectFromApiCallConfig(userId, projectName, apiCallConfigFromApiPlaygroundPage);
    } else {
      return newProject(userId, projectName, projectType);
    }
  }, [apiCallConfigFromApiPlaygroundPage, projectType, projectName, user?.id]);

  const editProjectFromApiPlayground = apiCallConfigFromApiPlaygroundPage !== undefined;

  if (editProjectFromApiPlayground && page !== 'editDetails') {
    // Start on the second page, don't want to set the type. It's set in the apiCallConfig
    setPage('editDetails');
  }

  const typeSelectedCallback = useCallback((selectedType: CollectorType) => {
    setProjectType(selectedType);
    setPage('editDetails');
    scrollToTop();
  }, [setProjectType, setPage]);

  const backCallback = useCallback(() => {
    if (editProjectFromApiPlayground) {
      navigate(-1);
    } else {
      setPage('chooseType');
      scrollToTop();
    }
  }, [editProjectFromApiPlayground, setPage, navigate] );

  const cancelButtonVisible = !editProjectFromApiPlayground;

  if (showFakeLoadingScreen) {
    return <div className="w-full h-full">
      <FullWidthLoadingSpinner />
    </div>;
  } 

  switch (page) {
    case 'chooseType': return <NewProjectSelectType projectName={projectName} callback={typeSelectedCallback} setNameCallback={setProjectName} />;
    case 'editDetails': return <NewProjectEditProject mode='newProject' project={newProjectConfig} backCallback={backCallback} cancelButtonVisible={cancelButtonVisible} />
  }
};
