import ResourceCard, { Bold, Title } from "./ResourceCard";
import { useQuickStartContext } from "./QuickStartCard";


export default function ApiResources() {
  const { programmingLanguage } = useQuickStartContext();

  return (
    <>
      <ResourceCard
        title={ <Title>Check out <Bold>how to get started</Bold></Title> }
        linkHref={ `https://docs.scraperapi.com/${programmingLanguage}` }
      />

      <ResourceCard
        title={ <Title>Use the <Bold>API Endpoint Method</Bold></Title> }
        linkHref={ `https://docs.scraperapi.com/${programmingLanguage}/making-requests/api-endpoint-method` }
      />

      <ResourceCard
        title={ <Title>Scrape more successful with the <Bold>Async method</Bold></Title> }
        linkHref={ `https://docs.scraperapi.com/${programmingLanguage}/making-requests/async-requests-method` }
      />

      <ResourceCard
        title={ <Title>Use one of the <Bold>Structured Data Endpoints</Bold></Title> }
        linkHref={ `https://docs.scraperapi.com/${programmingLanguage}/making-requests/structured-data-collection-method` }
      />

      <ResourceCard
        title={ <Title>Learn more about the <Bold>Proxy Port Method</Bold></Title> }
        linkHref={ `https://docs.scraperapi.com/${programmingLanguage}/making-requests/proxy-port-method` }
      />
    </>
  );
};
