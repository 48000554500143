import { useMemo } from "react";
import { Navigate, Outlet, useOutletContext } from "react-router-dom";

import { FeatureSwitch, isFeatureAvailable } from "./FeatureSwitches";

import { useUser } from "routes/dataroutes/UserData";


export function useFeatureSwitch(featureSwitch: FeatureSwitch | undefined) {
  const user = useUser();

  const featureAvailable = useMemo(() => {
    return isFeatureAvailable(featureSwitch, user);
  }, [ user, featureSwitch ]);

  return featureAvailable;
}


export function FeatureSwitchLayout(
  {
    featureSwitch,
    fallbackNavigateTo = "/"
  }: {
    featureSwitch: FeatureSwitch;
    fallbackNavigateTo?: string;
  }) {
  const outletContext = useOutletContext();

  const featureEnabled = useFeatureSwitch(featureSwitch);

  if (featureEnabled) {
    return <Outlet context={ outletContext } />;
  } else {
    return <Navigate to={fallbackNavigateTo} replace />;
  }

}
