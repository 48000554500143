import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import scraperApi from "api";
import { defaultCountryCodes } from "components/hosted-scraping/extraApiParams";
import axios from "axios";

export type SettingsFromApi = {
  availableCountryCodes: string[];
};

let SettingsFromApiContext = createContext<SettingsFromApi>({
  availableCountryCodes: defaultCountryCodes
});

export default function ApiSettingsProvider({ children }: { children: ReactNode }) {

  const [ countryCodes, setCountryCodes ] = useState<string[]>(defaultCountryCodes);

  const fetchCountryCodes = useCallback(
    async (controller: AbortController | undefined) => {
      try {
        const allIssues = await scraperApi.settings.availableCountryCodes({
          signal: controller?.signal
        });

        setCountryCodes(allIssues);
      } catch (err) {
        if (!axios.isCancel(err)) {
          console.error(err);
        }
      }
    },
    []
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchCountryCodes(controller);
    return () => controller.abort();
  }, [fetchCountryCodes]);

  let value = {
    availableCountryCodes: countryCodes
  };

  return (
    <SettingsFromApiContext.Provider value={value}>
      {children}
    </SettingsFromApiContext.Provider>
  );

};

export function useSettingsFromApi() {
  return useContext(SettingsFromApiContext);
}
