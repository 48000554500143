import { isValidQuery } from "components/hostedScrapingValidators";
import { SDEContent } from "./sdecontentTypes";

export const GoogleSearchContent: SDEContent = {
  projectType: 'async_google_search',
  projectCategory: 'google',
  apiUrlFn: (apiHost: string) => {
    return `${apiHost}/structured/google/search`;
  },
  docsSection: 'google-serp-api',
  docsSectionAsync: 'google-search-api-async',
  inputKey: 'query',
  inputsKey: 'queries',
  validatorFunction: isValidQuery,
  newProjectEditProject: {
    inputBoxLabels: {
      listLiteralInputTitle: 'Search terms',
      listLiteralInputPlaceholder: 'Enter one search term per line.',
    },
    inputSectionLabels: {
      inputSectionTitle: "Input search term",
      inputSectionDescription: "Enter search terms in the input field or upload CSV with your search terms to begin scraping Google.",
    },
    projectContent: {
      title: 'Google search',
      description: 'Extract Google search results with keywords and receive well-structured data such as organic and paid results, rankings and ads.',
      icon: 'google',
      inputTitle: 'Query',
    },
  },
  apiPlayground: {
    inputSectionLabels: {
      inputSectionTitle: 'Input search term',
      inputSectionDescription: 'Enter Google search terms or full URLs to begin scraping Google.',
    },
    projectContent: {
      title: 'Google search',
      description: 'Extract Google search results with keywords and receive well-structured data such as organic and paid results, rankings and ads.',
      icon: 'google',
    },
    enterInputsPlaceholder: 'query',
    enterInputsMessage: 'Enter keyword for scraping',
    codeViewPlaceHolder: 'Please provide a valid search term',
    dropdownTitle: 'Google Search'
  }
};
