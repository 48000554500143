import { ReactNode, useState } from "react";
import { Outlet, useOutletContext } from "react-router-dom";

import { cx } from "utils";


type Layout = "horizontal" | "vertical";
type Wrap = "xxs" | "xs" | "sm" | "md" | "lg" | "xl" | "2xl";

export type LayoutContent = {
  top?: JSX.Element;
  bottom?: JSX.Element;
  left?: JSX.Element;
  right?: JSX.Element;

  /**
   * Layout defining the sizes of sidebars. With "vertical" (default) the top and bottom bars are full width, with "horizontal" the left and right bars are full height.
   *
   * @default "vertical"
   */
  layout?: Layout;

  /**
   * Breakpoint before which the layout should be wrapped to a single column. The wrapped order of components will be:
   *  for "vertical" layout:
   *   1. top
   *   2. left
   *   3. main
   *   4. right
   *   5. bottom
   *
   *  for "horizontal" layout:
   *   1. left
   *   2. top
   *   3. main
   *   4. bottom
   *   5. right
   *
   * @default false
   */
  wrap?: Wrap;

  /**
   * Whether the "main" content should be scrollable when wrapped.
   *
   * @default false
   */
  scrollMainWhenWrapped?: boolean;
}

interface LayoutProps {
  grid: string;
  top: string;
  left: string;
  main: string;
  right: string;
  bottom: string;
}

// As Tailwind.css does not support dynamic classnames, we need to list all combinations here so its preprocessor will find them and will generate the proper classes.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type TailwindHack =
  "xxs:grid" | "xs:grid" | "sm:grid" | "md:grid" | "lg:grid" | "xl:grid" | "2xl:grid" |
  "xxs:grid-cols-pageLayout" | "xs:grid-cols-pageLayout" | "sm:grid-cols-pageLayout" | "md:grid-cols-pageLayout" | "lg:grid-cols-pageLayout" | "xl:grid-cols-pageLayout" | "2xl:grid-cols-pageLayout" |
  "xxs:grid-rows-pageLayout" | "xs:grid-rows-pageLayout" | "sm:grid-rows-pageLayout" | "md:grid-rows-pageLayout" | "lg:grid-rows-pageLayout" | "xl:grid-rows-pageLayout" | "2xl:grid-rows-pageLayout" |
  "xxs:order-3" | "xs:order-3" | "sm:order-3" | "md:order-3" | "lg:order-3" | "xl:order-3" | "2xl:order-3" |
  "xxs:order-4" | "xs:order-4" | "sm:order-4" | "md:order-4" | "lg:order-4" | "xl:order-4" | "2xl:order-4" |
  "xxs:overflow-y-auto" | "xs:overflow-y-auto" | "sm:overflow-y-auto" | "md:overflow-y-auto" | "lg:overflow-y-auto" | "xl:overflow-y-auto" | "2xl:overflow-y-auto";

function getLayoutProps(layoutContent: LayoutContent | null): LayoutProps {
  const contentProps: LayoutProps = { grid: "", top: "", left: "", main: "", right: "", bottom: "" };

  if (layoutContent?.layout === "horizontal") {
    contentProps.left = "order-1 row-span-3";
    contentProps.top = "order-2";
    if (layoutContent?.wrap) {
      contentProps.main = `order-3 ${layoutContent.wrap}:order-4`;
      contentProps.bottom = `order-4 ${layoutContent.wrap}:order-5`;
      contentProps.right = `order-5 ${layoutContent.wrap}:order-3 row-span-3`;
    } else {
      contentProps.main = "order-4 overflow-y-auto";
      contentProps.bottom = "order-5";
      contentProps.right = "order-3 row-span-3";
    }
  } else {
    contentProps.top = "order-1 col-span-3";
    contentProps.left = "order-2";
    contentProps.main = "order-3";
    contentProps.right = "order-4";
    contentProps.bottom = "order-5 col-span-3";
  }

  if (layoutContent?.wrap) {
    contentProps.grid = `flex flex-col ${layoutContent.wrap}:grid ${layoutContent.wrap}:grid-cols-pageLayout ${layoutContent.wrap}:grid-rows-pageLayout`;
    if (layoutContent.scrollMainWhenWrapped) {
      contentProps.main += " overflow-y-auto";
    } else {
      contentProps.main += ` ${layoutContent.wrap}:overflow-y-auto`;
    }
  } else {
    contentProps.grid = "grid grid-cols-pageLayout grid-rows-pageLayout";
    contentProps.main += " overflow-y-auto";
  }

  return contentProps;
}

type BorderLayoutContextType = {
  layoutContent: LayoutContent | null;
  setLayoutContent: React.Dispatch<React.SetStateAction<LayoutContent | null>>;
};

export default function BorderLayout({ className, children, ...props }: LayoutContent & { className?: string, children?: ReactNode | ReactNode[] }) {
  const outletContext = useOutletContext<any>();

  const [ layoutContent, setLayoutContent ] = useState<LayoutContent | null>(props || null);

  // if used with children then props are used, otherwise layoutContent state is used when used with <Outlet />
  const layoutProps = getLayoutProps(children ? props : layoutContent);
  const layoutComponents = children ? props : layoutContent;

  return (
    <div className={ cx("w-full h-full", className, layoutProps.grid) }>
      <div className={ layoutProps.top }>{ layoutComponents?.top || <></> }</div>
      <div className={ layoutProps.left }>{ layoutComponents?.left || <></> }</div>
      <div className={ layoutProps.main }>
        { children || <Outlet context={ { ...outletContext, layoutContent, setLayoutContent } }/> }
      </div>
      <div className={ layoutProps.right }>{ layoutComponents?.right || <></> }</div>
      <div className={ layoutProps.bottom }>{ layoutComponents?.bottom || <></> }</div>
    </div>
  );
};

export function useBorderLayout() {
  return useOutletContext() as BorderLayoutContextType;
}
