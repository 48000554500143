import { Navigate, Outlet, useLocation, useOutletContext } from "react-router-dom";

import { useUser } from "routes/dataroutes/UserData";


interface Props {
  children?: JSX.Element;
}

export const BlockedLayout = ({ children }: Props) => {

  const user = useUser();
  const location = useLocation();
  const outletContext = useOutletContext();

  const locationState = location.state as any;
  const from = locationState?.from || locationState?.background || locationState?.backgroundLocation || "/";

  if (user?.isBlocked) {
    return (
      <Navigate to={from} replace/>
    );
  } else {
    return children || <Outlet context={ outletContext }/>;
  }

};
