import { Navigate, Outlet, useLocation, useOutletContext } from "react-router-dom";

import { useUser } from "routes/dataroutes/UserData";

import { setCookie } from "utils/cookieUtils";


export const ProtectedLayout = ({ children }: { children?: JSX.Element }) => {
  const user = useUser();
  const location = useLocation();
  const outletContext = useOutletContext();

  if (user?.email?.toLowerCase()?.endsWith(".inactive")) {
    // user has been logically deleted
    return (
      <Navigate
        to="/login"
        state={{ from: location.pathname, background: location }}
        replace
      />
    );
  }
  // Found the user but not the apiKey,
  // In the current business logic, this most likely means the user is not activated or is blocked
  if (user && !user.apiKey && !user.isBlocked) {
    return (
      <Navigate
        to="/send-activation-email"
        state={{ from: location.pathname, background: location }}
        replace
      />
    );
  }

  // Couldn't find a user
  if (!user) {
    // saving the current url to redirect to after GitHub login, otherwise we would lose this info during the redirects
    setCookie("sapiTargetUrl", location.pathname, { expires: 1 });

    return (
      <Navigate
        to="/login"
        state={{ from: location.pathname, background: location }}
        replace
      />
    );
  } else {
    return children || <Outlet context={ outletContext } />;
  }
};

export default ProtectedLayout;
