import { OnboardingAnswerType } from "./OnboardingQuestion";
import FreeTrialSteps from "./FreeTrialSteps";

import OnboardingDarts from "assets/images/onboarding-darts.png";
import OnboardingRocket from "assets/images/onboarding-rocket.png";
import OnboardingGuy from "assets/images/onboarding-guy.png";


export interface IOnboardingQuestion {
  id?: string;
  question: string;
  type: OnboardingAnswerType;
  mandatory?: boolean;
  answers: string[];
  hasOtherAnswer?: boolean;
}

interface IOnboardingStepper {
  step: string;
  icon?: JSX.Element;
  headline?: string;
  content?: string | JSX.Element;
}

export interface IOnboardingQuestionPage {
  id?: string;
  title?: string;
  description?: string | JSX.Element;
  icon?: JSX.Element;
  stepper: IOnboardingStepper;
  questions?: IOnboardingQuestion[];
}

const onboardingQuestionPages: IOnboardingQuestionPage[] = [
  {
    title: "Almost there",
    stepper: {
      step: "Complete signup"
    }
  },
  {
    id: "welcome",
    title: "Welcome to ScraperAPI",
    description: "We’ll help you with getting started and setting the best experience tailored just for you.",
    stepper: {
      step: "Introduction",
      icon: <img src={OnboardingGuy} alt="welcome to scraperapi" className="w-[100px] h-[100px]" />,
      headline: "Thanks for signing up!",
      content: "Let's start your scraping journey with us."
    },
    questions: [
      {
        id: "profession",
        question: "What is your profession?",
        type: "single-select",
        mandatory: true,
        answers: [
          "Engineer / Developer",
          "Lead / CEO / CTO",
          "Freelancer",
          "Data Analyst / Scientist",
          "Marketing / SEO manager",
          "Researcher",
          "Product Manager / Product Owner / Project Manager"
        ],
        hasOtherAnswer: true
      },

      {
        id: "industry",
        question: "Your industry?",
        type: "single-select",
        mandatory: true,
        answers: [
          "Data Research",
          "Marketing & Advertisement",
          "Computer Engineering / IT Services",
          "Real Estate",
          "eCommerce / Marketplace",
          "AI / ML",
          "HR-Tech",
          "Fintech",
          "University / School"
        ],
        hasOtherAnswer: true
      },

      {
        id: "experience",
        question: "What describes you the best?",
        type: "single-select",
        mandatory: true,
        answers: [
          "No experience with scraping APIs",
          "Basic understanding of scraping APIs",
          "Previous usage of scraping APIs",
          "Highly experienced in data scraping"
        ]
      }
    ]
  },

  {
    id: "goal",
    title: "What is your goal?",
    icon: <img src={OnboardingDarts} alt="what is your goal" className="w-[32px] h-[32px]" />,
    stepper: {
      step: "Your goal",
      icon: <img src={OnboardingGuy} alt="welcome to scraperapi" className="w-[100px] h-[100px]" />,
      headline: "Why do we ask these questions?",
      content: "These questions help us personalize your scraping experience and cater to your data needs."
    },
    questions: [
      {
        id: "here_to",
        question: "I am here to:",
        type: "multi-select",
        mandatory: true,
        answers: [
          "Get raw HTML data from scraping",
          "Get already structured data downloadable in JSON or CSV",
          "Learn more about ScraperAPI and scraping in general",
          "Browse the dashboard"
        ],
        hasOtherAnswer: true
      },

      {
        id: "want_to_scrape",
        question: "I want to scrape:",
        type: "multi-select",
        mandatory: true,
        answers: [
          "Ecommerce Domains, like Amazon or Idealo",
          "Social Domains like Twitter, Linkedin or Social Media Sites",
          "Search Engines like Google or Yahoo",
          "Custom URLs",
          "Don’t know yet"
        ],
        hasOtherAnswer: true
      }
    ]
  },

  {
    title: "Let's get started",
    icon: <img src={OnboardingRocket} alt="let's get started" className="w-[32px] h-[32px]" />,
    stepper: {
      step: "Getting started",
      icon: <img src={OnboardingGuy} alt="welcome to scraperapi" className="w-[100px] h-[100px]" />,
      headline: "How your free trial works?",
      content: <FreeTrialSteps />
    },
    questions: [
      {
        question: "With ScraperAPI you will get:",
        type: "readonly-listing",
        answers: [
          "A fast & steady API to collect data",
          "Endpoints for specific domains to collect structured data",
          "The ability to scale your data collection with our async service",
          "A low-code solution to automate your scraping projects"
        ]
      }
    ]
  }
];


export const pageData = onboardingQuestionPages;

export const stepperSteps = onboardingQuestionPages.map(questionsPage => questionsPage.stepper);
