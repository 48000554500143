const Messages = {

  renewalDisabledTaggedMessage: "This feature has been disabled for your plan. Please reach out to [growth@scraperapi.com|contact_growth_email] or your account manager if you wish to change your current API credit plan.",

  cannotCancelBecauseOfUnpaidInvoicesMessage: "Cancellation not possible due to unpaid invoices. [Contact support|contact_support] if you have questions or need help.",

  apiKeyCardTooltipMessage: 'The API key is used to authenticate requests and is a unique number. If you think your key was exposed to a third party, you can generate a new one by clicking on the "manage" button.',

};

export default Messages;
