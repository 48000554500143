import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { NavigateFunction, useNavigate, useRevalidator } from "react-router-dom";

import scraperApi from "api";

import EmailInput from "./EmailInput";
import PasswordInput from "./PasswordInput";

import CaptchaField from "components/CaptchaField";
import ExtLink from "components/ExtLink";
import FormWithValidation from "components/FormWithValidation";
import InputFieldWithValidation from "components/InputFieldWithValidation";
import SeparatorText from "components/SeparatorText";
import SignInButton from "components/SignInButton";
import SubmitButton from "components/SubmitButton";
import Toaster from "components/Toaster";
import { ErrorMessage } from "components/Modal/ErrorMessage";

import useFormattedActionError from "hooks/useFormattedActionError";
import { useGoogleOAuth } from "hooks/useGoogleOAuth";


const handleAuthResponse = async (response: any, navigate: NavigateFunction, successCallback: () => Promise<void>, gaEvent?: string) => {
  if (response.includes("A sign in link has been sent to ")) {
    return navigate("/magic-link", { replace: true });
  }

  await successCallback?.();

  if (gaEvent) {
    window?.gtag("event", gaEvent);
  }
};


interface OAuthButtonsProps {
  googleButtonMode?: "signup" | "signin";
  googleButtonText?: string;
  googleCallback?: (token: string) => Promise<any>;
  googleRedirect?: string;
  gitHubButtonText?: string;
  gitHubRedirectUri?: string;
  defaultErrorMessage: ((authWith?: string) => string) | string;
}

function OAuthButtons(
  {
    googleButtonMode,
    googleButtonText,
    googleCallback,
    googleRedirect,
    gitHubButtonText,
    gitHubRedirectUri,
    defaultErrorMessage
  }: OAuthButtonsProps
) {

  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const googleBtnElm = useRef(null);
  const [ oAuthError, setOAuthError ] = useState<string | null>(null);

  const { hideGoogleOAuth } = useGoogleOAuth({
    googleButtonMode: googleButtonMode || "signin",
    setOAuthError,
    googleBtnElm,
    credentialsCallback: async (googleCredentials: any) => {
      try {
        const response = await googleCallback?.call(undefined, googleCredentials.credential);

        return handleAuthResponse(
          response,
          navigate,
          async () => {
            if (googleRedirect) {
              revalidator.revalidate();
              return navigate(googleRedirect);
            } else {
              return revalidator.revalidate();
            }
          }
        );
      } catch (err) {
        setOAuthError((err as Error).message || (typeof defaultErrorMessage === "string" ? defaultErrorMessage : defaultErrorMessage("Google")));
      }
    }
  });

  const gitHubConnect = useCallback(async () => {
      try {
        const gitHubState = await scraperApi.auth.github.state();
        window.location.href = `https://github.com/login/oauth/authorize?scope=user:email&client_id=${process.env.REACT_APP_GITHUB_OAUTH_CLIENT_ID}&state=${gitHubState}&redirect_uri=${gitHubRedirectUri}`;
      } catch (error) {
        setOAuthError((error as Error).message || (typeof defaultErrorMessage === "string" ? defaultErrorMessage : defaultErrorMessage("GitHub")));
      }
    },
    [
      setOAuthError, gitHubRedirectUri, defaultErrorMessage
    ]
  );


  return (
    <>
    <div className="flex flex-col lg:flex-row lg:justify-between space-y-4 lg:space-y-0 lg:space-x-4">
        {!hideGoogleOAuth && googleCallback && googleButtonText && (
          <div ref={googleBtnElm}>
            <SignInButton
              provider="google"
              onClick={() => setOAuthError(null)}
            />
          </div>
        )}
        {gitHubRedirectUri && gitHubButtonText && (
          <div>
            <SignInButton
              provider="github"
              onClick={gitHubConnect}
            />
          </div>
        )}
      </div>
      <p className="mt-2 text-sm text-red-600 dark:text-error-600">{oAuthError}</p>
    </>
  );
}


interface EmailAndPasswordFormProps {
  readonlyEmail?: string;
  askForPassword?: boolean;
  showCaptcha?: boolean;
  showTerms?: boolean;
  emailButtonText?: string;
  errorToasterId?: string;

  state?: any;
  children?: ReactNode;
}

function EmailAndPasswordForm(
  {
    readonlyEmail,
    askForPassword = true,
    showCaptcha = false,
    showTerms = false,
    emailButtonText,
    errorToasterId,
    state,
    children
  }: EmailAndPasswordFormProps
) {
  const [ captchaCompleted, setCaptchaCompleted ] = useState(!showCaptcha);

  const actionError = useFormattedActionError();

  useEffect(() => {
    if (actionError) {
      Toaster.error(actionError, undefined, { toastId: errorToasterId });
    }
  }, [ actionError, errorToasterId ]);


  return (
    <FormWithValidation method="POST" onSubmit={ () => Toaster.dismiss() } state={ state }>
      <div className="flex flex-col space-y-4">
        <EmailInput readonlyEmail={ readonlyEmail }/>
        { askForPassword &&
            <PasswordInput/>
        }
        { showCaptcha &&
            <CaptchaField setCompleted={ (completed: boolean) => setCaptchaCompleted(completed) }/>
        }
        { showTerms &&
            <InputFieldWithValidation
                id="terms"  // needs to be set to link the label, so the checkbox can also be ticked by clicking on the label
                name="terms"
                label={ <>
                  By signing up you agree to our{ " " }
                  <ExtLink
                    className="underline text-brandPrimary dark:text-primary-600 underline-offset-2"
                    href="https://www.scraperapi.com/terms/"
                  >
                    Terms & Conditions
                  </ExtLink>
                  { " " }and to scrape in compliance with the Terms & Conditions of the website you intend to scrape.
                </> }
                labelPosition="right"
                type="checkbox"
                required
                requiredErrorMessage="You have to accept our Terms & Conditions."
            />
        }
      </div>

      { emailButtonText && (
        <div className="my-8">
          <SubmitButton
            text={ emailButtonText }
            className="button button-primary"
            size="LG"
            fullWidth
            checkFormValidity
            disabled={ !captchaCompleted || (actionError === null) }
          />
        </div>
      ) }
      <ErrorMessage errorMessage={ actionError }/>
      { children }
    </FormWithValidation>
  );
}


export default function AuthForm(
  {
    children,
    ...props
  }: {
    children?: ReactNode,
  } & OAuthButtonsProps & EmailAndPasswordFormProps
) {
  // TODO props should be split in 2, based on the interfaces, and we should only pass the related fields down to the other components
  return (
    <>
      { (props.googleCallback || props.gitHubRedirectUri) && (
        <>
          {/* passing all the props here regardless of what fields are present in the 2 different interfaces. */}
          {/* it is safe now, but be careful when changing those interfaces! */}
          <OAuthButtons
            { ...props }
          />
          <div className="my-8">
            <SeparatorText label="or"/>
          </div>
        </>
      ) }
      {/* passing all the props here regardless of what fields are present in the 2 different interfaces. */}
      {/* it is safe now, but be careful when changing those interfaces! */}
      <EmailAndPasswordForm
        { ...props }
      >
        { children }
      </EmailAndPasswordForm>
    </>
  );

};
