module.exports = {
    // new theme colors
    primary: {
        50: "#EDEDFD",
        100: "#DADBFB",
        200: "#B1B3F6",
        300: "#8D90F2",
        400: "#686CEE",
        500: "#3F44E9",
        600: "#1A22E4",
        700: "#1318A9",
        800: "#0D1072",
        900: "#060837"
    },
    accent: {
        50: "#F8F0FF",
        100: "#F2E0FF",
        200: "#E7C7FF",
        300: "#D9A8FF",
        400: "#CD8CFF",
        500: "#B24DFF",
        600: "#950AFF",
        700: "#7100C7",
        800: "#4B0085",
        900: "#260042"
    },
    neutral: {
        50: "#ECF0F3",
        100: "#D6DEE5",
        200: "#AEBECC",
        300: "#7D96AB",
        400: "#5F7C96",
        500: "#465C6F",
        600: "#374858",
        700: "#293642",
        800: "#1C242C",
        900: "#0E1216"
    },
    info: {
        50: "#E7F9FE",
        100: "#C9F2FD",
        200: "#99E7FA",
        300: "#63DAF8",
        400: "#32CFF6",
        500: "#0ABAE5",
        600: "#0891B2",
        700: "#066B84",
        800: "#044758",
        900: "#02242C"
    },
    success: {
        50: "#E7F8ED",
        100: "#D4F2DF",
        200: "#A8E5BF",
        300: "#79D89C",
        400: "#4ECB7C",
        500: "#33AD60",
        600: "#298A4C",
        700: "#1E6639",
        800: "#154727",
        900: "#0A2314"
    },
    warning: {
        50: "#FEF7EC",
        100: "#FDEFD9",
        200: "#FADFB2",
        300: "#F8CE8C",
        400: "#F5BE65",
        500: "#F3AE3F",
        600: "#E6940E",
        700: "#AD6F0B",
        800: "#734A07",
        900: "#3A2504"
    },
    error: {
        50: "#FDECEC",
        100: "#FCDADA",
        200: "#F8B4B4",
        300: "#F59494",
        400: "#F16E6E",
        500: "#EE4949",
        600: "#E51515",
        700: "#AD1010",
        800: "#700A0A",
        900: "#380505"
    },


    // old theme colors
    brandDarkest: {
        DEFAULT: "#090A3A",
        50: "#888AED",
        100: "#7779EA",
        200: "#5356E4",
        300: "#3034DF",
        400: "#1F22C7",
        500: "#191CA4",
        600: "#141681",
        650: "#112C70",
        700: "#0E105D",
        800: "#090A3A", // This is the one defined in the figma file
        900: "#010209"
    },
    brandDark: {
        DEFAULT: "#083489",
        50: "#A1BFF9",
        100: "#8DB2F8",
        200: "#6798F6",
        300: "#407EF4",
        400: "#1A63F2",
        500: "#0D51D6",
        600: "#0A43B0",
        700: "#083489", // This is the one defined in the figma file
        800: "#052054",
        900: "#020C1F"
    },
    brandPrimary: {
        DEFAULT: "#1A22E4",
        50: "#E3E4FC",
        100: "#D0D2FA",
        200: "#ACAFF6",
        300: "#878BF1",
        400: "#6268ED",
        500: "#3E45E9",
        600: "#1A22E4", // This is the one defined in the Figma file
        700: "#141AB2",
        800: "#0F137F",
        900: "#090B4D"
    },
    brandLight: "#5CABF6",
    brandLightest: {
        DEFAULT: "#F0F8FF",
        50: "#F0F8FF", // This is the one defined in the Figma file
        75: "#D9EDFF",
        100: "#D4EBFF",
        200: "#9CD1FF",
        300: "#64B7FF",
        400: "#2C9CFF",
        500: "#0081F3",
        600: "#0063BA",
        700: "#004682",
        800: "#00284A",
        900: "#000A12"
    },
    lightestGray: {
        DEFAULT: "#F5F5F5",
        50: "#FFFFFF",
        75: "#F9F9F9",
        100: "#F5F5F5", // This is the one defined in the Figma file
        200: "#D9D9D9",
        300: "#BDBDBD",
        400: "#A1A1A1",
        500: "#858585",
        600: "#696969",
        700: "#4D4D4D",
        800: "#313131",
        900: "#151515"
    },
    lightGray: {
        DEFAULT: "#A1B3C1",
        50: "#FFFFFF",
        100: "#FFFFFF",
        200: "#FFFFFF",
        300: "#EBEFF2",
        400: "#D2DBE1",
        500: "#BAC7D1",
        600: "#A1B3C1", // This is the one defined in the Figma file
        700: "#7F98AB",
        800: "#6C808F", // THis is the one defined in the Figma file
        900: "#4A5F70"
    },
    gray: {
        DEFAULT: "#465C6F",
        50: "#BFCCD7",
        100: "#B2C2CF",
        200: "#99ADBF",
        300: "#8099AF",
        400: "#67859F",
        500: "#567188",
        600: "#465C6F", // This is the one defined in the Figma file
        700: "#303F4D",
        800: "#1B232A",
        900: "#050608"
    },
    borderColor: {
        DEFAULT: "#E2E8EE",
        50: "#EFF2F6",
        100: "#E2E8EE", // This is the one defined in the Figma file
        200: "#BFCCD9",
        300: "#9BB0C5",
        400: "#7894B0",
        500: "#597897",
        600: "#445C74",
        700: "#2F4050",
        800: "#1A242D",
        900: "#06080A"
    },
    red: {
        DEFAULT: "#D94F46",
        50: "#FFFFFF",
        100: "#FCF0EF",
        200: "#F5D0CD",
        300: "#EEB0AC",
        400: "#E78F8A",
        500: "#E06F68",
        600: "#D94F46", // This is the one defined in the Figma file
        700: "#C03127",
        800: "#91251E",
        900: "#621914"
    },
    orange: {
        DEFAULT: "#FDA906",
        500: "#FFF1D7" // This is the one that is defined in figma.
    },
    green: {
        DEFAULT: "#4AB890",
        50: "#CEECE1",
        100: "#BFE6D8",
        200: "#A2DAC6",
        300: "#85CFB4",
        400: "#67C3A2",
        500: "#4AB890", // This is the one defined in the figma file
        600: "#399171",
        700: "#296952",
        800: "#194032",
        900: "#091813"
    },
    signinGray: {
        DEFAULT: "#202124",
        50: "#4f5052"
    },
    darkGray: {
        DEFAULT: "#202124",
        50: "#4f5052"
    },
    codeViewPurple: {
        DEFAULT: "#1B1B43",
    }
};
