import { createContext, ReactNode, useContext, useMemo, useState } from "react";
import { v4 as uuidV4 } from "uuid";

import ServiceScriptProvider, { ServiceScript } from "providers/ServicesProvider/ServiceScriptProvider";


const CHARGEBEE_SERVICE_NAME = "Chargebee";



interface ChargebeePortalSections {
  SUBSCRIPTION_DETAILS: string;
  SUBSCRIPTION_CANCELLATION: string;
  EDIT_SUBSCRIPTION: string;
  VIEW_SCHEDULED_CHANGES: string;
  ACCOUNT_DETAILS: string;
  EDIT_ACCOUNT_DETAILS: string;
  ADDRESS: string;
  EDIT_BILLING_ADDRESS: string;
  EDIT_SHIPPING_ADDRESS: string;
  EDIT_SUBSCRIPTION_CUSTOM_FIELDS: string;
  PAYMENT_SOURCES: string;
  ADD_PAYMENT_SOURCE: string;
  EDIT_PAYMENT_SOURCE: string;
  VIEW_PAYMENT_SOURCE: string;
  CHOOSE_PAYMENT_METHOD_FOR_SUBSCRIPTION: string;
  BILLING_HISTORY: string;
}

export type ChargebeePortalSection = keyof ChargebeePortalSections;

export interface ChargebeeContextType {
  chargebeeInstance: any;
  chargebeePortalSections: ChargebeePortalSections;
}

interface Props {
  children: ReactNode;
  publishableKey: string;
  site: string;
  enableGTMTracking?: boolean;
  enableGATracking?: boolean;
}
let ChargebeeContext = createContext<ChargebeeContextType>(null!);
function ChargebeeProvider({
  children,
  publishableKey,
  site,
  enableGTMTracking,
  enableGATracking
}: Props) {

  const [ initialized, setInitialized ] = useState(false);

  const chargebeeInstance = useMemo(() => {
    if (initialized) {
      try {
        return window.Chargebee.getInstance();
      } catch (error) {
        return window.Chargebee.init({
          site,
          publishableKey,
          enableGTMTracking,
          enableGATracking
        });
      }
    } else {
      window.Chargebee = undefined;
      return null;
    }
  }, [ enableGATracking, enableGTMTracking, publishableKey, site, initialized ]);

  const chargebeeScript: ServiceScript = {
    name: CHARGEBEE_SERVICE_NAME,
    domId: uuidV4(),
    src: "https://js.chargebee.com/v2/chargebee.js",
    onLoadCallback: () => { setInitialized(true); },
    onUnloadCallback: () => { setInitialized(false); }
  };

  const portalSections = useMemo(() => {
    if (initialized) {
      return window.Chargebee.getPortalSections();
    } else {
      return null;
    }
  }, [ initialized ]);

  let value = {
    chargebeeInstance,
    chargebeePortalSections: portalSections
  };
  return (
    <>
      <ServiceScriptProvider
        { ...chargebeeScript }
      />
      <ChargebeeContext.Provider value={value}>
        {children}
      </ChargebeeContext.Provider>
    </>
  );
}

export function useChargebee() {
  return useContext(ChargebeeContext);
}

export default ChargebeeProvider;
