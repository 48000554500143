import NumberAboveOrEqualsFeatureSwitchCheck from "./NumberAboveOrEqualsFeatureSwitchCheck";
import { FeatureSwitch } from "components/FeatureSwitch/FeatureSwitches";


export default class UserSignedUpAfter extends NumberAboveOrEqualsFeatureSwitchCheck {

  constructor(featureSwitch: FeatureSwitch) {
    super(featureSwitch, (user) => Date.parse(user.signedUpAt || "") || -1);
  }

  protected parseFeatureValue(featureSwitchValue: string | undefined): number | undefined {
    return Date.parse(featureSwitchValue || "") || undefined;
  }

}
