import axios from "axios";
import { createContext, ReactNode, useCallback, useContext, useState, useEffect } from "react";

import scraperApi from "api";

import { ProjectConfigWithStats } from "./types";

import { useUser } from "routes/dataroutes/UserData";


export type HostedScrapingProjectsContextType = {
  refreshInBackground: (controller: AbortController | undefined) => Promise<void>;
  refreshProject: (projectId: number, controller: AbortController | undefined) => Promise<void>;
  inProgress: boolean;
  projects: ProjectConfigWithStats[];
};

const HostedScrapingProjectsContext = createContext<HostedScrapingProjectsContextType>(null!);


export default function HostedScrapingProjectsProvider({ children }: { children: ReactNode }) {

  const [ projects, setProjects ] = useState<ProjectConfigWithStats[]>([]);
  const [ inProgress, setInProgress ] = useState<boolean>(true);

  const user = useUser();

  const fetchProjects = useCallback(
    async (controller?: AbortController) => {
      const projects = await scraperApi.hostedScraping.projects({
        signal: controller?.signal,
      });
      setProjects(projects);
    },
    []
  );

  const fetchSingleProject = useCallback(
    async (projectId: number, controller?: AbortController) => {
      try {
        setInProgress(true);
        const project = await scraperApi.hostedScraping.singleProject(projectId, {
          signal: controller?.signal,
        });
        const newProjectList = [ ...projects ];
        const index = newProjectList.findIndex((p) => (p.id === projectId));
        if (index === -1) {
          newProjectList.push(project);
        } else {
          newProjectList[index] = project;
        }

        setProjects(newProjectList);
        setInProgress(false);
      } catch (err) {
        // If it's aborted it's from the unmounting of the component
        if (axios.isCancel(err)) {
          setInProgress(true);
        } else {
          setInProgress(false);
        }
      }
    },
    [ projects ]
  );

  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      try {
        setInProgress(true);
        await fetchProjects(controller);
        setInProgress(false);
      } catch (err) {
        // If it's aborted it's from the unmounting of the component
        if (axios.isCancel(err)) {
          setInProgress(true);
        } else {
          setInProgress(false);
        }
      }
    })();

    return () => {
      controller.abort();
    }
  }, [fetchProjects, user]);

  const value = {
    projects,
    inProgress,
    // In background means no update of progress
    refreshInBackground: fetchProjects,
    refreshProject: fetchSingleProject,
  };

  return (
    <HostedScrapingProjectsContext.Provider value={value}>
      {children}
    </HostedScrapingProjectsContext.Provider>
  );
}

export function useHostedScrapingProjects() {
  return useContext(HostedScrapingProjectsContext);
}
