import { LinkText } from "components/LinkText";
import { WebhookInputConfig } from "providers/HostedScrapingProvider/types";

export const AccessWebhookInputURL = ({url}: WebhookInputConfig) => (
  <div>
     { url !== null && url !== undefined && (<div>
        <div >
          <a href={url}>
            <LinkText>
              {url}
            </LinkText>
          </a>
        </div>
      </div>)}
  </div>
);