import { Listbox } from "@headlessui/react";
import { ListboxButton, listboxButtonClasses, ListboxElement, listboxOptionClasses, listboxOptionsClasses, OptionsTransition, placeHolderListboxButtonClasses, placeHolderListboxOptionClasses, selectedListboxOptionClasses, shortListboxOptionsClasses } from "components/Listbox";



export const pickOptionAndConcat = (options: {value: string|undefined, text: string}[], valuesToFind: string[]) => {
  const foundOptions = options.filter(({value, text}) => valuesToFind.includes(value as string));
  return foundOptions.map(({text}) => text).join(', '); 
};

type AdditionalOptionsLMultiListboxProps = {
  values: string[],
  options: {value: string|undefined, text: string}[],
  callback: (selected: string[]) => void,
  shortList?: boolean,
  buttonTestId?: string
};

/*
 * This function is and adapter to convert a callback that expects a comma
 * separated list to a callback that expects a list of strings.
 */
export const callbackWithCommaSeparatedString = (fn: (arg0: string) => void) => {
  return (values: (string|undefined)[]) => {
    if (values.includes(undefined)) {
      // This means the user clicked on None so we need to clear the list
      fn('');
    } else {
      fn(values.join(','));
    }
  };
};

export const splitCommaSeparatedString = (str: string | undefined): string[] => {
  if (str === undefined) {
    return [];
  } else {
    return str.split(',').map((s) => s.trim());
  };
}

export const AdditionalOptionsMultiListbox = ({values, options, callback, shortList = false, buttonTestId}: AdditionalOptionsLMultiListboxProps) => {
  return (<Listbox value={values} onChange={callback} multiple>
    <div className="w-full shadow">
      <Listbox.Button className={values.length > 0 ? listboxButtonClasses : placeHolderListboxButtonClasses} data-testid={buttonTestId}>
        <ListboxButton content={pickOptionAndConcat(options, values)} placeholder="None" />
      </Listbox.Button>
      <OptionsTransition>
          <Listbox.Options className={shortList ? shortListboxOptionsClasses : listboxOptionsClasses}>
              {
                options.map((option) => {
                  const isSelected = values.includes(option.value!);
                  const optionClass = isSelected ? selectedListboxOptionClasses 
                    : option.value ? listboxOptionClasses
                    : placeHolderListboxOptionClasses;
                  return (
                    <Listbox.Option key={option.value || 'none'} className={optionClass} value={option.value}>
                      <ListboxElement primaryText={option.text} selected={isSelected} />
                    </Listbox.Option>
                  );
                })
              }
          </Listbox.Options>
      </OptionsTransition>
    </div>
  </Listbox>);
};
