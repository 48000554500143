import { isValidQuery } from "components/hostedScrapingValidators";
import { SDEContent } from "./sdecontentTypes";

export const AmazonSearchContent: SDEContent = {
  projectType: 'async_amazon_search',
  projectCategory: 'amazon',
  apiUrlFn: (apiHost: string) => {
    return `${apiHost}/structured/amazon/search`;
  },
  docsSection: 'amazon-search-api',
  docsSectionAsync: 'amazon-search-api-async',
  inputKey: 'query',
  inputsKey: 'queries',
  validatorFunction: isValidQuery,
  newProjectEditProject: {
    inputBoxLabels: {
      listLiteralInputTitle: 'Search terms',
      listLiteralInputPlaceholder: 'Enter one search term per line.',
    },
    inputSectionLabels: {
      inputSectionTitle: "Input search term",
      inputSectionDescription: "Based on the data type you selected, you need to add search terms to begin scraping Amazon.",
    },
    projectContent: {
        title: 'Amazon search',
        description: 'Extract data from keywords in the Amazon search and download the results as well-structured data in a JSON file.',
        icon: 'amazon',
        inputTitle: 'Query',
      },
  },
  apiPlayground: {
    inputSectionLabels: {
      inputSectionTitle: 'Input search term',
      inputSectionDescription: 'Enter Amazon search terms or full URLs to begin scraping Amazon.',
    },
    projectContent: {
        title: 'Amazon search',
        description: 'Extract data from keywords in the Amazon search.',
        icon: 'amazon',
    },
    enterInputsPlaceholder: 'query',
    enterInputsMessage: 'Enter keyword for scraping',
    codeViewPlaceHolder: 'Please provide a valid search term',
    dropdownTitle: 'Amazon Search'
  }
};
