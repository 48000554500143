import { useEffect } from "react";
import { useFetcher } from "react-router-dom";

import noChartDataIllustration from "assets/images/dashboard-no-chart-data-illustration.png";

import Button from "components/Button";
import Card from "components/Card";
import ProgressBar from "components/ProgressBar";
import { PureTooltip } from "components/Tooltip";
import Spinner from "components/Spinner";

import { SubscriptionType, UserType } from "providers/UserProvider";

import { useUser } from "routes/dataroutes/UserData";

import { fmtDate } from "utils";
import { dateDiff } from "utils/dateUtils";
import { DAY } from "utils/timeConstants";

import UsageHistoryCharts from "./UsageHistoryCharts";


const getRemainingDays = (user: UserType | null, subscription: SubscriptionType | undefined) => {
  if (user?.isFreeTrial) {
    const diff = dateDiff(new Date(user.signedUpAt!), Date.now(), DAY);
    return Math.max(0, 7 - diff);
  }

  return subscription ? dateDiff(Date.now(), subscription.next_renewal_at * 1000, DAY) : undefined;
};

const getTooltipContent = (user: UserType | null, subscription: SubscriptionType | undefined) => {
  if (user?.isFreeTrial) {
    return "Your trial ends on " + fmtDate(
      new Date(new Date(user.signedUpAt!).getTime() + (7 * DAY)), { weekday: undefined }
    ) + ".";
  }

  return subscription ? "Resets on " + fmtDate(new Date(subscription.next_renewal_at * 1000), { weekday: undefined }) + "." : undefined;
}

function PlanEndsInLabel() {
  const user = useUser();

  const subscriptionFetcher = useFetcher<{ activeSubscription: SubscriptionType } | undefined>();

  useEffect(() => {
    if ((subscriptionFetcher.state === "idle") && (subscriptionFetcher.data === undefined)) {
      subscriptionFetcher.load("/billing-data/subscription/details");
    }
  });

  const remainingDays = getRemainingDays(user, subscriptionFetcher.data?.activeSubscription);

  const tooltipContent = getTooltipContent(user, subscriptionFetcher.data?.activeSubscription);


  return (
    <PureTooltip content={ tooltipContent }>
      <div
        className="flex flex-row gap-x-1 items-center text-neutral-700 text-sm border-b-[1px] border-b-neutral-700 border-dotted cursor-default">
        { remainingDays === 0 && <span>Ends today</span> }
        { remainingDays !== 0 && (
          <>
            <span>Ends in</span>
            { remainingDays ?? <Spinner theme="secondary" size="XXS"/> }
            <span>{ (remainingDays || 2) > 1 ? "days" : "day" }</span>
          </>
        ) }
      </div>
    </PureTooltip>
  );
}

function PlanUsageCard() {
  const user = useUser();

  return (
    <Card
      title={ user?.isFreeTrial ? "Trial" : (user?.planName + " plan") }
      extra={ <PlanEndsInLabel /> }
      size="MD"
      borderless
      footer={ <Button text="Upgrade to larger plan" className="button button-primary w-full" href="/billing" /> }
    >
      <div className="flex flex-col h-full py-4 gap-y-4">
        <ProgressBar current={ user?.requestCount } max={ user?.apiCallLimit } label="API credits used" fullWidth/>
        <ProgressBar current={ user?.concurrentRequests } max={ user?.concurrentRequestLimit } label="Concurrent threads" fullWidth/>
      </div>
    </Card>
  );
}

function NoUsagePanel() {
  return (
    <div className="flex flex-col gap-y-5 items-center self-center justify-self-center max-w-[274px]">
      <img src={ noChartDataIllustration } alt="there is no data yet" />
      <div className="flex flex-col gap-y-1.5 items-center">
        <p className="font-medium">There is no data yet!</p>
        <p className="text-sm text-center">Let’s get started and make your first request to see your statistics.</p>
      </div>
    </div>
  );
}

function ChartsPanel() {
  const user = useUser();

  return user?.madeFirstRequest ?
    <UsageHistoryCharts/> :
    <NoUsagePanel/>;
}

export default function PlanOverviewAndStatsCard() {
  return (
    <div className="flex flex-col gap-y-4">
      <div className="font-semibold text-neutral-900">Your plan</div>
      <Card size="LG">
        <div className="grid grid-cols-[330px_minmax(0,1fr)] gap-x-8 h-full w-full">
          <PlanUsageCard />
          <ChartsPanel />
        </div>
      </Card>
    </div>
  );
};
