import ApiKeyCard from "./ApiKeyCard";
import WhatsNewCard from "./WhatsNewCard";
import NeedHelpCard from "./NeedHelpCard";


export default function RightSidebar() {
  return (
    <div className="flex flex-col w-[360px] pl-6 gap-y-6">
      <ApiKeyCard />
      <WhatsNewCard />
      <NeedHelpCard />
    </div>
  );
};
