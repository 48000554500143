import { ReactNode } from "react";

import { cx } from "utils";


interface ICardStackProps {
  children?: ReactNode;
  horizontal?: boolean;
  className?: string;
}

export default function CardStack(
  {
    children,
    className,
    horizontal = true
  }: ICardStackProps
) {
  return (
    <div className={ cx(
      "flex justify-between group/card-stack",
      horizontal ? "flex-row" : "flex-col",
      className
    ) }>
      { children }
    </div>
  );
};
