import { Banner } from "components/IssuesBanner/Banner";

import { useNewDashboardDesign } from "providers/NewDashboardDesignProvider";


export default function NewDashboardDesignAvailableBanner() {
  const { newDashboardDesignSetting: setting, updateNewDashboardDesignSetting: updateSetting } = useNewDashboardDesign();

  return (
    <>
      { !setting && (
        <Banner
          title={
            <span className="flex gap-x-2 items-center justify-center text-sm text-primary-800">
              <span>Would you like to take a look at our new dashboard design?</span>
              <button className="hover:underline hover:text-primary-900" onClick={ () => updateSetting("yes") }>Yes</button>
              <button className="hover:underline hover:text-primary-900" onClick={ () => updateSetting("no") }>No</button>
              <button className="hover:underline hover:text-primary-900" onClick={ () => updateSetting("never") }>Don't ask again</button>
            </span>
          }
          dismissible={ true }
          severity="info"
        />
      ) }
    </>
  );
};
