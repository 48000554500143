import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import scraperApi from "api";
import AuthPage, { AuthPageLink, AuthPageLinks } from "components/AuthPage";
import PasswordChangeForm from "components/PasswordChangeForm";


export default function ResetPassword() {
  const navigate = useNavigate();

  const resetPasswordCallback = useCallback(
    async (oldPassword: string, newPassword: string) => {
      await scraperApi.auth.resetPassword(newPassword);
      navigate("/");
    },
    [
      navigate
    ]
  );

  return (
    <AuthPage
      title="Reset Password"
      heading={[ "Reset password", "Enter your new password below to change it." ]}
    >

      <PasswordChangeForm
        askOldPassword={false}
        buttonText="Reset Password"
        changePasswordCallback={resetPasswordCallback}
      />

      <AuthPageLinks>
        <AuthPageLink text="" link={{ to: "/login", text: "Go Back" }} />
      </AuthPageLinks>

    </AuthPage>
  );
}
