import { cx } from "utils";

import { ReactComponent as ScraperAPILogo } from "assets/icons/scraperapi-logo.svg";


export default function TopLeftLogo({ content, className }: { content: JSX.Element, className?: string }) {
  return (
    <div className="relative flex items-center justify-center w-full h-full px-4 md:px-8">
      <div className="absolute top-4 left-4 w-44">
        <ScraperAPILogo />
      </div>
      <div className={ cx("w-full max-w-[500px] mt-24 mb-20 md:my-20", className) }>
        {content}
      </div>
    </div>
  )
};
