export const async_walmart_review = {
  input: "5253396052",
  result: "{\n" +
    "  \"product_name\": \"HOBIBEAR Boys Sneakers Kids Running Lightweight Breathable Sport Tennis Shoes（Toddles/Little Kids/Big Kids）\",\n" +
    "  \"product_url\": \"https://www.walmart.com/ip/HOBIBEAR-Boys-Sneakers-Kids-Running-Lightweight-Breathable-Sport-Tennis-Shoes-Toddles-Little-Kids-Big-Kids/1347882796\",\n" +
    "  \"rating\": 4.0556,\n" +
    "  \"review_count\": 18,\n" +
    "  \"reviews\": [\n" +
    "    {\n" +
    "      \"title\": \"good\",\n" +
    "      \"text\": \"design,price and true size overall like.\",\n" +
    "      \"author\": \"vi\",\n" +
    "      \"date_published\": \"3/10/2024\",\n" +
    "      \"rating\": 4,\n" +
    "      \"positive_feedback\": 0,\n" +
    "      \"negative_feedback\": 0\n" +
    "    },\n" +
    "    {\n" +
    "      \"title\": \"Didn't last long\",\n" +
    "      \"text\": \"Did not hold up very long to little boy running/ playing/ activities.\",\n" +
    "      \"author\": \"firefli529\",\n" +
    "      \"date_published\": \"5/11/2024\",\n" +
    "      \"rating\": 3,\n" +
    "      \"positive_feedback\": 0,\n" +
    "      \"negative_feedback\": 0,\n" +
    "      \"badges\": [\n" +
    "        \"Verified Purchase\",\n" +
    "        \"Incentivized Review\"\n" +
    "      ]\n" +
    "    },\n" +
    "...\n" +
    "}"
};
