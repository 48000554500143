import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  TooltipModel,
  ChartOptions,
  ChartData
} from "chart.js";
import { useCallback, useMemo, useRef, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { cx } from "../../utils";
ChartJS.register(ArcElement, Tooltip, Legend);

export default function SemiCircleChart({
  success,
  failure,
  circumference,
  legendPosition,
  legend
}: {
  success: number;
  failure: number;
  circumference: number;
  legend: string[];
  legendPosition: 'bottom'|'right';
}) {
  const [tooltipData, setTooltipData] = useState<TooltipModel<"doughnut">>();
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const [tooltipPos, setTooltipPos] = useState<{ top: number; left: number }>();

  const neverDidRequest = useMemo(
    () => success === 0 && failure === 0,
    [failure, success]
  );

  const chartRef = useRef(null);
  const customTooltip = useCallback(
    (context: { tooltip: TooltipModel<"doughnut"> }) => {
      if (neverDidRequest) return;

      const { opacity } = context.tooltip;
      setTooltipData(context.tooltip);
      if (opacity === 0) {
        // Hide tooltip visibilty
        return setTooltipVisible(false);
      }

      const chart = chartRef.current;
      // @ts-ignore
      const canvas = chart?.canvas;
      if (!canvas) return;

      // Enable tooltip visibilty
      setTooltipVisible(true);

      // Set position of tooltip
      const left = context.tooltip.x;
      const top = context.tooltip.y;

      // Handle tooltip multiple rerender
      if (tooltipPos?.top !== top) {
        setTooltipPos({ top, left });
      }
    },
    [neverDidRequest, tooltipPos?.top]
  );

  const HTMLTooltip = useCallback(
    ({
      data,
      position,
      visibility
    }: {
      data: TooltipModel<"doughnut"> | undefined;
      position: { top: number; left: number };
      visibility: boolean;
    }) => {
      // console.log(data?.dataPoints[0].label);
      return (
        <div
          className={cx(
            "absolute p-4 overflow-hidden pointer-events-none transition-all bg-white border shadow duration-300 border-borderColor dark:border-neutral-200 flex flex-col gap-y-4",
            visibility ? "opacity-100" : "opacity-0"
          )}
          style={{ top: position?.top, left: position?.left }}
        >
          <p className="text-sm font-bold text-gray dark:text-neutral-600">Requests</p>
          <div className="space-y-2 text-sm">
            {data?.dataPoints?.map(dataPoint => (
              <div
                key={position?.left + `${dataPoint.raw}${dataPoint.label}`}
                className="flex items-center gap-x-2"
              >
                <div
                  className="w-4 h-4"
                  style={{
                    backgroundColor:
                      data.labelColors[
                        dataPoint.datasetIndex
                      ].backgroundColor?.toString()
                  }}
                />
                <p
                  key={dataPoint.datasetIndex}
                  className="flex w-full space-x-2"
                >
                  <span>{dataPoint.label}</span>
                  <span className="font-bold text-gray dark:text-neutral-600">
                    {dataPoint.formattedValue}
                  </span>
                </p>
              </div>
            ))}
          </div>
        </div>
      );
    },
    []
  );

  const ChartData = useMemo(() => {
    return {
      labels: legend,
      datasets: [
        {
          data: [success, failure, success + failure > 0 ? 0 : 1],
          backgroundColor: ["#1a22e4", "#C4C4C4", "#F8F8F8"],
          rotation: -90,
          circumference: circumference,
          weight: 0.1
        }
      ]
    } as ChartData<"doughnut">;
  }, [failure, success, circumference, legend]);

  const ChartOptions = useMemo(() => {
    return {
      cutout: "85%",
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: legendPosition,
          labels: { font: { family: "Work Sans" }, boxHeight: 12, boxWidth: 12 }
        },
        tooltip: {
          enabled: false,
          mode: "index",
          external: customTooltip
        }
      }
    } as ChartOptions<"doughnut">;
  }, [customTooltip, legendPosition]);

  return (
    <div className="relative">
      {tooltipPos && (
        <HTMLTooltip
          data={tooltipData}
          position={tooltipPos}
          visibility={tooltipVisible}
        />
      )}
      <Doughnut ref={chartRef} data={ChartData} options={ChartOptions} />
    </div>
  );
}
