import { useParams, useNavigate } from "react-router-dom";

import { useHostedScrapingProjects } from "providers/HostedScrapingProvider";
import { NewProjectEditProject } from './NewProjectEditProject';
import { ProjectNotFound } from "components/hosted-scraping/ProjectNotFound";
import { FullWidthLoadingSpinner } from "components/FullWidthLoadingSpinner";

export default function ProjectEditPage() {
  const navigate = useNavigate();
  const { projects, refreshProject, inProgress } = useHostedScrapingProjects();
  const { projectId: projectIdParam} = useParams() as { projectId: string};

  const projectId = parseInt(projectIdParam);
  
  const project = projects && projects.find((p) => (p.id === projectId));

  if (project === undefined && !inProgress) {
    return (<ProjectNotFound/>);
  }

  if (project === undefined) {
    return (<FullWidthLoadingSpinner />);
  }

  return (
    <NewProjectEditProject mode='editProject' project={project} cancelButtonVisible backCallback={()=>{
      refreshProject(projectId, new AbortController()).then(
        () => navigate(`/projects/${projectId}`)
      )
    }}/>
  )
}