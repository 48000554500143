import { createContext, ReactNode, useCallback, useContext, useState } from "react";


export type ServiceIdentifier = {
  id?: string;
  name: string;
};

export type ServiceInfo = ServiceIdentifier & {
  domId: string;
};

export type ServicesContextType = {
  loadedServices: ServiceInfo[];
  findService: (serviceIdentifier: ServiceIdentifier) => ServiceInfo | undefined;
  loadService: (serviceInfo: ServiceInfo) => boolean;
  unloadService: (serviceInfo: ServiceInfo) => boolean;
};


export const ServicesContext = createContext<ServicesContextType>({
  loadedServices: [],
  findService: () => undefined,
  loadService: () => false,
  unloadService: () => false
});

export default function ServicesProvider({ children }: { children: ReactNode }) {

  const [ loadedServices, setLoadedServices ] = useState<ServiceInfo[]>([]);


  const findService = useCallback((serviceIdentifier: ServiceIdentifier) => {
    return loadedServices.find(service => (service.id && (service.id === serviceIdentifier.id)) || (service.name && (service.name === serviceIdentifier.name)));
  }, [ loadedServices ]);

  const loadService = useCallback((serviceInfo: ServiceInfo) => {
    const loadedService = findService(serviceInfo);
    if (!loadedService) {
      setLoadedServices([
        ...loadedServices,
        serviceInfo
      ]);
      return true;
    }
    return false;
  }, [ loadedServices, findService ]);

  const unloadService = useCallback((serviceInfo: ServiceInfo) => {
    const loadedService = findService(serviceInfo);
    if (loadedService) {
      setLoadedServices(loadedServices.filter(service => (!service.id || (service.id !== serviceInfo.id)) && (!service.name || (service.name !== serviceInfo.name))));
      return true;
    }
    return false;
  }, [ loadedServices, findService ]);


  return (
    <ServicesContext.Provider
      value={{
        loadedServices,
        findService,
        loadService,
        unloadService
      }}>
      {children}
    </ServicesContext.Provider>
  );
};


export function useServices() {
  return useContext(ServicesContext);
}
