import { useState, useEffect, useRef } from "react";
import { Listbox } from "@headlessui/react";
import "react-datepicker/dist/react-datepicker.css";

import { ProjectDetailsHead } from 'components/hosted-scraping/ProjectDetailsHead';
import {
  isAmazonProject, isGoogleProject, isAsyncUrlsProject, isWalmartProject, isEbayProject,
  getInputSectionLabelsForAP, enterInputsMessageAP, enterInputsPlaceholderAP, codeViewPlaceHolderAP,
  sdeDescriptors
} from "sdecontent";
import { ProjectSummarySidebar } from "components/hosted-scraping/project-summary/ProjectSummarySidebar";
import Button from 'components/Button';
import { InputComments } from 'components/hosted-scraping/edit-project-components/InputComments';
import { UrlProjectAsyncApiParams, AmazonProjectAsyncApiParams, GoogleProjectAsyncApiParams, WalmartProjectAsyncApiParams, EbayProjectAsyncApiParams } from 'components/hosted-scraping/edit-project-components/editApiParams';
import { useLocalStorage } from "hooks/useLocalStorage";
import { ConfigProblem, ConfigProblemWithMessage, validateInput } from "components/hostedScrapingValidators";
import { SectionTitle } from "components/hosted-scraping/edit-project-components/SectionTitle";
import { Separator } from "components/hosted-scraping/edit-project-components/Separator";
import type { ApiCallConfig, ScrapingMethod, SupportedLanguages } from "./ApiPlaygroundTypes";
import { SectionRightHandSide } from "components/hosted-scraping/edit-project-components/SectionRightHandSide";
import { Section } from "components/hosted-scraping/edit-project-components/Section";
import { sampleCode } from "components/hosted-scraping/apiPlaygroundTemplates";
import Alert from "components/Alert";

import Toaster from "components/Toaster";
import { ListboxButton, ListboxElement, OptionsTransition, listboxOptionClasses, listboxButtonClasses, shortListboxOptionsClassesOverflowing, selectedListboxOptionClasses } from "components/Listbox";
import { FullWidthLoadingSpinner } from "components/FullWidthLoadingSpinner";
import { ReactComponent as CopyIcon } from "assets/icons/copy-icon.svg";
import { PlayIcon } from "@heroicons/react/outline";
import { useDebouncer } from "hooks/useDebouncer";
import scraperApi from "api";
import { CodeView } from "components/CodeView";
import { saveAs } from "file-saver";
import axios from "axios";
import { cx } from "utils";
import { useUser } from "routes/dataroutes/UserData";
import { AsyncApiParams, CollectorType, CostCalculationProject } from "providers/HostedScrapingProvider/types";
import { trackApiPlaygroundCopyToClipboardClicked, trackApiPlaygroundDataCopied, trackApiPlaygroundLanguageSelectorClicked, trackApiPlaygroundParameterChanged, trackApiPlaygroundSdeSelected, trackApiPlaygroundURLEntered } from "utils/Tracking";
import { RadioGroup, RadioGroupElement } from "components/RadioGroup";
import Spinner from "components/Spinner";
import { AdditionalOptionsTextBox } from "components/hosted-scraping/edit-project-components/AdditionalOptionsTextBox";
import { ErrorProblemsBox } from "components/hosted-scraping/edit-project-components/ErrorProblemsBox";
import BorderedPage from "components/BorderedPage";
import BorderLayout from "layouts/BorderLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { pickOption } from "components/hosted-scraping/edit-project-components/AdditionalOptionsListBox";

const supportedLanguages: { value: SupportedLanguages, text: string }[] = [
  {value: 'curl', text: 'cURL'},
  {value: 'python', text: 'Python'},
  {value: 'nodejs', text: 'NodeJS'},
  {value: 'php', text: 'PHP'},
  {value: 'ruby', text: 'Ruby'},
  {value: 'java', text: 'Java'},
];

const sdeCollectorTypes: { value: CollectorType, text: string }[] =
  sdeDescriptors.map(sde => ({ value: sde.projectType, text: sde.apiPlayground.dropdownTitle }));

interface TryItResult {
  status: number;
  statusText: string;
  responseText: string;
}

const isJson = (text: string): boolean => {
  try {
    JSON.parse(text);
    return true;
  } catch {
    return false;
  }
}

const tryToFormatJSON = (originalText: string): string => {
  try {
    const obj = JSON.parse(originalText);
    return JSON.stringify(obj, null, 2);
  } catch {
    return originalText;
  }
}

const enterInputsMessage = (scrapingMethod: ScrapingMethod, collectorType: CollectorType | undefined) => {
    if (scrapingMethod === 'async') {
      return 'Enter URL addresses for scraping';
    } else if (scrapingMethod === 'proxy_mode' || scrapingMethod === 'api') {
      return 'Enter URL address for scraping';
    } else if (scrapingMethod === 'structured_data_endpoint') {
      return enterInputsMessageAP(collectorType);
    } else {
      return '';
    }
}

const enterInputsPlaceholder = (scrapingMethod: ScrapingMethod, collectorType: CollectorType | undefined) => {
  if (scrapingMethod === 'async' || scrapingMethod === 'proxy_mode' || scrapingMethod === 'api') {
    return 'https://httpbin.org/anything';
  } else if (scrapingMethod === 'structured_data_endpoint') {
    return enterInputsPlaceholderAP(collectorType);
  } else {
    return '';
  }
}

const codeViewPlaceholderMessage = (scrapingMethod: ScrapingMethod, collectorType: CollectorType | undefined) => {
  if (scrapingMethod === 'async' || scrapingMethod === 'proxy_mode' || scrapingMethod === 'api') {
    return 'Please provide a valid URL';
  } else if (scrapingMethod === 'structured_data_endpoint') {
    return codeViewPlaceHolderAP(collectorType);
  } else {
    return '';
  }
}

export const ApikeyBox = ({apiKey}: {apiKey: string}) => {
  const [recentlyCopied, setRecentlyCopied] = useState(false);
  let timerHandle = useRef<number>();

  useEffect(() => {
    timerHandle.current = window.setTimeout(
      () => setRecentlyCopied(false),
      3000
    );

    // Clear the timer if we unmount before completion
    return () => clearTimeout(timerHandle.current);
  }, [recentlyCopied]);

  const copyApiKeyToClipboard = () => {
    if (recentlyCopied) {
      return;
    }
    navigator.clipboard.writeText(apiKey)
    .then(() => {
      Toaster.success('API key copied to clipboard');
      setRecentlyCopied(true);
    })
    .catch((error) => {
      console.error('Error copying to clipboard', error);
      Toaster.error('API key copy failed');
    });
  };

  return (<div className={"flex flex-row gap-2 bg-lightestGray dark:bg-neutral-50 w-full p-3 items-center text-gray dark:text-neutral-600 text-sm text-normal mb-5"}>
    <div className="font-medium">API key:</div>
    <div>{apiKey}</div>
    <div className="w-5 h-5 cursor-pointer" onClick={copyApiKeyToClipboard}>
      <CopyIcon className="w-5 h-5" />
    </div>
  </div>);
};

const TryItResultStatus = ({status, statusText}: {status: number, statusText: string}) => {
  const color = status === 200 ? 'text-green dark:text-success-600' : 'text-red dark:text-error-600';
  const message = `${status} ${statusText}!`;
  return (
    <span>
      <span>Status: </span>
      <span className={color}>{message}</span>
    </span>
  );
};

type SelectLanguageListboxProps = {
  value: string|undefined,
  options: {value: string|undefined, text: string}[],
  callback: (selected: string|undefined) => void,
  buttonTestId?: string
};

export const codeViewlistboxButtonClasses  = "cursor-pointer px-2.5 py-2.5 bg-codeViewPurple dark:bg-accent-900 border pr-10 text-left  relative border-lightGray dark:border-neutral-500 flex items-center gap-x-2 sm:text-sm";
export const codeViewlistboxOptionsClasses = "absolute py-1 mt-1 overflow-auto text-base bg-codeViewPurple dark:bg-accent-900 shadow-lg ring-1 ring-lightGray dark:ring-neutral-500 focus:outline-none sm:text-sm z-50"
export const codeViewShortListboxOptionsClasses = cx(codeViewlistboxOptionsClasses, "h-44");
export const codeViewlistboxOptionClasses  = "py-2.5 px-4 hover:bg-brandPrimary dark:hover:bg-primary-600 hover:text-white cursor-pointer transition z-50"

const SelectLanguageListbox = ({value, options, callback, buttonTestId}: SelectLanguageListboxProps) => {
  return (<Listbox value={value} onChange={callback}>
    <div className="">
      <Listbox.Button className={codeViewlistboxButtonClasses} data-testid={buttonTestId}>
        <ListboxButton content={pickOption(options, value)} />
      </Listbox.Button>
      <OptionsTransition>
          <Listbox.Options className={codeViewShortListboxOptionsClasses}>
            {
              options.map((option) => {
                return (
                  <Listbox.Option key={option.value || 'none'} className={codeViewlistboxOptionClasses} value={option.value}>
                    <ListboxElement primaryText={option.text}/>
                  </Listbox.Option>
                );
              })
            }
          </Listbox.Options>
      </OptionsTransition>
    </div>
  </Listbox>);
};

const AdditionalOptionsTextArea = ({value, placeholder, callback}:{value: string | number | undefined, placeholder: string, callback: (value: string|undefined) => void}) => {
  return (<div className="w-full">
    <textarea className="w-full border border-lightest shadow placeholder-gray-200 dark:placeholder-neutral-200 text-sm p-2" placeholder={placeholder} value={value} onChange={(ev) => {
      const newValue = ev.target.value;
      if (newValue === "") {
        callback(undefined);
      } else {
        callback(newValue);
      }
    }} />
  </div>);
};

export type ApiPlaygroundLocationState = {
  language: SupportedLanguages;
  scrapingMethod: ScrapingMethod;
  collectorType: CollectorType;
  url: string;
};

export function ApiPlaygroundEditDetails (
  {
    backCallback,
    newApiCallConfig,
  }: {
      backCallback: () => void,
      newApiCallConfig: ApiCallConfig,
    }) {

  const user = useUser();
  const navigate = useNavigate();
  const locationState = useLocation().state as ApiPlaygroundLocationState | undefined;
  const [scrapingMethod, setScrapingMethod] = useLocalStorage<ScrapingMethod>('apiRequestScrapingMethod', newApiCallConfig.scrapingMethod);
  const [apiCallConfig, setApiCallConfig] = useState<ApiCallConfig>({ ...newApiCallConfig, scrapingMethod });
  const [detailsOpen, setDetailsOpen] = useLocalStorage<'up'|'down'>('editProjectShowDetails', 'up');
  const [language, setLanguage] = useLocalStorage<SupportedLanguages>('apiRequestSelectedLanguage', 'python');

  useEffect(() => {
    setLanguage(locationState?.language || language);
    setScrapingMethod(locationState?.scrapingMethod || scrapingMethod);
    setApiCallConfig({
      input: locationState?.url || apiCallConfig.input,
      scrapingMethod: locationState?.scrapingMethod || apiCallConfig.scrapingMethod,
      config: { ...apiCallConfig.config, type: locationState?.collectorType || apiCallConfig.config.type },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ locationState?.language, locationState?.scrapingMethod, locationState?.collectorType ]);

  const [costCalculationProblems, setCostCalculationProblems] = useState<ConfigProblemWithMessage[]>([]);
  const problems = [...costCalculationProblems];

  // const [inputProblemMessage, setInputProblemMessage] = useState<string|undefined>();
  const [cost, setCost] = useState<number|undefined>(undefined);
  const [costLoading, setCostLoading] = useState<boolean>(false);

  const [tryItResult, setTryItResult] = useState<TryItResult | undefined>(undefined);
  const [tryItInProgress, setTryItInProgress] = useState<boolean>(false);


  // TODO: This is too big. Cost calculation should not need that much data
  const apiCallConfigToProjectConfigForCostCalculation = (language: string, apiCallConfig: ApiCallConfig): CostCalculationProject => {
    return {
      language,
      name: '',
      userId: 0,
      scrapingInterval: 'daily',
      cron: "0 1 * * *",
      enabled: false,
      supposedToRunAt: undefined,
      input: { type: 'list_literal', list: apiCallConfig.input},
      config: { type: apiCallConfig.config.type, apiParams: apiCallConfig.config.apiParams },
      output: { type: 'save' },
      demoProject: false,
      notificationConfig: {
        notifyOnSuccess: 'never',
        notifyOnFailure: 'with_every_run',
        notificationChanged: new Date(),
      },
    }
  };

  // Cost calculation --------------------------
  useEffect(() => {
    if (apiCallConfig.input === undefined || apiCallConfig.input.trim().length === 0) {
      setCost(undefined);
      setCostCalculationProblems([]);
      return;
    }
    setCostLoading(true);
    const controller = new AbortController();
    scraperApi.hostedScraping.projectCost(apiCallConfigToProjectConfigForCostCalculation(language, apiCallConfig), 'api_playground', {signal: controller.signal})
      .then((response) => {
        setCost(response.cost);
        if (response.errorMessages && response.errorMessages.length > 0) {
          setCostCalculationProblems([ { problem: ConfigProblem.BackendCostCalculationError, multipleMessages: response.errorMessages } ]);
        } else {
          setCostCalculationProblems([]);
        }
      })
      .catch((err) => {if (!axios.isCancel(err)) {
        console.error(err);
        setCostCalculationProblems([ { problem: ConfigProblem.BackendCostCalculationError, message: 'Network error' } ]);
      }})
      .finally(() => setCostLoading(false));
    return () => { controller.abort(); };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useDebouncer(apiCallConfig.input, 1000), useDebouncer(apiCallConfig.config.apiParams), useDebouncer(language)]); // proj is deliberately not included!

  useEffect( () => {
    if (apiCallConfig.config.type === 'async_urls' && scrapingMethod === 'structured_data_endpoint') {
      setApiCallConfig({...apiCallConfig, config: {...apiCallConfig.config, type: 'async_google_search'}});
    }
    if (apiCallConfig.config.type !== 'async_urls' && scrapingMethod !== 'structured_data_endpoint') {
      setApiCallConfig({...apiCallConfig, config: {...apiCallConfig.config, type: 'async_urls'}});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiCallConfig.config.type, scrapingMethod]);


  if (user === null || user === undefined) {
    return <FullWidthLoadingSpinner/>;
  }

  const showDetailsClicked = () => {
    setDetailsOpen(detailsOpen === 'up' ? 'down' : 'up');
  }

  // https://www.iana.org/assignments/media-types/media-types.xhtml
  const fileNameAndMimeTypeForLanguage = (language: SupportedLanguages): {fileName: string, mimeType: string} => {
    switch (language) {
      case 'curl': return { fileName: 'scrape.sh', mimeType: 'text/x-sh' };
      case 'python': return { fileName: 'scrape.py', mimeType: 'text/x-python' };
      case 'nodejs': return { fileName: 'scrape.js', mimeType: 'text/javascript' };
      case 'php': return { fileName: 'scrape.php', mimeType: 'text/x-php' };
      case 'ruby': return { fileName: 'scrape.rb', mimeType: 'application/x-ruby' };
      case 'java': return { fileName: 'Scrape.java', mimeType: 'text/x-java' };
    }
  }

  const doDownloadCode = () => {
    if (templateContent === undefined) {
      return;
    }
    const {fileName, mimeType} = fileNameAndMimeTypeForLanguage(language);
    const blob = new Blob([templateContent], {
      type: `${mimeType};charset=utf-8`
    });
    trackApiPlaygroundDataCopied('downloadButton', apiCallConfig, cost);
    saveAs(blob, fileName);
  };

  const scheduleAsHostedScraperProject = () => {
    navigate('/projects/new', { state: { apiCallConfig } });
  }

  const tryIt = async() => {
    if (templateContent === undefined) {
      return;
    }
    setTryItInProgress(true);
    // setTimeout(() => {
      // document.getElementById('apiplayground-try-it-progress')?.scrollIntoView({behavior: 'smooth'});
    // }, 100);
    try {
      // TODO: proper signal, that goes away when the user navigates away
      const controller = new AbortController();
      const tryItResult: TryItResult = await scraperApi.hostedScraping.tryIt(apiCallConfig, {signal: controller.signal}) as TryItResult;
      setTryItResult(tryItResult);
      setTimeout(() => {
        document.getElementById('api-playground-status-container')?.scrollIntoView({behavior: 'smooth'});
      }, 100);
    } finally {
      setTryItInProgress(false);
    }
  };

  const removeUnneededValues = (key: unknown ,newValue:  number | string | boolean | undefined, newApiParams: AsyncApiParams): void => {
    const isFalsyvalue = typeof newValue === 'undefined'
    || (typeof newValue === 'boolean' && !newValue)
    || (typeof newValue === 'string' && newValue === '');

    if (key !== 'followRedirect' && isFalsyvalue) {
      delete newApiParams[key as keyof typeof newApiParams];
    } else if (key === 'followRedirect' && newValue === true) {
      delete newApiParams[key as keyof typeof newApiParams];
    }
  }

  const updateApiParams = (key: string) => (newValue: number | string | boolean | undefined, apiParamsToUse?: AsyncApiParams) => {
    const oldApiParams = apiParamsToUse ?? apiCallConfig.config.apiParams;
    const newApiParams = {
      ...oldApiParams,
      [key]: newValue,
    };

    // False should be removed
    removeUnneededValues(key, newValue, newApiParams);

    trackApiPlaygroundParameterChanged(key, newValue);
    setApiCallConfig({...apiCallConfig, config:{ ...apiCallConfig.config, apiParams: newApiParams}});

    return newApiParams;
  }

  const changeConfigType = (cm: CollectorType) => {
    trackApiPlaygroundSdeSelected(cm as string);
    setApiCallConfig({...apiCallConfig, config: {type: cm, apiParams: apiCallConfig.config.apiParams}});
  }

  const changeScrapingMethod = (sm: ScrapingMethod) => {
    setScrapingMethod(sm);
    setApiCallConfig({...apiCallConfig, scrapingMethod: sm});
  }

  const updateInput = (input: string | undefined) => {
    trackApiPlaygroundURLEntered(input);
    setApiCallConfig({...apiCallConfig, input: input ?? '' })
  };

  const updateSelectedLanguage = (lang: string | undefined) => {
    setLanguage(lang as SupportedLanguages);
    trackApiPlaygroundLanguageSelectorClicked(lang);
  };

  const inputSectionLabels = getInputSectionLabelsForAP(apiCallConfig.config.type);

  const templateContent = sampleCode(
    language,
    apiCallConfig.scrapingMethod,
    apiCallConfig.config.type,
    apiCallConfig.input,
    user.apiKey,
    apiCallConfig.config.apiParams ?? {}
  );

  const copyCodeToClipboard = () => {
    if (templateContent === undefined) {
      return;
    }
    trackApiPlaygroundCopyToClipboardClicked();
    trackApiPlaygroundDataCopied('copyButton', apiCallConfig, cost);
    navigator.clipboard.writeText(templateContent)
    .then(() => {
      Toaster.success('Code copied to clipboard');
    })
    .catch((error) => {
      console.error('Error copying to clipboard', error);
      Toaster.error('Code copy failed');
    });
  };

  const copyResultToClipboard = () => {
    const result = tryItResult?.responseText;
    if (result === undefined) {
      return;
    }
    navigator.clipboard.writeText(result)
    .then(() => {
      Toaster.success('Scraping result copied to clipboard');
    })
    .catch((error) => {
      console.error('Error copying to clipboard', error);
      Toaster.error('Code copy failed');
    });
  };

  const inputErrors = validateInput(apiCallConfig.config.type, {type: 'list_literal', list: apiCallConfig.input});
  const areSettingsValid = inputErrors.length === 0;
  const codeViewContent = areSettingsValid ? templateContent : undefined;
  const codeViewPlaceholder = areSettingsValid ? undefined : codeViewPlaceholderMessage(scrapingMethod, apiCallConfig.config.type);

  const rightSidebar =
    <ProjectSummarySidebar
      variant="api-playground"
      details={[]}
      config={apiCallConfig.config}
      cost={cost}
      costInProgress={costLoading}
    />;

  const actionButtons =
    (<div className="flex flex-row flex-wrap justify-between">
      <div>
        <Button text="Cancel" centerAlign className="button button-tertiary" href="/" size="LG" />
      </div>
      <div className="flex flex-row gap-2">
        <Button text="Schedule as a DataPipeline project" className="button button-secondary" onClick={scheduleAsHostedScraperProject} size="LG" />
        <Button text="Download code" className="button button-primary" onClick={doDownloadCode} size="LG" />
      </div>
    </div>);

  return (
    <BorderLayout
      layout="horizontal"
      wrap="xl"
      bottom={
        <div className="px-8 py-5 space-y-5">
          <div className="w-full border-t border-slate-200"/>
          { actionButtons }
        </div>
      }
      right={ <div className="w-full xl:w-80 xl:h-full">{ rightSidebar }</div> }
    >
      <BorderedPage
        title="API Playground"
      >
        <div className="px-4">
          <Alert className="my-3"
                   description="When using the API Playground, it is important to remember that you must run the code by yourself. The code must be copied to your clipboard and the request must be submitted to the server by yourself."/>
          <ProjectDetailsHead variant="api-playground" configType={ apiCallConfig.scrapingMethod === 'structured_data_endpoint' ? apiCallConfig.config.type : 'async_urls' }/>

          <Separator/>

          <SectionTitle number={ 1 } title="Build request"/>
          { (problems.some(p => p.problem === ConfigProblem.InvalidInput))
            ? (<div className="text-red dark:text-error-600">Invalid input</div>)
            : <></>
          }
          <Section>
            <InputComments title={inputSectionLabels.inputSectionTitle} description={inputSectionLabels.inputSectionDescription}/>
            <SectionRightHandSide>
              <div>Select scraping method</div>
              <RadioGroup className="hidden dark:visible" type="button" name='scrapingMethod' value={ apiCallConfig.scrapingMethod } onChange={ changeScrapingMethod }>
                  <RadioGroupElement value="api" label="API"/>
                  <RadioGroupElement value="async" label="Async"/>
                  <RadioGroupElement value="proxy_mode" label="Proxy mode"/>
                  <RadioGroupElement value="structured_data_endpoint" label="Structured Data Endpoints"/>
              </RadioGroup>
              <RadioGroup className="visible dark:hidden" name='scrapingMethod' value={ apiCallConfig.scrapingMethod } onChange={ changeScrapingMethod }>
                  <RadioGroupElement value="api" label="API"/>
                  <RadioGroupElement value="async" label="Async"/>
                  <RadioGroupElement value="proxy_mode" label="Proxy mode"/>
                  <RadioGroupElement value="structured_data_endpoint" label="Structured Data Endpoints"/>
              </RadioGroup>

              {
                scrapingMethod === 'structured_data_endpoint' &&
                (<>
                Structured Data Endpoints
                <Listbox value={apiCallConfig.config.type} onChange={ changeConfigType }>
                  <div className="">
                    <Listbox.Button autoFocus={false} className={listboxButtonClasses} data-testid="sde-listbox">
                      <ListboxButton content={pickOption(sdeCollectorTypes, apiCallConfig.config.type)} />
                    </Listbox.Button>
                    <OptionsTransition>
                        <Listbox.Options className={shortListboxOptionsClassesOverflowing}>
                          {
                            sdeCollectorTypes.map((option) => {
                              const selected = apiCallConfig.config.type === option.value;
                              return (
                                <Listbox.Option key={option.value || 'none'} className={selected ? selectedListboxOptionClasses : listboxOptionClasses} value={option.value}>
                                  <ListboxElement primaryText={option.text} selected={selected}/>
                                </Listbox.Option>
                              );
                            })
                          }
                        </Listbox.Options>
                    </OptionsTransition>
                  </div>
                </Listbox>
                </>)
              }

              <div className="mb-2">{enterInputsMessage(scrapingMethod, apiCallConfig.config.type)}<span className="text-brandPrimary dark:text-primary-600"> (Required)</span></div>
              {
                apiCallConfig.scrapingMethod === 'async'
                  ? <AdditionalOptionsTextArea value={apiCallConfig.input} placeholder={enterInputsPlaceholder(scrapingMethod, apiCallConfig.config.type)} callback={updateInput} />
                  : <AdditionalOptionsTextBox value={apiCallConfig.input} placeholder={enterInputsPlaceholder(scrapingMethod, apiCallConfig.config.type)} callback={updateInput} />
              }
              <ErrorProblemsBox allProblems={problems} interestingProblems={[ConfigProblem.BackendCostCalculationError]} breakAll/>
            </SectionRightHandSide>
          </Section>

          <Separator/>

          <Section>
            <InputComments
              title="Additional options &amp; filters"
              description={ <p>Fine-tune your web scraping with additional options and parameters.</p> }
              showDetailsSwitch={ detailsOpen }
              onDetailsClicked={ showDetailsClicked }
              testId="testAdditionalOptionsAndFilters"/>
            <SectionRightHandSide>
              <div className="">Select advanced options</div>
              { detailsOpen === 'up' && isAmazonProject(apiCallConfig.config.type) &&
                (<AmazonProjectAsyncApiParams collectorConfig={apiCallConfig.config} problems={problems} updateApiParams={updateApiParams} checkSubscription={false}/>)}
              { detailsOpen === 'up' && isGoogleProject(apiCallConfig.config.type) &&
                (<GoogleProjectAsyncApiParams collectorConfig={apiCallConfig.config} problems={problems} updateApiParams={updateApiParams} checkSubscription={false}/>)}
              { detailsOpen === 'up' && isAsyncUrlsProject(apiCallConfig.config.type) &&
                (<UrlProjectAsyncApiParams variant="api-playground" collectorConfig={apiCallConfig.config} problems={problems} updateApiParams={updateApiParams} />)}
              { detailsOpen === 'up' && isWalmartProject(apiCallConfig.config.type) &&
                (<WalmartProjectAsyncApiParams collectorConfig={apiCallConfig.config} problems={problems} updateApiParams={updateApiParams} />)}
              { detailsOpen === 'up' && isEbayProject(apiCallConfig.config.type) &&
                (<EbayProjectAsyncApiParams collectorConfig={apiCallConfig.config} problems={problems} updateApiParams={updateApiParams} />)}
            </SectionRightHandSide>
          </Section>

          <Separator/>

          {/* <SectionTitle number={ 2 } title="Output settings"/>
          <Section>
            <InputComments title="Choose the result format" description="Only applicable when the results are parsed" />
            <SectionRightHandSide className='w-full'>
              {
                (isSDEProject(apiCallConfig.config.type)
                || (isAsyncUrlsProject(apiCallConfig.config.type) && Boolean(apiCallConfig.config.apiParams?.autoparse))
                || (apiCallConfig.scrapingMethod === 'async' && Boolean(apiCallConfig.config.apiParams?.autoparse))
                || (apiCallConfig.scrapingMethod === 'proxy_mode' && Boolean(apiCallConfig.config.apiParams?.autoparse))) &&
                (<>
                  <div className="mt-5">
                    Select format
                  </div>
                  <AdditionalOptionsListbox
                    value={apiCallConfig.config?.apiParams?.outputFormat === 'csv' ? 'csv' : 'json'}
                    options={[
                      { value: 'csv', text: 'CSV' },
                      { value: 'json', text: 'JSON' }
                    ]}
                    callback={updateApiParams('outputFormat')}
                    />
                  </>)
              }
            </SectionRightHandSide>
          </Section> */}

          <SectionTitle number={ 2 } title="Integrate into codebase"/>
          <Section>
            <SectionRightHandSide className='w-full'>
              <ApikeyBox apiKey={ user.apiKey ?? '?' }/>

              <div className="w-full dark:border dark:border-gray-200">
                <div className="flex flex-row justify-between p-2.5 bg-codeViewPurple dark:bg-neutral-50 text-white">
                  <SelectLanguageListbox value={language} options={supportedLanguages} callback={updateSelectedLanguage} />
                  <div className="flex flex-row gap-x-2">
                    <Button text="Try it" icon={ { Icon: PlayIcon } } centerAlign className="button button-codeeditorplay dark:button-primary" onClick={tryIt} size="MD" />
                    <Button icon={ { Icon: CopyIcon } } text="Copy to clipboard" centerAlign className="ml-2 button button-codeeditor dark:button-secondary" onClick={copyCodeToClipboard} size="MD" />
                  </div>
                </div>
                <CodeView content={ codeViewContent } placeholder={ codeViewPlaceholder } language={ language } minHeight="10rem" />
              </div>

              <div id="api-playground-status-container" className="my-5">
                { tryItInProgress &&
                    <div className="flex flex-row items-center gap-2">
                        <div className=""><Spinner className="w-5 h-5 animate-spin text-lightGray dark:text-neutral-500"/></div>
                        <div className="">Scraping in progress</div>
                    </div> }
                { !tryItInProgress && tryItResult &&
                    <TryItResultStatus status={ tryItResult.status } statusText={ tryItResult.statusText }/>
                }
              </div>

              { tryItResult && !tryItInProgress &&
                  <div id="apiplayground-try-it-result-container" className="w-full dark:border dark:border-gray-200">
                      <div className="flex flex-row justify-between p-2.5 bg-codeViewPurple dark:bg-neutral-50 text-white">
                          <div/>
                          <Button icon={ { Icon: CopyIcon } } text="Copy to clipboard" centerAlign className="button button-secondary ml-2" onClick={copyResultToClipboard} size="MD" />
                      </div>
                      <CodeView content={tryToFormatJSON(tryItResult.responseText)} placeholder="" language={isJson(tryItResult.responseText) ? "json" : "html"} minHeight="10rem" />
                  </div>
              }

            </SectionRightHandSide>
          </Section>
        </div>

      </BorderedPage>
    </BorderLayout>
  );

}
