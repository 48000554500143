import { isValidWalmartCategory } from "components/hostedScrapingValidators";
import { SDEContent } from "./sdecontentTypes";

export const WalmartCategoryContent: SDEContent = {
  projectType: 'async_walmart_category',
  projectCategory: 'walmart',
  apiUrlFn: (apiHost: string) => {
    return `${apiHost}/structured/walmart/category`;
  },
  docsSection: 'walmart-category-api',
  docsSectionAsync: 'walmart-category-api-async',
  inputKey: 'category',
  inputsKey: 'categories',
  validatorFunction: isValidWalmartCategory,
  newProjectEditProject: {
    inputBoxLabels: {
      listLiteralInputTitle: 'Category',
      listLiteralInputPlaceholder: 'Enter one category id per line.',
    },
    inputSectionLabels: {
      inputSectionTitle: "Input category IDs",
      inputSectionDescription: "Enter category IDs in the input field or upload CSV with your categories to begin scraping Walmart.",
    },
    projectContent: {
      title: 'Walmart category',
      description: 'Extract product data from Walmart category pages with category IDs such as name, price and ratings.',
      icon: 'walmart',
      inputTitle: 'Category',
    },
  },
  apiPlayground: {
    inputSectionLabels: {
      inputSectionTitle: 'Input category ID',
      inputSectionDescription: 'Enter category IDs to begin scraping Walmart.',
    },
    projectContent: {
      title: 'Walmart category',
      description: 'Extract product data from Walmart category pages with category IDs such as name, price and ratings.',
      icon: 'walmart',
    },
    enterInputsPlaceholder: '1085666_3316357_9663742',
    enterInputsMessage: 'Enter category ID for scraping',
    codeViewPlaceHolder: 'Please provide a valid category id',
    dropdownTitle: 'Walmart Category'
  }
};
