import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";


export function useContactSales() {
  const location = useLocation();
  const navigate = useNavigate();

  const callback = useCallback(() => {
    return navigate("/book-sales-call", { state: { backgroundLocation: location } });
  }, [ navigate, location ]);

  return { contactSalesFn: callback };
}
