import { parseExpression } from 'cron-parser';

// TODO: This has a version in the backend code
export const intoCronExpression = (startDate: Date, schedulingType: 'hourly'|'daily'|'weekly'|'monthly'): string => {
  const minute = startDate.getUTCMinutes();
  const hour = startDate.getUTCHours();
  const day = startDate.getUTCDate();
  const weekDay = startDate.getUTCDay();
  switch (schedulingType) {
    case 'hourly':
      // In this minute
      return `${minute} * * * *`;
    case `daily`:
      // This hour, this minute every day
      return `${minute} ${hour} * * *`;
    case `weekly`:
      // This hour, this minute every week, this weekday
      return `${minute} ${hour} * * ${weekDay}`;
    case `monthly`:
      return `${minute} ${hour} ${day} * *`;
  }
}

export const isValidCron = (cron: string): {valid: boolean, message?: string} => {
  try {
    parseExpression(cron, { utc: true, currentDate: new Date() });
    return { valid: true };
  } catch (e){
    return { valid: false, message: (e as Error).message  };
  }
};

export const calculateNextRuns = (cron: string, startingDate?: Date, count: number = 3): Date[] => {
  try {
    // Calculate next run
    const interval = parseExpression(cron, { utc: true, currentDate: startingDate });

    const result = [];

    while (count--) {
      const date = interval.next().toDate()
      result.push(date);
    }

    return result;
  } catch (err) {
    console.error(err);
    return [];
  }
};
