import { ChangeEvent, useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";
import _ from "lodash";

import scraperApi from "api";

import InputField, { ErrorMsg } from "components/InputField";
import Spinner from "components/Spinner";

import { CouponDetails } from "providers/UserProvider";


interface ICouponCodeInputProps {
  couponDetails?: CouponDetails[];
  targetPlanSlug?: string;
  className?: string;
}

export default function CouponCodeInput(
  {
    couponDetails,
    targetPlanSlug,
    className
  }: ICouponCodeInputProps
) {
  const { setError, clearErrors } = useFormContext();
  const [ validatedCouponName, setValidatedCouponName ] = useState<string | undefined>();
  const [ validationInProgress, setValidationInProgress ] = useState<boolean>(false);

  const checkAlreadyUsedCoupon = useCallback((couponCode: string) => {
    if (couponDetails?.find(c => c.coupon_code === couponCode)) {
      setError("coupon", {
        type: "custom",
        message: "That coupon has already been used."
      });
      return true;
    }

    clearErrors("coupon");
    return false;
  }, [ couponDetails, setError, clearErrors ]);

  const debouncedCouponCheck = _.debounce(
    async (couponCode: string) => {
      if (!checkAlreadyUsedCoupon(couponCode)) {
        if (couponCode) {
          try {
            setValidationInProgress(true);
            const couponDetails = await scraperApi.subscription.checkCoupons([ couponCode ], targetPlanSlug);
            clearErrors("coupon");
            setValidatedCouponName(couponDetails[0].coupon_name);
          } catch (error) {
            const err = error as any;
            setError("coupon", {
              type: "custom",
              message: err.message || "There was an error checking the coupon code"
            });
            setValidatedCouponName(undefined);
          } finally {
            setValidationInProgress(false);
          }
        }
      }
    },
    500
  );

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    debouncedCouponCheck.cancel();
    debouncedCouponCheck(e.target.value);
  }, [ debouncedCouponCheck ]);

  return <div className={ className }>
    <InputField
      label="Coupon Code"
      placeholder="Enter a coupon code"
      type="text"
      name="couponCode"
      onChange={ process.env.REACT_APP_REALTIME_COUPON_VALIDATION === "true" ? onChange : undefined }
      TrailingElm={ validationInProgress ? <Spinner className="w-4 h-4 animate-spin" /> : undefined }
    />
    { validatedCouponName && (
      <div className="text-sm">{ validatedCouponName }</div>
    )}
    <ErrorMsg name="coupon" />
  </div>;
};
