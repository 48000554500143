import { ForwardedRef, forwardRef, InputHTMLAttributes } from "react";

import InputFieldComponents from "components/InputFieldComponents/InputFieldComponents";


export interface IInputFieldWithValidationProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string | JSX.Element;
  labelPosition?: "top" | "bottom" | "left" | "right";
  TrailingElement?: JSX.Element;
  inputPaddingForTrailingElement?: string;
  isValidated?: boolean;
  requiredErrorMessage?: string;
  fullWidth?: boolean;
  typeMismatchErrorMessage?: string;
  tooLongErrorMessage?: string;
  tooShortErrorMessage?: string;
  patternMismatchErrorMessage?: string;
  formatValue?: (unformattedValue: string | ReadonlyArray<string> | number | undefined, cursorPosition?: number | null) => [ string, number | null ];
  unformatValue?: (value: string, cursorPosition: number | null) => [ string | undefined, number | null ];
  unformattedInputRef?: React.RefObject<HTMLInputElement>;
  errorMessage?: string;
  successMessage?: string;
  relative?: boolean;
  onFocusLost?: (event: any, setTrackedValue: (value: string | number | string[]) => void) => void;
}


const InputFieldWithValidation = forwardRef((props: IInputFieldWithValidationProps, ref: ForwardedRef<HTMLInputElement>) => {
  // TODO this now looks like an unnecessary wrapper...
  return (
    <InputFieldComponents ref={ ref } { ...props } />
  );
});

export default InputFieldWithValidation;
