import _ from "lodash";

import { BillingPeriodUnit, Plan, SubscriptionType } from "providers/UserProvider";
import { Coupon, CouponError } from "types/Coupons";


export const allPlans = [
  'business',
  'enterprise',
  'free',
  'enterprise-6-months',
  'enterprise-12-months',
  'hobby_2',
  'business_2',
  'professional_1',
  'startup',
  'startup_2',
  'student_npo',
  'hobby',
  'hobby_1',
  'custom',
  'extended_trial',
  'annual_professional_1',
  'annual_business_2',
  'annual_startup_2',
  'annual_hobby_2'
] as const;

export type AvailablePlan = typeof allPlans[number];

export const canUsePremium = (planSlug: string | undefined) => {
  return planSlug !== "free" && planSlug !== undefined;
}

export const canUseUltraPremium = (planSlug: string | undefined) => {
  return planSlug !== "free" && planSlug !== undefined;
}

export const canUseAdvancedGeotargeting = (subscription: SubscriptionType | undefined) => {
  if (!subscription) {
    return false;
  }
  const { plan_id: planSlug } = subscription;
  if (isUsingChargebeeCustomFields(planSlug)) {
    return subscription.cf_api_geotargeting === "Disabled" ? false : true;
  } else {
    return planSlug !== undefined && (planSlug.startsWith("business")
      ||  planSlug.startsWith("professional")
      ||  planSlug.startsWith("enterprise")
      ||  planSlug.startsWith("annual_business")
      ||  planSlug.startsWith("annual_professional")
      ||  planSlug.startsWith("annual_enterprise"));
  }
}

export const isEnterprise = (planSlug: string | undefined) => {
  return planSlug?.startsWith("enterprise");
};

export const manualRenewToThisIfScheduled = (planSlug: string | undefined) => {
  return planSlug && (planSlug.startsWith("enterprise") || [ "custom", "extended_trial" ].includes(planSlug));
};

export const isUsingChargebeeCustomFields = (planSlug: string | undefined) => {
  return planSlug && (planSlug.startsWith("enterprise") || [ "custom", "extended_trial" ].includes(planSlug));
};

export const hasToContactUsBeforeChange = (planSlug: string | undefined) => {
  return planSlug && (planSlug.startsWith("enterprise") || [ "custom", "extended_trial" ].includes(planSlug));
};

export const isAnnualPlan = (planSlug: string | undefined) => {
  return planSlug?.startsWith("annual_");
};

export const getPriceMultiplier = (period1: number, periodUnit1: BillingPeriodUnit, period2: number, periodUnit2: BillingPeriodUnit) => {
  const periodMultipliers = {
    month: 1,
    year: 12
  };

  return (periodMultipliers[periodUnit2] * period2) / (periodMultipliers[periodUnit1] * period1);
};

export const applyCoupon = (plan: Plan, coupon?: Coupon | CouponError) => {
  if (!coupon || _.has(coupon, "error")) {
    plan.discount_price = undefined;
  } else {
    const couponType = coupon as Coupon;
    if (couponType.discount_type === "percentage") {
      plan.discount_price = ((plan.price || 0) * (100 - (couponType.discount_percentage || 0))) / 100;
    } else if (couponType.discount_type === "fixed_amount") {
      plan.discount_price = Math.max((plan.price || 0) - (couponType.discount_amount || 0), 0);
      // TODO apply fix amount discount
    } else {
      // unsupported discount type, or empty coupon code
      plan.discount_price = undefined;
    }
  }

  return plan;
};
