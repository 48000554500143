import React, { useEffect, useState } from "react";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  ListboxProps
} from "@headlessui/react";

import { RiArrowDownSLine } from "@remixicon/react";

import { Sizes } from "styles/sizes";

import { cx } from "utils";


type SelectSizes = Sizes<{ button: string, icon: string, option: string }>;

const selectSizes: SelectSizes = {
  SM: {
    button: "h-8 px-2 gap-x-2 text-sm",
    icon: "w-4 h-4",
    option: "py-[5px] px-3 text-base"
  },
  MD: {
    button: "h-10 px-10 text-base",
    icon: "",
    option: "py-[5px] px-3 text-base"
  },
  LG: {
    button: "h-12 px-12 text-base",
    icon: "",
    option: "py-[5px] px-3 text-base"
  }
};

export type SelectOption<V> = {
  name: string;
  value: V;
};

interface ISelectProps<T> {
  value: T;
  size?: keyof SelectSizes;
  Icon?: React.ComponentType<any>;
  options: T[];
  className?: string;
  optionsClassName?: string;
  buttonClassName?: string;
  onChange?: (value: T) => void;
}

export default function Select<T extends SelectOption<any> | string>(
  {
    value,
    name,
    options,
    size = "MD",
    className = "w-[min-content]",
    optionsClassName = "h-52",
    buttonClassName = "w-full",
    Icon,
    onChange,
  }: ISelectProps<T> & Pick<ListboxProps, "name">
) {
  const nameAccessor = (option: T | string) => typeof option === "string" ? option as string : (option as SelectOption<any>).name;

  const [ selectedValue, setSelectedValue ] = useState(value);
  const selectSizeStyles = selectSizes[size];

  useEffect(() => {
    onChange?.(selectedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ selectedValue ]);  // onChange is left out on purpose, otherwise the effect will be called in an infinite loop


  return (
    <div className={ cx("relative", className) }>
      <Listbox
        name={ name }
        value={ selectedValue }
        onChange={ setSelectedValue }
        by={ (o1: T, o2: T) => nameAccessor(o1) === nameAccessor(o2) }
      >
        <ListboxButton className={ buttonClassName }>
          { ({ value }) =>
            <div className={ cx(
              "select select-button flex items-center justify-between",
              selectSizeStyles?.button
            ) }
            >
              <div className="flex flex-row gap-x-2 items-center">
                { Icon && <Icon className={ cx("select-icon", selectSizeStyles?.icon) }/> }
                { nameAccessor(value) }
              </div>
              <RiArrowDownSLine className="w-4 h-4 justify-self-end"/>
            </div>
          }
        </ListboxButton>
        <ListboxOptions className={ cx("absolute border dark:rounded w-full bg-white drop-shadow mt-1 z-10 overflow-auto", optionsClassName) } portal={ false }>
          { options.map((option) =>
            <ListboxOption
              key={ nameAccessor(option) }
              value={ option }
              className={ cx(
                selectSizeStyles?.option,
                "data-[focus]:bg-primary-100 data-[selected]:font-medium data-[selected]:bg-primary-200"
              ) }
            >
              { nameAccessor(option) }
            </ListboxOption>
          ) }
        </ListboxOptions>
      </Listbox>
    </div>
  );
};
