import { useCallback, useEffect, useState } from "react";

import { cx } from "utils";

import { SupportWidget } from "hooks/useSupportWidget";

import CloseButton from "components/CloseButton";

import { ServiceScript, useServiceScript } from "providers/ServicesProvider/ServiceScriptProvider";


const USE_ZOHO_WIDGET = process.env.REACT_APP_SUPPORT_WIDGET_USE === "zoho";
const CLOSE_BUTTON_POSITION = process.env.REACT_APP_ZOHO_WIDGET_CLOSE_BUTTON_POSITION || "right";


let showSupportWidget: (email?: string) => void;
let hideSupportWidget: () => void;
let showSalesWidget: (email?: string) => void;
let hideSalesWidget: () => void;


function WidgetCloseButton(
  {
    overlay,
    closeCallback
  } : {
    overlay: boolean;
    closeCallback: () => void;
  }
) {
  return (
    <div className={
      cx("self-start pt-[21px] pr-[17px]",
        overlay && "h-[60px] bg-[#3f51b5]",
        CLOSE_BUTTON_POSITION === "left" && "absolute right-[45px]"
      )}
    >
        <CloseButton
          onPress={closeCallback}
          inverse
        />
    </div>
  );
}

function ZohoWidget(
  {
    widgetUrl,
    widgetWidth,
    closeCallback,
    visible
  }: {
    widgetUrl: string;
    widgetWidth: string;
    closeCallback: () => void;
    visible: boolean;
  }) {

  const [ showPlaceholder, setShowPlaceholder ] = useState(true);

  useEffect(() => {
    setShowPlaceholder(visible);
  }, [ visible ]);

  if (visible) {
    return (
      <div className={cx("bg-white border-2 absolute h-[700px] bottom-[20px] right-[30px] z-10 flex", widgetWidth)}>
        <iframe
          title="ZohoDesk Guided Conversation Widget"
          className="w-full h-full"
          src={widgetUrl}
          onLoad={() => setShowPlaceholder(false) }
        />
        { showPlaceholder && (
          <div className="absolute h-[60px] bg-[#3f51b5] w-full flex justify-end">
            <WidgetCloseButton overlay={false} closeCallback={closeCallback} />
          </div>
        )}
        { !showPlaceholder && (
          <WidgetCloseButton overlay={true} closeCallback={closeCallback} />
        )}
      </div>
    );
  } else {
    return <></>;
  }
}

export default function ZohoProvider() {

  const [ visibleWidget, setVisibleWidget ] = useState<"support" | "sales" | "none">("none");

  showSupportWidget = useCallback((email?: string) => {
    setVisibleWidget("support");
  }, [ setVisibleWidget ]);

  hideSupportWidget = useCallback(() => {
    setVisibleWidget("none");
  }, [ setVisibleWidget ]);

  showSalesWidget = useCallback((email?: string) => {
    setVisibleWidget("sales");
  }, [ setVisibleWidget ]);

  hideSalesWidget = useCallback(() => {
    setVisibleWidget("none");
  }, [ setVisibleWidget ]);

  const widgetWidth = CLOSE_BUTTON_POSITION === "left" ? "w-[400px]" : "w-[417px]";

  if (USE_ZOHO_WIDGET) {
    return (
      <>
        {process.env.REACT_APP_ZOHO_GC_SUPPORT_URL && (
          <ZohoWidget
            widgetUrl={process.env.REACT_APP_ZOHO_GC_SUPPORT_URL}
            widgetWidth={widgetWidth}
            closeCallback={hideSupportWidget}
            visible={visibleWidget === "support"}
          />
        )}

        {process.env.REACT_APP_ZOHO_GC_SALES_URL && (
          <ZohoWidget
            widgetUrl={process.env.REACT_APP_ZOHO_GC_SALES_URL}
            widgetWidth={widgetWidth}
            closeCallback={hideSalesWidget}
            visible={visibleWidget === "sales"}
          />
        )}
      </>
    );
  } else {
    return <></>;
  }

};


export function useZoho(): SupportWidget {

  return {
    showSupportForm: showSupportWidget,
    hideSupportForm: hideSupportWidget,
    showSalesForm: showSalesWidget,
    hideSalesForm: hideSalesWidget
  };

}

const ZOHO_SERVICE_SCRIPT: ServiceScript = {
  name: "Zoho Guided Conversations Embedded Support Script",
  src: process.env.REACT_APP_ZOHO_GC_SUPPORT_SCRIPT!,
  domId: "zoho-support-widget-embedded",
};

const ZOHO_CONTAINER_ID = "zohogc-helper-main";

export function useZohoGCScript(): SupportWidget {

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (typeof window.ZohoGCSettings === "undefined") {
        window.ZohoGCSettings = {
          isOpenDefault: true,
          needClose: true,
          removeMinimize: false
        }
      }
    }
  });

  const { loadServiceScript, unloadServiceScript } = useServiceScript(ZOHO_SERVICE_SCRIPT);

  const reloadZohoScript = useCallback(() => {
    const existingWidget = document.getElementById(ZOHO_CONTAINER_ID);
    if (existingWidget && existingWidget.children.length === 0) {
      // widget has been closed before, have to unload the script fist
      unloadServiceScript();
      existingWidget.remove();
    }

    loadServiceScript();
  }, [ loadServiceScript, unloadServiceScript ]);

  const hideZohoWidget = useCallback(() => {
    const existingWidget = document.getElementById(ZOHO_CONTAINER_ID);
    existingWidget?.remove();
    unloadServiceScript();
  }, [ unloadServiceScript ]);


  return {
    showSupportForm: reloadZohoScript,
    hideSupportForm: hideZohoWidget,
    showSalesForm: () => {},
    hideSalesForm: () => {},
  };

}
