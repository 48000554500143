import { createContext, ReactNode, useCallback, useContext } from "react";
import { useRevalidator } from "react-router-dom";

import scraperApi from "api";

import { ChargebeePortalSection, useChargebee } from "providers/ChargebeeProvider";
import { RequiredUserContextType, useUserProvider } from "providers/UserProvider";


export type ChargebeePortalContextType = {
  openChargebeePortal: (sectionType: ChargebeePortalSection, options?: any) => void;
  closeAllChargebeePortals: () => void;
  generatePayNowLink: () => Promise<string>;
};

let ChargebeePortalContext = createContext<ChargebeePortalContextType>(null!);

export default function ChargebeePortalProvider({ children }: { children: ReactNode }) {

  const revalidator = useRevalidator();
  const { subscription} = useUserProvider() as RequiredUserContextType;
  const { chargebeeInstance, chargebeePortalSections } = useChargebee();

  const openChargebeePortal = useCallback(
    (sectionType: ChargebeePortalSection, options?: any) => {
      // init the portal session with token and secret stuff from API
      chargebeeInstance.setPortalSession(
        scraperApi.subscription.billingSessions
      );

      chargebeeInstance
        .createChargebeePortal()
        .openSection(
          { params: { subscriptionId: subscription?.id }, sectionType: chargebeePortalSections[sectionType] },
          {
            close: revalidator.revalidate,  // TODO <-- this is too much here, we should not reload everything every time a portal dialog is closed
            ...options
          }
        );
    },
    [ chargebeeInstance, chargebeePortalSections, revalidator, subscription?.id ]
  );

  const closeAllChargebeePortals = useCallback(() => {
    chargebeeInstance.closeAll();
  }, [ chargebeeInstance ]);

  const generatePayNowLink = useCallback(
    async () => {
      return (await scraperApi.subscription.payNowLink())?.url;
    },
    []
  );

  let value: ChargebeePortalContextType = {
    openChargebeePortal,
    closeAllChargebeePortals,
    generatePayNowLink,
  };

  return (
    <ChargebeePortalContext.Provider value={value}>
      {children}
    </ChargebeePortalContext.Provider>
  );
};

export function useChargebeePortals() {
  return useContext(ChargebeePortalContext);
}
