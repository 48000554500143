import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState
} from "react";

export interface OnboardingTooltipType {
  register: (step: string) => void;
  unregister: (step: string) => void;
  availableSteps: string[];
  activeStep: string | null;
  isTourStarted: boolean;
  setIsTourStarted: (value: boolean) => void;
  skipAllAvailableSteps: () => void;
  showNextAvailableStep: () => void;
  completeStep: (step: string) => void;
}

let OnboardingTooltip = createContext<OnboardingTooltipType>(null!);
export default function OnboardTooltipProvider({
  children
}: {
  children: ReactNode;
}) {
  const [availableSteps, setAvailableSteps] = useState<string[]>([]);
  const [isTourStarted, setIsTourStarted] = useState(false);

  const register = useCallback((step: string) => {
    if (localStorage.getItem(step)) return;
    setAvailableSteps(prev => [...prev, step]);
  }, []);

  const unregister = useCallback((step: string) => {
    setAvailableSteps(prev => prev.filter(s => s !== step));
  }, []);

  const completeStep = useCallback((step: string) => {
    setAvailableSteps(prev => prev.filter(s => s !== step));
    localStorage.setItem(step, "true");
  }, []);

  const skipAllAvailableSteps = useCallback(() => {
    availableSteps.forEach(step => localStorage.setItem(step, "true"));
    setAvailableSteps([]);
  }, [availableSteps]);

  const showNextAvailableStep = useCallback(() => {
    localStorage.setItem(availableSteps[0], "true");
    setAvailableSteps(prev => {
      return prev.filter((_, index) => index !== 0);
    });
  }, [availableSteps]);

  let value = {
    register,
    unregister,
    availableSteps,
    activeStep: isTourStarted ? availableSteps[0] : null,
    setIsTourStarted,
    isTourStarted,
    skipAllAvailableSteps,
    showNextAvailableStep,
    completeStep
  };

  return (
    <OnboardingTooltip.Provider value={value}>
      {children}
    </OnboardingTooltip.Provider>
  );
}

export function useOnboardingTooltip() {
  return useContext(OnboardingTooltip);
}
