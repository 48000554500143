import { ConfigProblem, ConfigProblemWithMessage } from "components/hostedScrapingValidators";
import { NewProjectConfig, ProjectConfig } from "providers/HostedScrapingProvider";
import { ListLiteralInputConfig, UploadCsvListConfig, UploadJsonListConfig, WebhookInputConfig } from "providers/HostedScrapingProvider/types";
import { UploadFile, DownloadFile } from 'components/hosted-scraping/UploadFileDownloadFile';
import { AdditionalOptionsTextBox } from "./AdditionalOptionsTextBox";
import { ErrorProblemsBox } from "./ErrorProblemsBox";
import { getInputBoxLabelsHS } from "sdecontent";

const uploadErrorToProblems = (err: Error): ConfigProblemWithMessage[] => {
  return [{problem: ConfigProblem.InvalidUploadedFile, message: err.message }];
}

const ListLiteral = ({content, title, placeholder, problems, onChange}: {
    content: string,
    title: string,
    placeholder: string,
    problems: ConfigProblemWithMessage[],
    onChange: (newValue: string) => void }
  ) => {
  const hasProblem = (problem: ConfigProblem) => problems.some(p => p.problem === problem);

  return (
  <div>
      <div>{title}</div>
      <textarea className="w-full shadow border-gray-200 dark:border-neutral-200 placeholder-gray-200 dark:placeholder-neutral-200" defaultValue={content} placeholder={placeholder} onChange={(ev) => onChange(ev.target.value)}/>
      {hasProblem(ConfigProblem.EmptyLiteralList) && <div className="text-red-500 dark:text-error-500">Empty list. Please add or upload data.</div>}
      {hasProblem(ConfigProblem.InvalidLiteralList) && <div className="text-red-500 dark:text-error-500">Invalid elements in the list</div>}
      {hasProblem(ConfigProblem.DuplicatesInListLiteral) && <div className="text-red-500 dark:text-error-500">Duplicates in the list</div>}
      <ErrorProblemsBox allProblems={problems} interestingProblems={[ConfigProblem.EmptyLiteralList, ConfigProblem.InvalidLiteralList, ConfigProblem.DuplicatesInListLiteral, ConfigProblem.BackendCostCalculationError]}/>
  </div>);
}

const WebhookInput = ({url, title, placeholder, problems, onChange}: {
    url: string,
    title: string,
    placeholder: string,
    problems: ConfigProblemWithMessage[],
    onChange: (newUrl: string | undefined) => void }
  ) => {
  const hasProblem = (problem: ConfigProblem) => problems.some(p => p.problem === problem);

  return (
  <div>
      <div>{title}</div>
      <AdditionalOptionsTextBox value={url} placeholder={placeholder} callback={(value) => onChange(value)}/>
      {hasProblem(ConfigProblem.InvalidWebhookURL) && <div className="text-red-500 dark:text-error-500">Invalid webhook URL</div>}
      <ErrorProblemsBox allProblems={problems} interestingProblems={[ConfigProblem.InvalidWebhookURL, ConfigProblem.BackendCostCalculationError]}/>
  </div>);
}

type IInputSettingsProps = {
  proj: NewProjectConfig | ProjectConfig,
  problems: ConfigProblemWithMessage[],
  setProj: (newProject: NewProjectConfig | ProjectConfig) => void,
  fileUploaded: (project: NewProjectConfig | ProjectConfig) => void,
  setUploadFileProblems: (problems: ConfigProblemWithMessage[]) => void,
};

export const InputSettings = ({
  proj,
  problems,
  setProj,
  fileUploaded,
  setUploadFileProblems
}: IInputSettingsProps) => {
  const inputBoxLabels = getInputBoxLabelsHS(proj.config.type);

  const fileInput: UploadJsonListConfig | UploadCsvListConfig = proj.input as UploadJsonListConfig | UploadCsvListConfig;

  const hasProblem = (problem: ConfigProblem) => problems.some(p => p.problem === problem);
  const problemMessage = (problem: ConfigProblem) => problems.find(p => p.problem === problem)?.message;

  const handleFileUpload = (fileName: string, inputKey: string) => {

    const input = {...fileInput};
    input.fileName = fileName;
    input.inputKey = inputKey;

    const changedProject = {...proj, input}
    setProj(changedProject);
    setUploadFileProblems([]);
    fileUploaded(changedProject);
  }

  const handleUploadFileError = (err: Error) => {
    const problems = uploadErrorToProblems(err);
    setUploadFileProblems(problems);
  }

  const handleFileRemove = () => {
    setProj({...proj, input: { ...fileInput, inputKey: null} });
  }

  const changeListLiteral = (newValue: string) => {
    setProj({...proj, input: {...proj.input as ListLiteralInputConfig, list: newValue}});
  }

  const changeWebhookInputUrl = (newValue: string | undefined) => {
    setProj({...proj, input: {...proj.input as WebhookInputConfig, url: newValue ?? ''}});
  }

  return proj.input.type === 'list_literal'
    ? <ListLiteral
      title={inputBoxLabels.listLiteralInputTitle}
      placeholder={inputBoxLabels.listLiteralInputPlaceholder}
      content={(proj.input as ListLiteralInputConfig)?.list ?? ''}
      problems={problems}
      onChange={changeListLiteral}
      />
    : proj.input.type  === 'webhook_input'
    ? <WebhookInput
      title="Webhook URL"
      placeholder="The URL where the input list will be fetched from"
      url={(proj.input as WebhookInputConfig)?.url ?? ''}
      problems={problems}
      onChange={changeWebhookInputUrl}
    />
    : proj.input.type  === 'upload_json_list' && !fileInput.inputKey
    ? <>
        <UploadFile inputType={'upload_json_list'} fileUploadDone={handleFileUpload} uploadError={handleUploadFileError} projectType={ proj.config.type } />
        {hasProblem(ConfigProblem.MissingUploadedFile) && <div className="text-red-500 dark:text-error-500">Missing file</div>}
        {hasProblem(ConfigProblem.InvalidUploadedFile) && <div className="text-red-500 dark:text-error-500">{`Invalid file: ${problemMessage(ConfigProblem.InvalidUploadedFile)}`}</div>}
      </>
    : proj.input.type  === 'upload_csv_list' && !fileInput.inputKey
    ? <>
        <UploadFile inputType={'upload_csv_list'} fileUploadDone={handleFileUpload} uploadError={handleUploadFileError} projectType={ proj.config.type } />
        {hasProblem(ConfigProblem.MissingUploadedFile) && <div className="text-red-500 dark:text-error-500">Missing file</div>}
        {hasProblem(ConfigProblem.InvalidUploadedFile) && <div className="text-red-500 dark:text-error-500">{`Invalid file: ${problemMessage(ConfigProblem.InvalidUploadedFile)}`}</div>}
      </>
    : proj.input.type  === 'upload_json_list' && fileInput.inputKey
    ? <DownloadFile inputKey={fileInput.inputKey} inputType={'upload_json_list'} fileName={fileInput.fileName} fileRemovedCallback={handleFileRemove} />
    : proj.input.type  === 'upload_csv_list' && fileInput.inputKey
    ? <DownloadFile inputKey={fileInput.inputKey} inputType={'upload_csv_list'} fileName={fileInput.fileName} fileRemovedCallback={handleFileRemove} />
    : null
}
