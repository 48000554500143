import Spinner from "components/Spinner";
import { CollectorConfig } from "providers/HostedScrapingProvider/types";
import { followRedirectToDisableFollowRedirect } from "providers/HostedScrapingProvider/negateFollowRedirects";

type ISummaryRowProps = {
  title: string;
  value: string;
};

export const Separator = () => (
  <div className="w-full border-t border-slate-200 mt-4 mb-4" />
)

export const SummaryRow = ({title, value}: ISummaryRowProps) => {
  return (<div className="flex flex-row flew-wrap">
    <div className="font-semibold pr-2 whitespace-nowrap">{title}</div>
    <div className="overflow-hidden text-ellipsis">{value}</div>
  </div>);
}

export const textsHS = {
  costRow: {
    costPerJob: 'Cost per job:',
  },
  content: (creditsLeft: number, runsLeft: string | number, pluralS: 's'|'') => `You have ${creditsLeft} credits left in this billing period. With those remaining credits you would be able to run this project ${runsLeft} time${pluralS}.`
}

export const textsAP = {
  costRow: {
    costPerJob: 'Cost per request:',
  },
  content: (creditsLeft: number, runsLeft: string | number, pluralS: 's'|'') => `You have ${creditsLeft} credits left in this billing period. With those remaining credits you would be able to make ${runsLeft} request${pluralS} this month.`
}

type SideBarTexts = typeof textsHS;

export const CostRow = ({cost, costInProgress, texts}: {cost: number | undefined, costInProgress: boolean, texts: SideBarTexts}) => {
  const pluralS = cost === 1 ? '' : 's';
  return (<div className="flex flex-row flex-wrap items-center justify-between h-10 ">
    <div className="font-semibold pr-2">{texts.costRow.costPerJob}</div>
    {costInProgress ? <Spinner className="w-6 h-6 text-lightGray dark:text-neutral-500 animate-spin" />
      : <div><span data-testid="testEstimatedCost" className="text-semibold text-4xl">{cost ?? '-'}</span> <span className="text-sm">credit{pluralS}</span></div>
    }
  </div>);
};

export const UrlProjectDetails = ({collectorConfig}: {collectorConfig: CollectorConfig}) => {
  return (<>
    {collectorConfig.apiParams?.premium && <SummaryRow title="Premium Ips" value="On" />}
    {collectorConfig.apiParams?.ultraPremium && <SummaryRow title="Ultra premium" value="On" />}
    {followRedirectToDisableFollowRedirect(collectorConfig.apiParams?.followRedirect as boolean | undefined) && <SummaryRow title="Disable follow redirects" value="On" />}
    {collectorConfig.apiParams?.render && <SummaryRow title="JS Rendering" value="On" />}
    {collectorConfig.apiParams?.waitForSelector && <SummaryRow title="Wait for selector" value={collectorConfig.apiParams?.waitForSelector as string} />}
    {collectorConfig.apiParams?.retry404 && <SummaryRow title="Retry 404" value="On" />}
    {collectorConfig.apiParams?.autoparse  && <SummaryRow title="Auto parse"  value="On" />}
    {collectorConfig.apiParams?.countryCode && <SummaryRow title="Country Code" value={collectorConfig.apiParams?.countryCode as string} />}
    {collectorConfig.apiParams?.deviceType && <SummaryRow title="Device Type" value={collectorConfig.apiParams?.deviceType as string} />}
    {collectorConfig.apiParams?.sessionNumber && <SummaryRow title="Session number" value={String(collectorConfig.apiParams?.sessionNumber)} />}
    {collectorConfig.apiParams?.maxCost && <SummaryRow title="Max Cost" value={String(collectorConfig.apiParams?.maxCost)} />}
    {collectorConfig.apiParams?.binaryTarget && <SummaryRow title="Binary target" value="On" />}
    {collectorConfig.apiParams?.keepHeaders && <SummaryRow title="Keep headers" value="On" />}
  </>);
}

export const AmazonProjectDetails = ({collectorConfig}: {collectorConfig: CollectorConfig}) => {
  return (<>
    {collectorConfig.apiParams?.countryCode && <SummaryRow title="Country Code" value={collectorConfig?.apiParams?.countryCode as string} />}
    {collectorConfig.apiParams?.tld && <SummaryRow title="Domain" value={collectorConfig?.apiParams?.tld as string} />}
  </>);
}

export const GoogleProjectDetails = ({collectorConfig}: {collectorConfig: CollectorConfig}) => {
  return (<>
    {collectorConfig.apiParams?.countryCode && <SummaryRow title="Country Code" value={collectorConfig?.apiParams?.countryCode as string} />}
    {collectorConfig.apiParams?.tld && <SummaryRow title="Domain" value={collectorConfig?.apiParams?.tld as string} />}
    {collectorConfig.apiParams?.uule && <SummaryRow title="UULE" value={collectorConfig?.apiParams?.uule as string} />}
    {collectorConfig.apiParams?.num && <SummaryRow title="Nr. of results" value={String(collectorConfig?.apiParams?.num)} />}
    {collectorConfig.apiParams?.additionalParams && <SummaryRow title="Additional parameters" value="On" />}
  </>);
}

export const WalmartProjectDetails = ({collectorConfig}: {collectorConfig: CollectorConfig}) => {
  return (<>
    {collectorConfig.apiParams?.page && <SummaryRow title="Page" value={String(collectorConfig?.apiParams?.page)} />}
    {collectorConfig.apiParams?.tld && <SummaryRow title="Domain" value={collectorConfig?.apiParams?.tld as string} />}
    {collectorConfig.apiParams?.sort && <SummaryRow title="Sort by" value={collectorConfig?.apiParams?.sort as string} />}
  </>);
}

export const EbayProjectDetails = ({collectorConfig}: {collectorConfig: CollectorConfig}) => {
  return (<>
    {collectorConfig.apiParams?.tld && <SummaryRow title="Domain" value={collectorConfig?.apiParams?.tld as string} />}
    {collectorConfig.apiParams?.countryCode && <SummaryRow title="Country code" value={collectorConfig?.apiParams?.countryCode as string} />}
    {collectorConfig.apiParams?.page && <SummaryRow  title="Page" value={collectorConfig.apiParams?.page as string} />}
    {collectorConfig.apiParams?.items_per_page && <SummaryRow  title="Items per page" value={collectorConfig.apiParams?.items_per_page as string} />}
    {collectorConfig.apiParams?.seller_id && <SummaryRow  title="Seller ID" value={collectorConfig.apiParams?.seller_id as string} />}
    {collectorConfig.apiParams?.condition && <SummaryRow  title="Condition" value={collectorConfig.apiParams?.condition as string} />}
    {collectorConfig.apiParams?.buying_format && <SummaryRow  title="Buying format" value={collectorConfig.apiParams?.buying_format as string} />}
    {collectorConfig.apiParams?.show_only && <SummaryRow  title="Show only" value={collectorConfig.apiParams?.show_only as string} />}
    {collectorConfig.apiParams?.sort_by && <SummaryRow  title="Sort by" value={collectorConfig.apiParams?.sort_by as string} />}
  </>);
}