import { NavigateOptions, To, useLocation, useNavigate } from "react-router-dom";


export function useStatefulNavigate() {
  const navigate = useNavigate();
  const location = useLocation();

  return (...args: any[]) => {
    if (typeof args[0] === "string") {
      const [ to, options ] = args as [ To, NavigateOptions? ];
      navigate(to, { state: location?.state, ...options });
    } else {
      const [ delta ] = args as [ number ];
      navigate(delta);
    }
  };
}
