import { ForwardedRef, forwardRef, Fragment, ReactNode, useCallback, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";

import { cx } from "utils";

import { StatefulComponentThemeProps, Theme, Themes } from "styles/themes";

import { useContactSales } from "hooks/useContactSales";

import { useOnboardingTooltip } from "providers/OnboardingTooltipProvider";
import { BillingPeriodUnit } from "providers/UserProvider";

import Button from "components/Button";
import { useFeatureSwitch } from "components/FeatureSwitch";
import PriceTag from "components/PriceTag";
import Spinner from "components/Spinner";
import { PureTooltip } from "components/Tooltip";

import PlanFeatures from "./planFeatures";
import { PlanActions } from "./PlanActions";


export interface PricingPlanProps {
  name: string;
  price: number | undefined;
  originalPrice?: number;
  period?: number;
  periodUnit?: BillingPeriodUnit;
  isAnnual?: boolean;
  customPlanOverride?: CustomPlanOverrideType;
  planSlug: string;
  theme: Theme;
  bannerLabel?: string;
  buttonAction: PlanActions;
  buttonClassName?: string;
  buttonDisabled?: boolean;
  buttonTooltip?: JSX.Element | ReactNode | string;
  buttonTooltipClickable?: boolean;

  planAutoRenewal: number | undefined;

  planIdx: number;
  planInActionIdx: number | undefined;
  setPlanInActionIdx: (planIdx: number) => void;
}

interface PricingPlanThemeType extends StatefulComponentThemeProps {
  bannerFill: string;
  bannerTextColor: string;
  borderColor: string;
}

const PricingPlanThemes: Themes<PricingPlanThemeType> = {
  // warning: {
  //   bannerFill: "bg-warning-600",
  //   bannerTextColor: "text-white",
  //   borderColor: "border-warning-600"
  // },
  primary: {
    default: {
      base: "",
      disabled: "bg-lightGray dark:bg-neutral-500 text-black border-borderColor dark:border-neutral-200",
      bannerFill: "bg-brandPrimary dark:bg-primary-600",
      bannerTextColor: "text-white",
      borderColor: "border-brandPrimary dark:border-primary-600"
    },
    destructive: {
      base: "",
      bannerFill: "bg-red-600 dark:bg-error-600",
      bannerTextColor: "text-white",
      borderColor: "border-red-600 dark:border-error-600"
    },
  },
  secondary: {
    default: {
      base: "",
      bannerFill: "bg-lightGray dark:bg-neutral-500",
      bannerTextColor: "text-white",
      borderColor: "border-borderColor dark:border-neutral-200"
    },
  },
  // disabled: {
  //   default: {
  //     base: "",
  //     bannerFill: "bg-neutral-500",
  //     bannerTextColor: "text-black",
  //     borderColor: "border-neutral-200"
  //   }
  // }
};

export type CustomPlanOverrideType = {
  cf_api_auto_parsers?: string;
  cf_api_concurrency_limit?: number;
  cf_api_geotargeting?: string;
  cf_api_overage?: string;
  cf_api_premium_proxies?: string;
  cf_api_rendering?: string;
  cf_api_request_limit?: number;
};


function ComponentWithTooltip(
  {
    tooltipContent,
    tooltipClickable,
    className,
    children
  }: {
    tooltipContent: string | JSX.Element | ReactNode | undefined;
    tooltipClickable?: boolean;
    className?: string;
    children: JSX.Element | ReactNode;
  }
) {
  if (tooltipContent) {
    return (
      <PureTooltip content={ tooltipContent } className={ className } clickable={ tooltipClickable }>
        { children }
      </PureTooltip>
    );
  } else {
    return <div className={ className }>{ children }</div>;
  }
}


const PricingPlan = forwardRef(
  ({
     name,
     price,
     originalPrice,
     period = 1,
     periodUnit = "month",
     isAnnual,
     customPlanOverride,
     planSlug,
     theme,
     bannerLabel,
     buttonAction,
     buttonClassName,
     buttonDisabled,
     buttonTooltip,
     buttonTooltipClickable,
     planAutoRenewal,
     planIdx,
     planInActionIdx,
     setPlanInActionIdx
   }: PricingPlanProps,
   ref: ForwardedRef<HTMLDivElement>
  ) => {
    const location = useLocation();
    const { activeStep, showNextAvailableStep } =
      useOnboardingTooltip();

    const isCheckingBillingInfo = useMemo(() => {
        return planIdx === planInActionIdx;
      },
      [ planIdx, planInActionIdx ]
    );

    const setCheckingBillingInfo = useCallback(() => {
        setPlanInActionIdx(planIdx);
      },
      [ planIdx, setPlanInActionIdx ]
    );

    const features = PlanFeatures(planSlug, customPlanOverride);

    const cardTheme = PricingPlanThemes[theme] || PricingPlanThemes['secondary'];

    const buttonProps = {
      className: buttonClassName || "button button-secondary",
      fullWidth: true,
      centerAlign: true,
      icon: isCheckingBillingInfo ? {
        Icon: Spinner,
        style: "w-5 h-5 animate-spin",
        absolute: true
      } : undefined,
      disabled: buttonDisabled
    };

    if (planInActionIdx !== undefined) {
      buttonProps.disabled = true;
    }

    const checkBillingAddressInNewDialog = useFeatureSwitch("REACT_APP_NEW_SUBSCRIPTION_DIALOGS_BILLING_ADDRESS_USERS");
    const checkPaymentSourcesInNewDialog = useFeatureSwitch("REACT_APP_NEW_SUBSCRIPTION_DIALOGS_PAYMENT_SOURCES_USERS");
    const { contactSalesFn } = useContactSales();


    return (
      <div
        className="flex flex-col h-full w-full"
        ref={ ref }
      >
        <div
          className={ cx(!bannerLabel && "invisible", cardTheme?.default?.bannerFill, cardTheme?.default?.bannerTextColor, "text-center text-xs py-1") }>
          { bannerLabel || "placeholder" }
        </div>

        <div className={ cx(cardTheme?.default?.borderColor, "flex flex-col h-full px-5 py-6 border") }>
          <div className="text-sm uppercase text-brandDarkest dark:text-primary-800 font-medium">
            { name }
          </div>

          <div className={ "pt-1 pb-5 border-b border-borderColor dark:border-neutral-200" }>
            <PriceTag
              price={ price || "Custom" }
              originalPrice={ originalPrice }
              hasDiscount={ Boolean(price && originalPrice && (price !== originalPrice)) }
              period={ period }
              periodUnit={ periodUnit }
              isAnnual={ isAnnual || false }
            />
          </div>

          <ul className="flex flex-col pt-5 pb-6 space-y-4 text-xs">
            { features?.map((feature, idx) =>
              <li key={ idx } className="flex items-center">
                { feature }
              </li>
            ) }
          </ul>

          <div className="flex items-end mt-4 h-full">
            { buttonAction === "cancel_change" && (
              <Button
                { ...buttonProps }
                text="Cancel Change"
                href="/cancel-subscription-change"
                shouldPassBackgroundLocation
              />
            ) }

            { buttonAction === "contact_sales" && (
              <Button
                { ...buttonProps }
                text="Contact Sales"
                onClick={ contactSalesFn }
              />
            ) }

            { buttonAction === "downgrade" && (
              <Button
                { ...buttonProps }
                text="Downgrade"
                href={ `/downgrade-subscription/${ planSlug }` }
                shouldPassBackgroundLocation
              />
            ) }

            { buttonAction === "downgrade_annual" && (
              <Button
                { ...buttonProps }
                text="Downgrade"
                href={ `/downgrade-annual-subscription/${ planSlug }` }
                shouldPassBackgroundLocation
              />
            ) }

            { buttonAction === "downgrade_enterprise" && (
              <Button
                { ...buttonProps }
                text="Downgrade"
                href="/cancel-enterprise"
                shouldPassBackgroundLocation
              />
            ) }

            { buttonAction === "pay_now" && (
              <Button
                { ...buttonProps }
                text="Pay Now"
                href="/pay-now"
                shouldPassBackgroundLocation
                onClick={ buttonDisabled ? undefined : setCheckingBillingInfo }
              />
            ) }

            { buttonAction === "renew" && (
              <ComponentWithTooltip tooltipContent={ buttonTooltip } tooltipClickable={ buttonTooltipClickable } className="relative w-full flex items-stretch flex-shrink-0 justify-center">
                <Button
                  { ...buttonProps }
                  text="Renew Now"
                  href={ `/confirm-renewal/${ planSlug }` }
                  shouldPassBackgroundLocation
                  onClick={ buttonDisabled ? undefined : setCheckingBillingInfo }
                />
                { buttonDisabled && (
                  <div className="button button-primary disabled flex items-center px-3 !border-l-0">
                    <ChevronDownIcon
                      className="w-5 h-5 text-gray dark:text-neutral-600"
                      aria-hidden="true"
                    />
                  </div>
                ) }
                { !buttonDisabled && (
                  <Menu as="div"
                        className="border-t border-b border-r bg-brandPrimary-600 dark:bg-primary-600 hover:bg-brandPrimary-700 dark:hover:bg-primary-700 text-white shadow-sm">
                    <Menu.Button className="flex items-center justify-center h-full px-3">
                      <ChevronDownIcon
                        className="w-5 h-5 text-white group-hover:text-white"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                    <Transition
                      as={ Fragment }
                      show={ activeStep === "auto_renew" || undefined }
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        className="dyna-layout absolute right-0 mt-2 origin-top-right bg-white divide-y divide-gray-100 dark:divide-neutral-100 shadow-lg w-72 ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div
                          className="z-50 px-1 py-1">
                          <Menu.Item>
                            <>
                              <Link
                                to={ `/confirm-renewal/${ planSlug }` }
                                onClick={ setCheckingBillingInfo }
                                state={ { backgroundLocation: location } }
                                className="group !capitalize flex w-full items-center px-2 py-2 text-sm hover:bg-brandPrimary/50 dark:hover:bg-primary-600/50 hover:text-white text-gray-900 dark:text-neutral-900"
                              >
                                Renew now
                              </Link>
                              { activeStep === "auto_renew" &&
                                buttonAction === "renew" && (
                                  <div
                                    className="absolute left-0 capitalize -translate-y-[150%] md:translate-y-0 md:-translate-x-[105%] right-0 z-50 p-3 text-black bg-white border shadow-xl border-brandPrimary dark:border-primary-600"
                                  >
                                    You can set up auto-renewal here
                                    <div className="flex justify-end mt-2 gap-x-3">
                                      <button
                                        className="underline hover:text-brandPrimary-800 dark:hover:text-primary-700 text-brandPrimary dark:text-primary-600"
                                        onClick={ showNextAvailableStep }
                                      >
                                        Ok
                                      </button>
                                    </div>
                                  </div>
                                ) }
                            </>
                          </Menu.Item>
                          <Menu.Item>
                            <Link
                              to="/auto-renewal/95"
                              onClick={ setCheckingBillingInfo }
                              state={ { backgroundLocation: location } }
                              className={ cx(
                                (planAutoRenewal === 95) &&
                                "!bg-brandPrimary !dark:bg-primary-600 !text-white",
                                "group !capitalize flex w-full items-center px-2 py-2 text-sm hover:bg-brandPrimary/50 dark:hover:bg-primary-600/50 hover:text-white text-gray-900 dark:text-neutral-900"
                              ) }
                            >
                              Renew automatically at 95% usage
                            </Link>
                          </Menu.Item>
                          <Menu.Item>
                            <Link
                              to="/auto-renewal/100"
                              onClick={ setCheckingBillingInfo }
                              state={ { backgroundLocation: location } }
                              className={ cx(
                                (planAutoRenewal === 100) &&
                                "!bg-brandPrimary !dark:bg-primary-600 !text-white",
                                "group !capitalize flex w-full items-center px-2 py-2 text-sm hover:bg-brandPrimary/50 dark:hover:bg-primary-600/50 hover:text-white text-gray-900 dark:text-neutral-900"
                              ) }
                            >
                              Renew automatically at 100% usage
                            </Link>
                          </Menu.Item>
                          <Menu.Item>
                            <Link
                              to="/auto-renewal/clear"
                              onClick={ setCheckingBillingInfo }
                              state={ { backgroundLocation: location } }
                              className={ cx(
                                (planAutoRenewal === null) &&
                                "!bg-brandPrimary !dark:bg-primary-600 !text-white",
                                "group !capitalize flex w-full items-center px-2 py-2 text-sm hover:bg-brandPrimary/50 dark:hover:bg-primary-600/50 hover:text-white text-gray-900 dark:text-neutral-900"
                              ) }
                            >
                              Standard monthly renewal
                            </Link>
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                ) }
              </ComponentWithTooltip>
            ) }

            { buttonAction === "upgrade" && (
              <Button
                { ...buttonProps }
                text="Upgrade"
                href={ (checkBillingAddressInNewDialog || checkPaymentSourcesInNewDialog) ? `/billing/plans/upgrade/${ planSlug }` : `/upgrade-subscription/${planSlug}` }
                shouldPassBackgroundLocation={ !(checkBillingAddressInNewDialog || checkPaymentSourcesInNewDialog) }
                onClick={ (buttonDisabled || (checkBillingAddressInNewDialog && checkPaymentSourcesInNewDialog)) ? undefined : setCheckingBillingInfo }
              />
            ) }

            { buttonAction === "upgrade_annual" && (
              <Button
                { ...buttonProps }
                text="Upgrade"
                href={ `/upgrade-annual-subscription/${ planSlug }` }
                shouldPassBackgroundLocation
              />
            ) }

          </div>
        </div>
      </div>
    );
  });

export default PricingPlan;
