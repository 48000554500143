import { Controller, useForm } from "react-hook-form";
import { forwardRef, useImperativeHandle, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { CaptchaWidgetProps } from "./index";


const GoogleReCaptchaV2Widget = forwardRef(({ setCompleted }: CaptchaWidgetProps, ref) => {
  const { control, setError } = useForm();

  const localRef = useRef<ReCAPTCHA>(null);
  useImperativeHandle(ref, () => localRef.current);

  return (
    <Controller
      name="reCaptchaToken"
      defaultValue={ null }
      control={ control }
      rules={ { required: "ReCaptcha is required" } }
      render={ ({ field: { name } }: { field: { name: string } }) => (
        <ReCAPTCHA
          ref={ localRef }
          sitekey={ process.env.REACT_APP_RECAPTCHA_V2_SITE_KEY }
          onErrored={ () => {
            setError(name, {
              type: "custom",
              message: "There was an error with ReCaptcha. Please refresh the page and try again."
            });
            setCompleted?.(false);
          } }
          onExpired={ () => {
            setError(name, {
              type: "custom",
              message: "Your ReCaptcha token expired, please try again or refresh the page."
            });
            setCompleted?.(false);
          } }
          onChange={ (token: string | null) => {
            setCompleted?.(token !== null);
          } }
        />
      ) }
    />
  );
});

export default GoogleReCaptchaV2Widget;
