import { useContext, useEffect } from "react";

import { useServiceHasConsent } from "hooks/usercentrics/useServiceHasConsent";

import ServiceScriptProvider, { ServiceScript } from "providers/ServicesProvider/ServiceScriptProvider";
import { UsercentricsContext } from "providers/UsercentricsProvider";


export default function UsercentricsScriptProvider(usercentricsScript: ServiceScript) {

  const { unloadService } = useContext(UsercentricsContext);

  const hasConsent = useServiceHasConsent(usercentricsScript.name);

  useEffect(() => {
    if (!hasConsent) {
      // consent has not been given or has been revoked
      if (usercentricsScript.domId) {
        const ucScript = document.getElementById(usercentricsScript.domId);
        ucScript?.remove();
      }
      usercentricsScript.onUnloadCallback?.();
      unloadService(usercentricsScript);
    }
  }, [ usercentricsScript, hasConsent, unloadService ]);

  return (
    <>
      {hasConsent && <ServiceScriptProvider {...usercentricsScript} />}
    </>
  );

};
