import InputFieldWithValidation, { IInputFieldWithValidationProps } from "components/InputFieldWithValidation";


export default function EmailInput(
  {
    readonlyEmail
  }: {
    readonlyEmail?: string
  }
) {
  const inputProps: IInputFieldWithValidationProps = {
    name: "email",
    label: "Email Address",
    type: "email",
    placeholder: "Please enter your email address",
    required: true,
    typeMismatchErrorMessage: "Please enter a valid email address"
  };

  if (readonlyEmail) {
    inputProps.contentEditable = false;
    inputProps.readOnly = true;
    inputProps.value = readonlyEmail;
  }

  return <InputFieldWithValidation { ...inputProps } />;
};
