import { ReactComponent as ScraperAPILogo } from "assets/icons/scraperapi-logo.svg";


export default function GlobalLoadingIndicator() {
  return (
    <div
      className="absolute inset-0 z-20 flex flex-col items-center justify-center w-screen h-screen space-y-8 text-center bg-white">
      <ScraperAPILogo className="w-full max-w-[256px] -ml-[18px] h-auto flex-shrink-0 animate-pulse"/>
    </div>
  );
};
