import React, {
  ButtonHTMLAttributes,
  forwardRef,
  MouseEventHandler,
  ReactElement
} from "react";
import ReactDOMServer from "react-dom/server";

import { cx } from "utils";

import ExtLink from "components/ExtLink";
import { globalTooltipClass } from "components/Tooltip";
import Spinner from "components/Spinner";
import StatefulLink from "components/StatefulLink";

import { Size, Sizes } from 'styles/sizes';

type ButtonSizes = Sizes<{ padding: string; height: string; fontSize: string }>;

const buttonSizes: ButtonSizes = {
  SM: { padding: "px-3", height: "h-8", fontSize: "text-sm" },
  MD: { padding: "px-4", height: "h-10", fontSize: "text-base" },
  LG: { padding: "px-6", height: "h-12", fontSize: "text-base" },
};

export type IconPosition = "leading" | "trailing";

interface IconProps {
  Icon?: React.ComponentType<any>;
  style?: string;
  size?: string;
  absolute?: boolean;
  position?: IconPosition;
}

interface IContentProps {
  text?: string;
  icon?: IconProps;
  uppercase: boolean;
  className?: string;
  htmlTooltip?: string;
  isIconOnly?: boolean; // TODO this should be computed => !text && icon ?
  loading?: boolean;
  spinnerSize: "XS" | number;
  spinnerTheme: "primary" | string;
}

const Content = (
  {
    icon,
    text,
    uppercase,
    className,
    htmlTooltip,
    isIconOnly,
    loading,
    spinnerSize,
    spinnerTheme,
  }: IContentProps
) => {
  const iconElement = icon && (
    <div className={ cx(icon.style, isIconOnly && "mx-auto", icon.absolute && "absolute") }>
      { icon.Icon && <icon.Icon className={ cx(icon.size ?? "w-5 h-5", "justify-start flex-shrink-0") }/> }
    </div>
  );

  const spinnerElement = loading && (
    <Spinner theme={ spinnerTheme } size={ spinnerSize } className="text-current ml-2"/>
  );

  const hasText = text && text.trim() !== '';

  return (
    <div
      className={ cx(
        className,
        uppercase && "uppercase",
        htmlTooltip && globalTooltipClass,
        "text-center justify-center items-center transition-colors flex-shrink-0 duration-150"
      ) }
      data-tooltip-html={ htmlTooltip }
    >
      <div className={ cx(
        "flex items-center w-full h-full",
        !isIconOnly && "gap-x-2",
        isIconOnly ? "justify-center" : icon?.position === "trailing" ? "flex-row-reverse" : "flex-row"
      ) }>
        { iconElement }
        { hasText && <span className={ cx("self-center", !isIconOnly && "mx-auto") }>{ text }</span> }
        { spinnerElement }
      </div>
    </div>
  )
};

export interface ButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "size" | "onClick"> {
  text?: string;  // TODO can be replaced by the 'title' standard html attribute
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  href?: string;
  navigationState?: any;
  icon?: IconProps;
  fullWidth?: boolean;
  centerAlign?: boolean;
  size?: Size;
  shouldPassBackgroundLocation?: boolean;
  focusable?: boolean;
  tooltip?: string | ReactElement;
  loading?: boolean;
  uppercase?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      text,
      onClick,
      href,
      navigationState,
      icon,
      fullWidth,
      centerAlign,
      className = "button button-primary",
      shouldPassBackgroundLocation = false,
      size = "MD" as Size,
      type = "button",
      id,
      focusable = true,
      tooltip,
      disabled = false,
      loading = false,
      uppercase,
      ...buttonProps
    }: ButtonProps,
    ref
  ) => {
    const btnSizeStyles = buttonSizes[size];
    const htmlTooltip = typeof tooltip === "string" ? tooltip : (tooltip ? ReactDOMServer.renderToStaticMarkup(tooltip as ReactElement) : undefined);

    const hasText = text && (text.trim() !== '');
    const isIconOnly = !hasText && !!icon?.Icon;

    const getIconOnlyStyles = () => {
      if (isIconOnly) {
        switch (size) {
          case 'SM': return 'w-8 px-0';
          case 'MD': return 'w-10 px-0';
          case 'LG': return 'w-12 px-0';
          default: return '';
        }
      }
      return '';
    };

    const textOnly = className.split(" ").find(cn => cn.startsWith("button-text")) !== undefined;

    const getSizeStyles = () => {
      const { padding, height, fontSize } = btnSizeStyles!;
      return cx(
        textOnly ? '' : (isIconOnly ? height : `${padding} ${height}`),
        fontSize
      );
    };

    const spinnerSize: "XS" | number = size === "SM" ? 16 : "XS";
    const spinnerTheme: "primary" | "white" = "primary"; // TODO (theme === "secondary" || theme === "tertiary") ? "primary" : "white";

    const contentProps: IContentProps = {
      text,
      icon: icon,
      className: cx(
        fullWidth ? "flex" : "inline-flex",
      ),
      uppercase: uppercase !== undefined ? uppercase : false /* TODO Boolean(btnType?.default?.uppercase) */,
      // TODO ? focusable,
      htmlTooltip,
      isIconOnly,
      loading,
      spinnerSize,
      spinnerTheme
    };

    const buttonClassName = cx(
      "flex items-center",
      getSizeStyles(),
      className,
      textOnly ? "" : "", // TODO removeBorderClasses(buttonStyle) : buttonStyle
      fullWidth && "w-full",
      (centerAlign || isIconOnly) && "justify-center",
      "transition-colors duration-150",
      getIconOnlyStyles(),
      textOnly && "bg-transparent",
      disabled ? "disabled" : "cursor-pointer"
    );

    if (href) {
      if (href.startsWith("http")) {
        return (
          <ExtLink
            href={ href }
            id={ id }
            className={ cx("inline-flex items-center justify-center", buttonClassName) }
            onClick={ disabled ? undefined : onClick }
          >
            <Content { ...contentProps } />
          </ExtLink>
        );
      } else {
        return (
          <StatefulLink
            to={ href }
            id={ id }
            className={ cx("inline-flex items-center justify-center", buttonClassName) }
            onClick={ disabled ? undefined : onClick }
            addLocationToState={ shouldPassBackgroundLocation }
            state={ navigationState }
            disabled={ disabled }
          >
            <Content { ...contentProps } />
          </StatefulLink>
        );
      }
    }

    if (onClick || (type === "submit")) {
      return (
        <button
          ref={ ref }
          type={ (type === "submit" && disabled) ? "button" : type }
          id={ id }
          onClick={ disabled ? undefined : onClick }
          className={ buttonClassName }
          disabled={ disabled }
          { ...buttonProps }
        >
          <Content { ...contentProps } />
        </button>
      );
    }

    return <Content {...contentProps} className={cx(contentProps.className, buttonClassName)} />;
  }
);

export default Button;
