import { useSearchParams } from "react-router-dom";
import AuthPage, { AuthPageLink, AuthPageLinks } from "components/AuthPage";
import TaggedText from "components/TaggedText";


export default function AuthError() {
  const [ searchParams ] = useSearchParams();

  const title = 'ScraperAPI | ' + (searchParams.get('title') || 'Authentication Error');
  const error = searchParams.get('error') || 'Authentication error';
  const message = searchParams.get('message') || 'There was an error during authentication. Please try again later or contact our [support team|contact_support].';

  return (
    <AuthPage
      title={title}
      heading={[
        error, <TaggedText message={message}/>
      ]}
    >

      <AuthPageLinks>
        <AuthPageLink text="" link={{ to: "/login", text: "Go Back" }} />
        <AuthPageLink text="Having trouble signing up? Contact" link={{ to: "mailto:support@scraperapi.com", text: "support@scraperapi.com" }} />
      </AuthPageLinks>

    </AuthPage>
  );
};
