import ApiKeyTextField from "components/ApiKeyTextField";
import Button from "components/Button";
import Card from "components/Card";
import { Tooltip } from "components/Tooltip";

import Messages from "misc/Messages";


export default function ApiKeyCard() {
  return (
    <Card
      title={ <Tooltip content={ Messages.apiKeyCardTooltipMessage } className="self-start">API key</Tooltip> }
      extra={
        <Button
          text="Manage"
          size="SM"
          className="button button-text-primary text-xs"
          href="/api-keys"
          shouldPassBackgroundLocation
        />
      }
    >
      <ApiKeyTextField />
    </Card>
  );
};
