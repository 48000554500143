import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";


type ApiCallsContextType = {
  apiCallUuids: string[];
  addApiCallUuid: (apiCallUuid: string) => void;
  removeApiCallUuid: (apiCallUuid: string) => void;
  inProgress: boolean;
};

const ApiCallsContext = createContext<ApiCallsContextType>({
  apiCallUuids: [],
  inProgress: false,
  addApiCallUuid: () => {},
  removeApiCallUuid: () => {}
});

export default function ApiCallsProvider({ children }: { children: ReactNode }) {

  const [ apiCallUuids, setApiCallUuids ] = useState<string[]>([]);

  const addApiCallUuid = useCallback((apiCallUuid: string) => {
    setApiCallUuids(callUuids => [
      ...callUuids,
      apiCallUuid
    ]);
  }, [ setApiCallUuids ]);

  const removeApiCallUuid = useCallback((apiCallUuid: string) => {
    setApiCallUuids(callUuids => _.without(callUuids, apiCallUuid));
  }, [ setApiCallUuids ]);

  const inProgress = apiCallUuids.length > 0;

  const context = {
    apiCallUuids,
    inProgress,
    addApiCallUuid,
    removeApiCallUuid
  };

  return <ApiCallsContext.Provider value={context}>
    {children}
  </ApiCallsContext.Provider>

};

export function useApiCalls() {
  return useContext(ApiCallsContext);
}


export function useApiCall<T>(apiCallbackFn: () => Promise<T>) {

  const [ status, setStatus ] = useState<"not_started" | "in_progress" | "finished">("not_started");
  const [ error, setError ] = useState<Error | undefined>(undefined);
  const [ result, setResult ] = useState<T | undefined>(undefined);

  const { addApiCallUuid, removeApiCallUuid } = useApiCalls();

  useEffect(() => {
    const controller = new AbortController();

    (async () => {
      const callUuid = uuidv4();

      try {
        setStatus("in_progress");
        addApiCallUuid(callUuid);

        const res = await apiCallbackFn();

        setResult(res);
      } catch (error) {
        setError(error as Error);
      } finally {
        setStatus("finished");
        removeApiCallUuid(callUuid);
      }
    })();

    return () => {
      controller.abort();
    }
  }, [ apiCallbackFn, addApiCallUuid, removeApiCallUuid ]);

  return {
    status,
    error,
    result
  };

}
