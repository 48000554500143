export const async_google_mapssearch = {
  input: "vegan restaurants",
  result: "{\n" +
    "  \"url\": \"https://www.google.com/maps/search/vegan+restaurants/@21.028511,105.804817,12z\",\n" +
    "  \"scraper_name\": \"google-maps\",\n" +
    "  \"results\": [\n" +
    "    {\n" +
    "      \"name\": \"Katze Vegan & Vegetarian\",\n" +
    "      \"address\": [\n" +
    "        \"43 P. Hàng Bè\",\n" +
    "        \"Hàng Bạc\",\n" +
    "        \"Hoàn Kiếm\",\n" +
    "        \"Hà Nội 100000, Vietnam\"\n" +
    "      ],\n" +
    "      \"stars\": 5,\n" +
    "      \"ratings\": 1817,\n" +
    "      \"price_level\": \"₫100–200K\",\n" +
    "      \"url\": \"https://www.facebook.com/profile.php?id=100068076010419\",\n" +
    "      \"type\": [\n" +
    "        \"Vegan restaurant\"\n" +
    "      ],\n" +
    "      \"district\": \"Hàng Bạc, Hoàn Kiếm\",\n" +
    "      \"latitude\": 21.0328302,\n" +
    "      \"longitude\": 105.8538444,\n" +
    "      \"address_line\": \"Katze Vegan & Vegetarian, 43 P. Hàng Bè, Hàng Bạc, Hoàn Kiếm, Hà Nội 100000, Vietnam\",\n" +
    "      \"open\": {\n" +
    "        \"wednesday\": [\n" +
    "          \"11 a.m.–11:30 p.m.\"\n" +
    "        ],\n" +
    "        \"thursday\": [\n" +
    "          \"11 a.m.–11:30 p.m.\"\n" +
    "        ],\n" +
    "        \"friday\": [\n" +
    "          \"11 a.m.–11 p.m.\"\n" +
    "        ],\n" +
    "        \"saturday\": [\n" +
    "          \"11 a.m.–11:30 p.m.\"\n" +
    "        ],\n" +
    "        \"sunday\": [\n" +
    "          \"11 a.m.–11:30 p.m.\"\n" +
    "        ],\n" +
    "        \"monday\": [\n" +
    "          \"11 a.m.–11:30 p.m.\"\n" +
    "        ],\n" +
    "        \"tuesday\": [\n" +
    "          \"11 a.m.–11:30 p.m.\"\n" +
    "        ]\n" +
    "      },\n" +
    "      \"images\": [\n" +
    "        \"https://lh5.googleusercontent.com/p/AF1QipNImj-MzSXn2QiNLh-Yvhwm3wqOjqgj89dukv-v=w114-h86-k-no\",\n" +
    "        \"https://lh5.googleusercontent.com/p/AF1QipNImj-MzSXn2QiNLh-Yvhwm3wqOjqgj89dukv-v=w408-h306-k-no\"\n" +
    "      ]\n" +
    "    },\n" +
    "    ...\n" +
    "    {\n" +
    "      \"name\": \"V-Cuisine\",\n" +
    "      \"address\": [\n" +
    "        \"29 P. Hàng Giầy\",\n" +
    "        \"Hàng Buồm\",\n" +
    "        \"Hoàn Kiếm\",\n" +
    "        \"Hà Nội, Vietnam\"\n" +
    "      ],\n" +
    "      \"stars\": 4.5,\n" +
    "      \"ratings\": 272,\n" +
    "      \"price_level\": \"₫1–100,000\",\n" +
    "      \"type\": [\n" +
    "        \"Vietnamese restaurant\",\n" +
    "        \"Restaurant\",\n" +
    "        \"Vegetarian restaurant\"\n" +
    "      ],\n" +
    "      \"district\": \"Hàng Buồm, Hoàn Kiếm\",\n" +
    "      \"latitude\": 21.0349632,\n" +
    "      \"longitude\": 105.85127999999999,\n" +
    "      \"address_line\": \"V-Cuisine, 29 P. Hàng Giầy, Hàng Buồm, Hoàn Kiếm, Hà Nội, Vietnam\",\n" +
    "      \"open\": {\n" +
    "        \"wednesday\": [\n" +
    "          \"10:30 a.m.–11:30 p.m.\"\n" +
    "        ],\n" +
    "        \"thursday\": [\n" +
    "          \"10:30 a.m.–11:30 p.m.\"\n" +
    "        ],\n" +
    "        \"friday\": [\n" +
    "          \"10:30 a.m.–11:30 p.m.\"\n" +
    "        ],\n" +
    "        \"saturday\": [\n" +
    "          \"10:30 a.m.–11:30 p.m.\"\n" +
    "        ],\n" +
    "        \"sunday\": [\n" +
    "          \"10:30 a.m.–11:30 p.m.\"\n" +
    "        ],\n" +
    "        \"monday\": [\n" +
    "          \"10:30 a.m.–11:30 p.m.\"\n" +
    "        ],\n" +
    "        \"tuesday\": [\n" +
    "          \"10:30 a.m.–11:30 p.m.\"\n" +
    "        ]\n" +
    "      },\n" +
    "      \"images\": [\n" +
    "        \"https://lh5.googleusercontent.com/p/AF1QipNMnc-iWu1jsVcazq3sx2vKg0p0gVSdQAewo20J=w114-h86-k-no\",\n" +
    "        \"https://lh5.googleusercontent.com/p/AF1QipNMnc-iWu1jsVcazq3sx2vKg0p0gVSdQAewo20J=w408-h306-k-no\"\n" +
    "      ]\n" +
    "    }\n" +
    "  ],\n" +
    "  \"next_page_url\": \"https://api.scraperapi.com?autoparse=true&url=https%3A%2F%2Fgoogle.com%2Fsearch%3Ftbm%3Dmap%26authuser%3D0%26pb%3D!1svegan%2520restaurants!4m8!1m3!1d119171.33864363436!2d105.804817!3d21.028511!3m2!1i1024!2i768!4f13.1!7i20!8i20!10b1!12m35!1m2!18b1!30b1!2m3!5m1!6e2!20e3!6m12!4b1!49b1!63m0!66b1!74i150000!75b1!85b1!89b1!91b1!114b1!149b1!196b1!10b1!12b1!13b1!14b1!16b1!17m2!3m1!1i1!20m3!5e2!6b1!14b1!46m1!1i0!94i1!19m4!2m3!1i360!2i120!4i8!20m57!2m2!1i203!2i100!3m2!2i4!5b1!6m6!1m2!1i86!2i86!1m2!1i408!2i240!7m42!1m3!1e1!2b0!3e3!1m3!1e2!2b1!3e2!1m3!1e2!2b0!3e3!1m3!1e8!2b0!3e3!1m3!1e10!2b0!3e3!1m3!1e10!2b1!3e2!1m3!1e9!2b1!3e2!1m3!1e10!2b0!3e3!1m3!1e10!2b1!3e2!1m3!1e10!2b0!3e4!2b1!4b1!9b0!22m5!1sLTYGZ42wG7eF9u8P4rnVoAU!7e81!14m1!3sLTYGZ42wG7eF9u8P4rnVoAU!15i9937!24m111!1m28!13m9!2b1!3b1!4b1!6i1!8b1!9b1!14b1!20b1!25b1!18m17!3b1!4b1!5b1!6b1!13b1!14b1!17b1!21b1!22b1!25b1!27m1!1b0!28b0!31b0!32b0!33m1!1b0!5m5!2b1!5b1!6b1!7b1!10b1!10m1!8e3!11m2!3m1!1i1!14m1!3b1!17b1!20m2!1e3!1e6!24b1!25b1!26b1!29b1!30m1!2b1!36b1!39m3!2m2!2i1!3i1!43b1!52b1!54m1!1b1!55b1!56m1!1b1!65m6!3m5!1m4!1m3!1m2!1i224!2i298!71b1!72m21!1m5!1b1!2b1!3b1!5b1!7b1!4b1!8m12!1m11!1m7!4m6!1m1!1i1!2m1!1i3!3m1!1i4!3sother_user_reviews!6m1!1i1!9b1!89b1!98m3!1i1!2i1!3i1!103b1!113b1!114m3!1i1!2m1!1i1!117b1!122m1!1b1!125b0!126b1!127i1!26m4!2m3!1i80!2i92!4i8!30m28!1m6!1m2!1i0!2i0!2m2!1i530!2i768!1m6!1m2!1i974!2i0!2m2!1i1024!2i768!1m6!1m2!1i0!2i0!2m2!1i1024!2i20!1m6!1m2!1i0!2i748!2m2!1i1024!2i768!34m19!2b1!3b1!4b1!6b1!7b1!8m6!1b1!3b1!4b1!5b1!6b1!7b1!9b1!12b1!14b1!20b1!23b1!25b1!26b1!37m1!1e81!42b1!49m9!3b1!6m2!1b1!2b1!7m2!1e3!2b1!8b1!9b1!50m3!2e2!3m1!3b1!61b1!67m3!7b1!10b1!14b0!69i708%26q%3Dvegan%2520restaurants%26tch%3D0%26ech%3D1\"\n" +
    "}"
};
