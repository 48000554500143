import { useContext } from "react";

import { UCWindow, UsercentricsContext } from "providers/UsercentricsProvider";


const getServiceInfo = (serviceName: string) => {
  const allServicesInfo = (window as UCWindow).UC_UI?.getServicesBaseInfo?.() || [];
  return allServicesInfo.find(({ name }) => serviceName === name) || null;
};


export function useServiceHasConsent(serviceName: string) {

  // TODO if UC is not initialized yet we could also check the local storage but for that we need the encoded service id
  const { isInitialized } = useContext(UsercentricsContext);

  const serviceInfo = isInitialized ? getServiceInfo(serviceName) : null;
  return serviceInfo?.consent?.status || null;

  // // check local storage in case Usercentrics is not yet initialized
  // const serviceFromLocalStorage = localStorage.find((service) => serviceId === service.id);
  // return serviceFromLocalStorage ? serviceFromLocalStorage.status : null;

}
