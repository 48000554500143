import React, { createContext, useContext, ReactNode, Children } from "react";
import { cx } from 'utils';
import { Size, Sizes } from 'styles/sizes';

type RadioGroupSizes = Sizes<{ height: string; padding: string; fontSize: string }>;

const radioGroupSizes: RadioGroupSizes = {
  SM: { height: "h-6", padding: "px-2", fontSize: "text-sm" },
  MD: { height: "h-8", padding: "px-3", fontSize: "text-sm" },
  LG: { height: "h-10", padding: "px-4", fontSize: "text-base" },
};

interface RadioGroupContextType<T> {
  type: 'default' | 'button';
  value: T;
  onChange: (value: T) => void;
  children: ReactNode;
  size: Size;
}

const RadioGroupContext = createContext<RadioGroupContextType<any> | null>(null);

interface RadioGroupProps<T> {
  type?: 'default' | 'button';
  value: T;
  onChange: (value: T) => void;
  children: ReactNode;
  name?: string;
  className?: string;
  size?: Size;
}

export function RadioGroup<T>({
  type = 'default',
  value,
  onChange,
  children,
  name,
  className = '',
  size = "MD"
}: RadioGroupProps<T>) {
  const containerClass = type === 'button'
    ? 'inline-flex flex-wrap mb-8 mt-2'
    : 'flex flex-row flex-wrap gap-x-4 gap-y-2 mb-8 mt-2';

  return (
    <RadioGroupContext.Provider value={{ type, value, onChange, children, size }}>
      <div
        role="radiogroup"
        aria-labelledby={name}
        className={cx(containerClass, className)}
      >
        {children}
      </div>
    </RadioGroupContext.Provider>
  );
}

const radiogroupPropertiesForHeadlessUI = `
relative float-left mt-0.5 mr-1 -ml-[1.5rem] h-5 w-5 appearance-none rounded-full border-2 border-solid border-[rgba(0,0,0,0.25)] dark:border-neutral-900 bg-primary 
before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] 
after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:bg-primary after:content-[''] 
checked:border-primary checked:bg-primary checked:before:opacity-[0.16] 
checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-blue dark:checked:after:border-primary-600 checked:after:bg-blue dark:checked:after:bg-white checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] 
hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
dark:disabled:opacity-100 dark:disabled:cursor-not-allowed dark:hover:border-primary-600
dark:disabled:checked:after:bg-neutral-500 dark:hover:disabled:checked:bg-white dark:hover:disabled:checked:border-neutral-300
dark:disabled:border-neutral-300 dark:disabled:bg-white
`;

const buttonStyles = `
px-4 py-2 border focus:outline-none flex items-center
`;

interface RadioGroupElementProps {
  value: string;
  label: string;
  disabled?: boolean;
}

export const RadioGroupElement: React.FC<RadioGroupElementProps> = ({
  value,
  label,
  disabled = false
}) => {
  const context = useContext(RadioGroupContext);
  if (!context) throw new Error("RadioGroupElement must be used within a RadioGroup");

  const { type, onChange, value: contextValue, children, size } = context;
  const checked = contextValue === value;

  const handleChange = () => {
    if (!disabled) {
      onChange(value);
    }
  };

  const getButtonStyle = () => {
    if (type !== 'button') return '';

    const elements = Children.toArray(children);
    const index = elements.findIndex((child: any) => child.props.value === value);
    const isFirst = index === 0;
    const isLast = index === elements.length - 1;

    const sizeStyle = radioGroupSizes[size || "MD"];

    return cx(
      buttonStyles,
      sizeStyle?.height,
      sizeStyle?.padding,
      sizeStyle?.fontSize,
      disabled
        ? 'bg-neutral-100 border-neutral-200 text-neutral-500 cursor-not-allowed'
        : checked
        ? 'bg-primary-600 border-primary-600 text-white hover:bg-primary-700'
        : 'bg-white text-neutral-900 border-neutral-900 hover:bg-neutral-100',
      !disabled && 'cursor-pointer',
      isFirst ? 'rounded-l' : isLast ? 'rounded-r' : '',
      !isFirst && 'border-l-0'
    );
  };

  return (
    <div className={type === 'button' ? 'inline-flex' : 'mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]'}>
      {type === 'default' ? (
        <>
        <input
          className={radiogroupPropertiesForHeadlessUI}
          type="radio"
          checked={checked}
          onChange={handleChange}
          id={value}
          disabled={disabled}
        />
        <label
          className={`mt-px inline-block pl-[0.15rem] ${
            disabled 
              ? 'text-neutral-500 cursor-not-allowed' 
              : 'hover:cursor-pointer'
          }`}
          htmlFor={value}
        >
          {label}
        </label>
      </>
      ) : (
        <button
          className={getButtonStyle()}
          onClick={handleChange}
          disabled={disabled}
        >
          {label}
        </button>
      )}
    </div>
  );
};
