import { useCallback, useEffect } from "react";

import { ServiceInfo, useServices } from "./index";


export type ServiceScript = ServiceInfo & {
  src: string;
  onLoadCallback?: () => void;
  onUnloadCallback?: () => void;
};

export default function ServiceScriptProvider(serviceScript: ServiceScript) {

  const { loadServiceScript } = useServiceScript(serviceScript);

  useEffect(() => {
    loadServiceScript();
  }, [ serviceScript, loadServiceScript ]);

  return <></>;

};

export function useServiceScript(serviceScript: ServiceScript) {
  const { loadService, unloadService } = useServices();

  const loadServiceScript = useCallback(() => {
    if (!document.getElementById(serviceScript.domId)) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.id = serviceScript.domId;
      script.src = serviceScript.src;
      script.onload = () => {
        if (loadService(serviceScript)) {
          serviceScript.onLoadCallback?.();
        }
      }
      document.body.appendChild(script);
    }
  }, [ loadService, serviceScript ]);

  const unloadServiceScript = useCallback(() => {
    const script = document.getElementById(serviceScript.domId);
    if (script) {
      script.remove();
      if (unloadService(serviceScript)) {
        serviceScript.onUnloadCallback?.();
      }
    }
  }, [ serviceScript, unloadService ]);


  return { loadServiceScript, unloadServiceScript };
}
