import { cx, fmtNumber } from "utils";


function IndefiniteBarContent() {
  // TODO should come up with some better animations here
  return (
    <div className="absolute w-full h-[8px] bg-neutral-300 animate-pulse" />
  );
}

function Bar(
  {
    current,
    max
  }: {
    current?: number,
    max?: number,
  }
) {
  let BarContent = <></>;

  if ((current !== undefined) && (max !== undefined)) {
    const progress = Math.min(current, max) / max;

    BarContent = (
      <div
        className={ cx(
          "absolute origin-left w-full h-[8px]",
          progress < .5 && "bg-primary-600",
          progress >= .5 && progress < .8 && "bg-warning-600",
          progress >= .8 && "bg-error-600"
        ) }
        style={ { transform: `scaleX(${ progress })` } }
      />
    );

  } else {
    // rendering indefinite progress bar
    BarContent = <IndefiniteBarContent />;
  }

  return (
    <div className="relative h-[8px] bg-transparent w-full">
      <div className="absolute w-full h-[8px] bg-neutral-100" />
      { BarContent }
    </div>
  );

}

export default function ProgressBar(
  {
    label,
    current,
    max,
    fullWidth = false,
  }: {
    label: string,
    current?: number,
    max?: number,
    fullWidth?: boolean,
  }
) {
  const formattedLabel = fmtNumber(current) + " / " + fmtNumber(max) + " " + label;

  return (
    <div className={ cx(
      "flex flex-col gap-y-2",
      fullWidth && "w-full"
    ) }
    >
      <div className="text-sm text-neutral-900">{ formattedLabel }</div>
      <Bar current={ current } max={ max } />
    </div>
  );
};
