import { useCallback, useEffect, useState } from "react";


export function useCopyToClipboard() {
  const [ recentlyCopied, setRecentlyCopied ] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => setRecentlyCopied(false), 3000);
    return () => clearTimeout(timeoutId);
  }, [ recentlyCopied ]);

  const copyToClipboardFn = useCallback(async (value: string | undefined) => {
    if (!value) {
      return;
    }

    try {
      await navigator.clipboard.writeText(value);
      setRecentlyCopied(true);
    } catch (error) {
      // TODO error handling
      console.error("Error copying to clipboard", error);
    }
  }, [ setRecentlyCopied ]);

  return [ recentlyCopied, copyToClipboardFn ] as const;
}
