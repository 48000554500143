import type { TypeSelectedCallback } from "components/hosted-scraping/select-type-components/typeSelectedCallback";
import { ProjectBreadcrumbPage } from "components/ProjectBreadcrumbPage";
import { AsyncUrlBar } from "components/hosted-scraping/select-type-components/AsyncUrlBar";
import { StructuredDatasourceCard } from "components/hosted-scraping/select-type-components/StructuredDatasourceCard";
import { validSDECollectorTypes } from "sdecontent";


export function NewProjectSelectType({projectName, callback, setNameCallback}: {projectName: string|undefined, callback: TypeSelectedCallback, setNameCallback: (name: string) => void}) {
  return (
    <ProjectBreadcrumbPage projectName={projectName} callback={setNameCallback}>
      <p className="mt-2 text-xl font-normal text-brandDarkest dark:text-neutral-900">
        What type of data do you need?
      </p>
      <p className="mt-3 text-sm text-gray-600 dark:text-neutral-600">
        ScraperAPI allows you to extract data from URLs or specific domains and paths, such as Google search results or Amazon product pages. With the specific domains, you will be able to download a JSON file with well-structured data. Simply choose the type of data you need and we'll handle the rest.
      </p>

      <div className="mt-8">
        <AsyncUrlBar variant="hosted-scraper" callback={callback} />
      </div>

      <div className="mt-24 mb-3 text-base">
        Structured data sources
      </div>
      <div className="flex flex-row flex-wrap gap-6">
        {validSDECollectorTypes.map((type) => <StructuredDatasourceCard variant="hosted-scraper" key={type} configType={type} callback={callback} />)}
      </div>
    </ProjectBreadcrumbPage>
  );
}
