export function setElementVisible(element: HTMLElement, visible: boolean, focus?: boolean) {
  element.style.display = visible ? "block" : "none";
  if (visible && focus) {
    element.focus();
  }
}

export function getNamedInputGroupNames(form: HTMLFormElement) {
  // Note: Object.keys works on chrome but not on firefox
  return Object.getOwnPropertyNames(form.elements).slice(form.elements.length);
}

function isMandatoryCheckboxGroup(elements: Element | RadioNodeList | null) {
  if (elements instanceof RadioNodeList) {
    const mandatoryCheckboxes = Array.from(elements).filter((element) => element instanceof HTMLInputElement && element.type === "checkbox" && element.required);
    if (mandatoryCheckboxes.length === elements.length) {
      return true;
    }
  }

  return false;
}

function hasMandatoryCheckboxGroup(form: HTMLFormElement, namedGroups?: string[]) {
  const groupNames = namedGroups || getNamedInputGroupNames(form);
  for (const groupName of groupNames) {
    if (isMandatoryCheckboxGroup(form.elements.namedItem(groupName))) {
      return true;
    }
  }
  return false;
}

function checkRadioNodeListValidity(radioNodeList: RadioNodeList) {
  const checkedAnswer = Array.from(radioNodeList).find(node => node instanceof HTMLInputElement && node.checked);
  return checkedAnswer !== undefined;
}

function checkValidity(element: Element | RadioNodeList | null) {
  if (element instanceof RadioNodeList) {
    return checkRadioNodeListValidity(element);
  } else if (element instanceof HTMLInputElement) {
    return element.checkValidity();
  } else {
    return true;
  }
}

export function checkFormValidity(form: HTMLFormElement) {
  const namedGroups = getNamedInputGroupNames(form);
  if (hasMandatoryCheckboxGroup(form)) {
    // have to validate input groups one by one because of the mandatory checkbox groups (multi-option questions)
    // which are not supported by the built-in form validity checks
    const invalidGroup = namedGroups.find(groupName => !checkValidity(form.elements.namedItem(groupName)));
    return invalidGroup === undefined;
  } else {
    // form does not contain any mandatory checkbox groups, so we can use the built-in form validity checks
    return form.checkValidity();
  }
}
