import { useEffect } from "react";
import { Title } from "react-head";

import { LayoutContent, useBorderLayout } from "layouts/BorderLayout";

import { cx } from "utils";


interface IBorderedPageProps extends LayoutContent {
  title: string | JSX.Element;
  browserTitle?: string;
  className?: string;
  children?: React.ReactNode;
}

export default function BorderedPage({ title, browserTitle, className, children, ...props }: IBorderedPageProps) {
  const { setLayoutContent } = useBorderLayout();

  useEffect(() => {
    setLayoutContent(layoutContent => {
      return { ...layoutContent, ...props };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ setLayoutContent ]); // props is missing from the dependency array by purpose, otherwise the screen will be re-rendered infinitely

  const pageTitle = browserTitle ?? (typeof title === "string" ? title : undefined);

  return <div className={ cx("px-2 md:px-8 dark:md:px-6", className) }>
    <Title>ScraperAPI { pageTitle && (" | " + pageTitle) }</Title>
    <div className="text-2xl py-6">
      { title }
    </div>
    { children }
  </div>;
};
