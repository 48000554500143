import { fmtNumber } from "utils";
import { isUsingChargebeeCustomFields } from "utils/planUtils";

import { CustomPlanOverrideType } from "components/PricingPlan";
import CheckedText from "components/CheckedComponent";


const credits = (amount: number) => {
  return <CheckedText><b>{fmtNumber(amount)}</b> API Credits</CheckedText>;
};

const threads = (amount: number) => {
  return <CheckedText><b>{fmtNumber(amount)}</b> Concurrent Threads</CheckedText>;
};

const geo = (geotargeting: string) => {
  return <CheckedText>{geotargeting}</CheckedText>;
};

export default function PlanFeatures(
  planId?: string,
  planOverrides?: CustomPlanOverrideType
) {
  if (planId === 'student_npo') {
    return [
      credits(50000),
      threads(25),
      geo("All Geotargeting")
    ];
  } else if (planId === "affiliate") {
    return [
      credits(50000),
      threads(20),
      geo("US & EU Geotargeting")
    ]
  } else if (planId === "annual_affiliate") {
    return [
      credits(50000),
      threads(20),
      geo("US & EU Geotargeting")
    ]
  } else if (planId === "hobby_1") {
    return [
      credits(250000),
      threads(20),
      geo("US & EU Geotargeting")
    ];
  } else if (planId === 'hobby_2') {
    return [
      credits(100000),
      threads(20),
      geo("US & EU Geotargeting")
    ];
  } else if (planId === "annual_hobby_2") {
    return [
      credits(100000),
      threads(20),
      geo("US & EU Geotargeting")
    ];
  } else if ((planId === "startup") || (planId === 'startup_2')) {
    return [
      credits(1000000),
      threads(50),
      geo("US & EU Geotargeting")
    ];
  } else if (planId === "annual_startup_2") {
    return [
      credits(1000000),
      threads(50),
      geo("US & EU Geotargeting")
    ];
  } else if ((planId === "business") || (planId === 'business_2')) {
    return [
      credits(3000000),
      threads(100),
      geo("All Geotargeting")
    ];
  } else if (planId === "annual_business_2") {
    return [
      credits(3000000),
      threads(100),
      geo("All Geotargeting")
    ];
  } else if (planId === "professional_1") {
    return [
      credits(14000000),
      threads(400),
      geo("All Geotargeting")
    ];
  } else if (planId === "annual_professional_1") {
    return [
      credits(14000000),
      threads(400),
      geo("All Geotargeting")
    ];
  } else if (isUsingChargebeeCustomFields(planId)) {
    if (planOverrides) {
      return [
        credits(planOverrides.cf_api_request_limit || 0),
        threads(planOverrides.cf_api_concurrency_limit || 0),
        planOverrides.cf_api_geotargeting === "Disabled" ? null : geo("All Geotargeting")
      ];
    } else {
      return [
        <span className="mx-2 text-justify">Need more than {fmtNumber(3000000)} API Credits with all premium features and dedicated support?</span>
      ];
    }
  }
};
