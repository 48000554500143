import { useFormContext } from "react-hook-form";
import Spinner from "components/Spinner";

const FormSubmittingSpinner = () => {
  const {
    formState: { isSubmitting }
  } = useFormContext();
  if (!isSubmitting) {
    return <></>;
  } else {
    return <Spinner className="w-5 h-5 mr-3 -ml-1 text-white animate-spin" />;
  }
};
export default FormSubmittingSpinner;
