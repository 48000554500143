import { IOnboardingQuestionPage } from "./Pages";
import OnboardingHeader from "./OnboardingHeader";
import OnboardingQuestion from "./OnboardingQuestion";
import { UserAnswerType } from "components/FormWithValidation/localStorage";


export default function QuestionsPage(
  {
    page,
    userAnswers,
    questionsClassName,
    showQuestionsHint = true
  }: {
    page: Omit<IOnboardingQuestionPage, "stepper">;
    userAnswers?: UserAnswerType;
    questionsClassName?: string;
    showQuestionsHint?: boolean;
  }
) {
  return (
    <div className="flex flex-col gap-y-11">
      <OnboardingHeader
        title={ page.title || "" }
        description={ page.description }
        icon={ page.icon }
      />

      { page.questions?.map((question, idx) =>
        <OnboardingQuestion
          key={ (question.id || "") + idx }
          { ...question }
          hasOtherOption={ question.hasOtherAnswer }
          userAnswers={ (question.id && userAnswers) ? userAnswers[question.id] : undefined }
          questionClassName={ questionsClassName }
          showHint={ showQuestionsHint }
        />
      ) }

    </div>
  );
};
