import { BaseSyntheticEvent, forwardRef } from "react";
import { FieldValues, FormProvider, UseFormReturn } from "react-hook-form";


const Form = forwardRef((
  {
    onSubmit,
    children,
    methods
  }: {
    onSubmit: (
      data: FieldValues,
      event?: BaseSyntheticEvent<object, any, any> | undefined
    ) => void;
    children: React.ReactNode;
    methods: UseFormReturn<FieldValues, object>;
  },
  ref: any
) => {
  const {
    handleSubmit,
    formState: { isSubmitting }
  } = methods;

  return (
    <FormProvider { ...methods }>
      <fieldset disabled={ isSubmitting }>
        <form onSubmit={ handleSubmit(onSubmit) } ref={ ref }>{ children }</form>
      </fieldset>
    </FormProvider>
  );
});

export default Form;
