import { FetcherWithComponents, Form, FormProps } from "react-router-dom";
import { useCallback, useEffect, useRef } from "react";

import { checkFormValidity, getNamedInputGroupNames } from "utils/htmlUtils";

import { getUserAnswersFromLocalStorage, setUserAnswersInLocalStorage, updateUserAnswers } from "./localStorage";


interface IFormWithValidationProps extends FormProps {
  onValidate?: (formRef: HTMLFormElement) => boolean;
  revalidateOnChange?: boolean;
  validationCallback?: (valid: boolean) => void;
  localStorageKey?: string;

  fetcher?: FetcherWithComponents<any>;
}

export default function FormWithValidation(
  {
    onChange,
    onValidate,
    revalidateOnChange = true,
    validationCallback,
    localStorageKey,
    fetcher,
    ...props
  }: IFormWithValidationProps
) {
  const formRef = useRef<HTMLFormElement>(null);

  const revalidateForm = useCallback(() => {
    if (formRef.current) {
      if (onValidate) {
        onValidate(formRef.current);
      } else if (validationCallback) {
        validationCallback(checkFormValidity(formRef.current));
      }
    }
  }, [ formRef, onValidate, validationCallback ])

  const onFormChangeCallback = useCallback((event: React.ChangeEvent<HTMLFormElement>) => {
    if (localStorageKey) {
      const userAnswers = getUserAnswersFromLocalStorage(localStorageKey);
      updateUserAnswers(userAnswers, event.currentTarget, event.target instanceof HTMLInputElement ? event.target : undefined);
      setUserAnswersInLocalStorage(localStorageKey, userAnswers);
    }

    if (revalidateOnChange) {
      revalidateForm();
    }

    onChange?.(event);
  }, [ revalidateOnChange, revalidateForm, onChange, localStorageKey ]);

  // attaching a mutation observer to the form to trigger revalidation when components are added to or removed from the form
  useEffect(() => {
    if (formRef.current) {
      const mutationObserver = new MutationObserver(revalidateForm);
      mutationObserver.observe(formRef.current, {
        childList: true,
        subtree: true
      });

      // TODO this return statement should be here but it breaks the observer for some reason (at least in local)
      // return () => {
      //   mutationObserver.disconnect();
      // }
    }
  }, [ formRef, revalidateForm ]);

  // load user answers from local storage
  useEffect(() => {
    if (localStorageKey && formRef.current) {
      const userAnswers = getUserAnswersFromLocalStorage(localStorageKey);
      const formQuestionIds = getNamedInputGroupNames(formRef.current);
      for (const questionId of formQuestionIds) {
        const answers = userAnswers[questionId];
        if (answers) {
          const answerInputs = formRef.current.elements.namedItem(questionId);
          if (answerInputs instanceof RadioNodeList) {
            Array.from(answerInputs)
              .filter(input => input instanceof HTMLInputElement && answers.includes(input.value))
              .forEach(input => (input as HTMLInputElement).checked = true);
          } else if (answerInputs instanceof HTMLInputElement) {
            answerInputs.value = answers[0];
          }
        }
      }

      revalidateForm();
    }
  }, [ localStorageKey, revalidateForm ]);


  const allFormProps = {
    ...props,
    ref: formRef,
    onChange: onFormChangeCallback,
    noValidate: true
  };


  if (fetcher) {
    return <fetcher.Form { ...allFormProps }/>;
  } else {
    return <Form { ...allFormProps } />;
  }
};
