import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useFetcher } from "react-router-dom";
import { Chart as ChartJS, BarController, ChartData, ChartOptions, TimeScale } from "chart.js";
import { Bar } from "react-chartjs-2";
import "chartjs-adapter-date-fns";

import { RiCalendarLine, RiDownloadLine, RiGlobalLine } from "@remixicon/react";


import Button from "components/Button";
import { PureTooltip } from "components/Tooltip";
import Select, { SelectOption } from "components/Select";
import Spinner from "components/Spinner";

import { Fetchers } from "routes/dataroutes/Fetchers";

import { cx } from "utils";
import { DAY, HOUR, MINUTE, SECOND } from "utils/timeConstants";

import tailwindColors from "tailwind.config/colors";


ChartJS.register(
  BarController,
  TimeScale
);


function BarChart(
  {
    chartOptions,
    chartData
  }: {
    chartOptions: ChartOptions<"bar">,
    chartData: ChartData<"bar", (ChartsData | number)[]>
  }
) {
  return (
    <div className="relative w-full h-[240px]">
      <Bar
        options={ chartOptions }
        data={ chartData }
      />
    </div>
  );
}

interface ChartsData {
  start_of_interval: string;
  domain: string;
  all_requests: number;
  successful_requests: number;
  failed_requests: number;
  cancelled_requests: number;
  credits_costs: number;
}

function UsageCharts(
  {
    data
  }: {
    data: ChartsData[];
  }
) {
  const chartsDataFetcher = useFetcher(Fetchers.CHARTS_DATA);

  const chartOptions: ChartOptions<"bar"> = useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: { display: false },
      },
      scales: {
        x: {
          type: "time",
          grid: { display: false },
          ticks: {
            color: tailwindColors.neutral["500"],
            font: { family: "Work Sans", size: 12 },
            maxRotation: 0,
          },
        },
        y: {
          ticks: {
            count: 4, precision: 0, padding: 16, color: tailwindColors.neutral["500"],
            font: { family: "Work Sans", size: 12 },
          },
          grid: { color: tailwindColors.neutral["100"] },
          border: { display: false }
        }
      },
      parsing: {
        xAxisKey: "interval_date"
      },
      animations: chartsDataFetcher.state === "idle" ? undefined : {
        backgroundColor: {
          duration: 700,
          loop: true,
          to: tailwindColors.neutral["200"],
          type: "color",
          easing: "linear"
        }
      }
    }
  }, [ chartsDataFetcher.state ]);

  const chartData: ChartData<"bar", ChartsData[]> = useMemo(() => {
    return {
      datasets: [
        {
          label: "Success",
          data: data,
          parsing: {
            yAxisKey: "credits_costs"
          },
          backgroundColor: tailwindColors.primary["600"],
          borderRadius: 2,
        },
        {
          label: "Failure",
          data: data,
          parsing: {
            yAxisKey: "failed_requests"
          },
          backgroundColor: tailwindColors.neutral["200"],
          borderRadius: 2,
        }
      ]
    };
  }, [ data ]);

  return <BarChart chartOptions={ chartOptions } chartData={ chartData } />;
}

function Legend(
  {
    text,
    className,
  }: {
    text: string;
    className?: string;
  }
) {
  return (
    <div className="flex flex-row gap-x-2 items-center">
      <div className={ cx(className, "w-3 h-3 rounded") } />
      <div className="text-sm text-neutral-900">{ text }</div>
    </div>
  );
}

const getElapsedTime = (date: Date) => {
  const diff = Date.now() - date.getTime();
  if (diff < MINUTE) {
    return {
      elapsed: Math.floor(diff / SECOND) + "s",
      interval: SECOND
    };
  }

  if (diff < HOUR) {
    return {
      elapsed: Math.floor(diff / MINUTE) + "m",
      interval: MINUTE
    };
  }

  if (diff < DAY) {
    return {
      elapsed: Math.floor(diff / HOUR) + "h",
      interval: HOUR
    };
  }

  return {
    elapsed: Math.floor(diff / DAY) + "d",
    interval: DAY
  };
}

function LastUpdated(
  {
    updatedAt,
    refresh
  }: {
    updatedAt?: Date;
    refresh?: () => void;
  }
) {
  const chartsDataFetcher = useFetcher(Fetchers.CHARTS_DATA);

  const elapsedAndInterval = useMemo(() => updatedAt ? getElapsedTime(updatedAt) : undefined, [ updatedAt ]);
  const [ lastUpdated, setLastUpdated ] = useState<string | undefined>(elapsedAndInterval?.elapsed);
  const [ updateInterval, setUpdateInterval ] = useState<number | undefined>(elapsedAndInterval?.interval);

  const tick = useCallback(() => {
    if (updatedAt) {
      const { elapsed, interval } = getElapsedTime(updatedAt);
      setLastUpdated(elapsed);
      setUpdateInterval(interval);
    }
  }, [ updatedAt ]);

  useEffect(() => {
    if (updateInterval) {
      const intervalHandle = setInterval(tick, updateInterval);

      return () => clearInterval(intervalHandle);
    }
  }, [ tick, updateInterval ]);

  useEffect(() => {
    if (updatedAt) {
      setUpdateInterval(SECOND);
    }
  }, [ updatedAt ]);

  const update = useCallback(() => {
    setLastUpdated("0s");
    refresh?.();
  }, [ refresh ]);

  const isUpdating = useMemo(() => {
    return (chartsDataFetcher.state !== "idle") || !lastUpdated;
  }, [ chartsDataFetcher.state, lastUpdated ]);


  return (
    <div className="flex flex-row items-center text-sm text-neutral-500">
      { isUpdating && (
        <div className="inline-flex gap-x-1 items-center"><span>Updating...</span><Spinner size="XXS" theme="secondary" /></div>
      ) }
      { !isUpdating && (
        <PureTooltip content="Click here to refresh">
          <div className="hover:underline cursor-pointer" onClick={ update }>Last updated { lastUpdated } ago</div>
        </PureTooltip>
      ) }
    </div>
  );
}

function LegendAndUpdate(
  {
    updatedAt,
    refresh
  }: {
    updatedAt?: Date;
    refresh?: () => void;
  }
) {
  return (
    <div className="flex flex-row justify-between items-center">
      <div className="flex flex-row gap-x-4 pl-12">
        <Legend className="bg-primary-600" text="Success" />
        <Legend className="bg-neutral-200" text="Failure" />
      </div>
      <LastUpdated updatedAt={ updatedAt } refresh={ refresh } />
    </div>
  );
}

function DownloadReportButton() {
  const perDomainReportFetcher = useFetcher();

  return (
    <Button
      text="Download report"
      icon={ {
        // TODO Spinner always uses the 'primary' theme, and there's no way to override it from here :(
        Icon: perDomainReportFetcher.state !== "idle" ? Spinner : RiDownloadLine,
        size: "!w-4 !h-4"
      } }
      className="button button-tertiary"
      size="SM"
      onClick={ () => {
        perDomainReportFetcher.load("/dashboard-data/per-domain-report")
      } }
      disabled={ perDomainReportFetcher.state !== "idle" }
    />
  );
}


type DomainOption = SelectOption<string>;
export type IntervalUnit = "month" | "week" | "day" | "hour" | "minute";
type IntervalOption = SelectOption<{ interval: number; resolution: { amount?: number, unit: IntervalUnit } }>;

// TODO domain options should be dynamic, we should let users enter domains to search for
const domainOptions: (DomainOption | string)[] = [
  { name: "All domains", value: "*" },
  "domain 1",
  "domain 2",
  "domain 3"
];

const intervalOptions: IntervalOption[] = [
  { name: "Last month", value: { interval: 4, resolution: { amount: 1, unit: "week" } } },
  { name: "Last week", value: { interval: 6, resolution: { amount: 1, unit: "day" } } },
  { name: "Last day", value: { interval: 12, resolution: { amount: 2, unit: "hour" } } },
  { name: "Last hour", value: { interval: 6, resolution: { amount: 10, unit: "minute" } } }
];

function FiltersAndDownload(
  {
    onChange,
  }: {
    onChange?: (value: any) => void;
  }
) {
  return (
    <div className="flex flex-row justify-between">
      <div className="flex flex-row gap-x-2">
        <Select
          name="domains"
          size="SM"
          className={ cx(
            "w-[156px]",
            process.env.REACT_APP_USAGE_CHARTS_SHOW_DOMAIN_FILTER !== "true" && "hidden"
          ) }
          Icon={ RiGlobalLine }
          options={ domainOptions }
          value={ domainOptions[0] }
          onChange={ onChange }
        />
        <Select
          name="interval"
          size="SM"
          className="w-[156px]"
          Icon={ RiCalendarLine }
          options={ intervalOptions }
          value={ intervalOptions[1] }
          onChange={ onChange }
        />
      </div>
      <DownloadReportButton />
    </div>
  );
}

const fakeChartDataWhileLoading = [
  {
    interval_date: new Date('2024-10-28'),
    credits_costs: 100,
    failed_requests: 10,
  },
  {
    interval_date: new Date('2024-10-27'),
    credits_costs: 200,
    failed_requests: 20,
  },
  {
    interval_date: new Date('2024-10-26'),
    credits_costs: 300,
    failed_requests: 30,
  },
  {
    interval_date: new Date('2024-10-25'),
    credits_costs: 400,
    failed_requests: 40,
  },
  {
    interval_date: new Date('2024-10-24'),
    credits_costs: 300,
    failed_requests: 30,
  },
  {
    interval_date: new Date('2024-10-23'),
    credits_costs: 200,
    failed_requests: 20,
  },
  {
    interval_date: new Date('2024-10-22'),
    credits_costs: 100,
    failed_requests: 10,
  },
];

export default function UsageHistoryCharts() {
  const formRef = useRef<HTMLFormElement>(null);
  const chartsDataFetcher = useFetcher(Fetchers.CHARTS_DATA);

  const submitForm = useCallback(() => {
    if (formRef.current) {
      chartsDataFetcher.submit(formRef.current);
    }
  }, [ chartsDataFetcher ]);

  useEffect(() => {
    if ((chartsDataFetcher.state === "idle") && (chartsDataFetcher.data === undefined)) {
      submitForm();
    }
  }, [ submitForm, chartsDataFetcher.state, chartsDataFetcher.data ]);

  // TODO error handling if charts data cannot be loaded for some reason
  //  in that case chartsDataFetcher.data.error should be present

  return (
    <chartsDataFetcher.Form
      method="GET"
      action="/dashboard-data/usage-history"
      ref={ formRef }
      className="flex flex-col gap-y-4 w-full justify-between"
    >
      <FiltersAndDownload onChange={ submitForm } />
      <UsageCharts data={ chartsDataFetcher.data?.data || fakeChartDataWhileLoading } />
      <LegendAndUpdate updatedAt={ chartsDataFetcher.state === "idle" ? chartsDataFetcher.data?.updatedAt : undefined } refresh={ submitForm } />
    </chartsDataFetcher.Form>
  );
};
