import { Title } from "react-head";

import { ReactComponent as ScraperAPILogo } from "assets/icons/scraperapi-logo.svg";
import { ReactComponent as RobotIllustration } from "assets/images/404-robot.svg";

import Button from "components/Button";

import { useUser } from "routes/dataroutes/UserData";


export default function NotFound() {

  const user = useUser();

  const linkProps = user ?
    { text: "go to dashboard", href: "/dashboard" } :
    { text: "log in", href: "/login" };

  return (
    <>
      <div className="absolute top-4 left-4 w-44">
        <ScraperAPILogo />
      </div>

      <Title>ScraperAPI - 404 page not found</Title>

      <div className="flex flex-col items-center justify-center w-full h-full space-y-8 text-center">
        <RobotIllustration />

        <div className="text-3xl text-brandDarkest dark:text-primary-800">Error 404: Robot brain malfunction. Page not found.</div>
        <div className="text-xl">Try changing your url or {linkProps.text}.</div>

        <Button { ...linkProps } />
      </div>
    </>
  );
};
