export const async_amazon_search = {
  input: "green shoes",
  result: "{\n" +
    "\t\"ads\": [\n" +
    "\t\t{\n" +
    "\t\t\t\"type\": \"editorial_recommendation\",\n" +
    "\t\t\t\"position\": 24,\n" +
    "\t\t\t\"name\": \"OtterBox COMMUTER SERIES Case for iPhone SE (3rd and 2nd gen) and iPhone 8/7 - Frustration Free Packaging - BLACK  Shamo's Crystal Clear Shock Absorption TPU Rubber Gel Case (Clear) Compatible with iPhone SE 2022 (3rd Generation), iPhone SE 2020 (2nd Generation) iPhone 8 and iPhone 7 (Clear)  Caseology Skyfall for iPhone SE Case 2022 for iPhone SE Case (2020) for iPhone 8 Case (2017) for iPhone 7 Case (2016) - Red  OtterBox DEFENDER SERIES Case for iPhone SE (3rd and 2nd gen) and iPhone 8/7 - Frustration Free Packaging - BLACK\",\n" +
    "\t\t\t\"image\": \"https://m.media-amazon.com/images/I/41UhTjBRSXL.jpg\",\n" +
    "\t\t\t\"has_prime\": true,\n" +
    "\t\t\t\"is_best_seller\": false,\n" +
    "\t\t\t\"is_amazon_choice\": false,\n" +
    "\t\t\t\"is_limited_deal\": true,\n" +
    "\t\t\t\"stars\": 4.6,\n" +
    "\t\t\t\"total_reviews\": 30455,\n" +
    "\t\t\t\"url\": \"https://www.amazon.com/OTTERBOX-COMMUTER-Case-iPhone-2nd/dp/B01K6PAZ1M/ref=sxin_25?asc_contentid=amzn1.osa.072eaf6a-2b65-4c5d-8fc8-e2c1fd79d775.ATVPDKIKX0DER.en_US&asc_contenttype=article&ascsubtag=amzn1.osa.072eaf6a-2b65-4c5d-8fc8-e2c1fd79d775.ATVPDKIKX0DER.en_US&content-id=amzn1.sym.2501e731-e00e-46aa-97f8-28a8de3ef511%3Aamzn1.sym.2501e731-e00e-46aa-97f8-28a8de3ef511&creativeASIN=B01K6PAZ1M&cv_ct_cx=iphone+8+case&cv_ct_id=amzn1.osa.072eaf6a-2b65-4c5d-8fc8-e2c1fd79d775.ATVPDKIKX0DER.en_US&cv_ct_pg=search&cv_ct_we=asin&cv_ct_wn=osp-single-source-pecos-desktop&keywords=iphone+8+case&linkCode=oas&pd_rd_i=B01K6PAZ1M&pd_rd_r=bae9220f-d157-437f-b19d-7a5fd9383519&pd_rd_w=I3qNE&pd_rd_wg=5mkHn&pf_rd_p=2501e731-e00e-46aa-97f8-28a8de3ef511&pf_rd_r=CMC486Z1AX373WD7YH90&qid=1675411169&sr=1-1-c26ac7f6-b43f-4741-a772-17cad7536576&tag=bestcont06-20\",\n" +
    "\t\t\t\"availability_quantity\": null,\n" +
    "\t\t\t\"spec\": {},\n" +
    "\t\t\t\"price_string\": \"$13.00$10.99$15.99$28.43\",\n" +
    "\t\t\t\"price_symbol\": \"$\",\n" +
    "\t\t\t\"price\": 13\n" +
    "\t\t}\n" +
    "\t],\n" +
    "\t\"amazons_choice\": [],\n" +
    "\t\"results\": [\n" +
    "\t\t{\n" +
    "\t\t\t\"type\": \"search_product\",\n" +
    "\t\t\t\"position\": 1,\n" +
    "\t\t\t\"name\": \"JETech Silicone Case for iPhone SE 3/2 (2022/2020 Edition), iPhone 8 and iPhone 7, 4.7-Inch, Silky-Soft Touch Full-Body Protective Case, Shockproof Cover with Microfiber Lining (Black)\",\n" +
    "\t\t\t\"image\": \"https://m.media-amazon.com/images/I/61gnLelc9NL.jpg\",\n" +
    "\t\t\t\"has_prime\": true,\n" +
    "\t\t\t\"is_best_seller\": false,\n" +
    "\t\t\t\"is_amazon_choice\": true,\n" +
    "\t\t\t\"is_limited_deal\": false,\n" +
    "\t\t\t\"stars\": 4.4,\n" +
    "\t\t\t\"total_reviews\": 27251,\n" +
    "\t\t\t\"url\": \"https://www.amazon.com/JETech-Silky-Soft-Protective-Shockproof-Microfiber/dp/B07QNR8LGW/ref=sr_1_1?keywords=iphone+8+case&qid=1675411169&sr=8-1\",\n" +
    "\t\t\t\"availability_quantity\": null,\n" +
    "\t\t\t\"spec\": {},\n" +
    "\t\t\t\"price_string\": \"$9.99\",\n" +
    "\t\t\t\"price_symbol\": \"$\",\n" +
    "\t\t\t\"price\": 9.99\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"type\": \"search_product\",\n" +
    "\t\t\t\"position\": 7,\n" +
    "\t\t\t\"name\": \"Speck Products CandyShell Grip iPhone SE (2022) Case| iPhone SE (2020)| iPhone 8| iPhone 7 - Charcoal Grey/Dark Poppy Red\",\n" +
    "\t\t\t\"image\": \"https://m.media-amazon.com/images/I/71ihPDYKoXL.jpg\",\n" +
    "\t\t\t\"section_name\": null,\n" +
    "\t\t\t\"has_prime\": true,\n" +
    "\t\t\t\"is_best_seller\": false,\n" +
    "\t\t\t\"is_amazon_choice\": false,\n" +
    "\t\t\t\"is_limited_deal\": false,\n" +
    "\t\t\t\"stars\": 4.7,\n" +
    "\t\t\t\"total_reviews\": 13375,\n" +
    "\t\t\t\"url\": \"https://www.amazon.com/Speck-Products-CandyShell-Phone-iPhone/dp/B0756XVXK1/ref=sxin_15_ac_d_mf_brs?ac_md=7-3-U3BlY2s%3D-ac_d_mf_brs_brs&content-id=amzn1.sym.1ad31f34-ba12-4dca-be4b-f62f7f5bb10d%3Aamzn1.sym.1ad31f34-ba12-4dca-be4b-f62f7f5bb10d&cv_ct_cx=iphone+8+case&keywords=iphone+8+case&pd_rd_i=B0756XVXK1&pd_rd_r=bae9220f-d157-437f-b19d-7a5fd9383519&pd_rd_w=hCD41&pd_rd_wg=5mkHn&pf_rd_p=1ad31f34-ba12-4dca-be4b-f62f7f5bb10d&pf_rd_r=CMC486Z1AX373WD7YH90&qid=1675411169&sr=1-4-8b2f235a-dddf-4202-bbb9-592393927392\",\n" +
    "\t\t\t\"availability_quantity\": null,\n" +
    "\t\t\t\"spec\": {},\n" +
    "\t\t\t\"price_string\": \"$16.88\",\n" +
    "\t\t\t\"price_symbol\": \"$\",\n" +
    "\t\t\t\"price\": 16.88\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"type\": \"search_product\",\n" +
    "\t\t\t\"position\": 8,\n" +
    "\t\t\t\"name\": \"xiwxi iPhone SE Case 2022/3rd/2020,iPhone 8/7 Case,with [2xTempered Glass Screen Protector] [360 Full Body Shockproof] [Heavy Dropproof],Hard PC+Soft Silicone TPU+Glass Screen Phone Case-Black\",\n" +
    "\t\t\t\"image\": \"https://m.media-amazon.com/images/I/81VS1nNTEGL.jpg\",\n" +
    "\t\t\t\"section_name\": null,\n" +
    "\t\t\t\"has_prime\": true,\n" +
    "\t\t\t\"is_best_seller\": false,\n" +
    "\t\t\t\"is_amazon_choice\": false,\n" +
    "\t\t\t\"is_limited_deal\": false,\n" +
    "\t\t\t\"stars\": 4.6,\n" +
    "\t\t\t\"total_reviews\": 1759,\n" +
    "\t\t\t\"url\": \"https://www.amazon.com/xiwxi-2xTempered-Protector-Shockproof-Case-Black/dp/B09VBHHZ78/ref=sxin_15_ac_d_mf_brs?ac_md=4-4-eGl3eGk%3D-ac_d_mf_brs_brs&content-id=amzn1.sym.1ad31f34-ba12-4dca-be4b-f62f7f5bb10d%3Aamzn1.sym.1ad31f34-ba12-4dca-be4b-f62f7f5bb10d&cv_ct_cx=iphone+8+case&keywords=iphone+8+case&pd_rd_i=B09VBHHZ78&pd_rd_r=bae9220f-d157-437f-b19d-7a5fd9383519&pd_rd_w=hCD41&pd_rd_wg=5mkHn&pf_rd_p=1ad31f34-ba12-4dca-be4b-f62f7f5bb10d&pf_rd_r=CMC486Z1AX373WD7YH90&qid=1675411169&sr=1-5-8b2f235a-dddf-4202-bbb9-592393927392\",\n" +
    "\t\t\t\"availability_quantity\": null,\n" +
    "\t\t\t\"spec\": {},\n" +
    "\t\t\t\"price_string\": \"$14.99\",\n" +
    "\t\t\t\"price_symbol\": \"$\",\n" +
    "\t\t\t\"price\": 14.99\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"type\": \"search_product\",\n" +
    "\t\t\t\"position\": 9,\n" +
    "\t\t\t\"name\": \"LeYi for iPhone 8 Case, iPhone 6s Case, iPhone 7 Case, iPhone 6 Case with 2 PCS Glass Screen Protector for Women Girls Boys, Liquid Silicone Shockproof Phone Case Cover with Stand for iPhone 8, Green\",\n" +
    "\t\t\t\"image\": \"https://m.media-amazon.com/images/I/61N3UK4wR1L.jpg\",\n" +
    "\t\t\t\"has_prime\": true,\n" +
    "\t\t\t\"is_best_seller\": false,\n" +
    "\t\t\t\"is_amazon_choice\": false,\n" +
    "\t\t\t\"is_limited_deal\": false,\n" +
    "\t\t\t\"stars\": 4.4,\n" +
    "\t\t\t\"total_reviews\": 1104,\n" +
    "\t\t\t\"url\": \"https://www.amazon.com/LeYi-iPhone-Protector-Silicone-Shockproof/dp/B09TR8DT8L/ref=sr_1_4?keywords=iphone+8+case&qid=1675411169&sr=8-4\",\n" +
    "\t\t\t\"availability_quantity\": null,\n" +
    "\t\t\t\"spec\": {},\n" +
    "\t\t\t\"price_string\": \"$6.99\",\n" +
    "\t\t\t\"price_symbol\": \"$\",\n" +
    "\t\t\t\"price\": 6.99\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"type\": \"search_product\",\n" +
    "\t\t\t\"position\": 15,\n" +
    "\t\t\t\"name\": \"JETech Case for iPhone SE 3/2 (2022/2020 Edition), iPhone 8 and iPhone 7, 4.7-Inch, Non-Yellowing Shockproof Phone Bumper Cover, Anti-Scratch Clear Back (Clear)\",\n" +
    "\t\t\t\"image\": \"https://m.media-amazon.com/images/I/617WNit9-8L.jpg\",\n" +
    "\t\t\t\"has_prime\": true,\n" +
    "\t\t\t\"is_best_seller\": false,\n" +
    "\t\t\t\"is_amazon_choice\": false,\n" +
    "\t\t\t\"is_limited_deal\": false,\n" +
    "\t\t\t\"stars\": 4.6,\n" +
    "\t\t\t\"total_reviews\": 121111,\n" +
    "\t\t\t\"url\": \"https://www.amazon.com/JETech-iPhone-4-7-Inch-Shock-Absorption-Anti-Scratch/dp/B01KF0FJX2/ref=sr_1_10?keywords=iphone+8+case&qid=1675411169&sr=8-10\",\n" +
    "\t\t\t\"availability_quantity\": null,\n" +
    "\t\t\t\"spec\": {},\n" +
    "\t\t\t\"price_string\": \"$9.99\",\n" +
    "\t\t\t\"price_symbol\": \"$\",\n" +
    "\t\t\t\"price\": 9.99\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"type\": \"search_product\",\n" +
    "\t\t\t\"position\": 16,\n" +
    "\t\t\t\"name\": \"Spigen Ultra Hybrid (Anti-Yellowing PC Back) Designed for iPhone SE 2022 Case/iPhone SE 3 Case 2022 / iPhone SE 2020 Case/iPhone 8 Case/iPhone 7 Case - Black\",\n" +
    "\t\t\t\"image\": \"https://m.media-amazon.com/images/I/51HnUHLG7eL.jpg\",\n" +
    "\t\t\t\"section_name\": \"Climate Pledge Friendly\",\n" +
    "\t\t\t\"has_prime\": true,\n" +
    "\t\t\t\"is_best_seller\": false,\n" +
    "\t\t\t\"is_amazon_choice\": false,\n" +
    "\t\t\t\"is_limited_deal\": false,\n" +
    "\t\t\t\"stars\": 4.6,\n" +
    "\t\t\t\"total_reviews\": 72783,\n" +
    "\t\t\t\"url\": \"https://www.amazon.com/gp/slredirect/picassoRedirect.html/ref=pa_sp_search_thematic_aps_sr_pg1_1?ie=UTF8&bwType=search&bwAsin=B01M1SCIOV&qualifier=1675411169&id=3243969273986750&widgetName=sp_search_thematic&url=%2FSpigen-Hybrid-Generation-Cushion-Technology%2Fdp%2FB01M1SCIOV%2Fref%3Dsxin_24_pa_sp_search_thematic-asin_sspa%3Fcontent-id%3Damzn1.sym.139755fc-e653-4f77-9e36-97d145a75e7c%253Aamzn1.sym.139755fc-e653-4f77-9e36-97d145a75e7c%26cv_ct_cx%3Diphone%2B8%2Bcase%26keywords%3Diphone%2B8%2Bcase%26pd_rd_i%3DB01M1SCIOV%26pd_rd_r%3Dbae9220f-d157-437f-b19d-7a5fd9383519%26pd_rd_w%3DNz97e%26pd_rd_wg%3D5mkHn%26pf_rd_p%3D139755fc-e653-4f77-9e36-97d145a75e7c%26pf_rd_r%3DCMC486Z1AX373WD7YH90%26qid%3D1675411169%26sr%3D1-1-4a643ae4-6005-4b15-bc31-2c5125e2b25b-spons%26psc%3D1\",\n" +
    "\t\t\t\"availability_quantity\": null,\n" +
    "\t\t\t\"spec\": {},\n" +
    "\t\t\t\"price_string\": \"$13.99\",\n" +
    "\t\t\t\"price_symbol\": \"$\",\n" +
    "\t\t\t\"price\": 13.99\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"type\": \"search_product\",\n" +
    "\t\t\t\"position\": 17,\n" +
    "\t\t\t\"name\": \"Spigen Tough Armor [2nd Generation] Designed for iPhone 8 Case (2017) / Designed for iPhone 7 Case (2016) - Black\",\n" +
    "\t\t\t\"image\": \"https://m.media-amazon.com/images/I/61B0XM+bXQL.jpg\",\n" +
    "\t\t\t\"section_name\": \"Climate Pledge Friendly\",\n" +
    "\t\t\t\"has_prime\": true,\n" +
    "\t\t\t\"is_best_seller\": false,\n" +
    "\t\t\t\"is_amazon_choice\": false,\n" +
    "\t\t\t\"is_limited_deal\": false,\n" +
    "\t\t\t\"stars\": 4.6,\n" +
    "\t\t\t\"total_reviews\": 37074,\n" +
    "\t\t\t\"url\": \"https://www.amazon.com/gp/slredirect/picassoRedirect.html/ref=pa_sp_search_thematic_aps_sr_pg1_2?ie=UTF8&bwType=search&bwAsin=B07481XLQN&qualifier=1675411169&id=3243969273986750&widgetName=sp_search_thematic&url=%2FSpigen-Generation-Kickstand-Protection-Technology%2Fdp%2FB07481XLQN%2Fref%3Dsxin_24_pa_sp_search_thematic-asin_sspa%3Fcontent-id%3Damzn1.sym.139755fc-e653-4f77-9e36-97d145a75e7c%253Aamzn1.sym.139755fc-e653-4f77-9e36-97d145a75e7c%26cv_ct_cx%3Diphone%2B8%2Bcase%26keywords%3Diphone%2B8%2Bcase%26pd_rd_i%3DB07481XLQN%26pd_rd_r%3Dbae9220f-d157-437f-b19d-7a5fd9383519%26pd_rd_w%3DNz97e%26pd_rd_wg%3D5mkHn%26pf_rd_p%3D139755fc-e653-4f77-9e36-97d145a75e7c%26pf_rd_r%3DCMC486Z1AX373WD7YH90%26qid%3D1675411169%26sr%3D1-2-4a643ae4-6005-4b15-bc31-2c5125e2b25b-spons%26psc%3D1\",\n" +
    "\t\t\t\"availability_quantity\": null,\n" +
    "\t\t\t\"spec\": {},\n" +
    "\t\t\t\"price_string\": \"$15.99\",\n" +
    "\t\t\t\"price_symbol\": \"$\",\n" +
    "\t\t\t\"price\": 15.99\n" +
    "\t\t},\n" +
    "\t\t{\n" +
    "\t\t\t\"type\": \"search_product\",\n" +
    "\t\t\t\"position\": 18,\n" +
    "\t\t\t\"name\": \"Spigen Ultra Hybrid [2nd Generation] Designed for iPhone 8 Plus Case (2017) / Designed for iPhone 7 Plus Case (2016) - Black\",\n" +
    "\t\t\t\"image\": \"https://m.media-amazon.com/images/I/61rPT5Jsh8L.jpg\",\n" +
    "\t\t\t\"section_name\": \"Climate Pledge Friendly\",\n" +
    "\t\t\t\"has_prime\": true,\n" +
    "\t\t\t\"is_best_seller\": false,\n" +
    "\t\t\t\"is_amazon_choice\": false,\n" +
    "\t\t\t\"is_limited_deal\": false,\n" +
    "\t\t\t\"stars\": 4.6,\n" +
    "\t\t\t\"total_reviews\": 30062,\n" +
    "\t\t\t\"url\": \"https://www.amazon.com/gp/slredirect/picassoRedirect.html/ref=pa_sp_search_thematic_aps_sr_pg1_3?ie=UTF8&bwType=search&bwAsin=B01N9D7DP2&qualifier=1675411169&id=3243969273986750&widgetName=sp_search_thematic&url=%2FSpigen-Generation-Backing-Cushion-Technology%2Fdp%2FB01N9D7DP2%2Fref%3Dsxin_24_pa_sp_search_thematic-asin_sspa%3Fcontent-id%3Damzn1.sym.139755fc-e653-4f77-9e36-97d145a75e7c%253Aamzn1.sym.139755fc-e653-4f77-9e36-97d145a75e7c%26cv_ct_cx%3Diphone%2B8%2Bcase%26keywords%3Diphone%2B8%2Bcase%26pd_rd_i%3DB01N9D7DP2%26pd_rd_r%3Dbae9220f-d157-437f-b19d-7a5fd9383519%26pd_rd_w%3DNz97e%26pd_rd_wg%3D5mkHn%26pf_rd_p%3D139755fc-e653-4f77-9e36-97d145a75e7c%26pf_rd_r%3DCMC486Z1AX373WD7YH90%26qid%3D1675411169%26sr%3D1-3-4a643ae4-6005-4b15-bc31-2c5125e2b25b-spons%26psc%3D1\",\n" +
    "\t\t\t\"availability_quantity\": null,\n" +
    "\t\t\t\"spec\": {},\n" +
    "\t\t\t\"price_string\": \"$11.99\",\n" +
    "\t\t\t\"price_symbol\": \"$\",\n" +
    "\t\t\t\"price\": 11.99\n" +
    "\t\t}\n" +
    "\t],\n" +
    "\t\"explore_more_items\": [],\n" +
    "\t\"pagination\": [\n" +
    "\t\t\"https://www.amazon.com/s?k=iphone+8+case&page=2&qid=1675411169&ref=sr_pg_2\",\n" +
    "\t\t\"https://www.amazon.com/s?k=iphone+8+case&page=3&qid=1675411169&ref=sr_pg_3\",\n" +
    "\t\t\"https://www.amazon.com/s?k=iphone+8+case&page=4&qid=1675411169&ref=sr_pg_4\",\n" +
    "\t\t\"https://www.amazon.com/s?k=iphone+8+case&page=5&qid=1675411169&ref=sr_pg_5\",\n" +
    "\t\t\"https://www.amazon.com/s?k=iphone+8+case&page=6&qid=1675411169&ref=sr_pg_6\",\n" +
    "\t\t\"https://www.amazon.com/s?k=iphone+8+case&page=7&qid=1675411169&ref=sr_pg_7\",\n" +
    "\t\t\"https://www.amazon.com/s?k=iphone+8+case&page=8&qid=1675411169&ref=sr_pg_8\",\n" +
    "\t\t\"https://www.amazon.com/s?k=iphone+8+case&page=9&qid=1675411169&ref=sr_pg_9\",\n" +
    "\t\t\"https://www.amazon.com/s?k=iphone+8+case&page=10&qid=1675411169&ref=sr_pg_10\",\n" +
    "\t\t\"https://www.amazon.com/s?k=iphone+8+case&page=11&qid=1675411169&ref=sr_pg_11\",\n" +
    "\t\t\"https://www.amazon.com/s?k=iphone+8+case&page=12&qid=1675411169&ref=sr_pg_12\",\n" +
    "\t\t\"https://www.amazon.com/s?k=iphone+8+case&page=13&qid=1675411169&ref=sr_pg_13\",\n" +
    "\t\t\"https://www.amazon.com/s?k=iphone+8+case&page=14&qid=1675411169&ref=sr_pg_14\",\n" +
    "\t\t\"https://www.amazon.com/s?k=iphone+8+case&page=15&qid=1675411169&ref=sr_pg_15\",\n" +
    "\t\t\"https://www.amazon.com/s?k=iphone+8+case&page=16&qid=1675411169&ref=sr_pg_16\",\n" +
    "\t\t\"https://www.amazon.com/s?k=iphone+8+case&page=17&qid=1675411169&ref=sr_pg_17\",\n" +
    "\t\t\"https://www.amazon.com/s?k=iphone+8+case&page=18&qid=1675411169&ref=sr_pg_18\",\n" +
    "\t\t\"https://www.amazon.com/s?k=iphone+8+case&page=19&qid=1675411169&ref=sr_pg_19\",\n" +
    "\t\t\"https://www.amazon.com/s?k=iphone+8+case&page=20&qid=1675411169&ref=sr_pg_20\"\n" +
    "\t]\n" +
    "}"
};
