import ReactCodeMirror, { ReactCodeMirrorProps } from "@uiw/react-codemirror";
import { SupportedLanguages } from "routes/dashboard/ApiPlaygroundTypes";

import {java} from "@codemirror/lang-java";
import {javascript} from "@codemirror/lang-javascript";
import {php} from "@codemirror/lang-php";
import {html} from "@codemirror/lang-html";

import { basicSetup as CMBasicSetup } from "codemirror"
import { cobalt } from "thememirror"

// At the time of writing codemirror 6 has no ruby and shell script support, so I
// used legacy mode from codemirror 5
//
// https://github.com/codemirror/legacy-modes

import {StreamLanguage} from "@codemirror/language";
import {shell} from "@codemirror/legacy-modes/mode/shell";
import {python} from "@codemirror/legacy-modes/mode/python";
import {ruby} from "@codemirror/legacy-modes/mode/ruby";

import { cx } from "utils";


export type CodeViewLanguages = SupportedLanguages | "json" | "html";

export interface CodeViewProps extends ReactCodeMirrorProps {
  language: CodeViewLanguages;
  content?: string;
}

export const CodeView = (
  {
    language,
    content,
    placeholder,
    className,
    basicSetup,
    ...codeMirrorProps
  }: CodeViewProps
) => {
  const languageExtension = (() => {
    switch (language) {
      case 'curl': return StreamLanguage.define(shell);
      case 'python': return StreamLanguage.define(python);
      case 'nodejs': return javascript();
      case 'php': return php();
      case 'ruby': return StreamLanguage.define(ruby);
      case 'java': return java();
      //-----
      case 'json': return javascript();
      case 'html': return html();
    }
  })();
  const extensions = [ languageExtension ];

  return (
    <ReactCodeMirror
      value={content}
      readOnly={true}
      extensions={ basicSetup ? extensions : [ ...extensions, CMBasicSetup ] }
      // theme="dark"
      // maxWidth="100rem"
      theme={cobalt}
      // minHeight="10rem"
      placeholder={placeholder}
      className={ cx("codeview-scrollbar", className) }
      basicSetup={ basicSetup }
      { ...codeMirrorProps }
    />
  )
}
