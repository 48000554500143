import Button from "components/Button";

interface Props {
  message: string;
  ctaText: string;
  ctaHref: string;
}

export default function Panel({ message, ctaText, ctaHref }: Props) {
  return (
    <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between px-8 py-4 bg-white border border-borderColor dark:border-neutral-200 text-gray dark:text-neutral-600 gap-4 lg:gap-x-6">
      {message}
      {/* TODO button should or should not pass backgroundLocation.. we'll need another property for that */}
      <Button text={ctaText} href={ctaHref} className="button button-secondary whitespace-nowrap" />
    </div>
  );
}
