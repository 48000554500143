import { useState } from "react";

import { RiEyeOffLine, RiEyeLine } from "@remixicon/react"

import InputFieldWithValidation from "components/InputFieldWithValidation";


function ShowPasswordIcon(
  {
    showPassword,
    onClick
  }: {
    showPassword: boolean,
    onClick: () => void
  }
) {
  const iconProps = {
    className: "w-5 h-5 cursor-pointer text-lightGray",
    onClick: onClick
  }
  return showPassword ?
    <RiEyeLine { ...iconProps } /> :
    <RiEyeOffLine { ...iconProps } />;
}

export default function PasswordInput() {
  const [ showPassword, setShowPassword ] = useState(false);


  return <InputFieldWithValidation
    name="password"
    label="Password"
    type={ showPassword ? "text" : "password" }
    placeholder="Please enter your password"
    required
    minLength={ 5 }
    tooShortErrorMessage="Your password has to be at least 5 characters long."
    TrailingElement={ <ShowPasswordIcon showPassword={ showPassword } onClick={() => setShowPassword(oldState => !oldState)} /> }
  />;
};
