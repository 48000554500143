import { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import { ServicesContextType, useServices } from "providers/ServicesProvider";


type UCServiceInfo = {
  id: string,
  name: string,
  consent: {
    status: boolean
  }
};

type LocalStorageServiceInfo = {
  id: string,
  status: boolean
};

export type UCWindow = Window & {
  UC_UI?: {
    getServicesBaseInfo?: () => UCServiceInfo[];
    isInitialized?: () => boolean;
  }
};

type UsercentricsContextType = ServicesContextType & {
  isInitialized: boolean;
  localStorage: LocalStorageServiceInfo[];
};

export const UsercentricsContext = createContext<UsercentricsContextType>({
  isInitialized: false,
  localStorage: [],
  loadedServices: [],
  findService: () => undefined,
  loadService: () => false,
  unloadService: () => false
});

export default function UsercentricsProvider({ children }: { children: ReactNode }) {

  const [ isInitialized, setInitialized ] = useState(false);
  const { loadedServices, findService, loadService, unloadService } = useServices();


  const initializedCallback = useCallback(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    if ((window as UCWindow).UC_UI?.isInitialized?.()) {
      setInitialized(true);
    } else {
      window.addEventListener('UC_UI_INITIALIZED', initializedCallback)
    }
  }, [ initializedCallback ]);

  const localStorage = useMemo(() => {
    const ucSettings = window.localStorage?.getItem("uc_settings");
    if (ucSettings) {
      try {
        const ucSettingsJSON = JSON.parse(ucSettings) as { services: { id: string, status: boolean }[] };
        return ucSettingsJSON.services.map(({ id, status }) => ({ id, status }));
      } catch (err) {
        // ignore errors
      }
    }
    return [];
  }, []);

  return (
    <UsercentricsContext.Provider
      value={{
        isInitialized,
        localStorage,
        loadedServices,
        findService,
        loadService,
        unloadService
      }}>
      {children}
    </UsercentricsContext.Provider>
  )
};
