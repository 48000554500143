import _ from "lodash";

import { UserType } from "providers/UserProvider";


const getNameFromEmail = (user: UserType): string => {
  return user?.email ? _.capitalize(user.email.split("@")[0]) : "";
}

export const getUsername = (user: UserType | null): string => {
  return user ?
    ((user.firstName || user.lastName) ? `${ user.firstName } ${ user.lastName }` : getNameFromEmail(user)) :
    "";
};

export const getUserInitials = (user?: UserType | null, username?: string): string => {
  return user ? getUsername(user)[0] : (username ? username[0] : "");
};
