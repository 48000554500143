import { Listbox } from "@headlessui/react";
import { ListboxButton, listboxButtonClasses, ListboxElement, listboxOptionClasses, listboxOptionsClasses, OptionsTransition, placeHolderListboxButtonClasses, placeHolderListboxOptionClasses, shortListboxOptionsClasses } from "components/Listbox";

export const pickOption = (options: {value: string|undefined, text: string}[], valueToFind: string|undefined) => {
  const option = options.find(({value, text}) => value === valueToFind);
  return option?.text || 'Please select';
}

type AdditionalOptionsListboxProps = {
  value: string|undefined,
  options: {value: string|undefined, text: string}[],
  callback: (selected: string|undefined) => void,
  shortList?: boolean,
  buttonTestId?: string
};

export const AdditionalOptionsListbox = ({value, options, callback, shortList = false, buttonTestId}: AdditionalOptionsListboxProps) => {
  return (<Listbox value={value} onChange={callback}>
    <div className="w-full shadow">
      <Listbox.Button className={value ? listboxButtonClasses : placeHolderListboxButtonClasses} data-testid={buttonTestId}>
        <ListboxButton content={pickOption(options, value)} />
      </Listbox.Button>
      <OptionsTransition>
          <Listbox.Options className={shortList ? shortListboxOptionsClasses : listboxOptionsClasses}>
              {
                options.map((option) => {
                  return (
                    <Listbox.Option key={option.value || 'none'} className={option.value ? listboxOptionClasses : placeHolderListboxOptionClasses} value={option.value}>
                      <ListboxElement primaryText={option.text}/>
                    </Listbox.Option>
                  );
                })
              }
          </Listbox.Options>
      </OptionsTransition>
    </div>
  </Listbox>);
};
