import _ from "lodash";

import FeatureSwitchCheck from "./FeatureSwitchCheck";
import { UserType } from "providers/UserProvider";


type UserIdOrEmail = {
  userId?: number;
  email?: string;
};

export default class UserInList extends FeatureSwitchCheck<UserIdOrEmail[]> {

  check(user: UserType): boolean {
    if (this.featureValue!.length === 0) {
      return true;
    } else {
      return _.some(this.featureValue!, idOrEmail => (idOrEmail.userId === user.id) || (idOrEmail.email === user.email));
    }
  }

  protected parseFeatureValue(featureSwitchValue: string | undefined): UserIdOrEmail[] | undefined {
    const idsOrEmails: UserIdOrEmail[] = [];

    if (featureSwitchValue) {
      const userList = featureSwitchValue.split(',');
      for (const user of userList) {
        const userId = Number.parseInt(user);
        if (userId) {
          idsOrEmails.push({ userId });
        } else {
          idsOrEmails.push({ email: user });
        }
      }
    }

    return idsOrEmails;
  }

}
