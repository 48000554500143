export const formatCreditsSpent = (spent: number|string|undefined|null): string => {
  if (spent === null || spent === undefined) {
    return '-';
  } else if (Number.isInteger(spent) 
    || (typeof spent === 'string' && Number.isInteger(Number.parseInt(spent as string, 10)))
    ) {
    const creditCredits = (spent === 1 || spent === "1") ? 'credit' : 'credits';
    return `${spent} ${creditCredits}`;
  } else {
    const num = Number.parseFloat(spent as string);
    return `${num.toFixed(2)} credits`;
  }
}
