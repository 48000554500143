import { useFeatureSwitch } from "components/FeatureSwitch";

import { ServiceScript } from "providers/ServicesProvider/ServiceScriptProvider";
import UsercentricsScriptProvider from "providers/UsercentricsProvider/UsercentricsScriptProvider";


// TODO should create a new custom DPS in Usercentrics for Customer.IO
export const CUSTOMER_IO_SERVICE_NAME = "ScraperAPI";

export default function CustomerIOProvider() {

  const cioInAppMessagesEnabled = useFeatureSwitch("REACT_APP_CIO_IN_APP_MESSAGES_USERS");

  const initCustomerIO = () => {
    (window as any)._cioInit?.();
  }

  const unloadCustomerIO = () => {
    let cioTrackerScript = document.getElementById("cio-tracker");
    cioTrackerScript?.remove();
    (window as any)._cio = undefined;
  }

  const customerIOScript: ServiceScript = {
    name: CUSTOMER_IO_SERVICE_NAME,
    domId: "cio-snippet",
    src: "./cioInApp.js",
    onLoadCallback: initCustomerIO,
    onUnloadCallback: unloadCustomerIO
  };

  return (
    <>
      {cioInAppMessagesEnabled && (
        <UsercentricsScriptProvider
          {...customerIOScript}
        />
      )}
    </>
  );

};

export function useCustomerIO() {

  return {
    // Generic API
    identify: (id: string) => {
      (window as any)._cio?.identify?.({ id: id });
    },
    page: (pageName: string = window.location.href, data: Record<string, unknown> = {}) => {
      (window as any)._cio?.page?.(pageName, data);
    },
    track: (eventId: string, data: Record<string, unknown>) => {
      (window as any)._cio?.track?.(eventId, data);
    },
    // Special events
    runJobNowButtonPressed: (projectId: number) => {
      (window as any)._cio?.track?.("run_job_now_button_pressed", { projectId } );
    },
  };

}
