import { useActionData } from "react-router-dom";

import AuthForm from "components/AuthForm";
import AuthPage, { AuthPageLink, AuthPageLinks } from "components/AuthPage";


export default function ForgotPassword() {
  const resetEmailSentTo = useActionData();

  const goBackLink =
    <AuthPageLinks>
      <AuthPageLink text="" link={{ to: "/login", text: "Go Back" }}/>
    </AuthPageLinks>;

  if (typeof resetEmailSentTo === 'string') {
    return (
      <AuthPage
        title="Forgot Password"
        heading={[ "Check your inbox", `An email has been sent to ${resetEmailSentTo} with a link to reset your password` ]}
      >

        {goBackLink}

      </AuthPage>
    );
  }

  return (
    <AuthPage
      title="Forgot Password"
      heading={[ "Reset password", "Enter your email below to receive password reset instructions." ]}
    >
      <AuthForm
        askForPassword={false}
        emailButtonText="Reset password"
        defaultErrorMessage="Something went wrong"
      />

      {goBackLink}

    </AuthPage>
  );
};
