import { useCallback } from "react";

import { useZoho, useZohoGCScript } from "providers/ZohoProvider";


const SUPPORT_WIDGET_USE = (() => {
  if ((process.env.REACT_APP_SUPPORT_WIDGET_USE === "zoho") &&
    (process.env.REACT_APP_ZOHO_GC_SUPPORT_SCRIPT)) {
    return "zoho-embedded";
  }

  if ((process.env.REACT_APP_SUPPORT_WIDGET_USE === "zoho") &&
    (process.env.REACT_APP_ZOHO_GC_SUPPORT_URL)) {
    return "zoho";
  }

  return "email";
})();


export interface SupportWidget {
  showSupportForm: (email?: string) => void;
  hideSupportForm?: () => void;
  showSalesForm: (email?: string) => void;
  hideSalesForm?: () => void;
}


export function useSupportWidget() {

  const zohoWidget = useZoho();
  const zohoEmbeddedWidget = useZohoGCScript();
  const emailWidget: SupportWidget = {
    showSupportForm: (email?: string) => {
      window.open("mailto:support@scraperapi.com", "_blank");
    },

    showSalesForm: (email?: string) => {
      window.open("mailto:sales@scraperapi.com", "_blank");
    }
  };

  let supportWidget = emailWidget;
  if (SUPPORT_WIDGET_USE === "zoho-embedded") {
    supportWidget = zohoEmbeddedWidget;
  } else if (SUPPORT_WIDGET_USE === "zoho") {
    supportWidget = zohoWidget;
  }

  const showSupportForm = useCallback((email?: string) => {
    supportWidget?.showSupportForm(email);
  }, [ supportWidget ]);

  const hideSupportForm = useCallback(() => {
    supportWidget?.hideSupportForm?.();
  }, [ supportWidget ]);

  const showSalesForm = useCallback((email?: string) => {
    supportWidget?.showSalesForm(email);
  }, [ supportWidget ]);

  const hideSalesForm = useCallback(() => {
    supportWidget?.hideSalesForm?.();
  }, [ supportWidget ]);


  return {
    showSupportForm,
    hideSupportForm,
    showSalesForm,
    hideSalesForm
  } as SupportWidget;

}
