import React from "react";
import { NavLink } from "react-router-dom";

import Card from "components/Card";

import { cx } from "utils";


interface IQuickLinkCardProps {
  Icon: React.ComponentType<any>;
  title: string;
  description: string;
  disabled?: boolean;
}

function QuickLinkCardContent({
  Icon,
  title,
  description,
  disabled = false
}: IQuickLinkCardProps) {
  const iconStyle = cx(
    "w-12 h-12 shrink-0 flex items-center justify-center",
    disabled ? "bg-[#1a22e4]/[0.08] dark:bg-neutral-50 text-primary-600 dark:text-neutral-500" : "bg-[#1a22e4]/[0.08] dark:bg-accent-100 text-primary-600 dark:text-accent-600"
  );

  const titleStyle = cx(
    "font-semibold",
    disabled ? "dark:text-neutral-500" : "dark:text-neutral-900"
  );

  const descriptionStyle = cx(
    "text-sm",
    disabled ? "text-gray dark:text-neutral-500" : "text-gray dark:text-neutral-700"
  );

  return (
    <Card hoverable={!disabled} className="w-72 h-full hover:bg-slate-50 dark:hover:bg-accent-50/50">
      <div className="flex flex-col space-y-4">
        <div className="space-x-3 flex items-center">
          <div className={iconStyle}>
            <Icon className="w-5 dark:w-6 h-5 dark:h-6" />
          </div>
          <div className={titleStyle}>{title}</div>
        </div>
        <div className={descriptionStyle}>{description}</div>
      </div>
    </Card>
  );
}

interface QuickLinkCardProps extends IQuickLinkCardProps {
  to: string;
}

export const QuickLinkCard = ({Icon, title, description, to, disabled = false}: QuickLinkCardProps) => {
  const content = (
    <QuickLinkCardContent
      Icon={Icon}
      title={title}
      description={description}
      disabled={disabled}
    />
  );

  return disabled ? (
    <div className="cursor-not-allowed">{content}</div>
  ) : (
    <NavLink to={to}>{content}</NavLink>
  );
};
