import { ReactComponent as CheckmarkIcon } from "assets/icons/checkmark.svg";
import { cx } from "utils";
import { ReactNode } from 'react';


interface ICheckedComponentProps {
  className?: string;
  children?: ReactNode
}

export default function CheckedComponent({
  className,
  children
}: ICheckedComponentProps) {

  return (
    <div className={cx(className, "flex gap-x-4")}>
      <span><CheckmarkIcon className="h-full"/></span>
      <span>{children}</span>
    </div>
  );
};
