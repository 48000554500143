import { ReactComponent as StatusDot } from "assets/icons/project-status-dot.svg";
import { ProjectConfig } from "providers/HostedScrapingProvider";
import { cx } from "utils";
import { COLOR_OK, COLOR_PAUSED } from "./ProjectColors";

type IProjectStatusProps = {
  project: ProjectConfig,
  className?: string,
}

export const ProjectStatus = ({project, className}: IProjectStatusProps) => {
  const status = project.enabled ? 'Scheduled' : 'Not scheduled';
  const color = project.enabled ? COLOR_OK : COLOR_PAUSED;

  return (<div className={cx(className, "flex flex-row")}>
    <div className="flex place-items-center pr-2">
      <StatusDot fill={color} stroke={color}/>
    </div>
    <div>
      {status}
    </div>
  </div>);
}
