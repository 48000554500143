import _ from "lodash";


export const formDataToStructuredObject = (formData: FormData): any => {
  const jsonObj = {};
  formData.forEach((value, key) => {
    if (_.has(jsonObj, key)) {
      const existingValue = _.get(jsonObj, key);
      if (Array.isArray(existingValue)) {
        existingValue.push(value);
      } else {
        _.set(jsonObj, key, [ existingValue, value ]);
      }
    } else {
      _.set(jsonObj, key, value);
    }
  });
  return jsonObj;
};
