import { DocumentTextIcon } from "@heroicons/react/outline";
import scraperApi from "api";
import { LinkText } from "components/LinkText";
import { downloadFileUsingTheBlobTrick } from "utils/downloadFileUsingTheBlobTrick";

const handleDownloadTheCurrentInput = (inputKey: string) => () => {
  scraperApi.hostedScraping.downloadProjectInput(inputKey)
    .then(downloadFileUsingTheBlobTrick('project_input.txt', 'text/plain'))
}

export const BeatifulDownloadUploadedFile = ({config}: any) => (
  <div>
     { config.inputKey !== null && (<div>
        <div >
          <button onClick={handleDownloadTheCurrentInput(config.inputKey)}>
            <LinkText>
              <div className="flex flex-row">
                <div className="w-5 h-5">
                  <DocumentTextIcon />
                </div>
                Download list
              </div>
            </LinkText>
          </button>
        </div>
      </div>)}
  </div>
);