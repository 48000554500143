import { useRef, useState } from "react";

import { ReactComponent as EyeIcon } from "assets/icons/eye-icon.svg";
import { ReactComponent as EyeOffIcon } from "assets/icons/eye-off-line-icon.svg";

import InputFieldWithValidation, { IInputFieldWithValidationProps } from "components/InputFieldWithValidation";


export default function CVVInput({ name, validate = true, ...props }: { name?: string, validate?: boolean } & IInputFieldWithValidationProps) {
  const hiddenInputRef = useRef<HTMLInputElement>(null);

  const [ showCVV, setShowCVV ] = useState(false);

  const unformatCVV = (cvvStr: string, cursorPosition: number | null): [ string, number | null ] => {
    const nonNullCursorPosition = cursorPosition || 0;
    const cvvBeforeCursor = cvvStr.slice(0, nonNullCursorPosition);
    const numbersBeforeCursor = cvvBeforeCursor.replace(/[\D]/g, "");
    const realCursorPosition = nonNullCursorPosition - (cvvBeforeCursor.length - numbersBeforeCursor.length);

    return [ (numbersBeforeCursor + cvvStr.slice(nonNullCursorPosition).replace(/[\D]/g, "")).slice(0, 3), realCursorPosition ];
  };

  const formatCVV = (cvv: string | ReadonlyArray<string> | number | undefined, cursorPosition?: number | null): [ string, number | null ] => {
    if (typeof cvv !== "string") {
      return [ "", 0 ];
    } else {
      return [ cvv.slice(0, 3), cursorPosition || null ];
    }
  };


  return (
    <>
      <input type="hidden" name={ name || "card_cvv" } ref={ hiddenInputRef } />
      <InputFieldWithValidation
        { ...props }
        label="CVV"
        type={ showCVV ? "text" : "password" }
        placeholder="XXX"
        pattern={ validate ? "\\d{3}" : undefined }
        patternMismatchErrorMessage="Invalid CVV code"
        value=""
        formatValue={ formatCVV }
        unformatValue={ unformatCVV }
        unformattedInputRef={ hiddenInputRef }
        required={ validate }
        TrailingElement={
          showCVV ? (
            <EyeIcon
              className="w-5 h-5 cursor-pointer"
              onClick={ () => setShowCVV(false) }
            />
          ) : (
            <EyeOffIcon
              className="w-5 h-5 cursor-pointer"
              onClick={ () => setShowCVV(true) }
            />
          )
        }
      />
    </>
  );
};
