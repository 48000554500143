import { useCallback, useMemo, useState } from 'react';
import { CollectorType } from 'providers/HostedScrapingProvider';
import { useCustomerIO } from 'providers/CustomerIOProvider';
import { ApiPlaygroundSelectType } from './ApiPlaygroundSelectType';
import { ApiPlaygroundEditDetails } from './ApiPlaygroundEditDetails';
import { ApiCallConfig, newApiCallConfig } from './ApiPlaygroundTypes';

type NewApiRequestPage = 'chooseType' | 'editDetails';


/*
 * -----------------------------------------------------------------------------
 * This page is disabled. It automatically goes with editing the api call config
 * It is still useful because it sets the default values for the editor
 * -----------------------------------------------------------------------------
 */

const scrolltoTop = () => {
  document.getElementById('the-big-content')?.scroll(0,0);
}

export default function NewApiRequest() {
  const [page, setPage] = useState<NewApiRequestPage>('editDetails');
  const [apiCallType, setApiCallType] = useState<CollectorType>('async_urls');
  const customerIO = useCustomerIO();
  customerIO.page();

  const newApiRequestConfig: ApiCallConfig = useMemo(() => {
    return newApiCallConfig(apiCallType)
  }, [apiCallType]);

  const typeSelectedCallback = useCallback((selectedType: CollectorType) => {
    setApiCallType(selectedType);
    setPage('editDetails');
    scrolltoTop();
  }, [setApiCallType, setPage]);

  const backCallback = useCallback(() => {
    setPage('chooseType');
    scrolltoTop();
  }, [setPage] );

  switch (page) {
    case 'chooseType': return <ApiPlaygroundSelectType callback={typeSelectedCallback} />;
    case 'editDetails': return <ApiPlaygroundEditDetails newApiCallConfig={newApiRequestConfig} backCallback={backCallback} />;
  };
};
