import { ReactNode } from "react";

import Card from "components/Card";
import ExtLink from "components/ExtLink";


export function Title(
  {
    children
  }: {
    children: ReactNode;
  }
) {
  return <div className="font-normal text-base">{ children }</div>;
}

export function Bold(
  {
    children
  }: {
    children?: ReactNode;
  }
) {
  return <span className="font-semibold">{ children }</span>;
}

function FooterLink(
  {
    to,
    text,
  }: {
    to: string;
    text: string;
  }
) {
  return (
    <div className="flex justify-end text-sm underline">
      <ExtLink href={ to }>{ text }</ExtLink>
    </div>
  );
}

export default function ResourceCard(
  {
    title,
    linkHref,
    linkText = "Go to documentation",
  }: {
    title: ReactNode;
    linkHref: string;
    linkText?: string;
  }
) {
  return (
    <Card
      title={ title }
      footer={ <FooterLink to={ linkHref } text={ linkText } /> }
      className="pt-4 first:pt-0"
      size="SM"
      borderless
    />
  );
};
