import { DAY } from "./timeConstants";

export function parseDateLabels(data: any, resolution: any) {
  for (const entry of data) {
    entry.interval_date = new Date(entry.start_of_interval).getTime()
  }

  return data;
}

const formatDateToYMD = (d: Date) => d.toISOString().slice(0,10);

export function domainReportFileName() {
  const now = new Date();
  const sixtyDaysAgo = new Date(now.getTime() - (60 * DAY));
  const reportName = `scraperapi_domainreport_${ formatDateToYMD(sixtyDaysAgo) }_${ formatDateToYMD(now) }.csv`;
  return reportName;
}

