import type { ConfigProblem, ConfigProblemWithMessage } from "components/hostedScrapingValidators";

const breakAllClasses = "text-red-500 dark:text-error-500 break-all";
const baseClasses = "text-red-500 dark:text-error-500";

interface IErrorProblemsBoxParams {
  interestingProblems: ConfigProblem[];
  allProblems: ConfigProblemWithMessage[];
  breakAll?: boolean;
}

export const ErrorProblemsBox = ({interestingProblems, allProblems, breakAll = false}: IErrorProblemsBoxParams) => {
  const classes = breakAll ? breakAllClasses : baseClasses;
  const messages =
    allProblems
      .filter(p => interestingProblems.includes(p.problem))
      .flatMap(p => p.message || p.multipleMessages)
      .filter(Boolean);

  if (messages.length === 0) {
    return null;
  } else if (messages.length === 1) {
    return (<div className={classes}>{messages[0]}</div>);
  } else {
    return (
    <div className={classes}>
      <div className="mb-1">{messages[0]}</div>
      <div className="mb-1 text-sm">
        <ul>
          {messages.slice(1).map((m, i) => <li key={i}>{m}</li>)}
        </ul>
      </div>
    </div>);
  }
}
