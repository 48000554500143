import { Outlet } from "react-router-dom";

import { ReactComponent as ScraperAPIIllustration } from "assets/images/scraperapi-illustration.svg";

import TopLeftLogo from "components/TopLeftLogo";


export default function AuthLayout() {
  return (
    <div className="z-10 flex flex-col md:flex-row w-full min-h-full">
      <div className="flex-1">
        <TopLeftLogo content={ <Outlet/> }/>
      </div>
      <div className="relative flex-1">
        <div className="flex flex-col items-center justify-center w-full h-full bg-brandLightest dark:bg-primary-50">
          <ScraperAPIIllustration className="w-3/5 my-16 max-w-[640px]"/>
          <div className="flex flex-col text-2xl lg:text-3xl text-center mb-16 mx-10">
            <span>Web Scraping is complex.</span>
            <span>We make it simple. </span>
          </div>
        </div>
      </div>
    </div>
  );
};
