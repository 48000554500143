import { ReactNode } from "react";
import { cx } from "utils";


interface SpanLinkProps {
  onClick?: () => void;
  className?: string,
  children: ReactNode
}

export default function SpanLink({
  onClick,
  className,
  children
}: SpanLinkProps) {

  return (
    <span
      onClick={onClick}
      className={cx(className, "underline transition-colors text-gray dark:text-neutral-600 hover:text-gray-800 dark:hover:text-neutral-800 cursor-pointer")}
    >
      {children}
    </span>
  );

};
