import { ProjectConfigWithStats, InputConnectorConfig, OutputConfig, ListLiteralInputConfig, WebhookOutputConfig, ScrapingInterval, WebhookInputConfig } from '../../providers/HostedScrapingProvider/types';
import { inputTypeToTitleAndDescription } from "./CollectorInputs";
import { outputTypeToTitleAndDescription } from "./CollectorOutputs";
import { AccessWebhookInputURL } from './config-view-components/AccessWebhookInputURL';
import { BeatifulDownloadUploadedFile } from "./config-view-components/DownloadInputFile";
import CronExpression from "./CronExpression";


export type ConfigurationEntryIcon = 'round-thingy'|'link'|'equalizer';

type ConfigurationViewEntry = {
  icon: ConfigurationEntryIcon;
  title: string;
  component: any;
}

type ConfigurationView = {
  input: ConfigurationViewEntry[];
  output: ConfigurationViewEntry[];
  interval: ConfigurationViewEntry | undefined;
}

const inputView = (input: InputConnectorConfig): ConfigurationViewEntry[] => {
  const inputType = inputTypeToTitleAndDescription(input.type); // TODO: These should be in one file
  switch (input.type) {
    case 'upload_json_list':
    case 'upload_csv_list': return [
      { icon: 'link', title: 'URL:', component: (<BeatifulDownloadUploadedFile config={input} />) },
    ];
    case 'webhook_input': return [
      { icon: 'round-thingy', title: 'Webhook URL:', component: (AccessWebhookInputURL(input as WebhookInputConfig))},
    ];
    case 'list_literal': return [
      { icon: 'round-thingy', title: inputType.title, component: (<textarea className="w-full pt-0 border-transparent focus:border-transparent focus:outline-none focus:ring-0" style={{resize: 'none'}} readOnly={true} rows={3} value={(input as ListLiteralInputConfig).list}/>) },
    ];
    default: return [
      { icon: 'round-thingy', title: inputType.title, component: null}
    ]
  }
};

const outputView = (output: OutputConfig): ConfigurationViewEntry[] => {
  const outputType = outputTypeToTitleAndDescription(output.type); // TODO: These should be in one file
  switch (output.type) {
    case 'devnull': return [
      { icon: 'round-thingy', title: 'Output to', component: (<span>output.title</span>) },
    ];
    case 'save': return [
      { icon: 'round-thingy', title: 'Output to', component: (<span>Saved</span>)},
    ];
    case 'webhook': return [
      { icon: 'round-thingy', title: 'Output to', component: (<span>Webhook</span>) },
      { icon: 'link', title: 'Webhook URL', component: (<span>{(output as WebhookOutputConfig).url}</span>) },
    ];
    default: return [
      { icon: 'round-thingy', title: outputType.title, component: null}
    ]
  }
};

const schedulingView = (schedulingEnabled: boolean, interval: ScrapingInterval, cronExpression: string): ConfigurationViewEntry | undefined => {
  if (!schedulingEnabled) {
    return undefined;
  }

  return {
    icon: 'link',
    title: 'Interval',
    component: <><span>{schedulingInterval(interval).text}</span>{ interval === "cron" && (<CronExpression cronExpression={cronExpression}/>) }</>
  };
};


export const projectConfigToShow = (project: ProjectConfigWithStats): ConfigurationView => ({
  input: inputView(project.input),
  output: outputView(project.output),
  interval: schedulingView(project.enabled, project.scrapingInterval, project.cron)
});


export type ScrapingIntervalSetting = ScrapingInterval | 'scheduling_disabled' | 'just_scrape_once';

export const schedulingInterval = (interval: ScrapingIntervalSetting ): ({text: string, value: ScrapingIntervalSetting | undefined}) => {
  switch (interval) {
    case 'hourly': return { text: 'Hourly', value: 'hourly' };
    case 'daily': return { text: 'Daily', value: 'daily' };
    case 'weekly': return { text: 'Weekly', value: 'weekly' };
    case 'monthly': return { text: 'Monthly', value: 'monthly' };
    case 'custom': return { text: 'Custom', value: 'custom' };
    case 'cron': return { text: 'Cron ', value: 'cron' };
    case 'scheduling_disabled': return { text: 'Scheduling disabled', value: 'scheduling_disabled' };
    case 'just_scrape_once': return { text: 'Just scrape once ', value: 'just_scrape_once' };
    default: return { text: 'unknown', value: undefined };
  }
};

export const validSchedulingIntervals: ScrapingInterval[] = ['hourly', 'daily', 'weekly', 'monthly', 'cron'];
