// Source: https://bobbyhadz.com/blog/javascript-format-date-yyyy-mm-dd-hh-mm-ss
const padToNDigits = (n: number) =>  (num: number) => {
  return num.toString().padStart(n, '0');
}

const padTo2Digits = padToNDigits(2);
const padTo3Digits = padToNDigits(3);

export const formatDate = (date: Date|string|null|undefined): string => {

  if (date === null || date === undefined) {
    return '-';
  }

  const d: Date = (typeof date === 'string') ? new Date(date) : date;

  return (
    [
      d.getFullYear(),
      padTo2Digits(d.getMonth() + 1),
      padTo2Digits(d.getDate()),
    ].join('-') +
    ' ' +
    [
      padTo2Digits(d.getHours()),
      padTo2Digits(d.getMinutes()),
    ].join(':')
  );
}

// For tooltips
export const formatFullDate = (date: Date|string|null|undefined): string => {
  if (date === null || date === undefined) {
    return '-';
  }

  const d: Date = (typeof date === 'string') ? new Date(date) : date;

  return (
    [
      d.getFullYear(),
      padTo2Digits(d.getMonth() + 1),
      padTo2Digits(d.getDate()),
    ].join('-') +
    ' ' +
    [
      padTo2Digits(d.getHours()),
      padTo2Digits(d.getMinutes()),
      padTo2Digits(d.getSeconds()),
    ].join(':') +
    '.' + 
      padTo3Digits(d.getMilliseconds())
    );
}
