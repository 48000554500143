import { FeatureSwitch } from "components/FeatureSwitch/FeatureSwitches";
import { UserType } from "providers/UserProvider";


export default abstract class FeatureSwitchCheck<T> {

  protected readonly featureValue: T | undefined;


  public constructor(featureSwitch: FeatureSwitch) {
    this.featureValue = this.parseFeatureValue(process.env[featureSwitch]);
  }

  protected abstract parseFeatureValue(featureSwitchValue: string | undefined): T | undefined;

  public abstract check(user: UserType): boolean;

}
