import { useActionData } from "react-router-dom";

import TaggedText from "components/TaggedText";

import IRouterActionError, { ActionError } from "routes/dataroutes/IRouterActionError";


export default function useFormattedActionError(data?: IRouterActionError | any): string | JSX.Element | undefined {
  const actionData = useActionData() as IRouterActionError;
  const errorData: ActionError | undefined = (data as IRouterActionError)?.error || actionData?.error;

  if (errorData?.taggedMessage) {
    return <TaggedText message={ errorData.taggedMessage.message } linkStyle={ errorData.taggedMessage.linkStyle }/>;
  } else {
    return errorData?.message;
  }

};
