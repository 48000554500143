import { createContext, Suspense, useContext } from "react";
import { Await, defer, Outlet, useAsyncError, useRouteLoaderData } from "react-router-dom";

import scraperApi from "api";

import GlobalLoadingIndicator from "components/GlobalLoadingIndicator";

import { UserType } from "providers/UserProvider";

import NotFound from "routes/NotFound";


const UserContext = createContext<UserType | null>(null);

export const id = "user-data";

export function loader() {
  return defer({ userPromise: scraperApi.auth.me() });
}

function ErrorHandler() {
  const error = useAsyncError() as any;

  if ([
    "user_cannot_load_data",
    "user_not_found"
  ].includes(error?.error_code)
  ) {
    return (
      <UserContext.Provider value={ null }>
        <Outlet/>
      </UserContext.Provider>
    );
  }

  throw error;
}

export function Component() {
  const loaderData = useRouteLoaderData(id) as { userPromise: Promise<UserType> };

  return (
    <Suspense fallback={ <GlobalLoadingIndicator/> }>
      <Await
        resolve={ loaderData.userPromise }
        errorElement={ <ErrorHandler/> }
      >
        { (user) => (
          <UserContext.Provider value={ user }>
            <Outlet/>
          </UserContext.Provider>
        ) }
      </Await>
    </Suspense>
  );
}

export function ErrorBoundary() {
  return <NotFound />;
}

export function useUser() {
  return useContext(UserContext);
}
